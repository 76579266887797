import { CreditNote } from "../billing/credit-note.models";
import { Invoice } from "../billing/invoice-models";
import { IDownloadFile } from "../export-models";

export type BillingItem = Invoice | CreditNote;

export type DownloadBillingItemResponse = {
  billingItem: Invoice;
  file: IDownloadFile;
};

export type ExportPdfRequest = {
  billingItems: BillingItem[];
  itemType: BillingItemTypes;
};

export type DownloadStatus = {
  [key: string]: ExportStatusStrings;
};

export enum ExportStatusStrings {
  Loading = "loading",
  Success = "fulfilled",
  Failed = "rejected",
}

export enum BillingItemTypes {
  Invoice = "invoice",
  CreditNote = "credit note",
}
