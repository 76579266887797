import { Component, OnInit, ViewChild } from "@angular/core";
import { AddContactComponent } from "src/app/components/misc/pop-up/add-contact/add-contact.component";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import {
  ICompanyContactCategory,
  IRemoveContactDialogData,
  ICategoryColumn,
  categoryColumns,
  userColumns,
  IUserColumn,
  ContactType,
} from "./shared/models";
import { RemoveContactComponent } from "src/app/components/misc/pop-up/remove-contact/remove-contact.component";
import { CompanyContactUserFilters } from "src/app/components/misc/filters/filters";
import { cloneDeep } from "lodash-es";
import { CompanyService } from "./shared/company-service.service";
import { IServicenowUser } from "../users/users.model";
import { PermissionService } from "src/app/services/permissions.service";
import { MenuItem, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ToastService } from "src/app/services/toast.service";
import { LazyLoadEvent } from "primeng/api";
import { IsPaginatorState, TableReset } from "src/app/helpers/table.helper";
import { Table } from "primeng/table";
import { TableColumn } from "src/app/models/table";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { Paginator, PaginatorState } from "primeng/paginator";
import { CompanyAPIService } from "src/app/services/api/company/company.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { IUserProfile } from "../users/shared/user-models";

@Component({
  selector: "company-contacts",
  templateUrl: "company-contacts.component.html",
  styleUrls: ["company-contacts.component.scss"],
  providers: [DialogService, MessageService],
})
export class ContactsComponent implements OnInit {
  @ViewChild("dataTable", { static: true }) dataTable: Table;
  @ViewChild("categoryTable") categoryTable: Table;
  @ViewChild("tablePaginator") paginator: Paginator;

  userTableFilters = cloneDeep(CompanyContactUserFilters);

  contactGroups: ICompanyContactCategory[] = [];
  authorisationGroups: ICompanyContactCategory[] = [];

  categorySubject = new BehaviorSubject<ICompanyContactCategory[]>([]);
  category$ = this.categorySubject.asObservable();

  categoryTableLoading: boolean = false;

  userSubject = new BehaviorSubject(null);
  user$ = this.userSubject.asObservable();
  // contacts: ICompanyContactCategory[];
  selectedCategory!: ICompanyContactCategory;
  categoryCols!: ICategoryColumn[];
  categoryTableColumn: ICategoryColumn[];
  ref: DynamicDialogRef;
  totalRecords: number = 0;
  cols!: IUserColumn[];
  defaultSelectedColumns: string[] = [];
  tableFilters: LazyLoadEvent;
  selectedUsers: IServicenowUser[] = [];
  _selectedColumns!: IUserColumn[];

  userTableColumn: TableColumn[] = userColumns;
  firstLoad: boolean = true;
  user: IUserProfile;
  staticTable: Table;

  get disableAddUserIcon(): boolean {
    return (
      this.user.uTimicoPortalRole === "Admin" ||
      this.user.uTimicoPortalRole === "Digital Space Admin"
    );
  }

  get disabledAddUserButton(): boolean {
    if (this.selectedCategory?.name === "Emergency Contact") {
      return !(
        this.user.uTimicoPortalRole === "Admin" ||
        this.user.uTimicoPortalRole === "Digital Space Admin"
      );
    }
    return false;
  }

  menuItems: MenuItem[] = [
    {
      label: "Contact Group",
      command: () => this.switchTab(this.contactGroups),
    },
    {
      label: "Authorisation Group",
      command: () => this.switchTab(this.authorisationGroups),
    },
  ];

  activeMenuItem = this.menuItems[0];

  constructor(
    private companyService: CompanyService,
    public permissionService: PermissionService,
    private breadcrumbService: BreadcrumbService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private companyAPIService: CompanyAPIService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUserOrImpersonatedUser();
    const breadcrumbs: MenuItem[] = [
      { label: "Company" },
      { label: "Contact and Authorisation Groups" },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    await this.loadContacts();
    this.selectedCategory = this.categorySubject.value[0];
  }

  async switchTab(categories: ICompanyContactCategory[]) {
    this.categorySubject.next(categories);
    this.selectedCategory = this.categorySubject.value[0];
    await this.loadUsers(this.staticTable);
    this.reset(this.staticTable);
  }

  async loadContacts() {
    this.categoryTableLoading = true;
    this.categoryTableColumn = categoryColumns;

    this.authorisationGroups = (
      await firstValueFrom(this.companyAPIService.getContacts())
    )?.map((contact) => {
      return { ...contact, type: ContactType.authorisation };
    });

    this.contactGroups = (
      await firstValueFrom(this.companyAPIService.getContactTypes())
    )?.map((contact) => {
      return { ...contact, type: ContactType.contact };
    });

    this.categorySubject.next(this.contactGroups);
    this.categoryTableLoading = false;
  }

  async loadUsers(table: Table, event?: LazyLoadEvent | PaginatorState) {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }

    const isFirstLoad = this.firstLoad ? true : false;
    table.loading = true;
    this.userSubject.next([]);
    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(userColumns));
      this.firstLoad = false;
    }
    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.userTableColumn,
      currentPerPage,
      currentPage,
      event,
      { CategoryID: this.selectedCategory?.sysId }
    );
    const response =
      this.selectedCategory.type === ContactType.contact
        ? await this.companyService.loadUsersForContactCategory(
            serviceNowFilter
          )
        : await this.companyService.loadUsersForCategory(serviceNowFilter);
    this.totalRecords = response.overallQueryCount;
    this.userSubject.next(response.result);

    this.staticTable = table;
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    table.loading = false;
  }

  async handleCategorySelection(selectedCategory: ICompanyContactCategory) {
    if (selectedCategory.sysId !== null) {
      this.selectedCategory = selectedCategory;
      this.reset(this.staticTable);
      await this.loadUsers(this.staticTable);
    }
  }

  addContactButtonClicked() {
    this.showAddContactDialog(this.selectedCategory);
  }

  addContactIconClicked(category: ICompanyContactCategory) {
    this.showAddContactDialog(category);
  }

  showAddContactDialog(category: ICompanyContactCategory) {
    this.ref = this.dialogService.open(AddContactComponent, {
      contentStyle: { overflow: "auto" },
      showHeader: false,
      width: "70%",
      data: {
        categorySysID: category.sysId,
        categoryName: category.name,
        type: this.selectedCategory.type,
      },
    });
    this.ref.onClose.subscribe((result) => {
      if (result !== undefined && result) {
        this.handleCategorySelection(this.selectedCategory);
        this.toastService.showToastSuccess(category.name, "User(s) added.");
      }
    });
  }

  removeContactIconClicked(selectedUser) {
    const dialogData: IRemoveContactDialogData = {
      categorySysID: this.selectedCategory.sysId,
      categoryName: this.selectedCategory.name,
      userSysID: selectedUser.sysId,
      username: selectedUser.firstName + " " + selectedUser.lastName,
      type: this.selectedCategory.type,
    };

    this.ref = this.dialogService.open(RemoveContactComponent, {
      showHeader: false,
      data: dialogData,
    });
    this.ref.onClose.subscribe((result) => {
      if (result !== undefined && result) {
        this.handleCategorySelection(this.selectedCategory);
        this.toastService.showToastSuccess(
          "Remove user - ",
          " Operation completed."
        );
      } else {
        this.toastService.showToastWarning(
          "Remove user - ",
          " Operation cancelled."
        );
      }
    });
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = userColumns
  ) {
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
