import { Component, OnInit } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "device-history",
  templateUrl: "./device-history.component.html",
})
export class DeviceHistoryComponent implements OnInit {
  public deviceInfo = null;

  constructor(private deviceService: DeviceDetectorService) {
    this.detectDevice();
  }

  public detectDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  ngOnInit() {
    this.detectDevice();
  }
}
