import { Component, Input } from "@angular/core";
import { NotificationService } from "src/app/services/notification.service";
import { Notification } from "../../../service-hub/notifications/models/notification-models";
@Component({
  selector: "notification-details",
  templateUrl: "./notification-details.component.html",
  styleUrl: "./notification-details.component.scss",
})
export class NotificationDetailsComponent {
  @Input() data: Notification;
  notification: Notification;

  constructor(private notificationService: NotificationService) {}
  ngOnInit(): void {
    this.notification = this.data;
  }

  openNotification(notification: Notification) {
    this.notificationService.openNotification(notification);
  }
}
