import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { OutageService } from "src/app/services/api/outage/outage.service";

@Injectable({
  providedIn: "root",
})
export class ServiceNotificationsService {
  constructor(private outAgeService: OutageService) {}

  async loadServiceNotifications() {
    let serviceNotificationsFilter = [
      { Column: "UHidden", Value: ["false"] },
      { Column: "InstallStatus", Value: ["!=7"] },
    ];
    const serviceNotifications = await firstValueFrom(
      this.outAgeService.getBusinessServiceSubscriptions(
        serviceNotificationsFilter,
        0
      )
    );

    return serviceNotifications;
  }

  //TODO Add interface
  async toggleServiceSubscription(service) {
    await firstValueFrom(
      this.outAgeService.updateSubscription(service.sysId, service.subscribed)
    );
  }
}
