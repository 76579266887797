import {
  ChangeDetectorRef,
  Component,
  OnInit,
  viewChild,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { cloneDeep } from "lodash-es";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { IUserProfile } from "../../company/users/shared/user-models";
import { AuthService } from "src/app/services/auth/auth.service";
import { ChangeRequestService } from "src/app/services/api/change-request/change-request.service";
import { LazyLoadEvent } from "primeng/api";
import { MenuItem } from "primeng/api/menuitem";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import {
  IsPaginatorState,
  SetSelectedColumns,
  TableReset,
} from "src/app/helpers/table.helper";
import { ExportTable } from "../../products-and-services/science-logic/cmdb-devices/shared/export-devices";
import { Table } from "primeng/table";
import { TableColumn, TableColumnColor } from "src/app/models/table";
import {
  ExportFileName,
  ExportService,
  ExportTableEndpoint,
  ExportType,
} from "src/app/services/export.service";
import { IDownloadFile, ITableToFile } from "src/app/models/export-models";
import { ToastService } from "src/app/services/toast.service";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { Paginator, PaginatorState } from "primeng/paginator";
import { changeColumns } from "./change.columns";
import { GetChangeRequestsResponseItem } from "src/app/models/change/change.models";

@Component({
  selector: "changes",
  templateUrl: "changes.component.html",
  styleUrls: ["changes.component.scss"],
})
export class ChangesComponent implements OnInit {
  @ViewChild("dataTable") dataTable: Table;
  @ViewChild("tablePaginator") paginator: Paginator;
  change: any;
  user: IUserProfile;

  changesSubject = new BehaviorSubject(
    <Array<GetChangeRequestsResponseItem>>[]
  );
  changes$ = this.changesSubject.asObservable();
  totalRecords: number = 0;
  tableLoading: boolean = false;
  loading: boolean;
  companyToggle: boolean = true;
  hasCompanyToggle: boolean;
  tableFilters: LazyLoadEvent;
  firstLoad: boolean = true;
  cols: TableColumn[] = cloneDeep(changeColumns);
  _selectedColumns: TableColumn[] = [];
  staticTable: Table;

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }
  exportOptions = [
    {
      label: "All",
      value: "all",
      command: () => this.exportAll(),
    },
    {
      label: "In View",
      value: "in_view",
      command: () =>
        ExportTable(
          this.dataTable,
          this.changesSubject.value,
          this._selectedColumns,
          "all"
        ),
    },
  ];

  permissions = ["TICKETS_COMPANY_READ"];

  constructor(
    private router: Router,
    private authService: AuthService,
    private ChangeRequestService: ChangeRequestService,
    private breadcrumbService: BreadcrumbService,
    private toastService: ToastService,
    private exportService: ExportService,
    private cdr: ChangeDetectorRef
  ) {}
  async ngOnInit() {
    this.tableLoading = true;
    const breadcrumbs: MenuItem[] = [
      { label: "Change Requests", routerLink: "/secure/tickets/changes" },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.user = await this.authService.getUser();

    this.hasCompanyToggle = this.user.uTimicoPortalPermissions.includes(
      "TICKETS_COMPANY_READ"
    );
  }

  async loadchanges(
    table: Table,
    event?: LazyLoadEvent | PaginatorState
  ): Promise<void> {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }
    this.user = await this.authService.getUser();
    table.loading = true;
    const isFirstLoad = this.firstLoad ? true : false;

    this.changesSubject.next([]);

    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(changeColumns));
      this.firstLoad = false;
    }

    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event
    );

    if (!this.companyToggle) {
      serviceNowFilter.Filter.push({
        Column: "URequestedFor",
        Value: [this.user?.sysId],
      });
    }

    const response = await firstValueFrom(
      this.ChangeRequestService.getChangeRequests(serviceNowFilter)
    );
    this.totalRecords = response.overallQueryCount;
    this.changesSubject.next(response.results);
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    table.loading = false;
  }

  async onToggleChange(table: Table): Promise<void> {
    await this.loadchanges(table);
  }

  getTextColor(value: string): string {
    return value === "Completed Successful" ? "text-primary" : "text-gray-900";
  }

  handleRowClicked(event) {
    this.router.navigateByUrl("secure/tickets/change/" + event.sysId);
  }

  handleColumnChange(val: any[]) {
    this.cols = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  getColor(colors: TableColumnColor[], value: string) {
    return colors[value] ?? null;
  }

  defaultExport() {
    ExportTable(this.dataTable, this.changesSubject.value, this.cols, "all");
  }

  async exportAll() {
    this.toastService.showToastInfo(
      "Downloading - ",
      " We are just sorting your data out for you. This won't take long."
    );
    const serviceNowFilter = BuildServiceNowQuery(this.dataTable, this.cols);
    const fields = this._selectedColumns.map((item) => item.exportField);
    const endpoint: string = ExportTableEndpoint.ChangeRequests;

    const body: ITableToFile = {
      Filter: serviceNowFilter.Filter,
      ExportType: ExportType.Csv,
      CurrentPage: 0,
      Amount: 25,
      Pagination: false,
      ExportColumns: fields,
    };

    const downloadedFile: IDownloadFile =
      await this.exportService.exportTableToFile(endpoint, body);

    this.exportService.downloadFile(
      downloadedFile,
      ExportType.Csv,
      ExportFileName.changeRequests
    );
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = changeColumns
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter((column) => column?.default).map((column) => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
