import { Component } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "app-ticket-confirmation",
  templateUrl: "./ticket-confirmation.component.html",
  styleUrls: ["./ticket-confirmation.component.scss"],
})
export class TicketConfirmationComponent {
  //TODO Add type
  dialogData: any;
  constructor(
    public ref: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig
  ) {
    this.dialogData = this.dialogConfig.data;
  }

  cancelButton(): void {
    this.ref.close(false);
  }
  submitButton(): void {
    this.ref.close(true);
  }
}
