import { Component, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "edit-user-permissions",
  templateUrl: "edit-user-permissions.component.html",
  styleUrls: ["edit-user-permissions.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EditUserPermissions {
  loading: boolean = false;

  role: {
    sysId: string;
    name: string;
  };

  userPermissions: string[];

  permissionsForm = new FormGroup({
    rolePermissions: new FormControl([]),
    additionalPermissions: new FormControl([]),
  });

  constructor(
    public ref: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig
  ) {
    this.role = this.dialogConfig?.data?.role;
    this.userPermissions = this.dialogConfig?.data?.userPermissions;
  }

  async ngOnInit() {
    this.permissionsForm.get("additionalPermissions").setValue(
      this.userPermissions.map((userPermission) => {
        return {
          sysId: userPermission,
        };
      })
    );
    // const permissionOptions = await firstValueFrom(
    //   this.userService.getRolePermissionsWithAdditional(this.selectedRole.sysId)
    // );
  }

  submit() {
    const rolePermissions = this.permissionsForm.value.rolePermissions.map(
      (rolePermission) => rolePermission.sysId
    );
    const additionalPermissions =
      this.permissionsForm.value.additionalPermissions.map(
        (additionalPermission) => additionalPermission.sysId
      );
    this.ref.close([...rolePermissions, ...additionalPermissions]);
  }

  close(): void {
    this.ref.close();
  }
}
