// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .p-tabview-nav {
  display: flex !important;
}

.p-tabview-nav li {
  flex: 1;
}
 */`, "",{"version":3,"sources":["webpack://./src/app/components/service-hub/tickets/changes/single/change.component.scss"],"names":[],"mappings":"AAAA;;;;;;;EAAA","sourcesContent":["/* .p-tabview-nav {\n  display: flex !important;\n}\n\n.p-tabview-nav li {\n  flex: 1;\n}\n */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
