import { Component } from "@angular/core";
import { FormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { RequiredValidation } from "src/app/form-validation/validators";
import { DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "generate-api-credentials",
  templateUrl: "generate-api-credentials.component.html",
  styleUrls: ["generate-api-credentials.component.scss"],
})
export class GenerateApiCredentialsComponent {
  credentialsFormControl = this.fb.group({
    name: ["", Validators.required],
  });

  friendlyNameFormControl = new UntypedFormControl("", RequiredValidation);

  constructor(private fb: FormBuilder, public ref: DynamicDialogRef) {}

  submitButton() {
    this.ref.close(this.credentialsFormControl.get("name")?.value);
  }

  cancelButton(): void {
    this.ref.close();
  }
}
