import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Subject, firstValueFrom } from "rxjs";
import { cloneDeep } from "lodash-es";
import { ServiceNotificationsDialogComponent } from "src/app/components/misc/pop-up/service-notifications-dialog/service-notifications-dialog.component";
import { IService } from "./models/service-notifications-models";
import { ServiceNotificationsService } from "./shared/service-notifications-service.service";
import { OutageService } from "src/app/services/api/outage/outage.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { MenuItem } from "primeng/api";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { IUserProfile } from "../../../company/users/shared/user-models";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "service-status",
  templateUrl: "status.component.html",
  styleUrls: ["status.component.scss"],
  providers: [DialogService],
})
export class ServiceStatusComponent implements OnInit, OnDestroy {
  currentPage: number = 0;
  services: Array<any> = [];
  pageData: any;
  destroy$ = new Subject();
  serviceNotifications: Array<IService> = [];
  ref: DynamicDialogRef | undefined;
  loading: boolean = false;
  showNotifcationButton: boolean = false;
  user: IUserProfile;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private serviceNotificationsService: ServiceNotificationsService,
    private outAgeService: OutageService,
    public dialogService: DialogService,
    private breadcrumbService: BreadcrumbService,
    private authService: AuthService
  ) {}
  async ngOnInit() {
    this.user = await this.authService.getUser();
    this.showNotifcationButton = this.user.uTimicoPortalPermissions.includes(
      "PROFILE_NOTIFICATIONS_READ"
    );
    const breadcrumbs: MenuItem[] = [{ label: "Service Status" }];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.route.data.subscribe((v) => (this.pageData = v));
    this.loadServicesAndStatus();
    await this.loadServiceNotifications();
  }

  async loadServiceNotifications() {
    this.loading = true;
    this.serviceNotifications =
      await this.serviceNotificationsService.loadServiceNotifications();
    this.loading = false;
  }

  ngOnDestroy() {
    this.destroy$.next(void 0);
    this.destroy$.complete();
  }

  async loadServicesAndStatus() {
    if (this.pageData.authorised) {
      this.services = await firstValueFrom(
        this.outAgeService.getAuthorisedOutagesInformation()
      );
    } else {
      this.services = await firstValueFrom(
        this.outAgeService.getAuthorisedOutagesInformation()
      );
    }
  }

  getServiceLabel(service, state) {
    if (state == "Outage") {
      let outages = service.details.find((d) => d.type == "Outage");
      if (outages != undefined) {
        if (outages.open.length > 0) {
          return "Outage";
        } else {
          return this.getServiceLabel(service, "Planned Maintenance");
        }
      } else {
        return this.getServiceLabel(service, "Planned Maintenance");
      }
    } else if (state == "Planned Maintenance") {
      let plannedOutages = service.details.find(
        (d) => d.type == "Planned Maintenance"
      );
      if (plannedOutages != undefined) {
        if (plannedOutages.open.length > 0) {
          let active = false;
          plannedOutages.open.forEach((openPlannedOutage) => {
            if (this.checkIsActivePlanned(openPlannedOutage)) {
              active = true;
            }
          });
          if (active) {
            return "Planned Maintenance";
          } else {
            return "Upcoming Maintenance";
          }
        } else {
          return this.getServiceLabel(service, "Degradation");
        }
      } else {
        return this.getServiceLabel(service, "Degradation");
      }
    } else if (state == "Degradation") {
      let degradation = service.details.find((d) => d.type == "Degradation");
      if (degradation != undefined) {
        if (degradation.open.length > 0) {
          return "Degradation";
        } else {
          return this.getServiceLabel(service, "Good");
        }
      } else {
        return this.getServiceLabel(service, "Good");
      }
    } else {
      return "Good";
    }
  }

  getServiceIcon(service, state) {
    if (state == "Outage") {
      let outages = service.details.find((d) => d.type == "Outage");
      if (outages != undefined) {
        if (outages.open.length > 0) {
          return "exclamation-triangle";
        } else {
          return this.getServiceIcon(service, "Planned Maintenance");
        }
      } else {
        return this.getServiceIcon(service, "Planned Maintenance");
      }
    } else if (state == "Planned Maintenance") {
      let plannedOutages = service.details.find(
        (d) => d.type == "Planned Maintenance"
      );
      if (plannedOutages != undefined) {
        if (plannedOutages.open.length > 0) {
          return "exclamation-triangle";
        } else {
          return this.getServiceIcon(service, "Degradation");
        }
      } else {
        return this.getServiceIcon(service, "Degradation");
      }
    } else if (state == "Degradation") {
      let degradation = service.details.find((d) => d.type == "Degradation");
      if (degradation != undefined) {
        if (degradation.open.length > 0) {
          return "exclamation-triangle";
        } else {
          return this.getServiceIcon(service, "Good");
        }
      } else {
        return this.getServiceIcon(service, "Good");
      }
    } else {
      return "check-circle";
    }
  }

  onTabChanged(event) {
    let service = this.services.filter(
      (x) => x.businessService?.name === event.tab.textLabel
    )[0];
    localStorage.setItem("outage", JSON.stringify(service));
  }

  openNotificationDialog() {
    this.ref = this.dialogService.open(ServiceNotificationsDialogComponent, {
      width: "25%",
      showHeader: false,
      data: { serviceNotifications: this.serviceNotifications },
      contentStyle: { overflow: "auto" },
      baseZIndex: 10000,
      autoZIndex: true,
    });
  }

  checkIsActivePlanned(outageDetail) {
    var datePipe = new DatePipe("en-US");
    var today = datePipe.transform(Date.now(), "dd/MM/yyyy");
    var start = outageDetail.start.split(" ")[0];
    if (start < today) {
      return true;
    } else {
      return false;
    }
  }

  redirect() {
    window.open("https://portal.digitalspace.co.uk/login");
  }
}
