import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Table } from "primeng/table";
import { firstValueFrom, Observable, Subject, takeUntil } from "rxjs";
import { TableColumnColor } from "src/app/models/table";
import { ToastService } from "src/app/services/toast.service";
import { AddCommentComponent } from "../pop-up/add-comment/add-comment.component";
import { CommentColumns, ticketData } from "../shared/comments.model";

export enum CommentTypes {
  Incident = "incident",
  RequestedItem = "requestedItem",
  Case = "case",
  Order = "order",
  OrderLine = "orderLine",
}
@Component({
  selector: "app-comments",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.scss"],
  providers: [DialogService],
})
export class CommentsComponent implements OnInit {
  destroy$ = new Subject<any>();
  @Input() comments$: Observable<any>;
  @ViewChild("dataTable", { static: true }) dataTable: Table;
  @Output() addCommentEvent = new EventEmitter<string>();
  tableColumn = CommentColumns;
  ref: DynamicDialogRef;
  ticket: ticketData;
  comments: any;
  canAddComments: boolean;

  constructor(
    private dialogService: DialogService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.comments$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data) {
        this.comments = data.comments;
        this.canAddComments = data.canAddComments;
      }
    });
  }

  getChipColor(colors: TableColumnColor[], value: string): string {
    return value === "Expedite" ? "Expedite" : colors[value] ?? null;
  }

  async openAddComment() {
    this.ref = this.dialogService.open(AddCommentComponent, {
      width: "32%",
      contentStyle: { overflow: "auto" },
      showHeader: false,
    });

    const result = await firstValueFrom(this.ref.onClose);

    if (result) {
      this.addCommentEvent.emit(result);
      return;
    }
    this.toastService.showToastWarning("Adding Comment", "Operation Cancelled");
  }
}
