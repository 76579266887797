// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* width: 100%;
display: inline-flex;
position: relative;
height: auto; */`, "",{"version":3,"sources":["webpack://./src/app/components/service-hub/products-and-services/domains/domain-admin/domain-admin.component.scss"],"names":[],"mappings":"AAAA;;;eAAA","sourcesContent":["/* width: 100%;\ndisplay: inline-flex;\nposition: relative;\nheight: auto; */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
