import { TableColumn } from "src/app/models/table";

export enum IncidentTableFields {
  number = "InvoiceNo",
  tag = "InvoiceTag",
  date = "InvoiceDate",
  sitID = "SiteId",
  net = "Net",
  vat = "Vat",
  account = "SiteName",
}

export type IInvoice = {
  company: any;
  companyId: number;
  date: Date;
  gross: number;
  invoiceId: number;
  invoiceNo: string;
  net: number;
  siteFullName: string;
  siteId: string;
  siteName: string;
  type: string;
  vat: number;
};

export type IInvoiceColumn = {
  field: string;
  header?: string;
  default: boolean;
  display?: any;
  filter?: {
    type: string;
    values: { label: string; value: string }[];
  };
  checkBoxVisible?: boolean;
  showSortIcon: boolean;
};

export const invoiceColumns: TableColumn[] = [
  {
    field: "invoiceNo",
    header: "Number",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    disabled: true,
    serviceNowField: IncidentTableFields.number,
  },
  {
    field: "date",
    header: "Date",
    display: { type: "date" },
    filter: {
      type: "dateRange",
    },
    showSortIcon: true,
    default: true,
    sortColumnOrder: 1,
    serviceNowField: IncidentTableFields.date,
  },
  {
    field: "invoiceTag",
    header: "Invoice Tag",
    display: { type: "text" },
    showSortIcon: true,
    default: false,
    serviceNowField: IncidentTableFields.tag,
  },
  {
    field: "net",
    header: "Net",
    display: { type: "number" },
    showSortIcon: true,
    default: true,
    serviceNowField: IncidentTableFields.net,
  },
  {
    field: "vat",
    header: "Vat",
    display: { type: "number" },
    showSortIcon: true,
    default: true,
    serviceNowField: IncidentTableFields.vat,
  },
  {
    field: "siteFullName",
    header: "Account",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: IncidentTableFields.account,
  },
];
