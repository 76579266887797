import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "change-main-details",
  templateUrl: "./main-details.component.html",
  styleUrls: ["../single/change.component.scss"],
})
export class MainDetailsComponent implements OnInit {
  @Input("change") change: any;

  constructor() {}
  ngOnInit() {}
}
