import { Component } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { RoleManagementService } from "src/app/components/service-hub/company/roles/shared/role-management.service";
import {
  IRolePermissions,
  ISelectedRole,
} from "src/app/components/service-hub/company/roles/shared/role-models";

@Component({
  selector: "app-role-information",
  templateUrl: "./role-information.component.html",
  styleUrls: ["./role-information.component.scss"],
})
export class RoleInformationDialogComponent {
  permissionsForSelectedRole: IRolePermissions[];
  selectedRole: any;
  roleInfo: ISelectedRole;
  loading: boolean = true;

  constructor(
    public roleManagementService: RoleManagementService,
    private dialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.selectedRole = await this.dialogConfig.data.record;
    this.roleInfo = this.roleManagementService.selectedRole;
    this.loadPermissions();
  }

  async loadPermissions() {
    this.permissionsForSelectedRole =
      await this.roleManagementService.getPermissionsForSelectedRole(
        this.selectedRole.sysId
      );
    this.loading = false;
  }

  getColumnLength(): number {
    if (this.permissionsForSelectedRole) {
      if (this.permissionsForSelectedRole.length % 2 === 0) {
        return this.permissionsForSelectedRole.length / 2;
      } else {
        return (this.permissionsForSelectedRole.length + 1) / 2;
      }
    }
  }
  closelButton(): void {
    this.ref.close();
  }
}
