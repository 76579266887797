import { Component } from "@angular/core";
import { MenuItem } from "primeng/api/menuitem";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";

@Component({
  selector: "cookies-policy",
  templateUrl: "cookies-policy.component.html",
  styleUrls: ["cookies-policy.component.scss"],
})
export class CookiesPolicyComponent {
  constructor(private breadcrumbService: BreadcrumbService) {}
  ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      { label: "Cookies Policy", routerLink: "/" },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }
}
