// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .p-datatable .p-datatable-wrapper {
  min-height: 13rem !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/misc/pop-up/filters/filter-dropdown/prime-ng-filters/add-edit-filters/add-edit-filters.component.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF","sourcesContent":["::ng-deep .p-datatable .p-datatable-wrapper {\n  min-height: 13rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
