import { TableColumn, TableSort } from "src/app/models/table";

export interface ICDRDataResponseItem {
  callType: string;
  callDest: string;
  callDate: Date;
  custCli: string;
  otherPartyNo: string;
  sellingDuration: number;
  uploadBytes: number;
  downloadBytes: number;
  totalBytes: number;
  billCost: number;
  siteID: number;
  companyID: number;
  siteName: number;
  subsite: string;
  gsmCode: string;
}

export enum unityField {
  callDate = "CallDate",
  type = "CallType",
  destination = "CallDest",
  custCli = "CustCli",
  number = "OtherPartyNo",
  duration = "SellingDuration",
  mb = "Downloadbytes",
  cost = "BillCost",
  gsmCode = "GSMCode",
  subsite = "SubSite",
}

enum exportField {
  callDate = "callDate",
  type = "type",
  destination = "destination",
  cli = "cli",
  custCli = "CustCli",
  number = "number",
  duration = "duration",
  mb = "mb",
  cost = "cost",
  gsmCode = "gsmCode",
  subsite = "subsite",
}

export interface IUsageColumn {
  field: string;
  header: string;
  display?: any;
  showSortIcon: boolean;
  default: boolean;
  disabled?: boolean;
  serviceNowField: string;
}

export const usageColumns: TableColumn[] = [
  {
    field: "callDate",
    header: "Call Date",
    display: { type: "date" },
    filter: {
      type: "dateRange",
    },
    showSortIcon: true,
    default: true,
    disabled: true,
    sortColumnOrder: 1,
    sortOrder: TableSort.desc,
    serviceNowField: unityField.callDate,
    exportField: exportField.callDate,
  },
  {
    field: "callType",
    header: "Type",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    disabled: false,
    serviceNowField: unityField.type,
    exportField: exportField.type,
  },
  {
    field: "custCli",
    header: "CLI",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    disabled: false,
    serviceNowField: unityField.custCli,
    exportField: exportField.custCli,
  },
  {
    field: "otherPartyNo",
    header: "Number",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    disabled: false,
    serviceNowField: unityField.number,
    exportField: exportField.number,
  },
  {
    field: "sellingDuration",
    header: "Duration (s)",
    display: { type: "number" },
    showSortIcon: true,
    default: true,
    disabled: false,
    serviceNowField: unityField.duration,
    exportField: exportField.destination,
  },
  {
    field: "billCost",
    header: "Cost",
    display: { type: "currency" },
    showSortIcon: true,
    default: true,
    disabled: false,
    serviceNowField: unityField.cost,
    exportField: exportField.cost,
  },
  {
    field: "callDest",
    header: "Destination",
    display: { type: "number" },
    showSortIcon: true,
    default: false,
    disabled: false,
    serviceNowField: unityField.destination,
    exportField: exportField.duration,
  },
  {
    field: "totalBytes",
    header: "MB",
    display: { type: "number" },
    showSortIcon: true,
    default: false,
    disabled: false,
    serviceNowField: unityField.mb,
    exportField: exportField.mb,
  },
  {
    field: "gsmCode",
    header: "GSM Code",
    display: { type: "text" },
    showSortIcon: true,
    default: false,
    disabled: false,
    serviceNowField: unityField.gsmCode,
    exportField: exportField.gsmCode,
  },
  {
    field: "subsite",
    header: "Cost Centre",
    display: { type: "text" },
    showSortIcon: true,
    default: false,
    disabled: false,
    serviceNowField: unityField.subsite,
    exportField: exportField.subsite,
  },
];
