import { Component, OnInit, ViewChild } from "@angular/core";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { ILoginHistory } from "../shared/models/account-models";
import { cloneDeep } from "lodash-es";
import { LoginHistoryColumns } from "../shared/models/login-history.models";
import { DialogService } from "primeng/dynamicdialog";
import { Table } from "primeng/table";
import { LazyLoadEvent } from "primeng/api";
import { Filters, TableColumn } from "src/app/models/table";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { UserService } from "src/app/services/api/user/user.service";
import { TableReset } from "src/app/helpers/table.helper";
import { Paginator } from "primeng/paginator";

@Component({
  selector: "login-history",
  templateUrl: "./login-history.component.html",
  providers: [DialogService],
})
export class LoginHistoryComponent implements OnInit {
  @ViewChild("dataTable") dataTable: Table;
  @ViewChild("tablePaginator") paginator: Paginator;
  loginSubject = new BehaviorSubject(null);
  login$ = this.loginSubject.asObservable();
  logins: Array<ILoginHistory>;
  currentPage: number = 0;
  rowsPerPage: number = 20;
  columns: TableColumn[] = LoginHistoryColumns;
  totalRecords: number = 0;
  firstLoad: boolean = true;

  constructor(private userService: UserService) {}

  ngOnInit() {}

  async loadHistory(table, event: LazyLoadEvent) {
    const isFirstLoad = this.firstLoad ? true : false;
    table.loading = true;
    // Reset items to empty array to ensure loader shows
    this.loginSubject.next([]);

    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(LoginHistoryColumns));
      this.firstLoad = false;
    }

    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.columns,
      currentPerPage,
      currentPage,
      event
    );
    const loginHistory = await firstValueFrom(
      await this.userService.getLoginHistory(serviceNowFilter)
    );

    this.loginSubject.next(loginHistory.result);
    this.totalRecords = loginHistory.overallQueryCount;
    table.loading = false;
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = LoginHistoryColumns,
    additionalFilters?: Filters
  ) {
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
