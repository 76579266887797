import { Component, OnInit, ViewChild } from "@angular/core";
import { cloneDeep } from "lodash-es";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { CrudColumns } from "./company-crud.models";
import { DialogService } from "primeng/dynamicdialog";
import { TableReset } from "../../../../helpers/table.helper";
import { Table } from "primeng/table";
import { LazyLoadEvent } from "primeng/api";
import { Filters, TableColumn } from "src/app/models/table";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { LogsService } from "src/app/services/api/logs/logs.service";
import { Paginator } from "primeng/paginator";

@Component({
  selector: "user-crud-activity",
  templateUrl: "user-crud-activity.component.html",
  styleUrls: ["user-crud-activity.component.scss"],
  providers: [DialogService],
})
export class UserActivityComponent implements OnInit {
  @ViewChild("dt1") dt1: Table;
  @ViewChild("tablePaginator") paginator: Paginator;
  logSubject = new BehaviorSubject(null);
  log$ = this.logSubject.asObservable();
  logs: Array<any>;
  columns: TableColumn[] = CrudColumns;
  defaultSelectedColumns: string[] = [];
  totalRecords: number = 0;
  firstLoad: boolean = true;

  constructor(private logsService: LogsService) {}

  ngOnInit() {}

  async getLogs(table, event: LazyLoadEvent) {
    const isFirstLoad = this.firstLoad ? true : false;
    table.loading = true;
    // Reset items to empty array to ensure loader shows
    this.logSubject.next([]);

    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(CrudColumns));
      this.firstLoad = false;
    }

    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.columns,
      currentPerPage,
      currentPage,
      event
    );

    const logs = await firstValueFrom(
      this.logsService.getUserLogs(serviceNowFilter)
    );

    this.logSubject.next(logs.result);
    this.totalRecords = logs.overallQueryCount;
    table.loading = false;
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = CrudColumns
  ) {
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
