import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "logout",
  templateUrl: "logout.component.html",
})
export class LogoutComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.signOut();
  }

  async signOut() {
    try {
      this.authService.logout();
    } catch (error) {
      console.error("error signing out: ", error);
    }
  }
}
