import { Component, Inject } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "show-api-credentials",
  templateUrl: "show-api-credentials.component.html",
  styleUrls: ["show-api-credentials.component.scss"],
})
export class ShowApiCredentialsComponent {
  dialogData: any;
  constructor(
    private dialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.dialogData = this.dialogConfig.data;
  }

  cancelButton(): void {
    this.ref.close(false);
  }
}
