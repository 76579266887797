import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "change-impact",
  templateUrl: "./change-impact.component.html",
  styleUrls: ["../single/change.component.scss"],
})
export class ChangeImpactComponent implements OnInit {
  @Input("change") change: any;

  constructor() {}
  ngOnInit() {}
}
