import { Inject, Injectable } from "@angular/core";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private apiRequest: ApiRequest) {}

  getWidgets() {
    const requestProps: IRequestOptions = {
      endpoint: "Dashboard/GetWidgets",
    };
    return this.apiRequest.request(requestProps);
  }

  getGraphs(graph: any) {
    const requestProps: IRequestOptions = {
      endpoint: "Dashboard/GetGraphs",
      body: graph,
    };
    return this.apiRequest.request(requestProps);
  }

  graphData(graph: any) {
    const requestProps: IRequestOptions = {
      endpoint: graph,
    };
    return this.apiRequest.request(requestProps);
  }
}
