import { Component } from '@angular/core';

@Component({
    selector: 'menu-bar-info',
    templateUrl: 'menu-info.component.html',
    styleUrls: ['menu-info.component.scss']
})
export class MenuInfoComponent {

}
