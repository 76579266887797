import { Component, Inject, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { INumbersList } from "src/app/components/service-hub/products-and-services/telephony/models/telephony-models";

@Component({
  selector: "app-view-numbers",
  templateUrl: "./view-numbers.component.html",
  styleUrls: ["./view-numbers.component.scss"],
})
export class ViewNumbersComponent {
  mobileNumbersData: INumbersList;
  constructor(
    private dialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.mobileNumbersData = this.dialogConfig.data;
  }

  closelButton(): void {
    this.ref.close();
  }
}
