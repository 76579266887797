import { Injectable } from "@angular/core";
import { MenuItem } from "primeng/api";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BreadcrumbService {
  private breadcrumbs = new BehaviorSubject<MenuItem[]>([]);
  breadcrumbs$ = this.breadcrumbs.asObservable();

  constructor() {}

  setBreadcrumbs(data: MenuItem[]): void {
    this.breadcrumbs.next(
      data.map((breadcrumb) => {
        return {
          ...breadcrumb,
          // target: breadcrumb.target ? breadcrumb.target : "_self",
        };
      })
    );
  }

  clearBreadcrumbs() {
    this.breadcrumbs.next([]);
  }
}
