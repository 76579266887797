import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { BehaviorSubject, Subject, firstValueFrom } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { cloneDeep } from "lodash-es";
import { OutageService } from "src/app/services/api/outage/outage.service";
import { DateTime } from "luxon";
import {
  SetMultiSelectColumnValues,
  SetSelectedColumns,
  TableReset,
} from "../../../../../helpers/table.helper";
import { TableColumn, TableColumnColor } from "src/app/models/table";
import { Table } from "primeng/table";
import { outageColumns } from "../../../../../models/outages-model";
import { ViewChild } from "@angular/core";

@Component({
  selector: "service-information",
  templateUrl: "information.component.html",
  styleUrls: [
    "information.component.scss",
    "../../../../global/menu/menu.component.scss",
  ],
})
export class ServiceInformationComponent implements OnInit, OnDestroy {
  @ViewChild("dataTable") dataTable: Table;
  @Input("service") service: any;
  @Input() tableColumns: TableColumn[];
  pageData: any;
  outagesSubject = new BehaviorSubject<any[]>([]);
  outages$ = this.outagesSubject.asObservable();
  outages: Array<any>;
  destroy$ = new Subject();
  loading: boolean = false;

  cols: TableColumn[] = cloneDeep(outageColumns);

  _selectedColumns: TableColumn[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private outAgeService: OutageService
  ) {}

  ngOnInit() {
    this.loadServices();
  }

  async loadServices() {
    this.loading = true;
    this.route.data
      .pipe(takeUntil(this.destroy$))
      .subscribe((v) => (this.pageData = v));
    if (this.service != null) {
      let outages: any = [];

      //TODO: review to see if we're still using this check
      if (this.pageData.authorised) {
        outages = await firstValueFrom(
          this.outAgeService.getAuthorisedOutageByService(
            this.service.businessService.sysId
          )
        );
      } else {
        outages = await firstValueFrom(
          this.outAgeService.getOutageByService({
            Column: "UCallerSysId",
            Value: [this.service.businessService.sysId],
          })
        );
      }
      outages = outages.map((outage) => {
        if (!outage.start) outage.start = "";
        if (!outage.end) outage.end = "";

        let start = DateTime.fromFormat(
          outage.start,
          "dd/MM/yyyy HH:mm"
        ).setZone(DateTime.local().zoneName);
        if (!start.isValid) {
          start = DateTime.fromFormat(outage.start, "dd/MM/yyyy").setZone(
            DateTime.local().zoneName
          );
        }
        let end = DateTime.fromFormat(outage.end, "dd/MM/yyyy HH:mm").setZone(
          DateTime.local().zoneName
        );
        if (!end.isValid) {
          end = DateTime.fromFormat(outage.end, "dd/MM/yyyy").setZone(
            DateTime.local().zoneName
          );
        }
        return {
          ...outage,
          start: start.isValid ? start.toJSDate() : null,
          end: end.isValid ? end.toJSDate() : null,
        };
      });
      this.cols = SetMultiSelectColumnValues(outageColumns, outages);
      this.outagesSubject.next(outages);
      this.outages = outages;
      this.loading = false;
    }
  }

  handleRowClicked(event) {
    if (this.pageData.authorised) {
      this.router.navigateByUrl(
        "secure/products-and-services/service/" +
          event.name.toLowerCase() +
          "/" +
          event.sysId
      );
    } else {
      this.router.navigateByUrl(
        "service/" + event.name.toLowerCase() + "/" + event.sysId
      );
    }
  }

  formatData() {
    this.service.details.forEach((element) => {
      element.open.forEach((open) => {
        this.outages.push(open);
      });

      element.closed.forEach((closedEle) => {
        this.outages.push(closedEle);
      });
    });
  }

  formatUkDate(dateStr) {
    var date = dateStr.substr(0, 10);
    var time = dateStr.substr(10, 5);
    var dateSplit = date.split("/");
    var timeSplit = time.split(":");
    return new Date(
      dateSplit[2],
      dateSplit[1] - 1,
      dateSplit[0],
      timeSplit[0],
      timeSplit[1]
    );
  }

  get sortedClosed() {
    return this.outages.sort((a, b) => {
      if (this.formatUkDate(a.start) < this.formatUkDate(b.start)) {
        return 1;
      }

      if (this.formatUkDate(a.start) > this.formatUkDate(b.start)) {
        return -1;
      }

      return 0;
    });
  }

  redirect() {
    window.open("https://portal.digitalspace.co.uk/login");
  }

  getServiceLabel(outage) {
    if (outage.type == "") {
      return "Good";
    } else if (outage.type == "Outage") {
      return "Outage";
    } else if (outage.type == "Degradation") {
      return "Degradation";
    } else if (outage.type == "Intermittent Service") {
      return "Intermittent Service";
    } else {
      var datePipe = new DatePipe("en-US");
      var today = datePipe.transform(Date.now(), "dd/MM/yyyy");
      var start = outage.start;
      if (
        outage.type == "Planned Maintenance" &&
        outage.state == "Closed Complete"
      )
        return "Completed Maintenance";
      if (start < today) {
        return "Planned Maintenance";
      } else {
        return "Upcoming Maintenance";
      }
    }
  }

  checkIsActivePlanned(service) {
    if (
      service.outages[0].state == "Open" &&
      service.outages[0].type == "Planned Maintenance" &&
      service.outages[0].start != ""
    ) {
      var datePipe = new DatePipe("en-US");
      var today = datePipe.transform(Date.now(), "dd/MM/yyyy");
      var start = service.outages[0].start;
      if (start < today) {
        return true;
      } else {
        return false;
      }
    } else {
      return null;
    }
  }

  getServiceLabelColour(service, state) {
    if (state == "Outage") {
      let outages = service.details.find((d) => d.type == "Outage");
      if (outages != undefined) {
        if (outages.open.length > 0) {
          return "danger";
        } else {
          return this.getServiceLabelColour(service, "Planned Maintenance");
        }
      } else {
        return this.getServiceLabelColour(service, "Planned Maintenance");
      }
    } else if (state == "Planned Maintenance") {
      let plannedOutages = service.details.find(
        (d) => d.type == "Planned Maintenance"
      );
      if (plannedOutages != undefined) {
        if (plannedOutages.open.length > 0) {
          let active = false;
          plannedOutages.open.forEach((openPlannedOutage) => {
            if (this.checkIsActivePlanned(openPlannedOutage)) {
              active = true;
            }
          });
          if (active) {
            return "danger";
          } else {
            return "warning";
          }
        } else {
          return this.getServiceLabelColour(service, "Degradation");
        }
      } else {
        return this.getServiceLabelColour(service, "Degradation");
      }
    } else if (state == "Degradation") {
      let degradation = service.details.find((d) => d.type == "Degradation");
      if (degradation != undefined) {
        if (degradation.open.length > 0) {
          return "warning";
        } else {
          return this.getServiceLabelColour(service, "Good");
        }
      } else {
        return this.getServiceLabelColour(service, "Good");
      }
    } else {
      return "primary";
    }
  }

  getColor(colors: TableColumnColor[], value: string) {
    return colors[value] ?? null;
  }

  getColorMetric(value: number) {
    if (value >= 95) return "danger";
    if (value >= 76 && value <= 94) return "warning";
    return "success";
  }
  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = outageColumns
  ) {
    this.loadServices();
    TableReset(table, columns, {
      firstLoad,
    });
  }

  ngOnDestroy() {
    this.destroy$.next(void 0);
    this.destroy$.complete();
  }
}
