import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { CostCentreService } from "src/app/services/cost-centre-service";
import { cloneDeep } from "lodash-es";
import { ProductAPIService } from "src/app/services/api/product/product.service";
import { MenuItem } from "primeng/api";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { LazyLoadEvent } from "primeng/api";
import { Table } from "primeng/table";
import {
  IsPaginatorState,
  SetSelectedColumns,
  TableReset,
} from "src/app/helpers/table.helper";
import { editCostCenterColumns } from "../models/cost-users.models";
import { ICostCentre } from "./shared/models";
import { TableColumn } from "src/app/models/table";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { Paginator, PaginatorState } from "primeng/paginator";
import { ProductCategories } from "../models/billing-models";

@Component({
  selector: "app-costcentres-users",
  templateUrl: "./costcentres-users.component.html",
  styleUrls: ["./costcentres-users.component.scss"],
})
export class CostcentresUsersComponent implements OnInit {
  @ViewChild("dataTable", { static: true }) dataTable: Table;
  @ViewChild("tablePaginator") paginator: Paginator;
  costCentreAndUserItems: Array<any>;
  costCentreAndUserRecordsSubject = new BehaviorSubject(null);
  costCentreAndUserItems$ = this.costCentreAndUserRecordsSubject.asObservable();
  siteId: string;
  cols: TableColumn[] = cloneDeep(editCostCenterColumns);
  _selectedColumns: TableColumn[] = [];
  totalRecords: number = 0;
  firstLoad: boolean = true;
  tableFilters: LazyLoadEvent;
  selectedCostCentres: ICostCentre[] = [];

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  constructor(
    private router: Router,
    private productService: ProductAPIService,
    public costCentreService: CostCentreService,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute
  ) {
    this.siteId = this.route?.snapshot?.params?.siteId;
  }

  ngOnInit(): void {
    const breadcrumbs: MenuItem[] = [
      {
        label: "Billing Overview",
        routerLink: `/secure/billing/${this.siteId}/overview`,
      },
      { label: "Cost Centres and Users" },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }

  async loadTableData(table: Table, event?: LazyLoadEvent | PaginatorState) {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }
    const isFirstLoad = this.firstLoad ? true : false;
    table.loading = true;
    this.costCentreAndUserRecordsSubject.next([]);
    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(editCostCenterColumns));
      this.firstLoad = false;
    }
    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event,
      { SiteID: this.siteId }
    );

    const response = await firstValueFrom(
      this.productService.getProduct(serviceNowFilter)
    );
    this.costCentreAndUserRecordsSubject.next(response.result);
    this.totalRecords = response.overallQueryCount;
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    table.loading = false;
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = editCostCenterColumns
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter((column) => column?.default).map((column) => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }

  openEditCostCentres(): void {
    this.costCentreService.costCentre = this.selectedCostCentres;
    const route =
      this.costCentreService.costCentre.length === 1
        ? `/secure/billing/${this.siteId}/costcentres-users/amend/individual`
        : `/secure/billing/${this.siteId}/costcentres-users/amend/group`;

    this.router.navigate([route]);
  }
}
