import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NotificationService } from "src/app/services/notification.service";
import { AddCommentComponent } from "src/app/components/misc/pop-up/add-comment/add-comment.component";
import { FileManagerComponent } from "src/app/components/misc/pop-up/file-manager/file-manager.component";
import { ViewFilesComponent } from "src/app/components/misc/pop-up/view-files/view-files.component";
import { TicketConfirmationComponent } from "src/app/components/misc/pop-up/ticket-confirmation/ticket-confirmation.component";
import { FileManagerService } from "src/app/components/misc/pop-up/file-manager/file-manager.service";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { FileService } from "src/app/services/api/file/file.service";
import { IncidentService } from "src/app/services/api/incident/incident.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { MenuItem } from "primeng/api/menuitem";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { Message } from "primeng/api";
import { AuthService } from "src/app/services/auth/auth.service";
import { IUserProfile } from "../../../company/users/shared/user-models";
import {
  ticketData,
  ticketType,
} from "src/app/components/misc/shared/comments.model";
import { TableNames } from "src/app/models/servicenow.model";
import { DateTime } from "luxon";
import { GetSingleIncidentResponse } from "src/app/models/incident/incident.models";
import { ToastService, ToastType } from "src/app/services/global/toast.service";
import {
  GenericApiErrorMessage,
  IsDisplayableException,
} from "src/app/helpers/error.helper";
import {
  CommentResponse,
  CreateCommentRequest,
  TicketComment,
} from "src/app/models/comments/comment.models";
import { ExpediteIncidentComponent } from "src/app/components/misc/pop-up/expedite-incident/expedite-incident.component";
import { CommentTypes } from "src/app/components/misc/comments/comments.component";
import { ProvideIncidentInformationComponent } from "src/app/components/misc/pop-up/provide-incident-information/provide-incident-information.component";

@Component({
  selector: "incident",
  templateUrl: "incident.component.html",
  styleUrls: ["incident.component.scss"],
  providers: [DialogService],
})
export class SingleIncidentComponent implements OnInit {
  ref: DynamicDialogRef;
  loading: boolean = false;
  user: IUserProfile;
  isImpersonating: boolean = false;
  sysId: string;
  incident: GetSingleIncidentResponse;
  ticketSubject = new BehaviorSubject<ticketData | null>(null);
  ticket$ = this.ticketSubject.asObservable();
  attachmentFiles: any = [];
  commentSubject = new BehaviorSubject(null);
  comments$ = this.commentSubject.asObservable();
  canAddComments: boolean = false;
  canUpdateTicket: boolean = false;
  CommentTypes = CommentTypes;
  hasCompanyServiceManager: boolean = false;
  hasBreachedSLA: boolean = false;
  providingInformation: boolean = false;

  messages: Message[] | undefined = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notifications: NotificationService,
    public fileManagerService: FileManagerService,
    private fileService: FileService,
    private incidentService: IncidentService,
    private dialogService: DialogService,
    private breadcrumbService: BreadcrumbService,
    private authService: AuthService,
    private toastService: ToastService
  ) {}

  get canExpediteIncident(): boolean {
    return (
      this.hasCompanyServiceManager &&
      this.canUpdateTicket &&
      !this.hasBreachedSLA
    );
  }

  get canEscalateIncident(): boolean {
    return (
      this.hasCompanyServiceManager &&
      this.canUpdateTicket &&
      this.hasBreachedSLA
    );
  }

  async ngOnInit() {
    this.user = await this.authService.getUserOrImpersonatedUser();
    this.isImpersonating = this.authService.isImpersonating();
    this.canUpdateTicket =
      this.user.uTimicoPortalPermissions.includes("TICKETS_INCIDENTS_UPDATE") ||
      this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_UPDATE");

    this.hasCompanyServiceManager = !!this.user.companyServiceManager;
    this.sysId = this.activatedRoute.snapshot.params["id"];

    this.providingInformation =
      this.activatedRoute.snapshot.queryParams["provideInformation"] &&
      this.canUpdateTicket;

    this.loading = true;
    await this.loadIncident();
    this.loading = false;

    if (this.ticketSubject.value) {
      //TODO: check if we need to use ticketSubject for this
      const breadcrumbs: MenuItem[] = [
        { label: "Incidents", routerLink: "/secure/tickets/incidents" },
        { label: this.incident?.number },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    }
  }

  async loadIncident() {
    try {
      const incident = await firstValueFrom(
        this.incidentService.getSingle(this.sysId)
      );
      this.notifications.readNotification(this.sysId);

      const updatedTicket = {
        sysId: incident?.sysId,
        state: incident?.state,
        type: ticketType.incident,
        canAddComments: this.canUpdateTicket,
      };
      this.ticketSubject.next(updatedTicket);
      this.incident = incident;
      this.hasBreachedSLA = this.incident.businessTimeLeft === "01/01/1970";
      this.incident.businessTimeLeft = this.formatEndDate(
        this.incident.businessTimeLeft
      );

      // if (this.incident.businessTimeLeft) {
      //   this.incident.businessTimeLeft = this.formatEndDate(
      //     this.incident.businessTimeLeft
      //   );
      // }

      await this.loadComments();
      await this.incidentLoadFiles(incident.sysId);
    } catch (error) {
      console.error(error);
      let message = {
        severity: ToastType.error,
        summary: "Error",
        detail: GenericApiErrorMessage,
        closable: false,
      };
      if (IsDisplayableException(error?.status)) {
        message = {
          severity: ToastType.error,
          summary: "Error",
          detail: error.error,
          closable: false,
        };
      }
      this.messages = [message];
    }
  }

  formatEndDate(date: string): string {
    const now = DateTime.fromFormat("01/01/1970 00:00", "dd/MM/yyyy HH:mm");
    const givenDate = DateTime.fromFormat(date, "dd/MM/yyyy HH:mm");

    if (!givenDate.isValid) {
      return "00 Days 00 Hours 00 Minutes";
    }

    const diff = givenDate
      .diff(now, ["days", "hours", "minutes"])
      .shiftTo("days", "hours", "minutes");

    const days = diff?.days?.toString().padStart(2, "0");
    const hours = diff?.hours?.toString().padStart(2, "0");
    const minutes = diff?.minutes?.toString().padStart(2, "0");

    return `${days} Days ${hours} Hours ${minutes} Minutes`;
  }

  async openProvideInformation(displayComment: CommentResponse) {
    this.ref = this.dialogService.open(ProvideIncidentInformationComponent, {
      width: "32%",
      data: {
        comment: displayComment.value,
        author: displayComment.uCommentedBy,
        date: displayComment.sysCreatedOn,
      },
      showHeader: false,
    });
    const result = await firstValueFrom(this.ref.onClose);
    if (result) {
      this.addComment(result);
      this.router.navigate([], {
        queryParams: {
          provideInformation: null,
        },
      });
      return;
    }
    this.toastService.add({
      severity: ToastType.warning,
      summary: "Adding Comment",
      detail: "Operation Cancelled",
    });
  }

  async loadComments() {
    this.canAddComments =
      this.incident?.state !== "Resolved" &&
      this.incident?.state !== "Closed" &&
      this.canUpdateTicket;
    let comments = await firstValueFrom(
      this.incidentService.getComments(this.sysId)
    );

    this.commentSubject.next({
      comments: comments,
      canAddComments: this.canAddComments,
    });

    if (!comments) {
      return;
    }
    if (
      this.providingInformation &&
      this.incident.callerId == this.user.sysId &&
      comments[0].sysCreatedBy != this.user.email
    ) {
      this.openProvideInformation(comments[0]);
    }
  }

  async createComment(comment: string) {
    this.loading = true;
    await this.addComment(comment);
    this.loading = false;
  }

  async addComment(comment: string) {
    const commentRequest: CreateCommentRequest = {
      itemSysId: this.incident.sysId,
      comment: comment,
    };
    const addComment = await firstValueFrom(
      this.incidentService.createComment(commentRequest)
    );

    if (this.incident.state === "Awaiting Info") {
      await firstValueFrom(
        this.incidentService.updateOne({
          SysId: this.incident.sysId,
          State: "2",
        })
      );
    }
    if (addComment) {
      this.toastService.add({
        severity: ToastType.success,
        summary: "Adding Comment",
        detail: "Comment Added",
      });
      await this.loadIncident();
    }
  }

  async incidentLoadFiles(sysId: string) {
    let files = await firstValueFrom(this.fileService.getFiles(sysId));
    this.attachmentFiles = files.fileList;
  }

  openAttachments() {
    this.ref = this.dialogService.open(ViewFilesComponent, {
      showHeader: false,
      data: {
        files: this.attachmentFiles,
        sysId: this.incident.sysId,
        table: TableNames.INCIDENT,
      },
    });
  }

  async openFileManager() {
    this.ref = this.dialogService.open(FileManagerComponent, {
      showHeader: false,
      width: "45%",
      data: {
        files: this.attachmentFiles,
        sysId: this.incident.sysId,
        table: "incident",
      },
    });
    const result = await firstValueFrom(this.ref.onClose);
    if (result) {
      this.toastService.add({
        severity: ToastType.success,
        summary: "File Manager",
        detail: "File(s) Uploaded",
      });
      this.loading = true;
      await this.loadIncident();
      this.loading = false;
    }
  }

  async close(closureNotes) {
    //TODO:rework this and the backend task as it returns null if its a success?!
    await firstValueFrom(
      this.incidentService.updateOne({
        SysId: this.incident.sysId,
        State: "6",
        CloseNotes: closureNotes,
        CloseCode: "customer_resolved",
      })
    );
    this.toastService.add({
      severity: ToastType.info,
      summary: "Incident",
      detail: "Your Incident will be closed within 4 days.",
    });
    this.loading = true;
    await this.loadIncident();
    this.loading = false;
  }

  async reopen() {
    await firstValueFrom(
      this.incidentService.updateOne({
        SysId: this.incident.sysId,
        State: "2",
      })
    );
    this.toastService.add({
      severity: ToastType.warning,
      summary: "Incident",
      detail: "Your Incident will be re-evaluated by our team.",
    });
    await this.loadIncident();
  }

  async openConfirmation(closing) {
    this.ref = this.dialogService.open(TicketConfirmationComponent, {
      width: "48%",
      contentStyle: { overflow: "auto" },
      showHeader: false,
      data: {
        title: "Are you sure?",
        message: "You will need to provide a reason in order to",
        messageReason: closing ? "resolve" : "re-open",
        messageEnd: " this Incident. Do you wish to continue?",
      },
    });
    const result = await firstValueFrom(this.ref.onClose);
    if (result) {
      await this.openRequiredComment(closing);
      return;
    }
    this.toastService.add({
      severity: ToastType.warning,
      summary: "Incident",
      detail: "Operation Cancelled.",
    });
  }

  async openRequiredComment(closing) {
    this.ref = this.dialogService.open(AddCommentComponent, {
      width: "32%",
      height: "42%",
      contentStyle: { overflow: "auto" },
      showHeader: false,
    });
    const result = await firstValueFrom(this.ref.onClose);
    if (result) {
      this.loading = true;
      if (closing) {
        await this.close(result);
        this.loading = false;
        return;
      }
      await this.addComment(result);
      await this.reopen();
      this.loading = false;
      return;
    }
    this.toastService.add({
      severity: ToastType.warning,
      summary: "Incident",
      detail: "Operation Cancelled.",
    });
  }

  getColor(value: string): string {
    switch (value) {
      case "New":
        return "new";
      case "In Progress":
        return "inprogress";
      case "Awaiting Info":
        return "awitinginfo";
      case "Resolved":
        return "resolved";
      case "Closed":
        return "closed";
      default:
        return "closed";
    }
  }

  async openExpediteIncident(): Promise<void> {
    this.ref = this.dialogService.open(ExpediteIncidentComponent, {
      width: "46%",
      contentStyle: { overflow: "auto" },
      showHeader: false,
    });

    const result = await firstValueFrom(this.ref.onClose);

    if (result) {
      const comment: CreateCommentRequest = {
        itemSysId: this.incident.sysId,
        comment: result,
      };
      const expediteIncident = await firstValueFrom(
        this.incidentService.expedite(comment)
      );

      if (expediteIncident) {
        this.toastService.add({
          severity: ToastType.info,
          summary: "Incident",
          detail: "Your Expedite request will be evaluated by our team.",
        });
      }
      this.loading = true;
      await this.loadIncident();
      this.loading = false;
      return;
    }
    this.toastService.add({
      severity: ToastType.warning,
      summary: "Incident",
      detail: "Operation Cancelled.",
    });
  }

  getContainerClasses(options: { [key: string]: boolean | string }) {
    const classes = {};
    if (options?.cols !== false) {
      classes[`col-${options?.cols || 12}`] = true;
    }
    if (options?.border) {
      classes["border-none"] = true;
      classes["border-top-1"] = true;
      classes["border-solid"] = true;
      classes["border-gray-300"] = true;
    }
    if (options?.borderBottom) {
      classes["border-bottom-1"] = true;
    }
    return classes;
  }
}
