import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ServiceNowFilterQuery } from "src/app/models/servicenow.model";
import { ProductAPIService } from "src/app/services/api/product/product.service";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  constructor(private productAPIService: ProductAPIService) {}

  async loadProductCategory(
    request: ServiceNowFilterQuery
  ): Promise<{ queryCount: any; result: any }> {
    const products = await firstValueFrom(
      this.productAPIService.getProduct(request)
    );
    const productsWithUsageData = await firstValueFrom(
      this.productAPIService.getProductsAdditionalData(
        products.result,
        request.Filter,
        request.SiteID
      )
    );

    let returnData = {
      queryCount: products.overallQueryCount,
      result:
        productsWithUsageData !== null
          ? productsWithUsageData.result
          : products.result,
    };
    return returnData;
  }

  async exportProductCategory(exportRequest) {
    const products = await firstValueFrom(
      this.productAPIService.getProduct(exportRequest)
    );

    const productsWithUsageData = await firstValueFrom(
      this.productAPIService.GetProductsAdditionalData(
        products.result,
        exportRequest.Filter,
        exportRequest.SiteID,
        exportRequest.ExportColumns,
        true
      )
    );

    let downloadFile = productsWithUsageData.downloadFile;
    return downloadFile;
  }
}
