import { TableColumn } from "src/app/models/table";
import { productCategories } from "./../shared/product-models";

enum managedTableColumns {
  productCategory = "productCategory",
  billingDescription = "billingDescription",
  cli = "cli",
  costCentre = "costCentre",
  siteUser = "siteUser",
  billingFrequency = "billingFrequency",
  charge = "charge",
  billingStart = "billingStart",
  billingEnd = "billingEnd",
  contractLength = "contractLength",
  contractEnd = "contractEnd",
}
export interface IManagedColumn {
  field: string;
  header?: string;
  default: boolean;
  display?: any;
  checkBoxVisible?: boolean;
  showSortIcon: boolean;
  disabled?: boolean;
  serviceNowField: string;
}

export const managedColumns: TableColumn[] = [
  {
    field: "productCategory",
    header: "Category",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: managedTableColumns.productCategory,
  },
  {
    field: "billingDescription",
    header: "Description",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: managedTableColumns.billingDescription,
  },
  {
    field: "cli",
    header: "CLI",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: managedTableColumns.cli,
  },
  {
    field: "costCentre",
    header: "Cost Centre",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: managedTableColumns.costCentre,
  },
  {
    field: "siteUser",
    header: "User",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: managedTableColumns.siteUser,
  },
  {
    field: "billingFrequency",
    header: "Billing Frequency",
    display: { type: "text" },
    filter: {
      type: "multiSelect",
      values: [
        { label: "Monthly", value: "Monthly" },
        { label: "One Off", value: "One Off" },
        { label: "Quarterly", value: "Quarterly" },
        { label: "Annually", value: "Annually" },
        { label: "Two Yearly", value: "Two Yearly" },
      ],
    },
    showSortIcon: true,
    default: true,
    serviceNowField: managedTableColumns.billingFrequency,
  },
  {
    field: "charge",
    header: "Charge",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: managedTableColumns.charge,
  },
  {
    field: "billingStart",
    header: "Billing Start Date",
    display: { type: "date" },
    filter: {
      type: "dateRange",
    },
    showSortIcon: true,
    default: false,
    serviceNowField: managedTableColumns.billingStart,
  },
  {
    field: "billingEnd",
    header: "Billing End Date",
    display: { type: "date" },
    filter: {
      type: "dateRange",
    },
    showSortIcon: true,
    default: false,
    serviceNowField: managedTableColumns.billingEnd,
  },
  {
    field: "contractLength",
    header: "Contract Term",
    filter: {
      type: "multiSelect",
      values: [
        { label: "12", value: "12" },
        { label: "14", value: "14" },
        { label: "24", value: "24" },
        { label: "36", value: "36" },
        { label: "60", value: "60" },
      ],
    },
    display: { type: "number" },
    showSortIcon: false,
    default: false,
    serviceNowField: managedTableColumns.contractLength,
  },
  {
    field: "contractEnd",
    header: "Contract End Date",
    display: { type: "date" },
    filter: {
      type: "dateRange",
    },
    showSortIcon: true,
    default: false,
    serviceNowField: managedTableColumns.contractEnd,
  },
];
