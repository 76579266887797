export type GetSingleIncidentResponse = {
  sysId: string;
  number: string;
  shortDescription: string;
  description: string;
  priority: string;
  state: string;
  callerId: string;
  callerName: string;
  sysCreatedOn?: Date;
  closeNotes: string;
  resolvedBy: string;
  resolvedAt?: Date;
  location: string;
  businessService: string;
  serviceComponent: string;
  symptom: string;
  configurationItem: string;
  businessTimeLeft: string;
  schedule: string;
  cmdbCi: string;
};

export enum IncidentTableColumnNames {
  number = "Number",
  description = "Description",
  shortDescription = "ShortDescription",
  raisedBy = "CallerId.name",
  createdOn = "SysCreatedOn",
  priority = "Priority",
  openedAt = "opened",
  state = "State",
  service = "ServiceComponent",
  survey = "SurveyStatus",
  symptom = "Symptom",
  location = "Location",
  onHoldReason = "HoldReason",
  cmdbCi = "CmdbCi",
}

export type GetMultipleIncidentsResponse = {
  sysId: string;
  number: string;
  shortDescription: string;
  callerId: string;
  sysCreatedOn?: Date;
  priority: string;
  state: string;
  serviceComponent: string;
  symptom: string;
  surveyId: string;
  location: string;
  holdReason: string;
}[];
