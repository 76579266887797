import { FilterMetadata } from "primeng/api";

export type FilterType =
  | "text"
  | "dropdown"
  | "multiSelect"
  | "multiSelectMetric"
  | "date"
  | "dateRange";

export type TableColumnColor = {
  [key: string]: string;
};

export type TableColumnDisplay = {
  type: string;
  colors?: TableColumnColor | null;
};

export enum TableSort {
  asc = 1,
  desc = -1,
}

export type TableColumn = {
  field: string;
  serviceNowField?: string;
  exportField?: string;
  header?: string;
  default: boolean;
  filter?: TableColumnFilter;
  order?: number;
  disabled?: boolean;
  showSortIcon?: boolean;
  display?: TableColumnDisplay;
  sortOrder?: TableSort;
  sortColumnOrder?: number;
  checkBoxVisible?: boolean;
  cell?: (data: any) => any;
  expand?: (data: any) => any;
};

export type TableColumnFilter = {
  type: FilterType;
  values?: { label: string; value: string | boolean }[];
  preset?: FilterMetadata;
  message?: string;
};

export type TableFiltersWithColumns = {
  [key: string]: FilterMetadataWithColumn | FilterMetadataWithColumn[];
};

export type FilterMetadataWithColumn = FilterMetadata & {
  column?: TableColumn;
};

export type Filters = { [key: string]: FilterMetadata | FilterMetadata[] };

export interface ITableClickEvent<T> {
  data: T;
  index: number;
  originalEvent: PointerEvent;
  type: string;
}
