// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-primary {
  border-color: #e31c79 !important;
}

.border-1 {
  border-width: 1px;
  border-style: solid;
}

.border-circle {
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/global/menu/menu.component.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;AACF;;AAEA;EACE,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".border-primary {\n  border-color: #e31c79 !important;\n}\n\n.border-1 {\n  border-width: 1px;\n  border-style: solid;\n}\n\n.border-circle {\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
