import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import {
  IGetNnowledgeResponse,
  knowledgeColumns,
} from "./shared/knowledge-models";
import { cloneDeep } from "lodash-es";
import { IUserProfile } from "../../company/users/shared/user-models";
import { AuthService } from "src/app/services/auth/auth.service";
import { KnowledgeService } from "src/app/services/api/knowledge/knowledge.service";
import { Table } from "primeng/table";
import { LazyLoadEvent } from "primeng/api";
import { MenuItem } from "primeng/api/menuitem";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { TableColumn } from "src/app/models/table";
import {
  IsPaginatorState,
  SetSelectedColumns,
  TableReset,
} from "src/app/helpers/table.helper";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { Paginator, PaginatorState } from "primeng/paginator";

@Component({
  selector: "knowledge",
  templateUrl: "knowledge-article.component.html",
  styleUrls: ["knowledge-article.component.scss"],
})
export class KnowledgeArticleComponent implements OnInit {
  @ViewChild("dataTable") dataTable: Table;
  @ViewChild("tablePaginator") paginator: Paginator;
  user: IUserProfile;
  knowledgeArticlesSubject = new BehaviorSubject<Array<IGetNnowledgeResponse>>(
    []
  );
  knowledgeArticles$ = this.knowledgeArticlesSubject.asObservable();
  cols: TableColumn[] = cloneDeep(knowledgeColumns);
  totalRecords: number = 0;
  _selectedColumns: TableColumn[] = [];
  firstLoad: boolean = true;

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private knowledgeService: KnowledgeService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      { label: " Support", routerLink: "/support" },
      { label: "Support Articles", routerLink: "/" },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }

  async loadKnowledgeArticles(
    table: Table,
    event?: LazyLoadEvent | PaginatorState
  ) {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }
    this.user = await this.authService.getUser();
    table.loading = true;
    const isFirstLoad = this.firstLoad ? true : false;

    this.knowledgeArticlesSubject.next([]);

    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(knowledgeColumns));
      this.firstLoad = false;
    }

    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event,
      { company: this.user.companyId }
    );

    const response = await firstValueFrom(
      this.knowledgeService.getKnowledgeArticles(serviceNowFilter)
    );

    this.knowledgeArticlesSubject.next(response.result);
    this.totalRecords = response.overallQueryCount;
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    table.loading = false;
  }

  handleKnowledgeRowClick(event) {
    this.router.navigateByUrl("secure/knowledge-article/" + event.sysId);
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = knowledgeColumns
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter((column) => column?.default).map((column) => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
