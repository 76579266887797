import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IServiceHubMenuItem } from "src/app/services/general/menu/menu";
import { MenuService } from "src/app/services/general/menu/menu.service";

@Component({
  selector: "menu-detail",
  templateUrl: "menu-detail.component.html",
  styleUrls: ["menu-detail.component.scss"],
})
export class MenuDetailComponent implements OnInit {
  menuItem: IServiceHubMenuItem;

  constructor(private route: Router, private menuService: MenuService) {}

  ngOnInit(): void {
    this.getQuickPathMenuItems();
  }

  redirect(menuItem) {
    this.route.navigateByUrl(menuItem.routerLink);
  }

  getQuickPathMenuItems(): void {
    const items = this.menuService.getQuickPathMenuItems(
      this.menuService.getUserMenuItems()
    );

    const usageMonitoringItems = items.find(
      (item) => item.label === "Usage & Monitoring"
    );
    this.menuItem = usageMonitoringItems ? usageMonitoringItems.items : [];
  }
}
