import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export enum ToastType {
  success = "success",
  error = "error",
  info = "info",
  warning = "warn",
}

export interface Toast {
  summary: string;
  severity: ToastType;
  detail: string;
}

@Injectable({
  providedIn: "root",
})
export class ToastService {
  private toastSubject = new BehaviorSubject<Toast | null>(null);
  toastState = this.toastSubject.asObservable();

  constructor() {}

  add(toast: Toast) {
    this.toastSubject.next(toast);
  }

  clear() {
    this.toastSubject.next(null);
  }
}
