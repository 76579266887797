import { Injectable } from "@angular/core";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";

@Injectable({
  providedIn: "root",
})
export class ReleaseNotesService {
  constructor(private apiRequest: ApiRequest) {}

  getReleaseNotes(url: string) {
    const requestProps: IRequestOptions = {
      endpoint: "ReleaseNotes/GetReleaseNotes",
      body: {
        Url: url,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  getReleaseNote() {
    const requestProps: IRequestOptions = {
      endpoint: "ReleaseNotes/GetReleaseNotes",
      body: {},
    };

    return this.apiRequest.request(requestProps);
  }

  releaseNoteViewed(relaseNote: string) {
    const requestProps: IRequestOptions = {
      endpoint: "releasenotes/releaseNoteViewed",
      body: relaseNote,
    };

    return this.apiRequest.request(requestProps);
  }
}
