import { Injectable } from "@angular/core";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";

@Injectable({
  providedIn: "root",
})
export class RadiusService {
  constructor(private apiRequest: ApiRequest) {}

  getRadiusAccount(account: any) {
    const requestProps: IRequestOptions = {
      endpoint: "RadiusAccounts/GetRadiusAccount",
      body: account,
    };

    return this.apiRequest.request(requestProps);
  }
}
