import { Component, input, Input } from "@angular/core";

@Component({
  selector: "app-ticket-item-display",
  templateUrl: "./ticket-item-display.component.html",
  styleUrl: "./ticket-item-display.component.scss",
})
export class TicketItemDisplayComponent {
  @Input() label!: string;
  @Input() value!: string;
  @Input() border = false;
  @Input() borderBottom = false;
  @Input() scheduleIcon = false;
  @Input() cols: number | boolean = 12;
  @Input() schedule: string;
  @Input() tooltip: string;
  constructor() {}
}
