import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { CompanyAPIService } from "src/app/services/api/company/company.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { BillingEntityService } from "src/app/services/api/billing-entity/billing-entity.service";
import { FormGroup } from "@angular/forms";
import { ToggleSelectDeselectAll } from "src/app/helpers/select.helper";
import { ContactType } from "src/app/models/contacts/contacts-model";
import { IUserProfile } from "../../shared/user-models";
import { PermissionService } from "src/app/services/permissions.service";

@Component({
  selector: "app-add-edit-user-additional",
  templateUrl: "./add-edit-user-additional.component.html",
  styleUrls: ["./add-edit-user-additional.component.scss"],
})
export class AddEditUserAdditionalComponent implements OnInit {
  @Input() addEditUserAdditionalForm: FormGroup;
  @Input() addEditUserPermissionsForm: FormGroup;
  @Output() childIsLoading = new EventEmitter();
  user: IUserProfile;
  loading: boolean = false;

  authorisationTypes: any[] = [];
  selectedAuthorisationTypes: any[] = [];

  billingEntities: any[] = [];
  selectedBillingEntities: any[] = [];

  contactTypes: ContactType[] = [];
  selectedContactTypes: ContactType[] = [];

  get hasSelectedBillingPermissionsToShowBillingEntities() {
    return this.setHasSelectedBillingPermissionsToShowBillingEntities([
      ...this.addEditUserPermissionsForm.get("rolePermissions").value,
      ...this.addEditUserPermissionsForm.get("additionalPermissions").value,
    ]);
  }

  get canShowContactTypes() {
    return this.authService.hasPermission(this.user, [
      "AUTHORISATION_TYPES_READ",
    ]);
  }

  constructor(
    private companyService: CompanyAPIService,
    private authService: AuthService,
    private billingEntityService: BillingEntityService,
    private permissionService: PermissionService
  ) {}

  async ngOnInit() {
    this.loading = true;
    this.childIsLoading.emit(true);
    this.user = await this.authService.getUser();
    await this.loadAuthorisationTypes();
    if (this.hasSelectedBillingPermissionsToShowBillingEntities) {
      await this.loadBillingEntities();
    }
    await this.loadContactTypes();
    this.loading = false;
    this.childIsLoading.emit(false);
  }

  setHasSelectedBillingPermissionsToShowBillingEntities(permissions) {
    for (const permission of permissions) {
      if (permission.name.includes("BILLING")) {
        return true;
      }
    }
    return false;
  }

  async loadAuthorisationTypes() {
    this.authorisationTypes = await firstValueFrom(
      this.companyService.getAuthorisationTypes(["Contacts"])
    );
    this.addEditUserAdditionalForm.get("authorisationTypes").setValue(
      this.authorisationTypes.filter((authorisationType) =>
        this.addEditUserAdditionalForm
          .get("authorisationTypes")
          .value.map(
            (selectedAuthorisationType) => selectedAuthorisationType.sysId
          )
          .includes(authorisationType.sysId)
      )
    );
    this.selectedAuthorisationTypes =
      this.addEditUserAdditionalForm.get("authorisationTypes").value;
  }

  async loadBillingEntities() {
    let filter = [{ Column: "UCompany", Value: ["=" + this.user.companyId] }];
    this.billingEntities = await firstValueFrom(
      this.billingEntityService.getBillingEntities(filter)
    );
    this.addEditUserAdditionalForm.get("billingEntities").setValue(
      this.billingEntities.filter((billingEntity) =>
        this.addEditUserAdditionalForm
          .get("billingEntities")
          .value.map((selectedBillingEntity) => selectedBillingEntity.sysId)
          .includes(billingEntity.sysId)
      )
    );
    this.selectedBillingEntities =
      this.addEditUserAdditionalForm.get("billingEntities").value;
  }

  async loadContactTypes() {
    let contactTypes = await firstValueFrom(
      this.companyService.getContactTypes()
    );

    this.addEditUserAdditionalForm.get("contactTypes").setValue(
      contactTypes.filter((contactType) =>
        this.addEditUserAdditionalForm
          .get("contactTypes")
          .value.map((selectedContactType) => selectedContactType.sysId)
          .includes(contactType.sysId)
      )
    );

    this.contactTypes = this.setDisabledContactTypes(contactTypes);
    this.selectedContactTypes =
      this.addEditUserAdditionalForm.get("contactTypes").value;
  }

  onBillingEntityChange() {
    this.addEditUserAdditionalForm
      .get("billingEntities")
      .setValue(this.selectedBillingEntities);
  }

  onAuthorisationTypesChange() {
    this.addEditUserAdditionalForm
      .get("authorisationTypes")
      .setValue(this.selectedAuthorisationTypes);
  }

  onContactTypesChange() {
    this.addEditUserAdditionalForm
      .get("contactTypes")
      .setValue(this.selectedContactTypes);
  }

  toggleSelectDeselectBillingEntities() {
    this.selectedBillingEntities = ToggleSelectDeselectAll(
      this.billingEntities,
      this.selectedBillingEntities
    );
    this.onBillingEntityChange();
  }

  toggleSelectDeselectAuthorisationTypes() {
    this.selectedAuthorisationTypes = ToggleSelectDeselectAll(
      this.authorisationTypes,
      this.selectedAuthorisationTypes
    );
    this.onAuthorisationTypesChange();
  }

  toggleSelectDeselectContactTypes() {
    const availableContactTypes = this.contactTypes.filter(
      (contactType) => !contactType.disabled
    );
    this.selectedContactTypes = ToggleSelectDeselectAll(
      availableContactTypes,
      this.selectedContactTypes
    );
    this.onContactTypesChange();
  }

  setDisabledContactTypes(contactTypes: ContactType[]): ContactType[] {
    const contactTypesComplete = contactTypes.map((contactType) => {
      contactType.disabled = true;
      if (
        this.authService.hasPermission(this.user, [
          "AUTHORISATION_TYPES_DELETE",
        ]) &&
        this.addEditUserAdditionalForm
          .get("contactTypes")
          .value?.find(
            (selectedContactType) =>
              contactType.sysId === selectedContactType.sysId
          )
      ) {
        contactType.disabled = false;
      }
      if (
        this.authService.hasPermission(this.user, [
          "AUTHORISATION_TYPES_CREATE",
        ]) &&
        !this.addEditUserAdditionalForm
          .get("contactTypes")
          .value?.find(
            (selectedContactType) =>
              contactType.sysId === selectedContactType.sysId
          )
      ) {
        contactType.disabled = false;
      }

      if (
        contactType.name === "Emergency Contact" &&
        !(
          this.user.uTimicoPortalRole === "Admin" ||
          this.user.uTimicoPortalRole === "Digital Space Admin"
        )
      ) {
        contactType.disabled = true;
      }

      return contactType;
    });
    return contactTypesComplete;
  }
}
