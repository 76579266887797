import { Component, OnInit } from "@angular/core";
import { CookieService as NgxCookieService } from "ngx-cookie-service";
import { firstValueFrom } from "rxjs";
import { CookieService } from "src/app/services/api/cookie/cookie.service";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "cookies",
  templateUrl: "cookies.component.html",
  styleUrls: ["cookies.component.scss"],
})
export class CookiesComponent implements OnInit {
  user: any;
  additionalCookiesStatus: boolean = false;
  additionalCookies: any;

  constructor(
    private cookieService: CookieService,
    private ngxCookieService: NgxCookieService,
    private authService: AuthService
  ) {}
  async ngOnInit() {
    this.user = await this.authService.getUser();
    this.loadCookiesPreferences();
  }

  toggleAdditionalCookies() {
    this.additionalCookiesStatus = !this.additionalCookiesStatus;
    let cookies = {
      SysId: this.additionalCookies != null ? this.additionalCookies.sysId : "",
      UCookiesType: this.additionalCookiesStatus ? "Additional Cookies" : "",
    };
    // TODO should this await?
    firstValueFrom(
      this.cookieService.getUserCookiesPreferences(
        cookies.SysId,
        cookies.UCookiesType
      )
    );

    this.ngxCookieService.set(
      "Accept-DigitalSpace-Additional-Cookies",
      this.additionalCookiesStatus.toString(),
      10000
    );
  }

  async loadCookiesPreferences() {
    const response = await firstValueFrom(
      this.cookieService.GetUserCookiesPreferences()
    );
    if (response != null) {
      this.additionalCookiesStatus =
        response.uCookiesType == "Additional Cookies";
      this.additionalCookies = response;
      this.ngxCookieService.set(
        "Accept-DigitalSpace-Additional-Cookies",
        this.additionalCookiesStatus.toString(),
        10000
      );
    }
  }
}
