import { TableColumnColor, TableColumnDisplay } from "src/app/models/table";

interface ICommentsColumn {
  field: string;
  header: string;
  showSortIcon: boolean;
  display: TableColumnDisplay;
  default: boolean;
  cell?: (data: any) => any;
  expand?: (data: any) => any;
}

export const commentColumns: ICommentsColumn[] = [
  {
    field: "type",
    header: "Type",
    display: {
      type: "chip",
      colors: {
        Comment: "Comment",
      },
    },
    showSortIcon: false,
    default: true,
  },
  {
    field: "sysCreatedOn",
    header: "Date",
    display: { type: "date" },
    showSortIcon: false,
    default: true,
  },
  {
    field: "uCommentedBy",
    header: "Commented By",
    display: { type: "text" },
    showSortIcon: false,
    default: true,
  },
  {
    field: "value",
    header: "Content",
    display: { type: "text" },
    showSortIcon: false,
    default: true,
    cell: (data) => data.uQuestion,
    expand: (data) => data.uAnswer,
  },
];
