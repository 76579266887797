import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { NotificationService } from "src/app/services/notification.service";
import { FileManagerComponent } from "src/app/components/misc/pop-up/file-manager/file-manager.component";
import { ViewFilesComponent } from "src/app/components/misc/pop-up/view-files/view-files.component";
import { FileManagerService } from "src/app/components/misc/pop-up/file-manager/file-manager.service";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { FileService } from "src/app/services/api/file/file.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { MenuItem } from "primeng/api/menuitem";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { Message, MessageService } from "primeng/api";
import { commentColumns } from "../models/service-request-single-models";
import { IUserProfile } from "../../../company/users/shared/user-models";
import { ToastService, ToastType } from "src/app/services/global/toast.service";
import {
  GenericApiErrorMessage,
  IsDisplayableException,
} from "src/app/helpers/error.helper";
import { CreateCommentRequest } from "src/app/models/comments/comment.models";
import { ServiceRequestService } from "src/app/services/api/service-request/service-request.service";
import { GetColor } from "./../../../../../helpers/table.helper";
import { ordersColors } from "src/app/models/order/order.model";

@Component({
  selector: "service-request",
  templateUrl: "service-request.component.html",
  styleUrls: ["service-request.component.scss"],
  providers: [DialogService, MessageService],
})
export class SingleServiceRequestComponent implements OnInit {
  user: IUserProfile;
  commentSubject = new BehaviorSubject(null);
  comments$ = this.commentSubject.asObservable();
  serviceRequestId: any;
  serviceRequest: any;
  comments: any;
  attachmentFiles: any;
  ref: DynamicDialogRef;
  loading: boolean = false;

  tableColumn = commentColumns;
  canViewComments: boolean = false;
  canViewCommentsBoth: boolean = false;
  isImpersonating: boolean = false;

  colors = ordersColors;

  getColor = GetColor;

  messages: Message[] | undefined = [];

  returnUrl: string;
  type: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private notifications: NotificationService,
    public fileManagerService: FileManagerService,
    private fileService: FileService,
    private serviceRequestService: ServiceRequestService,
    public authService: AuthService,
    private dialogService: DialogService,
    private breadcrumbService: BreadcrumbService,
    private toastService: ToastService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUser();
    this.isImpersonating = this.authService.isImpersonating();
    this.type = this.getBreadCrumbHeading();
    this.returnUrl = this.getBreadCrumbURL();

    this.activatedRoute.params.subscribe(async (params: Params) => {
      this.serviceRequestId = params["id"];
      await this.loadServiceRequest();

      const breadcrumbs: MenuItem[] = [
        { label: this.type, routerLink: this.returnUrl },
        { label: this.serviceRequest?.number },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    });
    this.canViewComments =
      this.user.uTimicoPortalPermissions.includes("TICKETS_REQUESTS_UPDATE") ||
      this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_UPDATE");

    this.canViewCommentsBoth = this.canViewComment();
  }

  canViewComment(): boolean {
    const isOrderPage = this.getBreadCrumbHeading() === "Orders";
    const hasRequestPermissions =
      this.user.uTimicoPortalPermissions.includes("TICKETS_REQUESTS_UPDATE") ||
      this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_UPDATE");

    const hasOrderPermissions = this.user.uTimicoPortalPermissions.includes(
      "TICKETS_ORDERS_UPDATE"
    );

    if (!hasOrderPermissions && isOrderPage) {
      return false;
    }
    if (hasOrderPermissions && isOrderPage) {
      return true;
    }
    if (!isOrderPage && hasRequestPermissions) {
      return true;
    } else {
      return false;
    }
  }

  async loadServiceRequest() {
    this.loading = true;
    try {
      const serviceRequest = await firstValueFrom(
        this.serviceRequestService.getSingle(this.serviceRequestId)
      );
      this.serviceRequest = serviceRequest;
      this.notifications.readNotification(serviceRequest.sysId);

      await this.loadComments();
      this.loadFiles();
    } catch (error) {
      console.error(error);
      let message = {
        severity: ToastType.error,
        summary: "Error",
        detail: GenericApiErrorMessage,
        closable: false,
      };
      if (IsDisplayableException(error?.status)) {
        message = {
          severity: ToastType.error,
          summary: "Error",
          detail: error.error,
          closable: false,
        };
      }
      this.messages = [message];
    } finally {
      this.loading = false;
    }
  }

  async loadComments() {
    const comments = await firstValueFrom(
      this.serviceRequestService.getComments(this.serviceRequest.sysId)
    );
    const canAddComments =
      this.canViewComment() &&
      this.serviceRequest?.state !== "Fulfilled" &&
      this.serviceRequest?.state !== "Closed" &&
      this.serviceRequest?.state !== "Resolved" &&
      this.serviceRequest.state !== "Cancelled";

    this.commentSubject.next({
      comments: comments,
      canAddComments: canAddComments,
    });
  }

  async addComment(comment: string) {
    const commentRequest: CreateCommentRequest = {
      itemSysId: this.serviceRequest.sysId,
      comment: comment,
    };
    const addComment = await firstValueFrom(
      this.serviceRequestService.createComment(commentRequest)
    );

    if (addComment) {
      this.toastService.add({
        severity: ToastType.success,
        summary: "Adding Comment",
        detail: "Comment Added",
      });
      await this.loadServiceRequest();
    }
  }

  async loadFiles() {
    let files = await firstValueFrom(
      this.fileService.getFiles(this.serviceRequest.sysId)
    );
    this.attachmentFiles = files.fileList;
  }

  async openFileManager() {
    this.ref = this.dialogService.open(FileManagerComponent, {
      showHeader: false,
      width: "45%",
      data: {
        files: this.attachmentFiles,
        sysId: this.serviceRequest.sysId,
        table: "sc_req_item",
      },
    });
    this.ref.onClose.subscribe(async (result) => {
      if (result) {
        this.toastService.add({
          severity: ToastType.success,
          summary: "File Manager",
          detail: "File(s) Uploaded",
        });
        await this.loadServiceRequest();
      }
    });
  }

  openAttachments(): void {
    this.ref = this.dialogService.open(ViewFilesComponent, {
      showHeader: false,
      data: {
        files: this.attachmentFiles,
        sysId: this.serviceRequest.sysId,
        table: "sc_req_item",
      },
    });
  }

  getBreadCrumbHeading(): string {
    if (window.location.href.includes("order")) {
      return "Orders";
    } else {
      return "Service Requests";
    }
  }

  getBreadCrumbURL(): string {
    if (window.location.href.includes("order")) {
      return "/secure/tickets/orders";
    } else {
      return "/secure/tickets/service-requests";
    }
  }
}
