import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'restricted',
    templateUrl: 'restricted.component.html'
})
export class RestrictedComponent {

    constructor(private route: Router) {}

    redirectToDashboard() {
        this.route.navigateByUrl("secure/dashboard");
    }
}