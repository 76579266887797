import { Injectable, EventEmitter } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LoadingSpinnerService {
  public messageChanged: EventEmitter<string> = new EventEmitter();

  setLoadingMessage(newMessage: string) {
    this.messageChanged.emit(newMessage);
  }
}
