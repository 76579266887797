import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CostCentreService } from "src/app/services/cost-centre-service";
import { EditCostCentreComponent } from "src/app/components/misc/pop-up/edit-cost-centre/edit-cost-centre.component";
import { EditCostUserComponent } from "src/app/components/misc/pop-up/edit-cost-user/edit-cost-user.component";
import {
  ICostCentre,
  APIFunction,
  ICostCentrePost,
  ICostCentrePostCLI,
  IConfirmColumn,
  IConfirmColumns,
} from "../shared/models";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { UnityService } from "src/app/services/api/unity/unity.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { MenuItem } from "primeng/api";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";

@Component({
  selector: "app-amend-costcentres-group",
  templateUrl: "./amend-costcentres-group.component.html",
  styleUrls: ["./amend-costcentres-group.component.scss"],
  providers: [DialogService],
})
export class AmendCostcentresGroupComponent {
  costCentresSubject = new BehaviorSubject(null);
  costCentres$ = this.costCentresSubject.asObservable();
  loading: boolean = false;
  updatedCostCentres: ICostCentre[] = [];
  costCentrePostByID: ICostCentre[] = [];
  costCentrePostByCLI: ICostCentre[] = [];
  items: MenuItem[] | undefined;
  steps: MenuItem[] | undefined = [];
  activeIndex: MenuItem = this.steps[0];
  activeStepIndex: number = 0;
  ref: DynamicDialogRef;

  cols!: IConfirmColumn[];
  tableColumn: IConfirmColumn[];
  siteId: string;

  selectedEditType = "group";

  costCentreForm = this.fb.group({
    costCentre: ["No Change"],
    costCentreId: [],
    user: ["No Change"],
    userID: [""],
  });

  constructor(
    private router: Router,
    private costCentreService: CostCentreService,
    private fb: FormBuilder,
    private unityService: UnityService,
    private dialogService: DialogService,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.siteId = this.route?.snapshot?.params?.siteId;
    const breadcrumbs: MenuItem[] = [
      { label: "Billing Overview", url: "/secure/billing/overview" },
      {
        label: "Cost Centre and User",
        routerLink: `/secure/billing/${this.siteId}/costcentres-users`,
      },
      { label: "Edit Fields" },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.items = [
      {
        label: "Amend",
      },
      {
        label: "Confirm",
      },
      {
        label: "Submit",
      },
    ];
    this.activeIndex = this.items[this.activeStepIndex];
    this.tableColumn = IConfirmColumns;
    this.cols = this.tableColumn;
  }

  async processCostCentreUpdates() {
    this.loading = true;
    this.costCentrePostByID = this.updatedCostCentres.filter(
      (item) => item.apiFunction === APIFunction.UpdateSiteProductItemByID
    );
    this.costCentrePostByCLI = this.updatedCostCentres.filter(
      (item) => item.apiFunction === APIFunction.UpdateCLIByID
    );

    const costCentrePostByID: ICostCentrePost[] = this.costCentrePostByID.map(
      ({ userID, costCentreID, currentID }) => ({
        SiteProductID: currentID,
        SubSiteID: costCentreID.toString(),
        SiteUserID: userID.toString(),
      })
    );

    const costCentrePostByCLI: ICostCentrePostCLI[] =
      this.costCentrePostByCLI.map(({ userID, costCentreID, cliid }) => ({
        SubSiteID: costCentreID.toString(),
        SiteUserID: userID.toString(),
        CLIID: cliid,
      }));

    this.loading = true;
    if (costCentrePostByID.length > 0) {
      const productIDResult = await firstValueFrom(
        this.unityService.updateSiteProductByIDList(costCentrePostByID)
      );
    }
    if (costCentrePostByCLI.length > 0) {
      const resultCLIResult = await firstValueFrom(
        this.unityService.updateCLIDetailsByIDList(costCentrePostByCLI)
      );
    }
    this.loading = false;
  }

  onMultiChange(): void {
    this.router.navigateByUrl(
      `secure/billing/${this.siteId}/costcentres-users/amend/individual`
    );
  }

  openCostCentreDialog(): void {
    this.ref = this.dialogService.open(EditCostCentreComponent, {
      showHeader: false,
      width: "50%",
      data: {
        id: this.siteId,
      },
    });
    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.upDateFormValues(result);
      }
    });
  }

  openUserDialog(): void {
    this.ref = this.dialogService.open(EditCostUserComponent, {
      showHeader: false,
      width: "50%",
      data: {
        id: this.siteId,
      },
    });
    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.upDateUserValues(result);
      }
    });
  }

  backButton(): void {
    this.clearData();
    this.router.navigateByUrl(
      `/secure/billing/${this.siteId}/costcentres-users`
    );
  }

  resetCostCentre(): void {
    this.costCentreForm.get("costCentre").setValue("No Change");
    const costCentresOld = this.costCentreService.costCentre.map((item) => {
      return { costCentre: item.costCentre, costCentreID: item.costCentreID };
    });

    this.updatedCostCentres = this.costCentreService.costCentre.map(
      (item, index) => {
        const { costCentre, costCentreID } = costCentresOld[index];
        return { ...item, costCentre, costCentreID };
      }
    );
  }

  resetUser(): void {
    this.costCentreForm.get("user").setValue("No Change");

    const siteUsersOld = this.costCentreService.costCentre.map((item) => {
      return { siteUser: item.siteUser, userID: item.userID };
    });

    this.updatedCostCentres = this.costCentreService.costCentre.map(
      (item, index) => {
        const { siteUser, userID } = siteUsersOld[index];
        return { ...item, siteUser, userID };
      }
    );
  }

  upDateUserValues(value): void {
    this.costCentreForm.get("user").setValue(value.name);
    this.costCentreForm.get("userID").setValue(value.id);

    if (!this.updatedCostCentres || this.updatedCostCentres.length === 0) {
      this.updatedCostCentres = this.costCentreService.costCentre;
    }

    this.updatedCostCentres = this.updatedCostCentres.map((item) => {
      return { ...item, siteUser: value.name, userID: value.id };
    });
  }

  upDateFormValues(value): void {
    this.costCentreForm.get("costCentre").setValue(value.name);

    if (!this.updatedCostCentres || this.updatedCostCentres.length === 0) {
      this.updatedCostCentres = this.costCentreService.costCentre;
    }

    this.updatedCostCentres = this.updatedCostCentres.map((item) => {
      return { ...item, costCentre: value.name, costCentreID: value.id };
    });
  }

  upDateTableValues(): void {
    const tableData =
      this.updatedCostCentres !== undefined &&
      this.updatedCostCentres.length > 0
        ? this.updatedCostCentres
        : this.costCentreService.costCentre;

    this.costCentresSubject.next(tableData);
  }

  clearData(): void {
    this.costCentreService.costCentre = [];
    this.updatedCostCentres = [];
  }

  finishButton(): void {
    this.clearData();
    this.router.navigateByUrl(
      `/secure/billing/${this.siteId}/costcentres-users`
    );
  }

  onActiveIndexChange(event: number): void {
    const previousIndex = this.activeStepIndex;

    if (previousIndex === 0 && event === 1) {
      this.upDateTableValues();
    } else if (event === 2) {
      this.processCostCentreUpdates();
    }

    this.activeStepIndex = event;
    this.activeIndex = this.items[this.activeStepIndex];
  }

  stepForward(): void {
    if (this.items[this.activeStepIndex + 1]) {
      this.onActiveIndexChange(this.activeStepIndex + 1);
    }
  }

  stepBack(): void {
    if (this.items[this.activeStepIndex - 1]) {
      this.onActiveIndexChange(this.activeStepIndex - 1);
    }
  }
}
