import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "not-found",
  templateUrl: "not-found.component.html",
})
export class NotFoundComponent {
  constructor(private router: Router, private location: Location) {}

  goBackButton(): void {
    this.location.back();
  }

  backToDashBoardButton(): void {
    this.router.navigate([`/secure/dashboard`]);
  }
}
