import { TableColumn, TableSort } from "src/app/models/table";

export interface IBillingEntity {
  sysId: string;
  name: string;
  unityId: string;
  uDefault: string;
  uActiveYesNo: string;
}

export interface IMobile {
  cliNumber: string;
  endDate: string;
  postcode: string;
  siteId: string;
  siteName: string;
  siteUser: string;
  startDate: string;
  subSite: string;
  type: string;
  bundleName: string;
  mb: string;
  mins: string;
  texts: string;
  service: string;
  network: string;
}

export interface IMonthlyUsageGraphBundle {
  data: string;
  texts: string;
  minutes: string;
}

export interface IBundleDetailsRequest {
  siteID: string;
  cliNumber: string;
}

export interface IBundleDetails {
  siteID: string;
  siteName: string;
  cliNumber: string;
  postCode: string;
  type: string;
  startDate: Date;
  endDate: Date;
  siteUser: string;
  subsite: string;
  // bundleMB: number,
  // bundleMins: number,
  // bundleTexts: number,
  usedMB?: number;
  usedMinutes?: number;
  usedTexts?: number;
}

export interface IBundleDetailsResponse {
  bundleDetails: IBundleDetails;
  bundleNames: Array<string>;
}

export interface ISelectedSite {
  siteID: string;
  cliNumber: string;
}

export interface IBarCategoryContainer {
  network: string;
  barCategories: IBarCategory[];
}

export interface IBarContainer {
  network: string;
  cli: string;
  bars: IBar[];
}

export interface IMultiBarContainer {
  network: string;
  clis: string[];
  bars: IBar[];
}

export interface IBarCategory {
  category: string;
  barList: IBar[];
}
export interface IBar {
  name: string;
  active: boolean;
  category: string;
}

export interface IUpdatedBarControls {
  categorised: any[];
  nonCategorised: IBar[];
}

export interface IBarDropdownOption {
  label: string;
  value: boolean;
}

export enum MobileTableColumns {
  Number = "cliNumber",
  Subsite = "siteName",
  User = "siteUser",
  Data = "mb",
  Minutes = "mins",
  Texts = "texts",
  Network = "network",
}

enum exportField {
  Number = "cliNumber",
  Subsite = "costCentre",
  User = "user",
  Data = "data",
  Minutes = "minutes",
  Texts = "texts",
  Network = "network",
}

export interface INumbersList {
  mobileNumbers: string[];
}

export interface ITelephonyColumn {
  field: string;
  header: string;
  display?: any;
  showSortIcon: boolean;
  default: boolean;
  disabled?: boolean;
  serviceNowField: string;
}

export const telephonColumns: TableColumn[] = [
  {
    field: "cliNumber",
    header: "Number",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    disabled: true,
    serviceNowField: MobileTableColumns.Number,
    exportField: exportField.Number,
  },
  {
    field: "siteName",
    header: "Cost Centre",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: MobileTableColumns.Subsite,
    exportField: exportField.Subsite,
  },
  {
    field: "siteUser",
    header: "User",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: MobileTableColumns.User,
    exportField: exportField.User,
  },
  {
    field: "mb",
    header: "Data",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: MobileTableColumns.Data,
    exportField: exportField.Data,
  },
  {
    field: "mins",
    header: "Minutes",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: MobileTableColumns.Minutes,
    exportField: exportField.Minutes,
  },
  {
    field: "texts",
    header: "Texts",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: MobileTableColumns.Texts,
    exportField: exportField.Texts,
  },
  {
    field: "network",
    header: "Network",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: MobileTableColumns.Network,
    exportField: exportField.Network,
  },
];
