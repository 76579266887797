import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";
import { IDownloadFile } from "src/app/models/export-models";
import { ServiceNowFilterQuery } from "src/app/models/servicenow.model";

@Injectable({
  providedIn: "root",
})
export class InvoiceService {
  constructor(private apiRequest: ApiRequest) {}

  getCreditNotes(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "Invoices/GetCreditNotes",
      body: request,
      requiresVpc: true,
    };
    return this.apiRequest.request(requestProps);
  }

  getCreditNotePdf(creditNote: string) {
    const requestProps: IRequestOptions = {
      endpoint: "Invoices/GetCreditNotePdf",
      body: creditNote,
      requiresVpc: true,
    };
    return this.apiRequest.request(requestProps);
  }

  getManualInvoicePdf(invoice: string): Observable<IDownloadFile> {
    const requestProps: IRequestOptions = {
      endpoint: "Invoices/GetInvoiceManualPdf",
      body: invoice,
      requiresVpc: true,
    };
    return this.apiRequest.request(requestProps);
  }

  getInvoicesPdf(
    companyId: string,
    invoiceId: string,
    invoiceNo: string
  ): Observable<IDownloadFile> {
    const requestProps: IRequestOptions = {
      endpoint: "Invoices/GetInvoicesPdf",
      body: {
        InvoiceCompanyId: companyId,
        InvoiceId: invoiceId,
        InvoiceNo: invoiceNo,
      },
      requiresVpc: true,
    };
    return this.apiRequest.request(requestProps);
  }

  getCDRCalls(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "Invoices/GetCDRCalls",
      body: request,
      requiresVpc: true,
    };
    return this.apiRequest.request(requestProps);
  }

  getCreditNote(invoiceNo: string) {
    const requestProps: IRequestOptions = {
      endpoint: "invoices/GetCreditNote",
      body: invoiceNo,
      requiresVpc: true,
    };
    return this.apiRequest.request(requestProps);
  }

  getCreditNotesList(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "invoices/GetCreditNotesList",
      body: request,
      requiresVpc: true,
    };
    return this.apiRequest.request(requestProps);
  }

  getCreditNotesCount(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "invoices/GetCreditNotesCount",
      body: request,
      requiresVpc: true,
    };
    return this.apiRequest.request(requestProps);
  }

  getManualInvoice(invoiceNo: string) {
    const requestProps: IRequestOptions = {
      endpoint: "invoices/GetManualInvoice",
      body: invoiceNo,
      requiresVpc: true,
    };
    return this.apiRequest.request(requestProps);
  }

  getInvoices(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "Invoices/GetInvoices",
      body: request,
      requiresVpc: true,
    };
    return this.apiRequest.request(requestProps);
  }

  getInvoicesList(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "Invoices/GetInvoicesList",
      body: request,
      requiresVpc: true,
    };
    return this.apiRequest.request(requestProps);
  }

  getInvoicesCount(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "Invoices/GetInvoicesCount",
      body: request,
      requiresVpc: true,
    };
    return this.apiRequest.request(requestProps);
  }
}
