import { Injectable } from "@angular/core";
import { IServiceHubMenuItem, menu } from "./menu";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  private menuItems: IServiceHubMenuItem[] = menu;
  private userMenuItems: IServiceHubMenuItem[] = [];
  constructor() {}

  setUserMenuItems(user) {
    this.filterQuickPath();
    this.userMenuItems = this.filterMenuItemsByPermission(
      this.menuItems,
      user.uTimicoPortalPermissions
    );
  }

  getUserMenuItems(): IServiceHubMenuItem[] {
    return this.userMenuItems;
  }

  getUserMegaMenuItems(): IServiceHubMenuItem[][] {
    return this.userMenuItems.map((menuItem) => {
      return [menuItem];
    });
  }

  filterQuickPath() {
    const usageMonitoringItem = this.menuItems.find(
      (item) => item.label === "Usage & Monitoring"
    );
    if (
      usageMonitoringItem &&
      usageMonitoringItem.items &&
      usageMonitoringItem.items.length < 5
    ) {
      this.menuItems.forEach((subItem) => {
        subItem.isQuickPath = false;
      });
    }
  }

  getQuickPathMenuItems(menu: IServiceHubMenuItem[]): IServiceHubMenuItem[] {
    const quickPathItems: IServiceHubMenuItem[] = [];
    for (const item of menu) {
      if (item.isQuickPath) {
        quickPathItems.push(item);
      }
      if (item.items) {
        quickPathItems.push(...this.getQuickPathMenuItems(item.items));
      }
    }
    return quickPathItems;
  }

  filterMenuItemsByPermission(
    menuItems: IServiceHubMenuItem[],
    userPermissions: string[]
  ): IServiceHubMenuItem[] {
    return menuItems
      .map((menuItem) => {
        const filteredItems = menuItem.items
          ? this.filterMenuItemsByPermission(menuItem.items, userPermissions)
          : [];
        const hasPermission = menuItem.permissions.some((permission) =>
          userPermissions.includes(permission)
        );
        if (hasPermission || filteredItems.length > 0) {
          const clonedMenuItem = { ...menuItem };
          if (filteredItems.length > 0) {
            clonedMenuItem.items = filteredItems;
          }
          return clonedMenuItem;
        }
        return null;
      })
      .filter((menuItem) => menuItem !== null) as IServiceHubMenuItem[];
  }
}
