import { Component, OnInit, ViewChild } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { NotificationService } from "src/app/services/notification.service";
import {
  Notification,
  INotificationColumn,
  NotificationColumns,
} from "../models/notification-models";
import { cloneDeep } from "lodash-es";
import { takeUntil } from "rxjs/operators";
import { firstValueFrom, Subject } from "rxjs";
import { MenuItem } from "primeng/api/menuitem";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { Table } from "primeng/table";
import { DialogService } from "primeng/dynamicdialog";
import { DateTime } from "luxon";
import { LazyLoadEvent } from "primeng/api";
import { IsPaginatorState, TableReset } from "src/app/helpers/table.helper";
import { Filters, TableColumn } from "src/app/models/table";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { NotificationsService } from "src/app/services/api/notifications/notifications.service";
import { Paginator, PaginatorState } from "primeng/paginator";
import { NotificationHelper } from "src/app/helpers/notifications/notification.helper";

@Component({
  selector: "app-notification-table",
  templateUrl: "./notification-table.component.html",
  styleUrls: ["./notification-table.component.scss"],
  providers: [DialogService],
})
export class NotificationTableComponent implements OnInit {
  @ViewChild("dataTable") dataTable: Table;
  @ViewChild("tablePaginator") paginator: Paginator;
  loading: boolean = false;
  notificationSubject = new BehaviorSubject(null);
  notifications$ = this.notificationSubject.asObservable();
  notifications: Notification[];
  currentPage: number = 0;
  rowCount: number = 20;
  markAllReadAvailable: boolean = false;
  destroy$ = new Subject();
  firstLoad: boolean = true;
  tableColumn: INotificationColumn[];
  selectedColumns: any[] = [];
  columns: TableColumn[] = NotificationColumns;
  totalRecords: number = 0;

  constructor(
    public notificationService: NotificationService,
    private notificationsService: NotificationsService,
    private breadcrumbService: BreadcrumbService,
    private notificationHelper: NotificationHelper
  ) {}

  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [{ label: "Notifications" }];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }

  async loadNotifications(
    table: Table,
    event?: LazyLoadEvent | PaginatorState
  ) {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }

    const isFirstLoad = this.firstLoad ? true : false;
    table.loading = true;

    this.notificationSubject.next([]);

    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(NotificationColumns));
      this.firstLoad = false;
    }

    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;
    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.columns,
      currentPerPage,
      currentPage,
      event
    );

    const response = await firstValueFrom(
      this.notificationsService.getAllNotifications(serviceNowFilter)
    );
    this.notifications = response.result.map((item) => {
      const formattedDate = DateTime.fromFormat(
        item.lastUpdated,
        "dd/MM/yyyy HH:mm"
      )
        .setZone(DateTime.local().zoneName)
        .toFormat("dd/MM/yyyy");

      return {
        ...item,
        lastUpdated: formattedDate,
      };
    });
    this.notificationSubject.next(this.notifications);
    this.totalRecords = response.overallQueryCount;
    this.setNotification();
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    table.loading = false;
  }

  setNotification() {
    this.notificationService
      .getMarkAllReadUpdate()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.markAllReadUpdate();
      });
    if (
      this.notifications.some(
        (notification) => notification.uNotificationRead === false
      )
    )
      this.markAllReadAvailable = true;
  }

  handleRowClicked(notification: Notification) {
    this.openNotification(notification);
  }

  openNotification(notification: Notification) {
    this.notificationHelper.openNotification(notification);
  }

  markAllReadUpdate() {
    this.notifications.forEach((notification) => {
      notification.uNotificationRead = true;
    });
    this.markAllReadAvailable = false;
  }

  markAllReadSelected() {
    this.notificationHelper.markAllAsRead();
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = NotificationColumns
  ) {
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
