import { Injectable } from "@angular/core";
import {
  UntypedFormArray,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import {
  ISelectedRole,
  IServiceNowRoleCreate,
  IServiceNowRoleUpdate,
} from "./role-models";
import { PermissionsService } from "src/app/services/api/permissions/permissions.service";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RoleManagementService {
  selectedRole: ISelectedRole;
  selectedRoleEdit: ISelectedRole;

  constructor(private permissionsService: PermissionsService) {}

  async getAllPortalPermissions() {
    let permissions = await firstValueFrom(
      this.permissionsService.getPermissions("UServicehubConfigurable", "true")
    );

    let groupedPermissions = await firstValueFrom(
      this.permissionsService.getGroupPortalPermissions(permissions)
    );

    return groupedPermissions;
  }

  async getPermissionsForSelectedRole(sysId) {
    let permissions = await firstValueFrom(
      this.permissionsService.getPermissionsFromRoleCategorised(sysId)
    );

    return permissions;
  }

  async createRole(role: IServiceNowRoleCreate) {
    const result = await firstValueFrom(
      this.permissionsService.createPortalRole(role)
    );
    return result;
  }

  async saveRole(role: IServiceNowRoleUpdate) {
    const result = await firstValueFrom(
      this.permissionsService.updatePortalRole(role)
    );
    return result;
  }

  validatePermissionSelected(): ValidatorFn {
    return (formArray: UntypedFormArray): ValidationErrors | null => {
      let permissionExists = false;

      formArray.controls.forEach((category) => {
        let permissions = category.value["permissions"];
        if (permissions) {
          permissions.forEach((permission) => {
            if (permission.active) {
              permissionExists = true;
              return null;
            }
          });
        }
      });

      if (permissionExists) {
        return null;
      } else {
        return { noPermissionSelected: true };
      }
    };
  }
}
