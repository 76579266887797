import { TableColumn, TableSort } from "src/app/models/table";
import { DateTime } from "luxon";
import {
  serviceRequestColors,
  ServiceRequestTableColumnName,
} from "src/app/models/service-request/service-request.models";

enum exportField {
  number = "number",
  shortDescription = "subject",
  description = "description",
  requestedFor = "RequestedFor",
  openedAt = "openedAt",
  state = "state",
}

export const serviceRequestOpenColumns: TableColumn[] = [
  {
    field: "number",
    header: "Number",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    disabled: true,
    serviceNowField: ServiceRequestTableColumnName.number,
    exportField: exportField.number,
  },
  {
    field: "shortDescription",
    header: "Subject",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: ServiceRequestTableColumnName.shortDescription,
    exportField: exportField.shortDescription,
  },
  {
    field: "requestedFor",
    header: "Request For",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: ServiceRequestTableColumnName.requestedFor,
    exportField: exportField.requestedFor,
  },
  {
    field: "openedAt",
    header: "Opened At",
    display: { type: "date" },
    filter: { type: "dateRange" },
    showSortIcon: true,
    sortColumnOrder: 1,
    sortOrder: TableSort.desc,
    default: true,
    serviceNowField: ServiceRequestTableColumnName.openedAt,
    exportField: exportField.openedAt,
  },
  {
    field: "state",
    header: "State",
    display: {
      type: "chip",
      colors: serviceRequestColors,
    },
    filter: {
      type: "multiSelect",
      values: [
        { label: "Pending", value: "Pending" },
        { label: "Open", value: "Open" },
        { label: "Work in progress", value: "Work in progress" },
        { label: "Awaiting info", value: "Awaiting info" },
        { label: "Fulfilled", value: "Fulfilled" },
      ],
    },

    showSortIcon: true,
    default: true,
    serviceNowField: ServiceRequestTableColumnName.state,
    exportField: exportField.state,
  },
];

const serviceRequestDefaultClosedDays = 90;

export const serviceRequestClosedColumns: TableColumn[] = [
  {
    field: "number",
    header: "Number",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    disabled: true,
    serviceNowField: ServiceRequestTableColumnName.number,
    exportField: exportField.number,
  },
  {
    field: "shortDescription",
    header: "Subject",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: ServiceRequestTableColumnName.shortDescription,
    exportField: exportField.shortDescription,
  },
  {
    field: "requestedFor",
    header: "Request For",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: ServiceRequestTableColumnName.requestedFor,
    exportField: exportField.requestedFor,
  },
  {
    field: "openedAt",
    header: "Opened At",
    display: { type: "date" },
    filter: {
      type: "dateRange",
      preset: {
        matchMode: "between",
        value: [
          DateTime.now()
            .minus({ days: serviceRequestDefaultClosedDays })
            .toJSDate(),
          DateTime.now().toJSDate(),
        ],
      },
      message: `By default the last ${serviceRequestDefaultClosedDays} days of closed service requests are shown`,
    },
    showSortIcon: true,
    sortColumnOrder: 1,
    sortOrder: TableSort.desc,
    default: true,
    serviceNowField: ServiceRequestTableColumnName.openedAt,
    exportField: exportField.openedAt,
  },
  {
    field: "state",
    header: "State",
    display: {
      type: "chip",
      colors: serviceRequestColors,
    },
    filter: null,
    showSortIcon: true,
    default: true,
    serviceNowField: ServiceRequestTableColumnName.state,
    exportField: exportField.state,
  },
];
