import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  DeleteDnsComponent,
  DeleteDnsRecordData,
} from "src/app/components/misc/pop-up/delete-dns/delete-dns.component";

import { DomainService } from "src/app/services/api/domain/domain.service";
import { DomainHelpersService } from "src/app/services/domain.service";
import { PermissionService } from "src/app/services/permissions.service";
import { MenuItem, Message, MessageService } from "primeng/api";
import { ExportTable } from "../../science-logic/cmdb-devices/shared/export-devices";
import {
  IDomainColumn,
  IDomainColums,
  IDomainRecord,
  IGetLandscapeDomainInformationResponse,
  ILandscapeDomainRecordUpdate,
  ILandscapeRecord,
  Route53Record,
  domainColumns,
} from "../shared/domain-models";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Table } from "primeng/table";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { ToastService, ToastType } from "src/app/services/global/toast.service";
import { AddEditDNSRecordComponent } from "src/app/components/misc/pop-up/add-edit-dns-record/add-edit-dns-record.component";
import {
  CleanDnsRecordString,
  ConvertTTLDisplayToNumber,
  HasArrayOverlap,
} from "src/app/helpers/dns.helper";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { TableColumn } from "src/app/models/table";
import { cloneDeep } from "lodash-es";
import { TableReset } from "src/app/helpers/table.helper";

export enum DomainAdminEvent {
  HostedZoneDeleted = "HostedZoneDeleted",
  HostedZoneSuspend = "HostedZoneSuspend",
  HostedZoneResume = "HostedZoneResume",
  HostedZoneAdd = "HostedZoneAdd",
}

export type DnsRecord = {
  // id: string | number;
  name: string;
  type: string;
  ttl: number;
  value: string[];
  canEdit: boolean;
  canDelete: boolean;
  route53Record: any;
  landscapeRecord: any;
};

export type DomainInfo = {
  name: string;
  company: string;
  reverseDns: boolean;
};

export type DnsCheck = {
  isLandscape: boolean;
  isDsManaged: boolean;
  isRoute53: boolean;
  nsRecords: string[];
};

export enum DomainManageUserMessages {
  ChangesInProgress = `The domain is changing. Please refresh the page.`,
  HostedZoneNotCreated = `Zone not yet created.`,
}

export type RecordsSource = "landscape" | "route53" | null;

@Component({
  selector: "domain",
  templateUrl: "domain.component.html",
  styleUrls: ["domain.component.scss"],
  providers: [DialogService, MessageService],
})
export class DomainComponent implements OnInit {
  @ViewChild("dt1") dt1: Table;
  ref: DynamicDialogRef | undefined;
  loading: boolean = false;

  domainSysId: string;
  domainInfo: DomainInfo;

  isDsAdmin: boolean = false;
  // isDsManaged: boolean = false;

  domainRecordsSubject = new BehaviorSubject<DnsRecord[]>(null);
  domainRecords$ = this.domainRecordsSubject.asObservable();

  domainStatus = new BehaviorSubject<undefined | string>(undefined);
  domainStatus$ = this.domainStatus.asObservable();

  tableColumns: IDomainColumn[];

  landscapeResponse: IGetLandscapeDomainInformationResponse;

  dnsCheck: DnsCheck;

  messages: Message[] | undefined;

  canView = false;

  changeInProgress: boolean = false;
  cols: TableColumn[];
  firstLoad: boolean = true;
  _selectedColumns: TableColumn[] = [];

  typeList: string[] = [
    "NS",
    "SOA",
    "A",
    "AAAA",
    "CNAME",
    "MX",
    "SRV",
    "TXT",
    "PTR",
  ];

  recordsSource: RecordsSource;

  constructor(
    private activatedRoute: ActivatedRoute,
    private domainService: DomainService,
    public dialogService: DialogService,
    private permissionService: PermissionService,
    private router: Router,
    private domainHelperService: DomainHelpersService,
    public toastService: ToastService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      {
        label: "Domains",
        routerLink: "/secure/products-and-services/domains/",
      },
      { label: "DNS Management" },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);

    this.isDsAdmin = !this.permissionService.checkPermission(
      "INTERNAL_DNS_ADMIN"
    )
      ? false
      : true;
    this.domainSysId = this.activatedRoute.snapshot.params.id;
    this.tableColumns = domainColumns;
    await this.loadDomain();
  }

  async handleDomainAdminEvent(event) {
    if (event === DomainAdminEvent.HostedZoneSuspend) {
      this.domainStatus.next("SUSPEND_IN_PROGRESS");
      this.canView = false;
      this.changeInProgress = true;
    }
    if (event === DomainAdminEvent.HostedZoneResume) {
      this.domainStatus.next("RESUME_IN_PROGRESS");
      this.canView = false;
      this.changeInProgress = true;
    }
    if (event === DomainAdminEvent.HostedZoneDeleted) {
      this.toastService.add({
        severity: ToastType.warning,
        summary: "DNS Record Management",
        detail: "Hosted Zone Delete",
      });
      await this.loadDomain();
    }
    if (event === DomainAdminEvent.HostedZoneAdd) {
      await this.loadDomain();
    }
  }

  async getDomainInfo(domainSysId): Promise<DomainInfo> {
    try {
      return await firstValueFrom(this.domainService.getDomain(domainSysId));
    } catch (error) {
      if (error?.status === 404) {
        this.toastService.add({
          severity: ToastType.error,
          summary: "DNS Record Management",
          detail: error?.error?.message,
        });
        this.router.navigate(["secure/products-and-services/domains"]);
      }
      // TODO handle any other type of error here
    }
  }

  async getDomainDns(domainName: string): Promise<DnsCheck> {
    try {
      return await firstValueFrom(this.domainService.getDnsCheck(domainName));
    } catch (error) {
      this.messages = [
        {
          severity: "warn",
          detail: `An error occurred when trying to resolve the NS records for ${this.domainInfo.name}. This may be expected if the domain is not registered yet`,
        },
      ];
    }
  }

  async getDomainRoute53(domainSysId: string) {
    try {
      const domainStatus = await firstValueFrom(
        this.domainService.getDomainStatus(domainSysId)
      );
      const records = await firstValueFrom(
        this.domainService.getDomainRecords(this.domainSysId)
      );
      return {
        domainStatus,
        records: this.buildRecords(records, "route53Record"),
      };
    } catch (error) {
      return null;
    }
  }

  async getDomainLandscape(domainName) {
    try {
      const landscapeResponse = await firstValueFrom(
        this.domainService.getRecordsLandscape(domainName)
      );
      const landscapeDomainDeleted =
        landscapeResponse?.response?.deleted ?? false;
      if (landscapeResponse && !landscapeDomainDeleted) {
        return {
          ...landscapeResponse?.response,
          records: this.buildRecords(
            landscapeResponse?.response?.records.length
              ? landscapeResponse?.response?.records.map((record) => {
                  return {
                    ...record,
                    ttl: ConvertTTLDisplayToNumber(record.ttl),
                    value: [record.value],
                  };
                })
              : [],
            "landscapeRecord"
          ),
        };
      }
    } catch (error) {
      // console.error(error);
      return null;
    }
  }

  async loadDomain() {
    this.loading = true;

    this.cols = cloneDeep(IDomainColums).filter(
      (col) => !(col.field === "edit" || col.field === "remove")
    );

    this.changeInProgress = false;
    // Get domain info from Service Now
    this.domainInfo = await this.getDomainInfo(this.domainSysId);
    // Check who hosts DNS and get NS records
    this.dnsCheck = await this.getDomainDns(this.domainInfo.name);
    // Check if Route 53 domain, get domain status and records
    const route53 = await this.getDomainRoute53(this.domainSysId);
    this.domainStatus.next(route53?.domainStatus);
    let landscape = null;
    // Default to Route53 if hosted zone exists
    let records = route53?.records?.length ? route53?.records : [];
    // TODO remove when landscape retired
    // If not Route53 check if Landscape
    if (!route53) {
      landscape = await this.getDomainLandscape(this.domainInfo.name);
      // If landscape and landscape domain not deleted, set landscape Response for Add/Edit and records
      if (landscape) {
        records = landscape?.records;
        this.landscapeResponse = landscape;
      }
    }
    // Set source of records
    this.recordsSource = this.setRecordsSource(route53, landscape);
    // Set and display messages for user
    this.messages = this.setUserMessages(
      route53,
      landscape,
      this.dnsCheck,
      this.domainInfo
    );
    // Determine if user can view
    this.canView = this.checkUserCanView(route53, landscape);
    this.domainRecordsSubject.next(records);
    this.loading = false;
  }

  setRecordsSource(route53, landscape): RecordsSource {
    if (route53) {
      return "route53";
    }
    if (landscape) {
      return "landscape";
    }
    return null;
  }

  setUserMessages(route53, landscape, dnsCheck, domainInfo) {
    if (
      ["RESUME_IN_PROGRESS", "SUSPEND_IN_PROGRESS"].includes(
        route53?.domainStatus?.suspendedStatus
      )
    ) {
      this.changeInProgress = true;
      return [
        {
          severity: "warn",
          detail: DomainManageUserMessages.ChangesInProgress,
        },
      ];
    }
    if (!route53 && !landscape) {
      return [
        {
          severity: "warn",
          // TODO write a better message, message probably needs to be different for DNS admins and customers
          detail: DomainManageUserMessages.HostedZoneNotCreated,
        },
      ];
    }
    const route53NsRecords = this.getDomainNsRecords(route53?.records);
    // if (route53 && dnsCheck?.isLandscape) {
    //   return [
    //     {
    //       severity: "warn",
    //       detail: `
    //         <p class="mb-2">We have recently migrated our DNS to a new platform, when making this change we have migrated your domain records to this platform and our old platform have been deprecated.</p>
    //         <p class="mb-2">Current name servers are: <br><br> ${dnsCheck?.nsRecords?.join(
    //           "<br>"
    //         )}</p>
    //         <p class="mb-2">Below is a list of records that were migrated to the new platform but our records indicate you have not yet changed your domain registrar to reflect this change, communication around this change has been sent via email to an address we have on file for you.</p>
    //         <p class="mb-2"><strong>Please note: Change made here will not be reflected until you update your domain registrar, Please update the name servers with your domain name provider to the following: <br><br> ${dnsCheck?.nsRecords?.join(
    //           "<br>"
    //         )}</strong></p>
    //         <p>Please contact support if you have any issues.</p>
    //       `,
    //     },
    //   ];
    // }
    if (
      route53NsRecords &&
      !HasArrayOverlap(
        route53NsRecords.map((record) => record.replace(/\.$/, "")),
        dnsCheck?.nsRecords?.length
          ? dnsCheck?.nsRecords?.map((record) => record.replace(/\.$/, ""))
          : []
      )
    ) {
      let detail = `<p class="mb-2">${
        domainInfo.name
      } does not point to Digital Space managed name servers, any DNS changes made will not be reflected until the name servers are updated.</p>
        <p class="mb-2">Current name servers are: <br><br>
        ${
          dnsCheck?.nsRecords?.length
            ? dnsCheck?.nsRecords?.join("<br> ")
            : "Not Found"
        }</p>
          <p class="mb-2">Please update the name servers with your domain name provider to the following: <br><br> ${route53NsRecords?.join(
            "<br>"
          )}</p>`;
      return [
        {
          severity: "warn",
          detail: detail,
        },
      ];
    }
    return [];
  }

  getDomainNsRecords(records: DnsRecord[]): string[] {
    const record = records?.find(
      (record) =>
        ["NS", "IN NS"].includes(record.type.toUpperCase()) &&
        record.name === "@"
    );
    return record?.value;
  }

  checkUserCanView(route53, landscape) {
    if (
      ["SUSPENDED", "RESUME_IN_PROGRESS", "SUSPEND_IN_PROGRESS"].includes(
        route53?.domainStatus?.suspendedStatus
      )
    ) {
      return false;
    }
    if (!route53 && !landscape) {
      return false;
    }
    return true;
  }

  buildRecords(
    sourceRecords: any,
    type: "route53Record" | "landscapeRecord"
  ): DnsRecord[] {
    return this.sortDnsRecords(
      sourceRecords.map((sourceRecord) => {
        const recordType = CleanDnsRecordString(
          sourceRecord.type,
          "in "
        ).toUpperCase();
        const name = CleanDnsRecordString(
          sourceRecord.name,
          this.domainInfo.name
        );
        const canModify = this.canModifyRecord(
          { ...sourceRecord, type: recordType },
          this.domainInfo.name
        );
        const record: DnsRecord = {
          name: name ? name : "@",
          type: recordType,
          ttl: sourceRecord.ttl,
          value: sourceRecord.value,
          route53Record: null,
          landscapeRecord: null,
          canEdit: canModify,
          canDelete: canModify,
        };
        record[type] = sourceRecord;
        return record;
      })
    );
  }

  sortDnsRecords(records: DnsRecord[]): DnsRecord[] {
    // Function to get the index of the type in the typeList
    const getTypeIndex = (type: string): number => {
      const index = this.typeList.indexOf(type);
      return index !== -1 ? index : this.typeList.length; // Return typeList.length if the type is not found to place it at the end
    };
    // Sort by type based on typeList order, then by name
    return records.sort((a, b) => {
      if (a.name === "@" && b.name !== "@") {
        return -1; // '@' records come before non-'@' records
      } else if (a.name !== "@" && b.name === "@") {
        return 1; // Non-'@' records come after '@' records
      } else {
        // Both records are either '@' or non-'@'
        const typeIndexA = getTypeIndex(a.type);
        const typeIndexB = getTypeIndex(b.type);

        if (a.name === "@" && b.name === "@") {
          // Both records are '@' records, so sort by type, then by value
          if (typeIndexA < typeIndexB) {
            return -1;
          } else if (typeIndexA > typeIndexB) {
            return 1;
          } else {
            // typeIndexA === typeIndexB
            return a.value.toString().localeCompare(b.value.toString()); // Compare values alphabetically
          }
        } else {
          // Both records are non-'@' records, so sort by type, then by name, then by value
          if (typeIndexA < typeIndexB) {
            return -1;
          } else if (typeIndexA > typeIndexB) {
            return 1;
          } else {
            // typeIndexA === typeIndexB
            const nameComparison = a.name.localeCompare(b.name);
            if (nameComparison !== 0) {
              return nameComparison; // If names are different, sort by name
            } else {
              // Names are the same, so compare values
              return a.value.toString().localeCompare(b.value.toString());
            }
          }
        }
      }
    });
  }

  canModifyRecord(record, domain) {
    if (["SOA"].includes(record.type)) {
      return false;
    }
    if (
      ["NS"].includes(record.type) &&
      (record.name === `${domain}.` || record.name === "@")
    ) {
      return false;
    }
    return true;
  }

  async addEditRecord(record: null) {
    this.ref = this.dialogService.open(AddEditDNSRecordComponent, {
      showHeader: false,
      width: "50%",
      data: {
        domainSysId: this.domainSysId,
        domainName: this.domainInfo.name,
        recordsSource: this.recordsSource,
        landscapeResponse: this.landscapeResponse,
        isReverse: this.domainInfo.reverseDns,
        existingRecords: this.domainRecordsSubject.value,
        record: record,
      },
    });
    this.ref.onClose.subscribe(async (result) => {
      if (result) {
        this.toastService.add({
          severity: ToastType.success,
          summary: "DNS Record Management",
          detail: "DNS Record Saved.",
        });
        await this.loadDomain();
      } else if (!result) {
        this.toastService.add({
          severity: ToastType.warning,
          summary: "DNS Record Management",
          detail: "Operation Cancelled.",
        });
      }
    });
  }

  // TODO Promise.all these deletion requests
  async deleteRecord(record: IDomainRecord) {
    const deleteDomainRecordData: DeleteDnsRecordData = {
      domain: this.domainInfo.name,
      subDomain: record.name,
      ttl: record.ttl,
      type: record.type,
      value: record.value,
    };

    this.ref = this.dialogService.open(DeleteDnsComponent, {
      showHeader: false,
      width: "34%",
      data: deleteDomainRecordData,
    });

    this.ref.onClose.subscribe(async (dialogResult) => {
      if (dialogResult) {
        this.loading = true;
        if (record.route53Record)
          await this.deleteRoute53Record(record.route53Record);
        if (record.landscapeRecord)
          await this.deleteLandscapeRecord(record.landscapeRecord);
        this.toastService.add({
          severity: ToastType.success,
          summary: "DNS Record Management",
          detail: "Domain deleted.",
        });
        await this.loadDomain();
        this.loading = false;
      } else {
        this.toastService.add({
          severity: ToastType.warning,
          summary: "DNS Record Management",
          detail: "Operation Cancelled.",
        });
      }
    });
  }

  async deleteLandscapeRecord(landscapeRecord: ILandscapeRecord) {
    const deleteLandscapeRecordUpdate: ILandscapeDomainRecordUpdate = {
      operation: "REMOVE",
      domainName: this.domainInfo.name,
      id: landscapeRecord.id,
      name: landscapeRecord.name,
      text: landscapeRecord.text,
      ttl: landscapeRecord.ttl,
      type: landscapeRecord.type,
      value: landscapeRecord.value.toString(),
      version: landscapeRecord.version,
    };
    let deleteLandscapeRecordRequest: any = this.landscapeResponse;
    const deleteRecordUpdate: ILandscapeDomainRecordUpdate[] = [
      deleteLandscapeRecordUpdate,
    ];
    deleteLandscapeRecordRequest.updates = deleteRecordUpdate;
    const response = await firstValueFrom(
      this.domainService.updateDomain(deleteLandscapeRecordRequest)
    );
    return response;
  }

  async deleteRoute53Record(record: Route53Record) {
    let deleteRoute53RecordRequest: Route53Record = {
      name: record.name,
      ttl: record.ttl,
      type: record.type,
      value: record.value,
    };
    const response = await firstValueFrom(
      this.domainService.deleteDomainRecord(
        this.domainSysId,
        deleteRoute53RecordRequest
      )
    );
    return response;
  }

  defaultExport() {
    const exportColums = domainColumns.filter(
      (column) => column.field !== "edit" && column.field !== "remove"
    );
    ExportTable(this.dt1, this.domainRecordsSubject.value, exportColums, "all");
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = IDomainColums
  ) {
    this.loadDomain();
    TableReset(table, columns, {
      firstLoad,
    });
  }
}
