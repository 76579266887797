import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { IPermission } from "src/app/components/service-hub/company/roles/shared/role-models";
import { UserService } from "src/app/services/api/user/user.service";
import { FormGroup } from "@angular/forms";
import { ToggleSelectDeselectAll } from "src/app/helpers/select.helper";

type PermissionOptions = {
  selectedRolePermissionCategories: {
    name: string;
    order: number;
    permissions: IPermission[];
  }[];
  additionalPermissionCategories: {
    name: string;
    order: number;
    permissions: IPermission[];
  }[];
};

@Component({
  selector: "app-add-edit-user-permissions",
  templateUrl: "./add-edit-user-permissions.component.html",
  styleUrls: ["./add-edit-user-permissions.component.scss"],
})
export class AddEditUserPermissionsComponent implements OnInit {
  @Input() addEditUserPermissionsForm: FormGroup;
  @Input() selectedRole: any;
  @Output() childIsLoading = new EventEmitter();
  loading: boolean = true;
  permissionOptions: PermissionOptions = {
    selectedRolePermissionCategories: [],
    additionalPermissionCategories: [],
  };
  selectedAdditionalPermissions: any[] = [];

  constructor(private userService: UserService) {}

  async ngOnInit() {
    this.loading = true;
    this.childIsLoading.emit(true);
    await this.loadAllMissingPermissions();
    this.loading = false;
    this.childIsLoading.emit(false);
  }

  async loadAllMissingPermissions() {
    let rolePermissions = [];
    if (this.selectedRole) {
      const permissionOptions = await firstValueFrom(
        this.userService.getRolePermissionsWithAdditional(
          this.selectedRole.sysId
        )
      );
      this.permissionOptions.additionalPermissionCategories =
        this.addCategoryToPermissions(permissionOptions.additionalPermissions);
      this.permissionOptions.selectedRolePermissionCategories =
        this.addCategoryToPermissions(permissionOptions.rolePermissions);
      this.permissionOptions.selectedRolePermissionCategories.map((item) => {
        rolePermissions = [...rolePermissions, ...item.permissions];
      });
      let additionalPermissions = [];
      this.permissionOptions.additionalPermissionCategories.map((item) => {
        additionalPermissions = [...additionalPermissions, ...item.permissions];
      });
      this.addEditUserPermissionsForm.get("additionalPermissions").setValue(
        additionalPermissions.filter((permissionOptionAdditionalPermission) =>
          this.addEditUserPermissionsForm
            .get("additionalPermissions")
            .value.map((additionalPermission) => additionalPermission.sysId)
            .includes(permissionOptionAdditionalPermission.sysId)
        )
      );
      this.selectedAdditionalPermissions = this.addEditUserPermissionsForm.get(
        "additionalPermissions"
      ).value;
    }
    this.addEditUserPermissionsForm
      .get("rolePermissions")
      .setValue(rolePermissions);
  }

  addCategoryToPermissions(categories) {
    return categories.map((category) => {
      return {
        ...category,
        permissions: category.permissions.map((permission) => {
          return { ...permission, category: category.name };
        }),
      };
    });
  }

  onPermissionsChange() {
    this.addEditUserPermissionsForm
      .get("additionalPermissions")
      .setValue(this.selectedAdditionalPermissions);
  }

  allCategoryPermissionsSelected(categoryPermissions): boolean {
    return categoryPermissions.every((permission) =>
      this.selectedAdditionalPermissions.includes(permission)
    );
  }

  toggleSelectCategoryPermissions(categoryPermissions) {
    this.selectedAdditionalPermissions = ToggleSelectDeselectAll(
      categoryPermissions,
      this.selectedAdditionalPermissions
    );
    this.onPermissionsChange();
  }
}
