import { Directive } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { isValidPhoneNumber } from "libphonenumber-js";
import { environment } from "src/environments/environment";

export function mobileNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    const regex = new RegExp("^07[0-9]{9}$", "i");

    const valid =
      environment.name === "development" || environment.name === "uat"
        ? isValidPhoneNumber(value, "GB")
        : regex.test(value);

    return !valid ? { mobileNumberForbidden: { value: value } } : null;
  };
}
