import { Injectable } from "@angular/core";
import { User } from "src/app/components/service-hub/company/users/users.model";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";
import { EditContact } from "src/app/models/contacts/contacts-model";
import { ServiceNowFilterQuery } from "src/app/models/servicenow.model";

@Injectable({
  providedIn: "root",
})
export class ContactsService {
  constructor(private apiRequest: ApiRequest) {}

  getContacts(userIds: string[]) {
    const requestProps: IRequestOptions = {
      endpoint: "Contact/GetContacts",
      body: userIds,
    };
    return this.apiRequest.request(requestProps);
  }

  getContactsList(filter: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "Contact/GetContactsList",
      body: filter,
    };
    return this.apiRequest.request(requestProps);
  }

  getContact(userId: string) {
    const requestProps: IRequestOptions = {
      endpoint: "Contact/GetContact",
      body: userId,
    };
    return this.apiRequest.request(requestProps);
  }

  createContact(contact: EditContact) {
    const requestProps: IRequestOptions = {
      endpoint: "Contact/CreateContact",
      body: contact,
    };
    return this.apiRequest.request(requestProps);
  }

  createContacts(users: User[]) {
    const requestProps: IRequestOptions = {
      endpoint: "Contact/CreateContacts",
      body: users,
    };
    return this.apiRequest.request(requestProps);
  }

  editContact(contact: EditContact) {
    const requestProps: IRequestOptions = {
      endpoint: "Contact/EditContact",
      body: contact,
    };
    return this.apiRequest.request(requestProps);
  }

  editContacts(users: User[]) {
    const requestProps: IRequestOptions = {
      endpoint: "Contact/EditContacts",
      body: users,
    };
    return this.apiRequest.request(requestProps);
  }

  checkContactEmailExists(email) {
    const requestProps: IRequestOptions = {
      endpoint: "Contact/CheckContactUsernameExists",
      body: email,
    };
    return this.apiRequest.request(requestProps);
  }
}
