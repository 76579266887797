import { Inject, Injectable } from "@angular/core";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";
import {
  ICostCentrePost,
  ICostCentrePostCLI,
} from "src/app/components/service-hub/billing/costcentres-users/shared/models";
import { ServiceNowFilterQuery } from "src/app/models/servicenow.model";

@Injectable({
  providedIn: "root",
})
export class UnityService {
  constructor(private apiRequest: ApiRequest) {}

  getCostCentresBySiteId(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "Unity/GetCostCentresBySiteID",
      body: request,
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }

  getUsersBySiteId(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "Unity/GetUsersBySiteID",
      body: request,

      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }

  updateSiteProductByIDList(updatedCostCentres: ICostCentrePost[]) {
    const requestProps: IRequestOptions = {
      endpoint: "Product/UpdateSiteProductByIDList",
      body: updatedCostCentres,
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }

  updateCLIDetailsByIDList(updatedCostCentres: ICostCentrePostCLI[]) {
    const requestProps: IRequestOptions = {
      endpoint: "Unity/UpdateCLIDetailsByIDList",
      body: updatedCostCentres,
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }
}
