import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { cloneDeep } from "lodash-es";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ProductService } from "../shared/product.service";
import { securityColumns } from "../models/security.models";
import { LazyLoadEvent } from "primeng/api";
import { Table } from "primeng/table";
import { ExportTable } from "../../products-and-services/science-logic/cmdb-devices/shared/export-devices";
import {
  IsPaginatorState,
  SetSelectedColumns,
  TableReset,
} from "src/app/helpers/table.helper";

import {
  ExportFileName,
  ExportService,
  ExportType,
} from "src/app/services/export.service";
import { ITableToFile } from "src/app/models/export-models";
import { ToastService } from "src/app/services/toast.service";
import { ProductCategories } from "../models/billing-models";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { TableColumn } from "src/app/models/table";
import { Paginator, PaginatorState } from "primeng/paginator";

@Component({
  selector: "app-security",
  templateUrl: "./security.component.html",
  styleUrls: ["./security.component.scss"],
})
export class SecurityComponent implements OnInit, OnDestroy {
  @ViewChild("dataTable") dataTable: Table;
  @ViewChild("tablePaginator") paginator: Paginator;
  @Input() selectedSite$ = new Observable();
  @Output() loadingChange = new EventEmitter<boolean>();
  selectedSiteId: string;

  cdr = inject(ChangeDetectorRef);

  tableDataSubject = new BehaviorSubject(null);
  tableData$ = this.tableDataSubject.asObservable();

  cols: TableColumn[] = cloneDeep(securityColumns);
  totalRecords: number = 0;
  firstLoad: boolean = true;
  _selectedColumns: TableColumn[] = [];
  tableLoading: boolean = false;
  staticTable: Table;
  destroy$ = new Subject<any>();

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  exportOptions = [
    {
      label: "All",
      value: "all",
      command: () => this.exportAll(),
    },
    {
      label: "In View",
      value: "in_view",
      command: () =>
        ExportTable(
          this.dataTable,
          this.tableDataSubject.value,
          this._selectedColumns,
          "all"
        ),
    },
  ];

  constructor(
    private productService: ProductService,
    private toastService: ToastService,
    private exportService: ExportService
  ) {}

  async ngOnInit() {
    this.selectedSite$
      .pipe(takeUntil(this.destroy$))
      .subscribe((siteID: string) => {
        if (siteID && siteID !== this.selectedSiteId) {
          this.selectedSiteId = null;
          this.cdr.detectChanges();
          this.selectedSiteId = siteID;
          this.cdr.detectChanges();
        }
      });
  }

  async loadProductData(table: Table, event?: LazyLoadEvent | PaginatorState) {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }
    const isFirstLoad = this.firstLoad ? true : false;

    this.loadingChange.emit(true);
    table.loading = true;
    this.tableDataSubject.next([]);
    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(securityColumns));
      this.firstLoad = false;
    }
    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event,
      { SiteID: this.selectedSiteId }
    );

    serviceNowFilter.Filter.push({
      Column: "ProductGroup",
      Value: [ProductCategories.security],
    });

    const response = await this.productService.loadProductCategory(
      serviceNowFilter
    );

    this.totalRecords = response.queryCount;
    this.tableDataSubject.next(response.result);
    this.staticTable = table;
    table.loading = false;
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    this.loadingChange.emit(false);
  }

  handleColumnChange(val: any[]) {
    this.cols = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  defaultExport() {
    ExportTable(this.dataTable, this.tableDataSubject.value, this.cols, "all");
  }

  async exportAll() {
    this.toastService.showToastInfo(
      "Downloading - ",
      " We are just sorting your data out for you. This won't take long."
    );
    ``;
    const serviceNowFilter = BuildServiceNowQuery(this.dataTable, this.cols);
    const fields = this._selectedColumns.map((item) => item.serviceNowField);

    serviceNowFilter.Filter.push({
      Column: "ProductGroup",
      Value: [ProductCategories.security],
    });

    const body: ITableToFile = {
      Filter: serviceNowFilter.Filter,
      ExportType: ExportType.Csv,
      CurrentPage: 0,
      Amount: 25,
      Pagination: false,
      ExportColumns: fields,
      SiteID: this.selectedSiteId,
    };

    try {
      const download = await this.productService.exportProductCategory(body);
      this.exportService.downloadFile(
        download,
        ExportType.Csv,
        ExportFileName.BillingSecurity
      );
    } catch {
      this.toastService.showToastError(
        "Error - ",
        "Unable to download table data "
      );
    }
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = securityColumns
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter((column) => column?.default).map((column) => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }

  ngOnDestroy() {
    this.destroy$.next(void 0);
    this.destroy$.complete();
  }
}
