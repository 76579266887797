import { Inject, Injectable } from "@angular/core";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(private apiRequest: ApiRequest) {}

  changePassword(
    userName: string,
    oldPassword: string,
    newPassword: string,
    confirmPassword: string,
    resetPassword: boolean
  ) {
    const requestProps: IRequestOptions = {
      endpoint: "Authentication/ChangePassword",
      body: {
        UserName: userName,
        OldPassword: oldPassword,
        Password: newPassword,
        ConfirmPassword: confirmPassword,
        PasswordNeedsReset: resetPassword,
      },
    };
    return this.apiRequest.request(requestProps);
  }

  forgottenPassword(email: string, baseUrl: string) {
    const requestProps: IRequestOptions = {
      endpoint: "Authentication/ForgottenPassword",
      body: {
        EmailAddress: email,
        BaseUrl: baseUrl,
      },
    };
    return this.apiRequest.request(requestProps);
  }
}
