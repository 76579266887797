import { Injectable } from "@angular/core";
import { Filter } from "src/app/components/misc/filters/filter/filter.component";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";
import { ServiceNowFilterQuery } from "src/app/models/servicenow.model";

@Injectable({
  providedIn: "root",
})
export class OutageService {
  constructor(private apiRequest: ApiRequest) {}

  getCount(filter: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "Outage/GetOutageCount",
      body: filter,
    };

    return this.apiRequest.request(requestProps);
  }

  getAuthorisedOutage(outAgeID: string) {
    const requestProps: IRequestOptions = {
      endpoint: "Outage/getAuthorisedOutage",
      body: outAgeID,
    };

    return this.apiRequest.request(requestProps);
  }

  getOutage(outageId: string) {
    const requestProps: IRequestOptions = {
      endpoint: "Outage/getOutage",
      body: outageId,
    };

    return this.apiRequest.request(requestProps);
  }

  getAuthorisedOutageByService(service: string) {
    const requestProps: IRequestOptions = {
      endpoint: "Outage/GetAuthorisedOutageByService",
      body: service,
    };

    return this.apiRequest.request(requestProps);
  }

  getOutageByService(filter: Filter) {
    const requestProps: IRequestOptions = {
      endpoint: "Outage/GetOutageByService",
      body: {
        Filter: filter,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  getAuthorisedOutagesInformation() {
    const requestProps: IRequestOptions = {
      endpoint: "Outage/GetAuthorisedOutagesInformation",
      body: "",
    };

    return this.apiRequest.request(requestProps);
  }

  getOutagesInformation(filter: any, currentPage: number) {
    const requestProps: IRequestOptions = {
      endpoint: "Outage/GetOutagesInformation",
      body: {
        Filter: filter,
        CurrentPage: currentPage,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  getBusinessServiceSubscriptions(filter: any, currentPage: number) {
    const requestProps: IRequestOptions = {
      endpoint: "Outage/GetBusinessServiceSubscriptions",
      body: {
        Filter: filter,
        CurrentPage: currentPage,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  updateSubscription(serviceToUpdate: string, isSubscribed: boolean) {
    const requestProps: IRequestOptions = {
      endpoint: "Outage/UpdateSubscription",
      body: {
        service: serviceToUpdate,
        subscribed: isSubscribed,
      },
    };

    return this.apiRequest.request(requestProps);
  }
}
