// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from "./environment.interface";

export const environment: Environment = {
  name: "development",
  production: true,
  apiUrl: "https://api.services-development.digitalspace.co.uk/",
  apiUrlVpc: "",
  baseUrl: "https://portal.services-development.digitalspace.co.uk/",
  NumberOfRowsShow: 20,
  countTime: 600000,
  enableGoogleAnalytics: false,
  portals: [],
  auth0: {
    domain: "dev-wawaunyqzxeah6hn.uk.auth0.com",
    clientId: "bopvcDfNJ2AJCE2TIcjxsxdPHxh4y6Mi",
    // check every 15 minute
    pollingInterval: 900000,
  },
  clarity: {
    enabled: false,
    projectId: null,
  },
  localStorage: {
    serviceHubUser: "serviceHubUser",
    serviceHubUserImpersonation: "serviceHubUserImpersonation",
    auth0IdToken: "auth0IdToken",
  },
  appVersion: `${require("../../package.json").version}-dev`,
  microservices: {
    domainManagement: "https://26yti8qw90.execute-api.eu-west-2.amazonaws.com",
    observability: "https://6imjuyxss1.execute-api.eu-west-2.amazonaws.com",
  },
  features: {
    raise_incident_suggest_kb_articles: true,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
