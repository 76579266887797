import { TableColumn } from "src/app/models/table";

export interface IGetAllNotificationsRequest {
  pagination: boolean;
  currentPage: number;
  rowCount: number;
  filter: any;
}

export type Notification = {
  category: string;
  shortDescription: string;
  lastUpdated: string;
  number: string;
  sysId: string;
  ticketSysId: string;
  uNotificationRead: boolean;
};

export enum NotificationTableColumns {
  "NotificationRead" = "notification-read",
  "Date" = "date",
  "Type" = "type",
  "Number" = "number",
  "ShortDescription" = "shortDescription",
}

enum snowFilter {
  "Date" = "LastUpdated",
  "Type" = "Category",
  "Number" = "Number",
  "Description" = "description",
}

export interface INotificationColumn {
  field: string;
  header?: string;
  default: boolean;
  display?: any;
  filter?: {
    type: string;
    values: { label: string; value: string }[];
  };
  checkBoxVisible?: boolean;
  showSortIcon: boolean;
}

export const NotificationColumns: TableColumn[] = [
  {
    field: "uNotificationRead",
    display: { type: "icon" },
    showSortIcon: false,
    default: true,
    filter: null,
  },
  {
    field: "lastUpdated",
    serviceNowField: snowFilter.Date,
    header: "Date",
    display: { type: "date" },
    filter: {
      type: "dateRange",
    },
    showSortIcon: true,
    default: true,
  },
  {
    field: "category",
    serviceNowField: snowFilter.Type,
    header: "Type",
    display: { type: "text" },
    filter: {
      type: "multiSelect",
      values: [
        { label: "Incidents", value: "Incident" },
        { label: "Requested Item", value: "Requested Item" },
      ],
    },
    showSortIcon: false,
    default: true,
  },
  {
    field: "number",
    serviceNowField: snowFilter.Number,
    header: "Number",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: false,
    default: true,
  },
  {
    field: "shortDescription",
    header: "Description",
    display: { type: "text" },
    showSortIcon: false,
    default: true,
  },
];
