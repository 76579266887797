import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { RoleManagementService } from "../shared/role-management.service";
import {
  IRolePermissions,
  ISelectedRole,
  User,
  UserRoleColumns,
} from "../shared/role-models";
import { cloneDeep } from "lodash-es";
import { UserService } from "src/app/services/api/user/user.service";
import { MenuItem } from "primeng/api/menuitem";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { PermissionsService } from "src/app/services/api/permissions/permissions.service";
import { Table } from "primeng/table";
import { LazyLoadEvent } from "primeng/api";
import { TableColumn } from "src/app/models/table";
import {
  IsPaginatorState,
  SetSelectedColumns,
} from "src/app/helpers/table.helper";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { Paginator, PaginatorState } from "primeng/paginator";
import { ServiceNowFilterQueryOrderByColumn } from "src/app/models/servicenow.model";

@Component({
  selector: "app-role-information",
  templateUrl: "./role-information.component.html",
  styleUrls: ["./role-information.component.scss"],
})
export class RoleInformationComponent implements OnInit, AfterViewInit {
  @ViewChild("tablePaginator") paginator: Paginator;
  loading: boolean = true;
  selectedRole: ISelectedRole;
  selectedRoleId: string | null = null;
  permissionsForSelectedRole: IRolePermissions[];
  permissionsSubject = new BehaviorSubject(null);
  permissions$ = this.permissionsSubject.asObservable();
  usersSubject = new BehaviorSubject(<User[]>[]);
  users$ = this.usersSubject.asObservable();
  totalRecords: number = 0;
  _selectedColumns: TableColumn[] = [];
  cols: TableColumn[] = cloneDeep(UserRoleColumns);

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  constructor(
    public router: Router,
    public roleManagementService: RoleManagementService,
    private userService: UserService,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private permissionsService: PermissionsService,
    private cdr: ChangeDetectorRef // Inject ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.selectedRoleId = this.route.snapshot.paramMap.get("id");
    const breadcrumbs: MenuItem[] = [
      {
        label: "Roles",
        routerLink: "/secure/company/roles",
      },
      {
        label: "Role Information",
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    if (this.selectedRoleId) {
      this.selectedRole = await this.loadRole(this.selectedRoleId);
      this.loading = true;
      await this.loadPermissions();
      this.loading = false;
    } else {
      this.navigateToRoleTable();
    }
  }

  ngAfterViewInit(): void {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      UserRoleColumns.filter((column) => column?.default).map(
        (column) => column?.field
      )
    );
    this.cdr.detectChanges(); // Trigger change detection manually
  }

  async loadRole(roleId: string) {
    try {
      const editUser: any = await firstValueFrom(
        this.permissionsService.getRole(roleId)
      );
      return editUser;
    } catch (error) {
      console.error(error);
    }
  }

  async loadPermissions() {
    this.permissionsForSelectedRole =
      await this.roleManagementService.getPermissionsForSelectedRole(
        this.selectedRole.sysId
      );
  }

  async loadUsersWithRole(
    table: Table,
    event?: LazyLoadEvent | PaginatorState
  ) {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }

    table.loading = true;
    this.usersSubject.next([]);

    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event
    );

    serviceNowFilter.Filter.push({
      Column: ServiceNowFilterQueryOrderByColumn.orderByAsc,
      Value: ["firstName", "lastName"],
    });

    serviceNowFilter.Filter.push({
      Column: "UTimicoPortalRole",
      Value: [this.selectedRole?.name],
    });

    const response = await firstValueFrom(
      this.userService.getUsers(serviceNowFilter)
    );

    this.totalRecords = response.overallQueryCount;
    this.usersSubject.next(response.users);
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    table.loading = false;
  }

  navigateToRoleTable() {
    this.router.navigateByUrl("secure/company/roles");
  }

  openEditRole() {
    this.router.navigateByUrl(
      `secure/company/roles/edit/${this.selectedRoleId}`
    );
  }

  getColumnLength(): number {
    if (this.permissionsForSelectedRole) {
      if (this.permissionsForSelectedRole.length % 2 === 0) {
        return this.permissionsForSelectedRole.length / 2;
      } else {
        return (this.permissionsForSelectedRole.length + 1) / 2;
      }
    }
  }

  formatName(user: User): string {
    return `${user.firstName} ${user.lastName}`;
  }
}
