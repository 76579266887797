import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { cloneDeep } from "lodash-es";
import { DateTime } from "luxon";
import { MenuItem } from "primeng/api/menuitem";
import { Paginator } from "primeng/paginator";
import { Table } from "primeng/table";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import {
  GetColor,
  SetSelectedColumns,
  TableReset,
} from "src/app/helpers/table.helper";
import { Filters, TableColumn, TableColumnColor } from "src/app/models/table";
import { OrderService } from "src/app/services/api/order/order.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { IUserProfile } from "../../company/users/shared/user-models";
import {
  ExportTable,
  GetExportItemsFromFullArray,
} from "../../products-and-services/science-logic/cmdb-devices/shared/export-devices";
import { ServiceNowFilterQuery } from "src/app/models/servicenow.model";
import { DigitalSpaceTabMenuItem } from "src/app/components/misc/digital-space-tab-menu/digital-space-tab-menu.component";
import { ConvertServiceNowDateToJsDate } from "src/app/helpers/date.helper";
import { sentenceCase } from "change-case";
import {
  OrderClosedStateValues,
  OrderOpenStateValues,
  OrderRequestSizeLimit,
  OrderTableColumnNames,
  OrderTabs,
} from "src/app/models/order/order.model";
import { OrderClosedColumns, OrderOpenColumns } from "./orders.columns";

@Component({
  selector: "orders",
  templateUrl: "orders.component.html",
  styleUrls: ["orders.component.scss"],
})
export class OrdersComponent implements OnInit {
  @ViewChild("dataTable") dataTable: Table;
  @ViewChild("tablePaginator") paginator: Paginator;

  OrderTabs = OrderTabs;

  ordersSubject = new BehaviorSubject(null);
  orders$ = this.ordersSubject.asObservable();

  user: IUserProfile;

  hasCompanyToggle: boolean;
  companyToggle: boolean = true;

  cols: TableColumn[] = cloneDeep(OrderOpenColumns);
  _selectedColumns: TableColumn[] = [];

  defaultRowsShown = 25;

  firstLoad: boolean = true;

  exportOptions = [
    {
      label: "All",
      value: "all",
      command: () => this.exportAll(),
    },
    {
      label: "In View",
      value: "in_view",
      command: () => this.defaultExport(),
    },
  ];

  tabs: DigitalSpaceTabMenuItem[] = [
    {
      label: OrderTabs.open,
      hovered: false,
    },
    {
      label: OrderTabs.closed,
      hovered: false,
    },
  ];

  activeTab: DigitalSpaceTabMenuItem = this.tabs[0];

  getColor = GetColor;

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  constructor(
    private router: Router,
    private orderService: OrderService,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUserOrImpersonatedUser();
    const breadcrumbs: MenuItem[] = [
      { label: "Order Requests", routerLink: "/secure/tickets/orders" },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.hasCompanyToggle = this.user.uTimicoPortalPermissions.includes(
      "TICKETS_COMPANY_READ"
    );
    await this.loadOrders();
  }

  async loadOrders() {
    this.dataTable.loading = true;

    this.ordersSubject.next([]);

    if (this.firstLoad) {
      this.reset(this.dataTable, false, this.cols);
      this.firstLoad = false;
    }

    const orders = (
      await Promise.all([
        this.getServiceRequests(),
        this.getOrderLineItemsRequest(),
      ])
    ).flatMap((results) => results);

    this.ordersSubject.next(orders);

    this.dataTable.loading = false;
  }

  tabChange(tab: DigitalSpaceTabMenuItem) {
    this.activeTab = tab;
    this.firstLoad = true;
    if (tab.label === OrderTabs.open) {
      this.cols = cloneDeep(OrderOpenColumns);
    }
    if (tab.label === OrderTabs.closed) {
      this.cols = cloneDeep(OrderClosedColumns);
    }
    this.loadOrders();
  }

  async getServiceRequests() {
    const serviceRequestCount =
      (await firstValueFrom(
        this.orderService.getCount(
          this.getQueryFilter(this.dataTable, this.cols)
        )
      )) ?? 0;

    const serviceRequestRequests = [];
    const totalPages = Math.ceil(serviceRequestCount / OrderRequestSizeLimit);
    for (let pageNumber = 0; pageNumber < totalPages; pageNumber++) {
      serviceRequestRequests.push(
        firstValueFrom(
          this.orderService.getMultiple(
            this.getQueryFilter(
              this.dataTable,
              this.cols,
              OrderRequestSizeLimit,
              pageNumber
            )
          )
        )
      );
    }

    const results = await Promise.all(serviceRequestRequests);

    return results
      .flatMap((result) => result)
      .map((item) => {
        return {
          ...item,
          type: "request",
          openedAt: ConvertServiceNowDateToJsDate(item.openedAt),
          state: sentenceCase(item.state),
        };
      });
  }

  async getOrderLineItemsRequest() {
    const orderLineRequestCount =
      (await firstValueFrom(
        this.orderService.getCount(
          this.getQueryFilter(this.dataTable, this.cols)
        )
      )) ?? 0;

    const orderLineRequests = [];
    const totalPages = Math.ceil(orderLineRequestCount / OrderRequestSizeLimit);
    for (let pageNumber = 0; pageNumber < totalPages; pageNumber++) {
      orderLineRequests.push(
        firstValueFrom(
          this.orderService.getMultiple(
            this.getQueryFilter(
              this.dataTable,
              this.cols,
              OrderRequestSizeLimit,
              pageNumber
            )
          )
        )
      );
    }

    const results = await Promise.all(orderLineRequests);

    return results
      .flatMap((result) => result)
      .map((item) => {
        return {
          ...item,
          type: "order-line",
          openedAt: ConvertServiceNowDateToJsDate(item.openedAt),
          state: sentenceCase(item.state),
        };
      });
  }

  getQueryFilter(
    table: Table,
    columns: TableColumn[],
    amount?: number,
    page?: number
  ): ServiceNowFilterQuery {
    let serviceNowFilterQuery = BuildServiceNowQuery(
      table,
      columns,
      amount,
      page
    );
    if (!this.companyToggle) {
      serviceNowFilterQuery.Filter.push({
        Column: OrderTableColumnNames.requestedForSysId,
        Value: [this.user?.sysId],
      });
    }

    if (this.activeTab.label === OrderTabs.open) {
      serviceNowFilterQuery.Filter.push({
        Column: OrderTableColumnNames.state,
        Value: OrderOpenStateValues,
      });
    }
    if (this.activeTab.label === OrderTabs.closed) {
      serviceNowFilterQuery.Filter.push({
        Column: OrderTableColumnNames.state,
        Value: OrderClosedStateValues,
      });
    }
    return serviceNowFilterQuery;
  }

  async onFiltersChanged() {
    if (this.activeTab.label === OrderTabs.closed) {
      await this.loadOrders();
    }
  }

  async onToggleChange(): Promise<void> {
    await this.loadOrders();
  }

  handleColumnChange(val: any[]) {
    this.cols = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  handleRowClicked(event) {
    if (event.type === "request") {
      this.router.navigateByUrl("secure/tickets/order/" + event.sysId);
    } else if (event.type === "order-line") {
      this.router.navigateByUrl("secure/tickets/orderlineitem/" + event.sysId);
    }
  }

  defaultExport() {
    const displayedRows = this.dataTable.filteredValue || this.dataTable.value;
    ExportTable(
      this.dataTable,
      GetExportItemsFromFullArray(
        displayedRows.map((item) => {
          return {
            ...item,
            openedAt: DateTime.fromJSDate(item.openedAt).toFormat(
              "dd/MM/yyyy HH:ss"
            ),
          };
        }),
        this.dataTable.first ?? 0,
        this.dataTable._rows ?? this.defaultRowsShown
      ),
      this.selectedColumns,
      "all"
    );
  }

  async exportAll() {
    const displayedRows = this.dataTable.filteredValue || this.dataTable.value;
    ExportTable(
      this.dataTable,
      displayedRows.map((item) => {
        return {
          ...item,
          openedAt: DateTime.fromJSDate(item.openedAt).toFormat(
            "dd/MM/yyyy HH:ss"
          ),
        };
      }),
      this.selectedColumns,
      "all"
    );
  }

  async resetTable() {
    this.cols =
      this.activeTab.label === OrderTabs.closed
        ? cloneDeep(OrderClosedColumns)
        : cloneDeep(OrderOpenColumns);
    this.firstLoad = true;
    await this.loadOrders();
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[],
    additionalFilters?: Filters
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter((column) => column?.default).map((column) => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
      additionalFilters: additionalFilters,
    });
  }
}
