import { Component } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { firstValueFrom } from "rxjs";
import { DomainAdminEvent } from "src/app/components/service-hub/products-and-services/domains/domain/domain.component";
import { DomainService } from "src/app/services/api/domain/domain.service";

@Component({
  selector: "app-manage-route53-hostedzone",
  templateUrl: "./manage-route53-hostedzone.component.html",
  styleUrls: ["./manage-route53-hostedzone.component.css"],
})
export class ManageRoute53HostedzoneComponent {
  loading: boolean = false;
  dialogTitle: string;
  remove: string;
  domainName: string;
  domainSysId: string;
  action: DomainAdminEvent;
  //domain: string;
  constructor(
    public ref: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private domainService: DomainService
  ) {
    this.dialogTitle = this.dialogConfig.data.title;
    this.action = this.dialogConfig.data.action;
    this.domainName = this.dialogConfig.data?.domainName;
    this.domainSysId = this.dialogConfig.data?.domainSysId;
    this.remove = this.dialogConfig.data.content;
  }

  async deleteHostedZone() {
    this.loading = true;
    if (this.action === DomainAdminEvent.HostedZoneDeleted) {
      const response = await firstValueFrom(
        this.domainService.deleteDomain(this.domainSysId)
      );
      this.loading = false;
      this.ref.close(true);
    } else if (this.action === DomainAdminEvent.HostedZoneSuspend) {
      const response = await firstValueFrom(
        this.domainService.suspendDomain(this.domainSysId)
      );
      this.loading = false;
      this.ref.close(true);
    } else if (this.action === DomainAdminEvent.HostedZoneResume) {
      const response = await firstValueFrom(
        this.domainService.resumeDomain(this.domainSysId)
      );
      this.loading = false;
      this.ref.close(true);
    } else if (this.action === DomainAdminEvent.HostedZoneAdd) {
      const response = await firstValueFrom(
        this.domainService.addDomain(this.domainSysId)
      );
      this.loading = false;
      this.ref.close(true);
    }
  }

  close(): void {
    this.ref.close(false);
  }
}
