export enum ServiceNowFilterQueryOrderByColumn {
  orderByAsc = "OrderBy",
  orderByDesc = "OrderByDesc",
}

export type ServiceNowFilterQueryColumn = {
  Column: string;
  Value: string[];
};

export type ServiceNowFilterQueryOrderBy = {
  Column: ServiceNowFilterQueryOrderByColumn;
  Value: string[];
};

export type ServiceNowFilterQueryGeneric =
  | ServiceNowFilterQueryColumn
  | ServiceNowFilterQueryOrderBy;

export type ServiceNowFilterQuery = {
  Amount: number;
  CurrentPage: number;
  Filter: ServiceNowFilterQueryGeneric[];
  [key: string]: any;
};

export enum TableNames {
  INCIDENT = "incident",
  REQUESTED_ITEM = "sc_req_item",
  CASE = "sn_customerservice_case",
  ORDER = "sc_req_item",
  ORDER_LINE = "sn_ind_tmt_orm_order_line_item",
}
