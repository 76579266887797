import { Component } from "@angular/core";
import { FileUploadService } from "src/app/services/file-upload.service";
import { FileUpload, FileUploadEvent } from "./file-upload";
import { PrimeNGConfig } from "primeng/api";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent {
  uploadedFiles: FileUpload[] = [];

  constructor(
    private fileUploadService: FileUploadService,
    private config: PrimeNGConfig
  ) {}

  filesSelected(event: FileUploadEvent) {
    const newFiles = event.currentFiles;

    const filesToAdd = newFiles.filter(
      (newFile) =>
        !this.uploadedFiles.some(
          (existingFile) =>
            existingFile.fileName === newFile.name &&
            existingFile.size === Math.ceil(newFile.size / 1024)
        )
    );

    for (const file of filesToAdd) {
      this.convertFile(file);
    }

    this.fileUploadService.filesToTransmit.next(this.uploadedFiles);
  }

  async convertFile(file: File): Promise<void> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      var errorMessage = this.FileUploadErrorChecker(file);
      reader.onload = (e) => {
        var convertedFile = {
          fileName: file.name,
          size: Math.ceil(file.size / 1024),
          base64Encoding: (e.target as FileReader).result,
          contentType: file.type,
          errorMessage: errorMessage,
        };
        this.uploadedFiles.push(convertedFile);
        resolve();
      };
    });
  }

  private FileUploadErrorChecker(file: File) {
    const fileMaxSize = 5;
    var errorMessage = "";

    if (file.size == 0) {
      errorMessage = "No content in file! Please try again.";
    }

    if (file.size / 1024 / 1024 > fileMaxSize) {
      errorMessage =
        "File exceeds the " +
        fileMaxSize +
        "MB attachment limit. Please try again.";
    }
    return errorMessage;
  }

  choose(event, callback) {
    callback();
  }

  onClearTemplatingUpload(clear) {
    this.uploadedFiles = [];
    this.fileUploadService.filesToTransmit.next(this.uploadedFiles);
    clear();
  }

  onRemoveTemplatingFile(
    event: MouseEvent,
    index: number,
    removeFileCallback
  ): void {
    this.uploadedFiles.splice(index, 1);
    removeFileCallback(event, index);
  }

  formatSize(bytes): string {
    const k = 1024;
    const dm = 3;
    const sizes = this.config.translation.fileSizeTypes;
    if (bytes === 0) {
      return `0 ${sizes[0]}`;
    }

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

    return `${formattedSize} ${sizes[i]}`;
  }

  fileIcon(file): string {
    const extension: string = file.split(".").pop()?.toLowerCase() || "";
    switch (extension) {
      case "png":
      case "jpg":
      case "jpeg":
        return "pi-image";
      case "pdf":
        return "pi-file-pdf";
      case "doc":
      case "docx":
        return "pi-file-word";
      default:
        return "pi-file";
    }
  }
}
