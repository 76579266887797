import { Injectable } from "@angular/core";
import { UploadAttachmentRequest } from "src/app/components/misc/pop-up/file-manager/file-manager.models";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";
import { CreateCommentRequest } from "src/app/models/comments/comment.models";

const taskDirectory = "OrderLineItem";

@Injectable({
  providedIn: "root",
})
export class OrderLineItemService {
  constructor(private apiRequest: ApiRequest) {}

  getCount(body: any) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetOrderLineItemsCount`,
      body: body,
    };

    return this.apiRequest.request(requestProps);
  }

  getSingle(orderLineItemId: string) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetSingleOrderLineItem`,
      body: orderLineItemId,
    };

    return this.apiRequest.request(requestProps);
  }

  getMultiple(body: any) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetMultipleOrderLineItems`,
      body: body,
    };

    return this.apiRequest.request(requestProps);
  }

  createComment(request: CreateCommentRequest) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/CreateOrderLineItemComment`,
      body: request,
    };

    return this.apiRequest.request(requestProps);
  }

  getComments(orderLineItemId: string) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetOrderLineItemComments`,
      body: orderLineItemId,
    };

    return this.apiRequest.request(requestProps);
  }

  getCharacteristics(request: string) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetOrderCharacteristics`,
      body: request,
    };

    return this.apiRequest.request(requestProps);
  }

  uploadAttachment(request: UploadAttachmentRequest) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/OrderLineItemUploadAttachment`,
      body: request,
    };

    return this.apiRequest.request(requestProps);
  }
}
