import { TableColumn, TableSort } from "src/app/models/table";

export interface IGetLocationsResponse {
  uBuildingName: string;
  UBuildingNumber: string;
  city: string;
  zip: string;
  fullName: string;
  sysId: string;
}

enum snowFilter {
  name = "fullName",
  building = "uBuildingNumber",
  city = "city",
  zip = "zip",
}

export interface ILocationColumn {
  field: string;
  header?: string;
  default: boolean;
  display?: any;
}

export interface ILocationColumn {
  field: string;
  header?: string;
  default: boolean;
  display?: any;
  filter?: {
    type: string;
    values: { label: string; value: string }[];
  };
  checkBoxVisible?: boolean;
  showSortIcon: boolean;
  showInFilter?: boolean;
}

export const locationColumns: TableColumn[] = [
  {
    field: "fullName",
    header: "Name",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: snowFilter.name,
    sortColumnOrder: 1,
    sortOrder: TableSort.asc,
  },
  {
    field: "uBuildingNumber",
    header: "Building",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: snowFilter.building,
  },
  {
    field: "city",
    header: "Town/City",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: snowFilter.city,
  },
  {
    field: "zip",
    header: "Postcode",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: snowFilter.zip,
  },
];
