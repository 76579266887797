import { Router } from "@angular/router";
import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import {
  IBillingEntity,
  IMobile,
  ITelephonyColumn,
  telephonColumns,
} from "./models/telephony-models";
import { TelephonyService } from "./shared/telephony-service.service";
import { EditBarsService } from "src/app/services/edit-bars.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { MenuItem } from "primeng/api/menuitem";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { LazyLoadEvent } from "primeng/api";
import { Table } from "primeng/table";
import {
  IsPaginatorState,
  SetSelectedColumns,
  TableReset,
} from "src/app/helpers/table.helper";
import { cloneDeep } from "lodash-es";
import { ExportTable } from "../science-logic/cmdb-devices/shared/export-devices";
import { BarringService } from "./shared/barring.service";
import { ToastService } from "src/app/services/toast.service";
import {
  ExportFileName,
  ExportService,
  ExportTableEndpoint,
  ExportType,
} from "src/app/services/export.service";
import { IDownloadFile, ITableToFile } from "src/app/models/export-models";
import { TableColumn } from "src/app/models/table";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { IUserProfile } from "../../company/users/shared/user-models";
import { TelephonyApiService } from "src/app/services/api/telephony-api/telephony-api.service";
import { Paginator, PaginatorState } from "primeng/paginator";

@Component({
  selector: "telephony",
  templateUrl: "telephony.component.html",
  styleUrls: ["telephony.component.scss"],
})
export class TelephonyComponent {
  @ViewChild("dataTable") dataTable: Table;
  @ViewChild("tablePaginator") paginator: Paginator;
  sites: IBillingEntity[];
  dropdownSelection: IBillingEntity;
  selectedSite: IBillingEntity;
  telephonySubject = new BehaviorSubject(null);
  telephony$ = this.telephonySubject.asObservable();
  amendBars: boolean = false;
  user: IUserProfile;
  tableFilters: LazyLoadEvent;
  cols: TableColumn[] = cloneDeep(telephonColumns);
  tableColumn: ITelephonyColumn[];
  defaultSelectedColumns: string[] = [];
  selectedSiteID: string;
  tableLoading: boolean = false;
  totalRecords: number = 0;
  initialSortColumns: any[] | undefined = undefined;
  _selectedColumns: TableColumn[] = [];
  selectedNumbers: IMobile[] = [];
  onLoad: any;
  firstLoad: boolean = true;
  staticTable: Table;

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  exportOptions = [
    {
      label: "All",
      value: "all",
      command: () => this.exportAll(),
    },
    {
      label: "In View",
      value: "in_view",
      command: () =>
        ExportTable(
          this.dataTable,
          this.telephonySubject.value,
          this._selectedColumns,
          "all"
        ),
    },
  ];

  constructor(
    private router: Router,
    private telephonyService: TelephonyService,
    private editBarsState: EditBarsService,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService,
    public barringService: BarringService,
    private toastService: ToastService,
    private exportService: ExportService,
    private telephonyApiService: TelephonyApiService
  ) {}

  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [{ label: "Mobile" }];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.editBarsState.numbers = [];
    await this.loadSites();
    this.canAmendBars();
    if (this.sites) {
      this.dropdownSelection = this.sites[0];
      this.selectedSite = this.sites[0];
    }
    this.tableLoading = true;
  }

  searchForSite() {
    this.selectedSite = this.dropdownSelection;
    this.selectedSiteID = this.selectedSite.unityId;
    this.loadMobilesForSite(this.staticTable);
  }

  async loadSites() {
    const sites = await this.telephonyService.loadSites();
    this.dropdownSelection = sites[0];
    this.selectedSite = sites[0];
    this.selectedSiteID = this.selectedSite.unityId;
    this.sites = sites;
  }

  async loadMobilesForSite(
    table: Table,
    event?: LazyLoadEvent | PaginatorState
  ) {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }
    table.loading = true;
    const isFirstLoad = this.firstLoad ? true : false;

    this.telephonySubject.next([]);

    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(telephonColumns));
      this.firstLoad = false;
    }

    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event,
      { SiteID: this.selectedSiteID }
    );

    const response = await firstValueFrom(
      this.telephonyApiService.getMobilePhones(serviceNowFilter)
    );

    this.telephonySubject.next(response.result);
    this.totalRecords = response.overallQueryCount;
    this.staticTable = table;
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    table.loading = false;
  }

  handleRowClicked(event): void {
    if (this.selectedNumbers.length === 0) {
      this.telephonyService.selectedSite = {
        siteID: event.siteId,
        cliNumber: event.cliNumber,
      };
      this.router.navigateByUrl("secure/products-and-services/telephony/data");
    }
  }

  openMultiBar() {
    this.router.navigateByUrl(
      "secure/products-and-services/telephony/multi-bar"
    );
  }

  amendDetails() {
    this.router.navigateByUrl("secure/billing/amend-users-subsites");
  }

  async canAmendBars(): Promise<void> {
    this.user = await this.authService.getUser();
    if (this.user.uTimicoPortalPermissions.includes("MOBILE_BARRING")) {
      this.amendBars = true;
    }
  }

  onSelectedRows(numbers: any[]) {
    this.editBarsState.numbers = numbers;
  }

  onMultipleNumbersEdit(): void {
    this.editBarsState.numbers = this.selectedNumbers;
    if (this.editBarsState.numbers.length === 1) {
      this.barringService.storedMobileNumber =
        this.editBarsState.numbers[0].cliNumber;
      this.router.navigateByUrl(
        "secure/products-and-services/telephony/edit-mobile-options"
      );
    } else {
      this.router.navigateByUrl(
        "secure/products-and-services/telephony/edit-multi-mobile-options"
      );
    }
  }

  handleColumnChange(val: any[]) {
    this.cols = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  defaultExport() {
    ExportTable(
      this.dataTable,
      this.telephonySubject.value,
      this._selectedColumns,
      "all"
    );
  }

  async exportAll() {
    this.toastService.showToastInfo(
      "Downloading - ",
      " We are just sorting your data out for you. This won't take long."
    );
    const serviceNowFilter = BuildServiceNowQuery(this.dataTable, this.cols);

    const fields = this._selectedColumns.map((item) =>
      item.exportField ? item.exportField : item.serviceNowField
    );
    const endpoint: string = ExportTableEndpoint.Telephony;

    const body: ITableToFile = {
      Filter: serviceNowFilter.Filter,
      ExportType: ExportType.Csv,
      CurrentPage: 0,
      Amount: 25,
      Pagination: false,
      ExportColumns: fields,
      SiteID: this.selectedSiteID,
    };

    const downloadedFile: IDownloadFile =
      await this.exportService.exportTableToFile(endpoint, body);

    this.exportService.downloadFile(
      downloadedFile,
      ExportType.Csv,
      ExportFileName.Telephony
    );
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = telephonColumns
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter((column) => column?.default).map((column) => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
