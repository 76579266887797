// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.underline {
  color: #bdbdbd !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/service-hub/products-and-services/science-logic/cmdb-devices/device-category-table/device-category-table.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF","sourcesContent":[".underline {\n  color: #bdbdbd !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
