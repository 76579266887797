import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { DomainsFilter } from "src/app/components/misc/filters/filters";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { OverlayContainer } from "@angular/cdk/overlay";
import { DomainService } from "src/app/services/api/domain/domain.service";
import {
  domainColumns,
  adminDomainColumns,
  IDomain,
} from "./shared/domains-models";
import { LazyLoadEvent, MenuItem, Message } from "primeng/api";
import { Table } from "primeng/table";
import { PermissionService } from "src/app/services/permissions.service";
import { MessageService } from "primeng/api";
import { ExportTable } from "../science-logic/cmdb-devices/shared/export-devices";
import { cloneDeep } from "lodash-es";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { TableColumn } from "src/app/models/table";
import {
  IsPaginatorState,
  SetSelectedColumns,
  TableReset,
} from "src/app/helpers/table.helper";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { Paginator, PaginatorState } from "primeng/paginator";
import {
  ExportFileName,
  ExportService,
  ExportTableEndpoint,
  ExportType,
} from "src/app/services/export.service";
import { IDownloadFile, ITableToFile } from "src/app/models/export-models";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "domains",
  templateUrl: "domains.component.html",
  styleUrls: ["domains.component.scss"],
  providers: [MessageService],
})
export class DomainsComponent implements OnInit {
  @ViewChild("dataTable") dataTable: Table;
  @ViewChild("tablePaginator") paginator: Paginator;
  loadingSubject = new BehaviorSubject<Array<IDomain>>([]);
  loading$ = this.loadingSubject.asObservable();
  filter = DomainsFilter;
  domainsRecordsSubject = new BehaviorSubject(null);
  domains$ = this.domainsRecordsSubject.asObservable();
  totalRecords: number = 0;
  messages: Message[] | undefined;
  tableFilters: LazyLoadEvent;
  isDsAdmin: boolean = false;
  domainDeleted: string;
  firstLoad: boolean = true;

  cols: TableColumn[];
  _selectedColumns: TableColumn[] = [];

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  exportOptions = [
    {
      label: "All",
      value: "all",
      command: () => this.exportAll(),
    },
    {
      label: "In View",
      value: "in_view",
      command: () =>
        ExportTable(
          this.dataTable,
          this.domainsRecordsSubject.value,
          this._selectedColumns,
          "all"
        ),
    },
  ];

  constructor(
    private router: Router,
    private overlayContainer: OverlayContainer,
    private domainService: DomainService,
    private permissionService: PermissionService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    public cd: ChangeDetectorRef,
    private breadcrumbService: BreadcrumbService,
    private exportService: ExportService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    const breadcrumbs: MenuItem[] = [{ label: "Domains" }];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.overlayContainer
      .getContainerElement()
      .setAttribute("rocole", "region"); //Resolves accessibility flagging while export button open

    this.isDsAdmin =
      this.permissionService.checkPermission("INTERNAL_DNS_ADMIN");
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  async loadDomains(table: Table, event?: LazyLoadEvent | PaginatorState) {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }
    const isDsAdmin =
      this.permissionService.checkPermission("INTERNAL_DNS_ADMIN");
    this.isDsAdmin = isDsAdmin;
    this.cols = isDsAdmin
      ? cloneDeep(adminDomainColumns)
      : cloneDeep(domainColumns);
    table.loading = true;
    this.domainDeleted = this.activatedRoute.snapshot.queryParams.domainDeleted;
    if (this.domainDeleted === "true") {
      this.showToast(
        "success",
        "Route53 Domain Management",
        "Hosted Zone deleted."
      );
      this.router.navigate([]);
    }

    table.loading = true;
    this.domainsRecordsSubject.next([]);
    const isFirstLoad = this.firstLoad ? true : false;

    const col: TableColumn[] = isDsAdmin ? adminDomainColumns : domainColumns;

    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(col));
      this.firstLoad = false;
    }

    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event
    );

    const response = await firstValueFrom(
      this.domainService.getDomains(serviceNowFilter)
    );

    this.domainsRecordsSubject.next(response.data);
    this.totalRecords = response.count;
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    table.loading = false;
  }
  //TODO Add interface
  async openDomain(domain) {
    this.router.navigate([
      "/secure/products-and-services/domain/",
      domain.sysId,
    ]);
  }

  showToast(severity: string, summary: string, detail: string) {
    this.messageService.add({ severity, summary, detail });
  }

  defaultExport() {
    ExportTable(
      this.dataTable,
      this.domainsRecordsSubject.value,
      this.cols,
      "all"
    );
  }

  async exportAll() {
    this.toastService.showToastInfo(
      "Downloading - ",
      " We are just sorting your data out for you. This won't take long."
    );

    const serviceNowFilter = BuildServiceNowQuery(this.dataTable, this.cols);
    const fields = this._selectedColumns
      .filter((item) => item.exportField)
      .map((item) => item.exportField);
    const endpoint: string = ExportTableEndpoint.Domains;
    const body: ITableToFile = {
      Filter: serviceNowFilter.Filter,
      ExportType: ExportType.Csv,
      CurrentPage: 0,
      Amount: 25,
      Pagination: false,
      ExportColumns: fields,
    };
    const downloadedFile: IDownloadFile =
      await this.exportService.exportTableToFile(endpoint, body);
    this.exportService.downloadFile(
      downloadedFile,
      ExportType.Csv,
      ExportFileName.Domains
    );
  }
  
  get col(): TableColumn[] {
    const isDsAdmin = this.permissionService.checkPermission("INTERNAL_DNS_ADMIN");
    this.isDsAdmin = isDsAdmin;
    this.cols = isDsAdmin ? cloneDeep(adminDomainColumns) : cloneDeep(domainColumns);
    return this.cols;
  }

  reset(table: Table, firstLoad: boolean = false, columns: TableColumn[] = this.col) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter((column) => column?.default).map((column) => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
