import { Table } from "primeng/table";
import {
  FilterMetadataWithColumn,
  Filters,
  TableColumn,
  TableColumnColor,
  TableFiltersWithColumns,
} from "../models/table";
import { cloneDeep } from "lodash-es";
import { FilterMetadata } from "primeng/api";
import { Paginator } from "primeng/paginator";

export const SortMultipleColumns = (cols): any[] | undefined => {
  const initialSortColumns = cols
    .filter((col) => col.default && col.order !== undefined)
    .map((col) => ({
      field: col.field,
      order: col.order === 1 ? 1 : -1,
    }));
  return initialSortColumns.length ? initialSortColumns : undefined;
};

export const SetSelectedColumns = (cols, defaultSelectedColumns) => {
  return cols.filter((col) => defaultSelectedColumns.includes(col.field));
};

export const SetMultiSelectColumnValues = (cols, items) => {
  const fieldsToUpdate = cols
    .filter((field) => field?.filter?.type === "multiSelect")
    .map((field) => field.field);
  return cols.map((col) => {
    const updateColumn = fieldsToUpdate.find((field) => field === col.field);
    if (updateColumn) {
      return {
        ...col,
        filter: {
          ...col.filter,
          values: [...new Set(items.map((item) => item[updateColumn]))]
            .map((value) => ({
              label: value !== null && value !== undefined ? value : "",
              value: value !== null && value !== undefined ? value : "",
            }))
            .sort((a, b) =>
              (a.label as string).localeCompare(b.label as string)
            ),
        },
      };
    }
    return col;
  });
};

export const ResetSelectedValues = (selectedValues) => {
  Object.keys(selectedValues).forEach((field) => {
    selectedValues[field] = [];
  });
  return selectedValues;
};

export const TableSetPresetFilters = (
  table: Table,
  columns: TableColumn[],
  options?: {
    additionalFilters?: Filters;
  }
) => {
  table.filters = {};
  for (const column of columns) {
    if (column?.filter?.preset) {
      table.filters[column.field] = cloneDeep(column.filter.preset);
    }
  }
  if (options?.additionalFilters) {
    for (const field in options.additionalFilters) {
      table.filters[field] = options.additionalFilters[field];
    }
  }
};

export const GetFiltersWithColumns = (
  table: Table,
  selectedColumns: TableColumn[]
): TableFiltersWithColumns => {
  const filtersWithColumns: TableFiltersWithColumns = cloneDeep(table.filters);
  const tableFilters: TableFiltersWithColumns = {};
  let hasFilterColumn = false;
  for (const field in filtersWithColumns) {
    const filter = filtersWithColumns[field];
    if (IsFilterMetadata(filter)) {
      // TODO look into rewriting this, has strange logic
      if (
        filter.value !== "object" &&
        filter.value !== null &&
        !(filter.value instanceof Date) &&
        !(typeof filter.value === "boolean") &&
        !filter.value?.length
      ) {
        delete filtersWithColumns[field];
        continue;
      }
      filter.column = selectedColumns.find(
        (column) => column.field.toLowerCase() === field.toLowerCase()
      );
      if (filter?.column?.filter !== null) {
        hasFilterColumn = true;
      }
    } else {
      for (const filerArrayItem of filter) {
        filerArrayItem.column = selectedColumns.find(
          (column) => column.field.toLowerCase() === field.toLowerCase()
        );
        if (filerArrayItem?.column?.filter !== null) {
          hasFilterColumn = true;
        }
      }
    }
    if (hasFilterColumn) {
      tableFilters[field] = filter;
    }
  }
  return tableFilters;
};

// Type guard to check if the value is FilterMetadata
export const IsFilterMetadata = (
  filter: any
): filter is FilterMetadata | FilterMetadataWithColumn => {
  return (
    filter &&
    typeof filter === "object" &&
    "value" in filter &&
    "matchMode" in filter
  );
};

export const TableApplyFilters = (table: Table, filters: Filters) => {
  table.filters = filters;
  table._filter();
};

export const TableReset = (
  table: Table,
  columns: TableColumn[],
  options?: {
    firstLoad?: boolean;
    paginator?: Paginator;
    additionalFilters?: Filters;
  }
) => {
  const isLazyTable = table.lazy ? true : false;
  if (isLazyTable) {
    table.lazy = false;
  }
  const cols = cloneDeep(columns);
  table.reset();
  if (options?.paginator) {
    options.paginator.rows = 25;
    options.paginator.onPageDropdownChange({
      originalEvent: new Event("change"),
      value: 0,
    });
  }
  // Reset sort
  const sortColumns = columns.filter((column) => column.sortOrder);
  if (sortColumns.length === 1) {
    const singleSortColumn = sortColumns[0];
    table._sortOrder = singleSortColumn.sortOrder;
    table._sortField = singleSortColumn.field;
    table.sortSingle();
  }
  // TODO handle multiple sort fields
  // reset filters
  TableSetPresetFilters(table, cols, {
    additionalFilters: options?.additionalFilters,
  });
  if (isLazyTable) {
    table.lazy = true;
  }
  // After load
  if (!options?.firstLoad) {
    table._filter();
  }
};

export const IsPaginatorState = (object: unknown) => {
  if (
    object &&
    Array.isArray(Object.keys(object)) &&
    Object.keys(object).length === 4 &&
    Object.keys(object).every((key) =>
      ["first", "page", "rows", "pageCount"].includes(key)
    )
  ) {
    return true;
  }
  return false;
};

export const GetColor = (
  colors: TableColumnColor,
  value: string
): string | null => {
  if (!colors || !value) {
    return null;
  }
  const lowerCaseValue = value.toLowerCase();
  const lowerCaseKeys = Object.keys(colors).reduce((acc, key) => {
    acc[key.toLowerCase()] = colors[key];
    return acc;
  }, {} as TableColumnColor[]);
  return lowerCaseKeys[lowerCaseValue] ?? null;
};
