import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { ExportService } from "src/app/services/export.service";
import { IDownloadFile } from "../models/export-models";
import * as JSZip from "jszip";
import { saveAs } from "file-saver";
import { DownloadBillingItemResponse } from "../models/export/export-pdf.model";
import { DateTime } from "luxon";

@Injectable({ providedIn: "root" })
export class DownloadInvoiceCreditNoteService {
  private renderer: Renderer2;

  constructor(
    private exp: ExportService,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  downloadToPdf(
    invoiceNo: string,
    file: IDownloadFile,
    fileNamePrefix: string = "Digital Space Invoice"
  ): boolean {
    if (file.byteArray.length === 0) {
      return false;
    }

    const fileAsBlob = this.exp.b64toBlob(file.byteArray, file.contentType);

    const downloadLink = this.renderer.createElement("a");
    this.renderer.setStyle(downloadLink, "display", "none");
    this.renderer.appendChild(document.body, downloadLink);

    const objectUrl = window.URL.createObjectURL(fileAsBlob);
    this.renderer.setAttribute(downloadLink, "href", objectUrl);

    if (file.fileName) {
      this.renderer.setAttribute(downloadLink, "download", file.fileName);
    } else {
      this.renderer.setAttribute(
        downloadLink,
        "download",
        `${fileNamePrefix} ${invoiceNo}.pdf`
      );
    }

    downloadLink.click();
    this.renderer.removeChild(document.body, downloadLink);
    window.URL.revokeObjectURL(objectUrl);

    return true;
  }

  async downloadAsZip(
    downloadInvoicesResponseArray: DownloadBillingItemResponse[],
    fileNamePrefix: string
  ): Promise<boolean> {
    const zip = new JSZip();
    const dateToday = DateTime.local().toFormat("dd-MM-yyyy");

    for (const response of downloadInvoicesResponseArray) {
      if (response.file.byteArray.length === 0) {
        continue;
      }

      const fileAsBlob = this.exp.b64toBlob(
        response.file.byteArray,
        response.file.contentType
      );
      zip.file(
        response.file.fileName ||
          `${fileNamePrefix} ${response.billingItem.invoiceNo}.pdf`,
        fileAsBlob
      );
    }

    if (Object.keys(zip.files).length === 0) {
      return false; // No valid files to download
    }

    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, `${fileNamePrefix}s ${dateToday}.zip`);

    return true;
  }
}
