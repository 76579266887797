import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "change-planning",
  templateUrl: "./change-planning.component.html",
  styleUrls: ["../single/change.component.scss"],
})
export class ChangePlanningComponent implements OnInit {
  @Input("change") change: any;

  constructor() {}
  ngOnInit() {}
}
