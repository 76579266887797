import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";
import {
  ICreateWebHookRequest,
  IDeleteWebHookRequest,
  IWebHook,
} from "src/app/components/service-hub/developer/web-hooks/models/web-hook.model";

@Injectable({
  providedIn: "root",
})
export class DeveloperService {
  constructor(private apiRequest: ApiRequest) {}

  getCompanyApiKeys() {
    const requestProps: IRequestOptions = {
      endpoint: "Developer/GetCompanyApiKeys",
    };
    return this.apiRequest.request(requestProps);
  }

  generateApiKey(apiKeyNane: string) {
    const requestProps: IRequestOptions = {
      endpoint: "Developer/GenerateApiKey",
      body: apiKeyNane,
    };
    return this.apiRequest.request(requestProps);
  }

  regenerateApiKey(apiKeyName: string) {
    const requestProps: IRequestOptions = {
      endpoint: "Developer/RegenerateApiKey",
      body: apiKeyName,
    };
    return this.apiRequest.request(requestProps);
  }

  revokeApiKey(apiKeyName: string) {
    const requestProps: IRequestOptions = {
      endpoint: "Developer/RevokeApiKey",
      body: apiKeyName,
    };
    return this.apiRequest.request(requestProps);
  }

  getWebHooks(filter: any, amount: number, currentPage: number) {
    const requestProps: IRequestOptions = {
      endpoint: "Developer/GetWebHooks",
      body: {
        Filter: filter,
        Amount: amount,
        CurrentPage: currentPage,
      },
    };
    return this.apiRequest.request(requestProps);
  }

  getWebHooksByEnvironment(environment: string): Observable<IWebHook[]> {
    const requestProps: IRequestOptions = {
      endpoint: "Developer/GetWebHooksByEnvironment",
      body: environment,
    };
    return this.apiRequest.request(requestProps);
  }

  createWebHook(webhook: ICreateWebHookRequest) {
    const requestProps: IRequestOptions = {
      endpoint: "Developer/CreateWebHook",
      body: webhook,
    };
    return this.apiRequest.request(requestProps);
  }

  updateWebHook(webbook: any) {
    const requestProps: IRequestOptions = {
      endpoint: "Developer/UpdateWebHook",
      body: webbook,
    };
    return this.apiRequest.request(requestProps);
  }

  deleteWebHook(webhook: IDeleteWebHookRequest): Observable<boolean> {
    const requestProps: IRequestOptions = {
      endpoint: "Developer/DeleteWebHook",
      body: webhook,
    };
    return this.apiRequest.request(requestProps);
  }
}
