export interface ICredentials {
  allowedIpAddresses: any;
  companySysId: string;
  created: Date;
  friendlyName: string;
  key: string;
  lastUsed: Date;
  userId: string;
}

export interface ICredentialColumn {
  field: string;
  header: string;
  display: any;
  showSortIcon: boolean;
}

export const credentialColumns: ICredentialColumn[] = [
  {
    field: "friendlyName",
    header: "Name",
    display: { type: "text" },
    showSortIcon: true,
  },
  {
    field: "lastUsed",
    header: "Last Used",
    display: { type: "text" },
    showSortIcon: true,
  },
  {
    field: "created",
    header: "Created at",
    display: { type: "text" },
    showSortIcon: true,
  },
  {
    field: "regenerate",
    header: "Regenerate",
    display: { type: "button" },
    showSortIcon: false,
  },
  {
    field: "remove",
    header: "Remove",
    display: { type: "icon" },
    showSortIcon: false,
  },
];
