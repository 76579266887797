import { Component, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";

@Component({
  selector: "service-hub",
  templateUrl: "service-hub.component.html",
  styleUrls: ["service-hub.component.scss"],
})
export class ServiceHubComponent implements OnInit {
  items: MenuItem[] | undefined;

  ngOnInit() {
    this.items = [
      {
        label: "Options",
        items: [
          {
            label: "Update",
            icon: "pi pi-refresh",
            command: () => {
              // this.update();
            },
          },
          {
            label: "Delete",
            icon: "pi pi-times",
            command: () => {
              // this.delete();
            },
          },
        ],
      },
      {
        label: "Navigate",
        items: [
          {
            label: "Angular",
            icon: "pi pi-external-link",
            url: "http://angular.io",
          },
          {
            label: "Router",
            icon: "pi pi-upload",
            routerLink: "/fileupload",
          },
        ],
      },
    ];
  }
}
