import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { LocalStorageService } from "src/app/services/local-storage-service";
import {
  IStateColor,
  ITableDataApiResponse,
  User,
  IUserTableResponse,
  IUsersColumn,
  usersColumns,
} from "./users.model";
import { IUserProfile } from "./shared/user-models";
import { AuthService } from "src/app/services/auth/auth.service";
import { ContactsService } from "src/app/services/api/contacts/contacts.service";
import { MenuItem } from "primeng/api";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { ExportTable } from "../../products-and-services/science-logic/cmdb-devices/shared/export-devices";
import { Table } from "primeng/table";
import { LazyLoadEvent } from "primeng/api";
import {
  IsPaginatorState,
  SetSelectedColumns,
  TableReset,
} from "src/app/helpers/table.helper";
import {
  ExportFileName,
  ExportService,
  ExportTableEndpoint,
  ExportType,
} from "src/app/services/export.service";
import { IDownloadFile, ITableToFile } from "src/app/models/export-models";
import { ToastService } from "src/app/services/toast.service";
import { cloneDeep } from "lodash-es";
import { TableColumn } from "src/app/models/table";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { Paginator, PaginatorState } from "primeng/paginator";
@Component({
  selector: "users",
  templateUrl: "users.component.html",
  styleUrls: ["users.component.scss"],
})
export class UsersComponent implements OnInit {
  @ViewChild("dataTable") dataTable: Table;
  @ViewChild("tablePaginator") paginator: Paginator;
  userSubject = new BehaviorSubject(null);
  users$ = this.userSubject.asObservable();
  user: IUserProfile;
  canAddEditUsers: boolean = false;
  tableLoading: boolean = false;
  totalRecords: number = 0;
  tableColumn: IUsersColumn[];
  defaultSelectedColumns: string[] = [];
  tableFilters: LazyLoadEvent;
  selectedUsers: User[] = [];

  cols: TableColumn[] = cloneDeep(usersColumns);

  firstLoad: boolean = true;
  _selectedColumns: TableColumn[] = [];

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  exportOptions = [
    {
      label: "All",
      value: "all",
      command: () => this.exportAll(),
    },
    {
      label: "In View",
      value: "in_view",
      command: () =>
        ExportTable(
          this.dataTable,
          this.userSubject.value,
          this._selectedColumns,
          "in_view"
        ),
    },
  ];

  constructor(
    private router: Router,
    private LocalStorageService: LocalStorageService,
    private authService: AuthService,
    private contactsService: ContactsService,
    private breadcrumbService: BreadcrumbService,
    private toastService: ToastService,
    private exportService: ExportService
  ) {}

  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [{ label: "Users" }];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.user = await this.authService.getUserOrImpersonatedUser();

    if (this.user.uTimicoPortalPermissions.includes("COMPANY_USERS_CREATE")) {
      this.canAddEditUsers = true;
    }
  }

  async loadUsers(table: Table, event?: LazyLoadEvent | PaginatorState) {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }
    table.loading = true;
    const isFirstLoad = this.firstLoad ? true : false;
    this.userSubject.next([]);
    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(usersColumns));
      this.firstLoad = false;
    }
    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event
    );

    const users: ITableDataApiResponse<IUserTableResponse> =
      await firstValueFrom(
        this.contactsService.getContactsList(serviceNowFilter)
      );

    this.userSubject.next(users.result);
    this.totalRecords = users.overallQueryCount;
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    table.loading = false;
  }

  openAddUser() {
    this.router.navigate(["secure/company/users/add"]);
    this.LocalStorageService.saveData("fromBroadBandOrder", "false");
  }

  handleRowClicked(event: any): void {
    // if (this.userMutipleSelectState.users.length == 0) {
    //   if (this.user.uTimicoPortalPermissions.includes("COMPANY_USERS_UPDATE")) {
    //     this.router.navigateByUrl(`/secure/company/users/edit/${event.sysId}`);
    //   }
    // }
  }

  onMultipleUserEdit() {
    // this.userMutipleSelectState.users = this.selectedUsers;
    if (this.selectedUsers.length == 1) {
      const user = this.selectedUsers[0];
      this.router.navigateByUrl(`/secure/company/users/edit/${user.sysId}`);
      return;
    }
    return this.router.navigate([
      "secure/company/users/multiple/edit",
      {
        users: JSON.stringify(this.selectedUsers.map((user) => user.sysId)),
      },
    ]);
  }

  getColor(colors: IStateColor, value: string) {
    return colors[value] ?? null;
  }

  getPortalAccessColor(value: boolean): string {
    if (value) {
      return "text-teal-300";
    } else {
      return "text-gray-900";
    }
  }

  handleColumnChange(val: any[]) {
    this.cols = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  defaultExport() {
    ExportTable(
      this.dataTable,
      this.userSubject.value,
      this.tableColumn,
      "all"
    );
  }

  async exportAll() {
    this.toastService.showToastInfo(
      "Downloading - ",
      " We are just sorting your data out for you. This won't take long."
    );

    const serviceNowFilter = BuildServiceNowQuery(this.dataTable, this.cols);
    const fields = this._selectedColumns.map((item) => item.exportField);
    const endpoint: string = ExportTableEndpoint.Users;
    const body: ITableToFile = {
      Filter: serviceNowFilter.Filter,
      ExportType: ExportType.Csv,
      CurrentPage: 0,
      Amount: 25,
      Pagination: false,
      ExportColumns: fields,
    };
    const downloadedFile: IDownloadFile =
      await this.exportService.exportTableToFile(endpoint, body);
    this.exportService.downloadFile(
      downloadedFile,
      ExportType.Csv,
      ExportFileName.users
    );
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = usersColumns
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter((column) => column?.default).map((column) => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
