import { TableColumnDisplay } from "../table";

export const ServiceRequestSizeLimit: number = 1000;

export enum ServiceRequestTabs {
  open = "Open",
  closed = "Closed",
}

export const ServiceRequestOpenStateValues = [
  "Pending",
  "Open",
  "Work In Progress",
  "Awaiting Info",
  "Fulfilled",
];
export const ServiceRequestClosedStateValues = ["Closed"];

export type GetServiceRequestsResponse = {
  sysId: string;
  shortDescription: string;
  state: string;
  openedAt?: Date;
  number: string;
  requestedFor: string;
};

//Column names match export DTOs in Customer portal
export enum ServiceRequestTableColumnName {
  number = "Number",
  shortDescription = "ShortDescription",
  description = "Description",
  requestedFor = "RequestedFor",
  requestedForSysId = "RequestedFor.SysId",
  openedAt = "OpenedAt",
  state = "State",
}

export enum CaseTableColumnName {
  number = ServiceRequestTableColumnName.number,
  shortDescription = ServiceRequestTableColumnName.shortDescription,
  description = ServiceRequestTableColumnName.description,
  requestedFor = ServiceRequestTableColumnName.requestedFor,
  openedAt = ServiceRequestTableColumnName.openedAt,
  state = ServiceRequestTableColumnName.state,
}

export type ServiceRequestColumn = {
  field: string;
  header: string;
  display: TableColumnDisplay;
  showSortIcon: boolean;
  default: boolean;
  disabled?: boolean;
  serviceNowField: string;
};

export const serviceRequestColors = {
  New: "new",
  "Work in progress": "workinprogress",
  Pending: "pending",
  "Awaiting Info": "awitinginfo",
  Resolved: "resolved",
  Closed: "closed",
  Fulfilled: "fulfilled",
  Open: "open",
};
