import { Component, Input } from '@angular/core';

@Component({
    selector: "progress-circle",
    templateUrl: "progress-circle.component.html"
})
export class ProgressCircle {


    @Input("percent") percent;
    @Input("title") title = "";
    @Input("subtitle") subtitle = "";
    @Input("radius") radius = 100;
    @Input("outerStrokeWidth") outerStrokeWidth = 10;
    @Input("innerStrokeWidth") innerStrokeWidth = 10;
    @Input("outerStrokeColor") outerStrokeColor = "#E31C79";
    @Input("innerStrokeColor") innerStrokeColor = "#e7e8ea";
    showUnits = false;



}