import { environment } from "src/environments/environment";
import { IUserDetails } from "../components/service-hub/account/shared/models/account-models";
import parsePhoneNumberFromString from "libphonenumber-js";

export const addEditNumberFormatter = (number: string): string => {
  const phoneNumber = parsePhoneNumberFromString(
    number.replace(/[^\d+]/g, ""),
    "GB"
  );

  const formattedNumber =
    environment.name === "development" || environment.name === "uat"
      ? phoneNumber.formatInternational()
      : phoneNumber.formatNational();

  return formattedNumber.replace(/\s/g, "");
};
