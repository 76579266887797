import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FileUploadService } from "src/app/services/file-upload.service";
import { FormBuilder, Validators } from "@angular/forms";
import { FileUpload } from "src/app/components/misc/file-upload/file-upload";
import {
  TicketData,
  Pac,
} from "src/app/components/service-hub/tickets/shared/incident-model";
import { CallService } from "src/app/services/api/call/call.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { RequestedItemsService } from "src/app/services/api/requested-items/requested-items.service";
import { firstValueFrom } from "rxjs";
import { ICreateInteractionRequest } from "src/app/services/api/interaction/interaction-models";
import { InteractionService } from "src/app/services/api/interaction/interaction.service";
import { MenuItem } from "primeng/api/menuitem";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";

@Component({
  selector: "create-service-request",
  templateUrl: "./create-service-request.component.html",
  styleUrls: ["./create-service-request.component.scss"],
})
export class CreateServiceRequestComponent implements OnInit {
  @ViewChild("requestStepper") stepper;
  showLoader: boolean = false;
  requestNumber: string;
  requestSysId: string;
  serviceRequestInfo: TicketData;
  pacRequestInfo: Pac;
  uploadedFiles: FileUpload[];
  serviceNowMobileNumberVariables: object = {};
  fileUploadErrors: object;
  canViewBroadbandOrder: boolean = false;
  items: MenuItem[] | undefined;
  steps: MenuItem[] | undefined = [];

  selectedCategory: any;
  selectedPacCategory: any;

  categories: any[] = [
    { name: "General Service Request", key: "general" },
    { name: "Mobile PAC Request", key: "mobile" },
    { name: "Cancel a Service", key: "cancel" },
  ];

  pacCategories: any[] = [
    {
      name: "Single",
      pacType: "2926b76adbb0bb409ccaf58dae9619df",
    },
    {
      name: "Multiple",
    },
  ];

  activeIndex: MenuItem = this.steps[0];
  activeStepIndex: number = 0;

  rasieRequestControls = this.fb.group({
    shortDescription: ["", [Validators.required]],
    description: ["", [Validators.required]],
  });

  raisePacRequestControls = this.fb.group({
    pacType: ["2926b76adbb0bb409ccaf58dae9619df"],
    mobileNumber: [""],
  });

  constructor(
    private router: Router,
    private fileUploadService: FileUploadService,
    private fb: FormBuilder,
    private callService: CallService,
    private requestedItemsService: RequestedItemsService,
    private authService: AuthService,
    private interactionService: InteractionService,
    public breadcrumbService: BreadcrumbService
  ) {
    this.fileUploadService.filesToTransmit.subscribe((data: FileUpload[]) => {
      this.uploadedFiles = data;
    });
  }

  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      { label: "Raise a Ticket", routerLink: "/secure/tickets/raise" },
      { label: "Raise a Service Request" },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.items = [
      {
        label: "Category",
      },
      {
        label: "Content",
      },
      {
        label: "Submit",
      },
    ];
    this.activeIndex = this.items[this.activeStepIndex];
    this.selectedCategory = this.categories[0];
    this.selectedPacCategory = this.pacCategories[0];
    this.pacInputSetValidators();
  }

  onActiveIndexChange(event: number): void {
    this.activeStepIndex = event;
    this.activeIndex = this.items[this.activeStepIndex];
  }

  stepForward(): void {
    if (this.items[this.activeStepIndex + 1]) {
      this.onActiveIndexChange(this.activeStepIndex + 1);
    }
  }

  backButton(): void {
    this.router.navigate(["secure/tickets/raise"]);
  }

  stepBack(): void {
    if (this.items[this.activeStepIndex - 1]) {
      this.onActiveIndexChange(this.activeStepIndex - 1);
    }
  }

  submitButton(): void {
    if (this.items[this.activeStepIndex + 1]) {
      this.onActiveIndexChange(this.activeStepIndex + 1);
      this.submitRequest();
    }
  }

  async requestType(): Promise<void> {
    let requestType = this.selectedCategory?.key;
    switch (requestType) {
      case "general":
      case "cancel":
        this.serviceRequestInfo = {
          shortDescription: this.rasieRequestControls.value?.shortDescription,
          description: this.rasieRequestControls.value?.description,
          tableName: "new_call",
          files: this.uploadedFiles?.filter((f) => f.errorMessage == ""),
        };
        this.fileUploadErrors = this.uploadedFiles?.filter(
          (f) => f.errorMessage != ""
        );
        break;
      case "mobile":
        this.serviceNowMobileNumberVariables = await firstValueFrom(
          this.requestedItemsService.getRequestItemVariables(
            this.raisePacRequestControls.value?.pacType
          )
        );
        this.serviceNowMobileNumberVariables[0].value =
          this.raisePacRequestControls.value?.mobileNumber;
        this.pacRequestInfo = {
          catalogId: this.raisePacRequestControls.value?.pacType,
          variables: this.serviceNowMobileNumberVariables,
        };
        break;
      default:
        break;
    }
  }

  async submitRequest(): Promise<void> {
    this.showLoader = true;
    await this.requestType();
    let requestType = this.selectedCategory?.key;

    switch (requestType) {
      case "general":
      case "cancel":
        let request: ICreateInteractionRequest = {
          title: this.serviceRequestInfo.shortDescription,
          description: this.serviceRequestInfo.description,
          files: this.serviceRequestInfo.files,
        };
        let response = await firstValueFrom(
          this.interactionService.createInteraction(request)
        );
        this.showLoader = false;
        break;
      case "mobile":
        let mobileResponse = await firstValueFrom(
          this.requestedItemsService.create(this.pacRequestInfo)
        );
        this.requestNumber = mobileResponse.requestNumber;
        this.requestSysId = mobileResponse.sysId;
        this.showLoader = false;
        break;
      default:
        break;
    }
  }

  raiseRequest(): void {
    this.stepper.next();
  }

  raiseAnotherRequest(): void {
    this.selectedCategory = this.categories[0];
    this.rasieRequestControls.reset();
    this.raisePacRequestControls.reset();
    this.raisePacRequestControls.controls.pacType.setValue(
      "2926b76adbb0bb409ccaf58dae9619df"
    );
    this.uploadedFiles = null;
    this.activeStepIndex = 0;
    this.onActiveIndexChange((this.activeStepIndex = 0));
  }

  viewTicket(): void {
    this.router.navigateByUrl(
      "secure/tickets/service-request/" + this.requestSysId
    );
  }

  pacInputSetValidators(): void {
    const pacType = this.selectedPacCategory?.name;
    this.raisePacRequestControls.get("mobileNumber").clearValidators();

    if (pacType === "Single") {
      this.raisePacRequestControls
        .get("mobileNumber")
        .setValidators([Validators.required, Validators.pattern(/^07\d{9}$/)]);
    } else {
      this.raisePacRequestControls
        .get("mobileNumber")
        .setValidators(Validators.required);
    }
    this.raisePacRequestControls.get("mobileNumber").updateValueAndValidity();
  }
}
