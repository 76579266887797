import { Component, OnInit, ViewChild } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Table } from "primeng/table";
import { cloneDeep } from "lodash-es";
import { PrimeNGFilterService } from "src/app/services/prime-ng-filter.service";
import { IsFilterMetadata } from "src/app/helpers/table.helper";
import { Filters } from "src/app/models/table";
import { DateTime } from "luxon";

@Component({
  selector: "app-add-edit-filters",
  templateUrl: "./add-edit-filters.component.html",
  styleUrls: ["./add-edit-filters.component.scss"],
})
export class AddEditFiltersComponent implements OnInit {
  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private primeNGFilterService: PrimeNGFilterService
  ) {}
  @ViewChild("dataTable", { static: true }) dataTable: any;
  cols: any[];
  futurePeriodFilter: number;

  selectedValues: any[] = [];

  ngOnInit() {
    this.cols = this.config?.data?.cols?.filter((col) => col?.filter !== null);
    this.futurePeriodFilter = this.config?.data?.futurePeriodFilter;
  }

  ngAfterContentInit() {
    this.applyCurrentFilters(this.config.data.table);
  }

  cancel() {
    this.ref.close();
  }

  applyCurrentFilters(table: Table) {
    const filters: Filters = cloneDeep(table.filters);
    for (const field in filters) {
      const filter = filters[field];
      const column = this.cols.find((column) => column.field === field);
      if (column?.filter === null) {
        continue;
      }
      if (IsFilterMetadata(filter)) {
        if (
          !Array.isArray(filter.value) ||
          !["multiSelect", "multiSelectMetric"].includes(column.filter?.type)
        ) {
          this.dataTable.filter(filter.value, field, filter.matchMode);
        } else {
          this.selectedValues[field] = filter.value;
        }
      }
    }
  }

  applyFilters(table: Table): void {
    const filters = cloneDeep(table.filters);
    for (const field in filters) {
      const filter = filters[field];
      if (
        IsFilterMetadata(filter) &&
        filter.matchMode === "between" &&
        Array.isArray(filter.value)
      ) {
        const values = filter.value.filter((value) => value !== null);
        if (values.length === 1 && values[0] instanceof Date) {
          filter.matchMode = "dateIs";
          filter.value = values[0];
          delete filter.operator;
        }
        if (
          values.length === 2 &&
          values[0] instanceof Date &&
          values[1] instanceof Date
        ) {
          filter.value = [
            DateTime.fromJSDate(values[0]).startOf("day").toJSDate(),
            DateTime.fromJSDate(values[1]).endOf("day").toJSDate(),
          ];
        }
      }
      if (IsFilterMetadata(filter) && filter.value instanceof Date) {
        filter.matchMode = "dateIs";
        filter.operator = "and";
      }
      if (IsFilterMetadata(filter) && filter.value === null) {
        delete filters[field];
      }
    }
    for (const field in this.selectedValues) {
      filters[field] = {
        value: this.selectedValues[field],
        matchMode:
          this.cols?.find((col) => col.field === field)?.filter?.type ===
          "multiSelectMetric"
            ? "multiSelectMetric"
            : "in",
      };
    }
    this.ref.close(filters);
  }

  getDatesFromCalendar(
    selectedDate: object,
    table: Table,
    field: string
  ): void {
    if (Array.isArray(selectedDate)) {
      this.primeNGFilterService.filterByDates(table, selectedDate, field);
    } else {
      this.primeNGFilterService.filterByDate(table, selectedDate, field);
    }
  }
}
