import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { cloneDeep } from "lodash-es";

@Component({
  selector: "app-add-edit-user-review",
  templateUrl: "./add-edit-user-review.component.html",
  styleUrls: ["./add-edit-user-review.component.scss"],
})
export class AddEditUserReviewComponent implements OnInit {
  @Input() addEditUserForm: FormGroup;
  @Input() pageTitlePrefix: string = "Add";
  @Output() childIsLoading = new EventEmitter();
  review;

  loading: boolean = true;

  constructor() {}

  ngOnInit(): void {
    this.review = cloneDeep(this.addEditUserForm.value);
    this.review.permissions = this.organizePermissionsByCategory([
      ...this.review.permissions.rolePermissions.map((permission) => ({
        ...permission,
        rolePermission: true,
      })),
      ...this.review.permissions.additionalPermissions,
    ]);
  }

  organizePermissionsByCategory(
    permissions: any[]
  ): { name: string; permissions: any[] }[] {
    const categoriesWithPermissions: { [category: string]: any[] } = {};

    permissions.forEach((permission) => {
      const { category } = permission;

      if (!categoriesWithPermissions[category]) {
        categoriesWithPermissions[category] = [];
      }

      categoriesWithPermissions[category].push(permission);
    });

    const resultArray: { name: string; permissions: any[] }[] = Object.entries(
      categoriesWithPermissions
    ).map(([category, permissions]) => ({ name: category, permissions }));

    return resultArray;
  }
}
