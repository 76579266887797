import { TableColumn, TableSort } from "src/app/models/table";

export interface IBroadbandUser {
  company: string;
  installStatus: number;
  ipAddress: string;
  location: string;
  name: string;
  sysId: string;
  uDataUsage: string;
  uFttcAvailable: boolean;
  uFttpAvailable: boolean;
  uLastUpgradeNotification?: string;
  uNospId?: number;
  uNospUrl: string;
  uPackage: string;
  uPhone: string;
  uPostcode: string;
  uState: string;
  uWiresOnly: boolean;
  visible: boolean;
}

//Column names match export DTOs in Customer portal
enum BroadbandTableColumns {
  name = "Name",
  phone = "UPhone",
  package = "UPackage",
}
enum exportBroadbandColumns {
  name = "username",
  phone = "phoneNumber",
  package = "package",
}

export const broadbandColumns: TableColumn[] = [
  {
    field: "name",
    header: "Username",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    sortColumnOrder: 1,
    disabled: true,
    sortOrder: TableSort.asc,
    serviceNowField: BroadbandTableColumns.name,
    exportField: exportBroadbandColumns.name,
  },
  {
    field: "uPhone",
    header: "Circuit Reference",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: BroadbandTableColumns.phone,
    exportField: exportBroadbandColumns.phone,
  },
  {
    field: "uPackage",
    header: "Package",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: BroadbandTableColumns.package,
    exportField: exportBroadbandColumns.package,
  },
];

export interface IBroadbandMonthlyColumn {
  field: string;
  header: string;
  display: any;
  showSortIcon: boolean;
  default: boolean;
}

export const BroadbandMonthlyColumns: IBroadbandMonthlyColumn[] = [
  {
    field: "formattedDate",
    header: "Date ",
    display: { type: "date" },
    showSortIcon: true,
    default: true,
  },
  {
    field: "input",
    header: "	Upload (GB)",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
  },
  {
    field: "output",
    header: "Download (GB)",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
  },
];

export interface IBroadbandReport {
  field: string;
  header: string;
  display: any;
  showSortIcon: boolean;
  default: boolean;
}

export const broadbandReportColumns: IBroadbandReport[] = [
  {
    field: "date",
    header: "Date ",
    display: { type: "date" },
    showSortIcon: true,
    default: true,
  },
  {
    field: "input",
    header: "	Upload (GB)",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
  },
  {
    field: "output",
    header: "Download (GB)",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
  },
];

export type BroadBandUsage = {
  date: string;
  formattedDate: Date;
  input: string;
  output: string;
  sysId: string;
};
