import { Injectable } from "@angular/core";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";
import { ServiceNowFilterQuery } from "src/app/models/servicenow.model";

@Injectable({
  providedIn: "root",
})
export class KnowledgeService {
  constructor(private apiRequest: ApiRequest) {}

  getKnowledgeBase(sysId: string, shortDescription: string, helpful: boolean) {
    return this.apiRequest.request({
      endpoint: "Knowledge/PostKnowledgeArticleHelpfulness",
      body: {
        SysId: sysId,
        ShortDescription: shortDescription,
        Helpful: helpful,
      },
    });
  }

  getKnowledgeArticles(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "Knowledge/GetKnowledgeArticles",
      body: request,
    };

    return this.apiRequest.request(requestProps);
  }

  getKnowledgeArticle(sysId: string, companyId: string) {
    const requestProps: IRequestOptions = {
      endpoint: "Knowledge/GetKnowledgeArticle",
      body: {
        SysId: sysId,
        UCompany: companyId,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  getKnowledgeArticlesByMeta(searchCriteria: string[]) {
    const requestProps: IRequestOptions = {
      endpoint: "Knowledge/GetKnowledgeArticlesByMeta",
      body: {
        searchCriteria,
      },
    };
    return this.apiRequest.request(requestProps);
  }
}
