import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  firstValueFrom,
  Observable,
  Subject,
  switchMap,
  takeUntil,
} from "rxjs";
import { IncidentService } from "src/app/services/api/incident/incident.service";
import { KnowledgeService } from "src/app/services/api/knowledge/knowledge.service";
import { IncidentIssues, ServiceCategory } from "../models/identify-models";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { KnowledgeArticleSummary } from "../../../misc/knowledge-article/shared/knowledge-models";
import { environment } from "src/environments/environment";
import { cloneDeep } from "lodash-es";

type ExtendedServiceCategory = ServiceCategory & {
  originalNames: string[];
  children: ExtendedServiceCategory[];
};

@Component({
  selector: "identify-incident-issue-component",
  templateUrl: "./identify-incident-issue.component.html",
  styleUrl: "./identify-incident-issue.component.scss",
})
export class IdentifyIncidentIssueComponent {
  @Output() formComplete = new EventEmitter<any>();
  @Input() resetTrigger$: Observable<boolean>;

  private requestSubject = new Subject<any>();

  destroy$ = new Subject<any>();
  loading: boolean = false;
  serviceCategories:
    | Partial<ExtendedServiceCategory>[]
    | ExtendedServiceCategory[];
  ExtendedServiceCategory: ExtendedServiceCategory;
  subCategories: ExtendedServiceCategory[];
  symptoms: ExtendedServiceCategory[];

  suggestedArticles: KnowledgeArticleSummary[] = [];
  loadingSuggestedArticles: boolean = false;

  identifyForm = new FormGroup({
    category: new FormControl(null, Validators.required),
    subCategory: new FormControl(
      { value: null, disabled: true },
      Validators.required
    ),
    symptom: new FormControl(
      { value: null, disabled: true },
      Validators.required
    ),
  });

  constructor(
    private incidentService: IncidentService,
    private knowledgeService: KnowledgeService
  ) {}

  async ngOnInit() {
    this.resetTrigger$
      .pipe(takeUntil(this.destroy$))
      .subscribe((resetTrigger) => {
        if (resetTrigger) {
          this.resetForm();
        }
      });
    this.identifyForm.valueChanges.subscribe((value) => {
      this.suggestedArticles = [];
    });
    this.disableFormControls();
    await this.getExtendedServiceCategory();
    this.requestSubject
      .pipe(
        switchMap((category: ExtendedServiceCategory) => {
          const originalNames = category.originalNames
            ? category.originalNames
            : [category.originalName];

          const searchCriteria = [
            ...new Set([
              ...category.parents.map((parent) => parent.originalName),
              ...originalNames,
            ]),
          ];

          this.loadingSuggestedArticles = true;
          return this.knowledgeService.getKnowledgeArticlesByMeta(
            searchCriteria
          );
        })
      )
      .subscribe((articles) => {
        this.suggestedArticles = articles;
        this.loadingSuggestedArticles = false;
      });
  }

  async getExtendedServiceCategory(): Promise<void> {
    this.loading = true;
    const serviceCategoriesResponse = await firstValueFrom(
      this.incidentService.getIncidentCategories()
    );
    const serviceCategories = this.consolidateCategoriesByName(
      this.assignParents(serviceCategoriesResponse)
    );
    this.serviceCategories = serviceCategories;
    this.loading = false;
  }

  assignParents(
    serviceCategories: ExtendedServiceCategory[],
    directParent: ExtendedServiceCategory = null,
    parents: ExtendedServiceCategory[] | ServiceCategory[] = []
  ) {
    directParent = directParent ? cloneDeep(directParent) : null;
    if (directParent) {
      delete directParent.children;
      delete directParent.parents;
    }
    for (const ExtendedServiceCategory of serviceCategories) {
      if (!ExtendedServiceCategory.parents?.length) {
        ExtendedServiceCategory.parents = [];
      }
      if (directParent) {
        ExtendedServiceCategory.parents = [...parents, { ...directParent }];
      }
      if (ExtendedServiceCategory.children.length > 0) {
        this.assignParents(
          ExtendedServiceCategory.children,
          ExtendedServiceCategory,
          ExtendedServiceCategory.parents
        );
      }
    }
    return serviceCategories;
  }

  consolidateCategoriesByName = (
    categories: ExtendedServiceCategory[]
  ): Partial<ExtendedServiceCategory>[] => {
    let consolidated: { [key: string]: Partial<ExtendedServiceCategory> } = {};
    for (const item of categories) {
      if (!consolidated[item.name]) {
        consolidated[item.name] = {
          name: item.name,
          originalNames: [item.originalName],
          parents: item.parents,
          children: [...item.children],
        };
      } else {
        consolidated[item.name].parents = [
          ...consolidated[item.name].parents,
          ...item.parents,
        ];
        consolidated[item.name].originalNames.push(item.originalName);
        consolidated[item.name].children.push(...item.children);
      }
    }
    Object.values(consolidated).forEach((item) => {
      item.children.sort((a, b) => a.name.localeCompare(b.name));
    });
    return Object.values(consolidated);
  };

  setSubCategory(event: any) {
    // TODO removed as is it's not working as intended and potentially not valuable, commented out for testing on UAT
    // this.suggestArticles(event);
    const category: ExtendedServiceCategory = event.value;
    this.subCategories = category.children;
    this.symptoms = [];
    this.identifyForm.get("subCategory")?.reset();
    this.identifyForm.get("symptom")?.disable();
  }

  setSymptom(event: any): void {
    this.suggestArticles(event);
    const subCategory: ExtendedServiceCategory = event.value;
    this.identifyForm.get("symptom")?.reset();
    this.symptoms = subCategory.children;
  }

  disableFormControls(): void {
    this.identifyForm.get("category")?.valueChanges.subscribe((category) => {
      if (category) {
        this.identifyForm.get("subCategory")?.enable();
      }
    });

    this.identifyForm
      .get("subCategory")
      ?.valueChanges.subscribe((subCategory) => {
        if (subCategory) {
          this.identifyForm.get("symptom")?.enable();
        }
      });
  }

  async suggestArticles(event: any): Promise<void> {
    if (!environment?.features?.raise_incident_suggest_kb_articles) {
      return;
    }
    const category: ExtendedServiceCategory = event.value;
    this.requestSubject.next(category);
  }

  completeForm(): void {
    const incidentIssue: IncidentIssues = {
      category: this.identifyForm.value.category.sysId,
      subCategory: this.identifyForm.value.subCategory.sysId,
      symptom: this.identifyForm.value.symptom.sysId,
    };

    this.formComplete.emit(incidentIssue);
  }

  resetForm(): void {
    this.identifyForm.reset();
    this.symptoms = [];
    this.subCategories = [];
    this.identifyForm.get("subCategory")?.disable();
    this.identifyForm.get("symptom")?.disable();
  }
}
