import { Component, OnInit } from "@angular/core";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import * as FileSaver from "file-saver";
import { ReplacePdfPipe } from "src/app/pipes/replace-pdf.pipe";
import { FileService } from "src/app/services/api/file/file.service";
import {
  IServiceColumn,
  IServiceColumns,
} from "../shared/service-policies-interfaces";
import { MenuItem } from "primeng/api/menuitem";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-service-policies",
  templateUrl: "./service-policies.component.html",
  styleUrls: ["./service-policies.component.scss"],
})
export class ServicePoliciesComponent implements OnInit {
  domainsRecordsSubject = new BehaviorSubject(null);
  policies$ = this.domainsRecordsSubject.asObservable();
  policies: Array<any> = [];

  cols!: IServiceColumn[];
  tableColumn: IServiceColumn[];
  loading: boolean;

  constructor(
    private PDFReplace: ReplacePdfPipe,
    private fileService: FileService,
    private breadcrumbService: BreadcrumbService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    const breadcrumbs: MenuItem[] = [
      {
        label: "Service Policies",
        routerLink: "/secure/company/service-policies",
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.tableColumn = IServiceColumns;
    this.cols = this.tableColumn;
    this.getFilesFromBucket();
  }

  async getFilesFromBucket(): Promise<void> {
    this.loading = true;
    const bucketResponse = await firstValueFrom(
      this.fileService.getServicePoliciesFile()
    );
    bucketResponse.forEach((element) => {
      this.policies.push(this.PDFReplace.transform(element.key));
    });

    this.domainsRecordsSubject.next(this.policies);
    this.loading = false;
  }

  async downloadPolicy(eventClick): Promise<void> {
    const URLBuilder = await firstValueFrom(
      this.fileService.getDownloadPolicy(eventClick)
    );
    this.toastService.showToastInfo(
      "Downloading the policy - ",
      "this won't take long."
    );
    this.downloadPDF(URLBuilder, eventClick);
  }

  async downloadPDF(UrlBase, fileName): Promise<void> {
    let UrlAaBob = await (await fetch(UrlBase)).blob();
    FileSaver.saveAs(UrlAaBob, fileName);
  }
}
