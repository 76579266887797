import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit {
  @Input() options: Array<String>;
  @Input() type: any;
  @Output() refresh = new EventEmitter();

  constructor() {}

  val: any;
  @Output() filterChange = new EventEmitter();
  @Input()
  get filterValue() {
    return this.val;
  }

  set filterValue(val) {
    this.val = val;
    this.filterChange.emit(val);
  }

  ngOnInit() {}

  submit() {
    this.refresh.emit();
  }
}

export class Filter {
  Column: string = "";
  Value: string[] = [];
  Type?: string = "";
  Options?: any[] = [];
  DynamicOptions?: any = {};
  Visibility?: boolean = false;
  InputSettings?: any = {};
  DatePickerOptions?: any = {};
  Placeholder? = "Select";
}
