import { Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { IUserDetails } from "src/app/components/service-hub/account/shared/models/account-models";
import { AuthService } from "src/app/services/auth/auth.service";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { ContactsService } from "src/app/services/api/contacts/contacts.service";
import { ServiceNowValidatorService } from "src/app/services/existing-service-now-validator.service";

export enum UserExistsByEmail {
  yes = "YES",
  no = "NO",
  pending = "PENDING",
}

@Component({
  selector: "app-impersonate-user",
  templateUrl: "./impersonate-user.component.html",
  styleUrls: ["./impersonate-user.component.scss"],
})
export class ImpersonateUserComponent {
  profile: IUserDetails;
  errorMessage: string;
  loading: boolean = false;

  userExistsByEmail = UserExistsByEmail;

  checkingUserExistsByEmail: UserExistsByEmail = UserExistsByEmail.no;

  emailValidation = new FormControl("", {
    validators: [Validators.required, Validators.email],
    updateOn: "blur",
    asyncValidators: [
      this.existingServiceNowValidator.checkUserExistsByEmail(""),
    ],
  });

  constructor(
    private authService: AuthService,
    private contactsService: ContactsService,
    private existingServiceNowValidator: ServiceNowValidatorService,
    public ref: DynamicDialogRef
  ) {
    this.emailValidation.statusChanges.subscribe((status) => {
      this.checkingUserExistsByEmail = status as UserExistsByEmail;
    });
  }

  async ImpersonateUser(): Promise<void> {
    try {
      this.loading = true;
      const email = this.emailValidation.value;
      await this.authService.impersonateUser(email);
    } catch (error) {
      this.loading = false;
      // TODO handle error
      console.error(error);
    }
  }

  enterKeyPressed(event) {
    if (event.keyCode == 13 && this.emailValidation.valid)
      this.ImpersonateUser();
  }

  close() {
    this.ref.close();
  }
}
