import { Injectable } from "@angular/core";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";

const taskDirectory = "RequestedItems";

@Injectable({
  providedIn: "root",
})
export class RequestedItemsService {
  constructor(private apiRequest: ApiRequest) {}

  create(request: any) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/createServiceRequest`,
      body: request,
    };

    return this.apiRequest.request(requestProps);
  }

  getRequestItemVariablesFull() {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/getRequestItemVariablesFull2afad9fddb320b004686f7461d961998`,
    };

    return this.apiRequest.request(requestProps);
  }

  getRequestItemVariables(pacType: any) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/getRequestItemVariables`,
      body: pacType,
    };

    return this.apiRequest.request(requestProps);
  }
}
