export interface IdetailsColumn {
  field: string;
  header: string;
  showSortIcon: boolean;
  display: any;
  default: boolean;
  cell?: (data: any) => any;
  expand?: (data: any) => any;
}

export const detailsColumns: IdetailsColumn[] = [
  {
    field: "date",
    header: "Date",
    display: { type: "date" },
    showSortIcon: false,
    default: true,
  },
  {
    field: "raisedBy",
    header: "Raised By",
    display: { type: "text" },
    showSortIcon: false,
    default: true,
  },
  {
    field: "content",
    header: "Content",
    display: { type: "text" },
    showSortIcon: false,
    default: true,
    cell: (data) => data.uQuestion,
    expand: (data) => data.uAnswer,
  },
];
