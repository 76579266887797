import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ScienceLogicService } from "../api/science-logic/science-logic.service";
import {
  DevicesGeneralFilter,
  DevicesNetworkFilter,
  DevicesServerFilter,
  DevicesStorageFilter,
} from "src/app/components/misc/filters/filters";
import { DeviceServerColumns } from "./shared/device-server/device-server-columns";
import { DeviceServerDetailColumns } from "./shared/device-server/device-server-detail-columns";
import { DeviceServerUnreachableColumns } from "./shared/device-server/device-server-unreachable-column";
import { DeviceNetworkUnreachableColumns } from "./shared/device-network/device-network-unreachable-column";
import { DeviceNetworkDetailColumns } from "./shared/device-network/device-network-detail-columns";
import { DeviceNetworkColumns } from "./shared/device-network/device-network-columns";
import { DeviceGeneralUnreachableColumns } from "./shared/device-general/device-general-unreachable-column";
import { DeviceGeneralDetailColumns } from "./shared/device-general/device-general-detail-columns";
import { DeviceGeneralColumns } from "./shared/device-general/device-general-columns";
import { DeviceStorageColumns } from "./shared/device-storage/device-storage-columns";
import { DeviceStorageDetailColumns } from "./shared/device-storage/device-storage-detail-columns";
import { DeviceStorageUnreachableColumns } from "./shared/device-storage/device-storage-unreachable-column";
import { LoadingSpinnerService } from "src/app/components/misc/loading-spinner/loading-spinner.service";
import { splitArray } from "src/app/helpers/array.helper";
import { DeviceStoragePausedColumns } from "./shared/device-storage/device-storage-paused-columns";
import { DeviceGeneralPausedColumns } from "./shared/device-general/device-general-paused-columns";
import { DeviceNetworkPausedColumns } from "./shared/device-network/device-network-paused-colums";
import { DeviceServerPausedColumns } from "./shared/device-server/device-server-paused-columns";

const Increments = 250;
export const DefaultTab = "device-list";

@Injectable({
  providedIn: "root",
})
export class DevicesService {
  constructor(
    private scienceLogicService: ScienceLogicService,
    private loadingSpinnerService: LoadingSpinnerService
  ) {}

  getCategoryParams(category: string) {
    switch (category) {
      // Storage
      case "storage-paused-devices":
        return {
          cols: DeviceStoragePausedColumns,
          filter: DevicesStorageFilter,
        };
      case "storage-unreachable-devices":
        return {
          cols: DeviceStorageUnreachableColumns,
          filter: DevicesStorageFilter,
        };
      case "storage-detailed":
        return {
          cols: DeviceStorageDetailColumns,
          filter: DevicesStorageFilter,
        };
      case "storage-device-list":
        return {
          cols: DeviceStorageColumns,
          filter: DevicesStorageFilter,
        };
      // General
      case "general-paused-devices":
        return {
          cols: DeviceGeneralPausedColumns,
          filter: DevicesGeneralFilter,
        };
      case "general-unreachable-devices":
        return {
          cols: DeviceGeneralUnreachableColumns,
          filter: DevicesGeneralFilter,
        };
      case "general-detailed":
        return {
          cols: DeviceGeneralDetailColumns,
          filter: DevicesGeneralFilter,
        };
      case "general-device-list":
        return {
          cols: DeviceGeneralColumns,
          filter: DevicesGeneralFilter,
        };
      // Network
      case "network-paused-devices":
        return {
          cols: DeviceNetworkPausedColumns,
          filter: DevicesNetworkFilter,
        };
      case "network-unreachable-devices":
        return {
          cols: DeviceNetworkUnreachableColumns,
          filter: DevicesNetworkFilter,
        };
      case "network-detailed":
        return {
          cols: DeviceNetworkDetailColumns,
          filter: DevicesNetworkFilter,
        };
      case "network-device-list":
        return {
          cols: DeviceNetworkColumns,
          filter: DevicesNetworkFilter,
        };
      // Server
      case "server-paused-devices":
        return {
          cols: DeviceServerPausedColumns,
          filter: DevicesServerFilter,
        };
      case "server-unreachable-devices":
        return {
          cols: DeviceServerUnreachableColumns,
          filter: DevicesServerFilter,
        };
      case "server-detailed":
        return {
          cols: DeviceServerDetailColumns,
          filter: DevicesServerFilter,
        };
      case "server-device-list":
      default:
        return {
          cols: DeviceServerColumns,
          filter: DevicesServerFilter,
        };
    }
  }

  async getDevices(category: string) {
    this.loadingSpinnerService.setLoadingMessage(`Loading...`);
    const { cols, filter } = this.getCategoryParams(category);
    const devices = await firstValueFrom(
      this.scienceLogicService.getServiceNowDevices(filter)
    );
    return {
      columns: cols,
      devices: devices,
    };
  }

  async getDevicesUnreachable(category: string) {
    this.loadingSpinnerService.setLoadingMessage(`Loading...`);
    const { cols, filter } = this.getCategoryParams(category);
    const devices = await firstValueFrom(
      this.scienceLogicService.getScienceLogicDevicesUnreachable(filter)
    );
    return {
      columns: cols,
      devices: devices,
    };
  }

  async getDevicesPaused(category: string) {
    this.loadingSpinnerService.setLoadingMessage(`Loading...`);
    const { cols, filter } = this.getCategoryParams(category);
    const devices = await firstValueFrom(
      this.scienceLogicService.getScienceLogicDevicesPaused(filter)
    );
    return {
      columns: cols,
      devices: devices,
    };
  }

  async getDevicesDetailed(category: string) {
    this.loadingSpinnerService.setLoadingMessage(`Loading...`);
    const { cols, filter } = this.getCategoryParams(category);
    let devices = await firstValueFrom(
      this.scienceLogicService.getServiceNowDevices(filter)
    );
    devices = await this.getResultsIncrementally(devices, cols);
    return {
      devices: devices,
      columns: cols,
    };
  }

  async getResultsIncrementally(devices, cols) {
    const devicesSplit: any = splitArray(devices, Increments);
    let i = 0;
    for (const devicesPartial of devicesSplit) {
      const deviceIDs = devicesPartial.map((device: any) => device.deviceId);
      this.loadingSpinnerService.setLoadingMessage(
        `Loading ${i} of ${devices.length}...`
      );
      try {
        const deviceDetails = await firstValueFrom(
          this.scienceLogicService.getScienceLogicDevicesDetails(deviceIDs)
        );
        for (const devicesPartialSingle of devicesPartial) {
          const deviceDetail = deviceDetails.find((deviceDetailsItem) => {
            return deviceDetailsItem.id === devicesPartialSingle.deviceId;
          });
          for (const col of cols) {
            if (!devicesPartialSingle.hasOwnProperty(col.field)) {
              devicesPartialSingle[col.field] =
                deviceDetail && deviceDetail[col.field] !== null
                  ? deviceDetail[col.field]
                  : null;
            }
          }
        }
      } catch (err) {
        devicesPartial.map((devicesPartialSingle: any) => {
          for (const col of cols) {
            if (!devicesPartialSingle.hasOwnProperty(col.field)) {
              devicesPartialSingle[col.field] = undefined;
            }
          }
        });
        console.error(err);
      }
      i += deviceIDs.length;
    }
    return devices;
  }

  getDeviceCategoryAndSubCategory(
    str: string,
    delimiter: string = "-"
  ): [string, string] {
    const parts = str.split(delimiter);
    const middleIndex = Math.floor(parts.length / 2);
    const firstHalf = parts.slice(0, middleIndex).join(delimiter);
    const secondHalf = parts.slice(middleIndex).join(delimiter);
    return [firstHalf, secondHalf];
  }

  devicesUrlBuilder({
    category,
    subCategory,
  }: {
    category: string;
    subCategory?: string;
  }): string {
    let url = `/secure/products-and-services/monitoring/devices/${category}`;
    if (subCategory) {
      return `${url}/${subCategory}`;
    }
    return url;
  }
}
