import { Component, OnInit } from "@angular/core";
import { CompanyService } from "src/app/components/service-hub/company/company-contacts/shared/company-service.service";
import {
  ContactType,
  IRemoveContactDialogData,
  IRemoveUsersFromContactTypeRequest,
} from "src/app/components/service-hub/company/company-contacts/shared/models";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "app-remove-contact",
  templateUrl: "./remove-contact.component.html",
  styleUrls: ["./remove-contact.component.scss"],
})
export class RemoveContactComponent implements OnInit {
  submittingData: boolean = false;
  data: IRemoveContactDialogData;
  contactType: string;

  constructor(
    private companyService: CompanyService,
    private dialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.data = this.dialogConfig?.data;
    this.contactType = this.dialogConfig?.data.type;
  }

  ngOnInit(): void {}

  async submitClicked() {
    const removeUsersRequest: IRemoveUsersFromContactTypeRequest = {
      contactTypeId: this.data.categorySysID,
      userId: this.data.userSysID,
    };
    this.submittingData = true;

    const requestSuccessful =
      this.contactType === ContactType.contact
        ? await this.companyService.removeUserContactGroup(removeUsersRequest)
        : await await this.companyService.removeUserFromContactType(
            removeUsersRequest
          );
    this.closeDialog(requestSuccessful);
  }

  closeDialog(dialogResponse) {
    this.ref.close(dialogResponse);
  }

  close() {
    this.ref.close();
  }
}
