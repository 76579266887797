import { Injectable } from "@angular/core";
import { IServicenowUser } from "../../users/users.model";
import {
  IAddUsersToContactTypeRequest,
  ICompanyContactCategory,
  IRemoveUsersFromContactTypeRequest,
  ITableDataApiResponse,
  IUsersForCategoryRequest,
} from "./models";
import { CompanyAPIService } from "src/app/services/api/company/company.service";
import { firstValueFrom } from "rxjs";
import { ServiceNowFilterQuery } from "src/app/models/servicenow.model";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  constructor(private companyAPIService: CompanyAPIService) {}

  async loadUsersForCategory(requestBody: ServiceNowFilterQuery) {
    let users: ITableDataApiResponse<IServicenowUser> = await firstValueFrom(
      this.companyAPIService.getUsersByAuthorisationType(requestBody)
    );

    return users;
  }

  async loadUsersForContactCategory(requestBody: ServiceNowFilterQuery) {
    let users: ITableDataApiResponse<IServicenowUser> = await firstValueFrom(
      this.companyAPIService.getUsersByContactType(requestBody)
    );

    return users;
  }

  async loadAvailableUsersForCategory(requestBody: ServiceNowFilterQuery) {
    let users = await firstValueFrom(
      this.companyAPIService.getAvailableUsersForCategory(requestBody)
    );
    return users;
  }

  async loadAvailableUsersForContactCategory(
    requestBody: ServiceNowFilterQuery
  ) {
    let users = await firstValueFrom(
      this.companyAPIService.getAvailableUsersForCategoryContact(requestBody)
    );
    return users;
  }

  async addUserToContactType(addUsersRequest: IAddUsersToContactTypeRequest) {
    let requestSuccessful = await firstValueFrom(
      this.companyAPIService.addUserContacts(addUsersRequest)
    );
    return requestSuccessful;
  }

  async addUserToCompanyContact(
    addUsersRequest: IAddUsersToContactTypeRequest
  ) {
    const requestSuccessful = await firstValueFrom(
      this.companyAPIService.addUserCompanyContacts(addUsersRequest)
    );
    return requestSuccessful;
  }

  async removeUserFromContactType(
    removeUsersRequest: IRemoveUsersFromContactTypeRequest
  ) {
    let requestSuccessful = await firstValueFrom(
      this.companyAPIService.deleteUserContact(removeUsersRequest)
    );

    return requestSuccessful;
  }

  async removeUserContactGroup(
    removeUsersRequest: IRemoveUsersFromContactTypeRequest
  ) {
    let requestSuccessful = await firstValueFrom(
      this.companyAPIService.deleteuUserTypeContact(removeUsersRequest)
    );

    return requestSuccessful;
  }
}
