import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-add-edit-user-details",
  templateUrl: "./add-edit-user-details.component.html",
  styleUrls: ["./add-edit-user-details.component.scss"],
})
export class AddEditUserDetailsComponent implements OnInit {
  @Input() pageTitlePrefix: string = "Add";
  @Input() addEditUserDetailsForm: FormGroup;
  @Input() userPortals: any = [];
  @Input() checkEmailExistsStatus: string = "VALID";
  @Output() childIsLoading = new EventEmitter();

  selectedAddEditUserType = "single";

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onMultipleUserSelect() {
    this.router.navigate(["secure/company/users/multiple/add"]);
  }
}
