export interface ISupportColumn {
  field: string;
  header: string;
  display: any;
  showSortIcon: boolean;
  cell?: (data: any) => any;
  expand?: (data: any) => any;
}

export const credentialColumns: ISupportColumn[] = [
  {
    field: "uQuestion",
    header: "Title",
    display: { type: "text" },
    showSortIcon: true,
    cell: (data) => data.uQuestion,
    expand: (data) => data.uAnswer,
  },
];

export const SupportArticleFilters = [
  {
    Column: "UQuestion",
    Value: [],
    Type: "text",
    Options: [],
    Visibility: true,
  },
];
