import { TableColumn, TableSort } from "src/app/models/table";

export enum OutageServiceNowField {
  State = "state",
  Type = "type",
  Description = "shortDescription",
  Start = "start",
  End = "end",
}

export const outageColumns: TableColumn[] = [
  {
    field: "state",
    header: "State",
    default: true,
    display: {
      type: "chip",
      colors: { Open: "success", "Closed Complete": "dark" },
    },
    filter: {
      type: "multiSelect",
      values: [],
    },
    serviceNowField: OutageServiceNowField.State,
  },
  {
    field: "type",
    header: "Type",
    display: { type: "text" },
    default: true,
    filter: {
      type: "multiSelect",
      values: [],
    },
    serviceNowField: OutageServiceNowField.Type,
  },
  {
    field: "description",
    header: "Short Description",
    display: { type: "text" },
    default: true,
    serviceNowField: OutageServiceNowField.Description,
  },
  {
    field: "start",
    header: "Start Date",
    display: { type: "date" },
    filter: {
      type: "dateRange",
    },
    default: true,
    serviceNowField: OutageServiceNowField.Start,
  },
  {
    field: "end",
    header: "End Date",
    display: { type: "date" },
    filter: {
      type: "dateRange",
    },
    default: true,
    serviceNowField: OutageServiceNowField.End,
  },
];
