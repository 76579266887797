import { TableColumn } from "src/app/models/table";

export interface ICostCentreColumn {
  field: string;
  header?: string;
  default: boolean;
  display?: any;
  showSortIcon: boolean;
}

export interface ICostCentre {
  id: string;
  name: string;
}

export const costCentreColumns: TableColumn[] = [
  {
    field: "name",
    header: "Name",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: "Name",
  },
];
