// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-toggle:before {
  content: "Mine";
}

.btn-toggle:after {
  content: "Company";
  right: -5rem;
}

.btn-toggle {
  right: 50px;
}

:host ::ng-deep.request-heading {
  font-size: 26px;
  padding: 0;
  margin: 0;
}

.col-xs-6 {
  max-height: 40px;
}

.mat-menu-content:not(:empty) {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/service-hub/tickets/service-requests/service-requests.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACI,kBAAA;EACA,YAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACE,eAAA;EACA,UAAA;EACA,SAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,8BAAA;EACA,iCAAA;AACF","sourcesContent":[".btn-toggle:before {\n    content: 'Mine';\n}\n\n.btn-toggle:after {\n    content: 'Company';\n    right: -5rem;\n}\n\n.btn-toggle {\n    right: 50px;\n}\n\n:host ::ng-deep.request-heading{\n  font-size: 26px;\n  padding: 0;\n  margin: 0;\n}\n\n.col-xs-6{\n  max-height: 40px;\n}\n\n.mat-menu-content:not(:empty) {\n  padding-top: 0.5rem !important;\n  padding-bottom: 0.5rem !important;\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
