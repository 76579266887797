import { Directive, Input, ElementRef } from "@angular/core";
import { AuthService } from "../services/auth/auth.service";

@Directive({
  selector: "[permissions]",
})
export class PermissionDirective {
  constructor(private el: ElementRef, private authService: AuthService) {}

  @Input("permissions") permissions: Array<String>;

  async ngOnInit() {
    const user = await this.authService.getUser();
    let hasPermission: boolean;

    if (this.permissions.length == 0) hasPermission = true;

    this.permissions.forEach((permission) => {
      let match = user.uTimicoPortalPermissions.some((p) => p == permission);
      if (match) hasPermission = true;
    });

    if (hasPermission) {
      // this.el.nativeElement.style.display = "inline";
    } else {
      this.el.nativeElement.style.display = "none";
    }
  }
}
