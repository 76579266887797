import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "app-provide-incident-information",
  templateUrl: "./provide-incident-information.component.html",
  styleUrls: ["./provide-incident-information.component.scss"],
})
export class ProvideIncidentInformationComponent implements OnInit {
  infoForm = new FormGroup({
    infoComment: new FormControl("", Validators.required),
  });
  infoRequestComment: string;
  infoRequestAuthor: string;
  infoRequestDate: string;

  constructor(
    public ref: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig
  ) {
    this.infoRequestComment = this.dialogConfig.data?.comment;
    this.infoRequestAuthor = this.dialogConfig.data?.author;
    this.infoRequestDate = this.dialogConfig.data?.date;
  }

  ngOnInit() {}

  closeButton(): void {
    this.ref.close();
  }

  async submitInformation(): Promise<void> {
    this.ref.close(this.infoForm.value?.infoComment);
  }
}
