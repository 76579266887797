import { Component, Inject, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { FileManagerService } from "./file-manager.service";
import { ToastService } from "src/app/services/toast.service";
import { FileManagerDialogData } from "./file-manager.models";

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: "file-manager",
  templateUrl: "file-manager.component.html",
  styleUrls: ["file-manager.component.scss"],
})
export class FileManagerComponent implements OnInit {
  uploadedFiles: any[] = [];
  maxFileSizeBytes: number = 5242880;

  constructor(
    public ref: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig<FileManagerDialogData>,
    public fileManagerService: FileManagerService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.fileManagerService.sysId = this.dialogConfig?.data?.sysId;
    this.fileManagerService.table = this.dialogConfig?.data?.table;
  }

  async onUpload(event: UploadEvent) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.ref.close(true);
    await this.fileManagerService.filesSelected(this.uploadedFiles);
    await this.fileManagerService.uploadFile();
    this.fileManagerService.clearFilesToUpload();
  }

  closeDialog() {
    this.ref.close();
    this.toastService.showToastWarning(
      "Adding Attachment",
      "Operation Cancelled"
    );
    this.fileManagerService.clearFilesToUpload();
  }
}
