import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { BehaviorSubject, Subject, firstValueFrom } from "rxjs";
import { SupportArticleFilters } from "../shared/support-models";
import { cloneDeep } from "lodash-es";
import { HelpArticleService } from "src/app/services/api/help-article/help-article.service";
import { ISupportColumn } from "../shared/support-models";
import { Table } from "primeng/table";

@Component({
  selector: "app-support-category",
  templateUrl: "./support-category.component.html",
  styleUrls: ["./support-category.component.scss"],
})
export class SupportCategoryComponent implements OnInit {
  @Input() tableColumns: ISupportColumn;
  @Input("supportService") supportService: any;
  @ViewChild("dt1") dt1: Table;
  loading: boolean = false;
  supportArticlesSubject = new BehaviorSubject(null);
  supportArticles$ = this.supportArticlesSubject.asObservable();
  supportArticles: Array<any>;
  overlayVisible: boolean = false;
  formFilterInputs: any;

  filter = cloneDeep(SupportArticleFilters);
  constructor(private helpArticleService: HelpArticleService) {}

  ngOnInit() {
    this.loadSupportDetails();
  }

  async loadSupportDetails(): Promise<void> {
    this.loading = true;
    if (this.supportService != null) {
      let index = this.filter.find((f) => f.Column == "uCategory");
      if (!index) {
        this.filter.push({
          Column: "uCategory",
          Value: [this.supportService.category],
          Type: "text",
          Options: [],
          Visibility: false,
        });
      }

      const supportDetails = await firstValueFrom(
        this.helpArticleService.getSupportCateogoryContent(this.filter)
      );
      this.supportArticlesSubject.next(supportDetails);
      this.loading = false;
    }
  }
}
