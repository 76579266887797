import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "add-comment",
  templateUrl: "add-comment.component.html",
  styleUrls: ["add-comment-component.scss"],
})
export class AddCommentComponent {
  addCommentFormControl = this.fb.group({
    comment: ["", Validators.required],
  });

  comment: string;

  constructor(private fb: FormBuilder, public ref: DynamicDialogRef) {}

  addComment(): void {
    this.comment = this.addCommentFormControl.value?.comment;
    this.ref.close(this.comment);
  }

  closeButton(): void {
    this.ref.close();
  }
}
