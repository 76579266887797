import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "change-closure",
  templateUrl: "./change-closure.component.html",
  styleUrls: ["../single/change.component.scss"],
})
export class ChangeClosureComponent implements OnInit {
  @Input("change") change: any;

  constructor() {}
  ngOnInit() {}
}
