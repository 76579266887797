import { Component, OnInit, ViewChild } from "@angular/core";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { IBillingEntity } from "../../products-and-services/telephony/models/telephony-models";
import { cloneDeep } from "lodash-es";
import { IUsageColumn, usageColumns } from "./models/usage-models";
import { UserService } from "src/app/services/api/user/user.service";
import { InvoiceService } from "src/app/services/api/invoice/invoice.service";
import { MenuItem } from "primeng/api";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { LazyLoadEvent } from "primeng/api";
import { Table } from "primeng/table";
import { ExportTable } from "../../products-and-services/science-logic/cmdb-devices/shared/export-devices";
import {
  IsPaginatorState,
  SetSelectedColumns,
  TableReset,
} from "src/app/helpers/table.helper";
import { ToastService } from "src/app/services/toast.service";
import {
  ExportFileName,
  ExportService,
  ExportTableEndpoint,
  ExportType,
} from "src/app/services/export.service";
import { IDownloadFile, ITableToFile } from "src/app/models/export-models";
import { TableColumn } from "src/app/models/table";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { Paginator, PaginatorState } from "primeng/paginator";

@Component({
  selector: "billing-usage",
  templateUrl: "usage.component.html",
  styleUrls: ["usage.component.scss"],
})
export class BillingUsageComponent implements OnInit {
  @ViewChild("dataTable", { static: true }) dataTable: Table;
  @ViewChild("tablePaginator") paginator: Paginator;
  callDataSubject = new BehaviorSubject(null);
  callData$ = this.callDataSubject.asObservable();
  sites: IBillingEntity[];
  selectedSite: IBillingEntity;
  dropdownSelection: IBillingEntity;
  selectedSiteID: string;
  tableFilters: LazyLoadEvent;
  tableColumn: IUsageColumn[];
  totalRecords: number = 0;
  defaultSelectedColumns: string[] = [];
  _selectedColumns: TableColumn[] = [];
  firstLoad: boolean = true;
  staticTable: Table;

  cols: TableColumn[] = cloneDeep(usageColumns);

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  exportOptions = [
    {
      label: "All",
      value: "all",
      command: () => this.exportAll(),
    },
    {
      label: "In View",
      value: "in_view",
      command: () =>
        ExportTable(
          this.dataTable,
          this.callDataSubject.value,
          this._selectedColumns,
          "all"
        ),
    },
  ];

  constructor(
    private userService: UserService,
    private invoiceService: InvoiceService,
    private breadcrumbService: BreadcrumbService,
    private toastService: ToastService,
    private exportService: ExportService
  ) {}

  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [{ label: " Connectivity Usage" }];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    await this.loadSites();
    if (this.sites) {
      this.dropdownSelection = this.sites[0];
      this.selectedSite = this.sites[0];
    }
  }

  async loadCallData(
    table: Table,
    event?: LazyLoadEvent | PaginatorState
  ): Promise<void> {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }
    table.loading = true;
    const isFirstLoad = this.firstLoad ? true : false;

    this.callDataSubject.next([]);

    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(usageColumns));
      this.firstLoad = false;
    }

    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event
    );

    serviceNowFilter.Filter.push({
      Column: "SiteID",
      Value: [this.selectedSiteID],
    });

    const response = await firstValueFrom(
      this.invoiceService.getCDRCalls(serviceNowFilter)
    );

    this.callDataSubject.next(response.result);
    this.totalRecords = response.overallQueryCount;

    this.staticTable = table;
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    table.loading = false;
  }

  loadDataFromFilter() {
    this.selectedSite = this.dropdownSelection;
    this.selectedSiteID = this.selectedSite.unityId;
    this.loadCallData(this.staticTable);
  }

  async loadSites() {
    this.sites = await firstValueFrom(
      this.userService.getBillingEntities("invoice")
    );
  }

  handleColumnChange(val: any[]) {
    this.cols = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  defaultExport() {
    ExportTable(this.dataTable, this.callDataSubject.value, this.cols, "all");
  }

  async exportAll() {
    this.toastService.showToastInfo(
      "Downloading - ",
      " We are just sorting your data out for you. This won't take long."
    );

    const serviceNowFilter = BuildServiceNowQuery(this.dataTable, this.cols);
    serviceNowFilter.Filter.push({
      Column: "SiteID",
      Value: [this.selectedSiteID],
    });

    const fields = this._selectedColumns.map((item) =>
      item.exportField ? item.exportField : item.serviceNowField
    );

    const endpoint: string = ExportTableEndpoint.ConnectivityUsage;
    const body: ITableToFile = {
      Filter: serviceNowFilter.Filter,
      ExportType: ExportType.Csv,
      CurrentPage: 0,
      Amount: 25,
      Pagination: false,
      ExportColumns: fields,
    };
    const downloadedFile: IDownloadFile =
      await this.exportService.exportTableToFile(endpoint, body);
    this.exportService.downloadFile(
      downloadedFile,
      ExportType.Csv,
      ExportFileName.connectivityUsage
    );
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = usageColumns
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter((column) => column?.default).map((column) => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
