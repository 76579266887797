import { Component, OnInit } from "@angular/core";
import { IImpersonateUser } from "../models/user-model";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "impersonate-bar",
  templateUrl: "./impersonate-bar.component.html",
  styleUrls: ["./impersonate-bar.component.scss"],
})
export class ImpersonateBarComponent implements OnInit {
  impersonatedUser: IImpersonateUser;
  accountLocked: boolean;
  accountActive: boolean;

  constructor(private authService: AuthService) {}

  async ngOnInit() {
    this.impersonatedUser = this.authService.getImpersonatedUser();
    this.accountLocked = await this.checkLockOut();
    this.accountActive = await this.checkActive();
  }

  async checkLockOut(): Promise<boolean> {
    return this.impersonatedUser.lockedOut || false;
  }

  async checkActive(): Promise<boolean> {
    return this.impersonatedUser.active || false;
  }
}
