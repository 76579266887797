import { Directive, HostListener, EventEmitter, Output, HostBinding } from '@angular/core';  


@Directive({
  selector: '[appFileDrag]'
})
export class FileDragDirective {

  @Output('files') files: EventEmitter <File[]> = new EventEmitter();  
  @HostBinding('style.background') public background = '#FFFFFF'; 

  constructor() {}  

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {  
      evt.preventDefault();  
      evt.stopPropagation();  
      this.background = '#999999';  
  }  

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {  
      evt.preventDefault();  
      evt.stopPropagation();  
      this.background = '#EEEEEE';  
  }  

  @HostListener('drop', ['$event']) public onDrop(evt: any) {  
      evt.preventDefault();  
      evt.stopPropagation();  
      this.background = '#FFFFFF';  
      let files: File[] = [];  
      for (let i = 0; i < evt.dataTransfer.files.length; i++) {  
          const file = evt.dataTransfer.files[i];  
          files.push(file);  
      }  
      
      if (files.length > 0) {  
          this.files.emit(files);  
      }  
  }  

}
