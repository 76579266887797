import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MenuItem } from "primeng/api";
import { BehaviorSubject } from "rxjs";
import { Location, TitleCasePipe } from "@angular/common";
import {
  DevicesService,
  DefaultTab,
} from "src/app/services/devices/devices.service";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { HumanisePipe } from "src/app/pipes/humanise.pipe";

type DeviceMenuItem = MenuItem & {
  menuItems?: MenuItem[];
};
const humanisePipe = new HumanisePipe();
const titleCasePipe = new TitleCasePipe();
@Component({
  selector: "cmdb-devices",
  templateUrl: "cmdb-devices.component.html",
  styleUrls: ["cmdb-devices.component.scss"],
})
export class CmdbDevicesComponent implements OnInit {
  activeMenuItem: DeviceMenuItem;
  menuItems: DeviceMenuItem[] = [
    {
      label: "Server",
      menuItems: [
        {
          label: "Device List",
          command: () => this.changeActiveItem("server"),
        },
        {
          label: "Unreachable Devices",
          command: () => this.changeActiveItem("server", "unreachable-devices"),
        },
        {
          label: "Paused Devices",
          command: () => this.changeActiveItem("server", "paused-devices"),
        },
        {
          label: "Detailed",
          command: () => this.changeActiveItem("server", "detailed"),
        },
      ],
      command: () => this.changeActiveItem("server"),
    },
    {
      label: "Storage",
      command: () => this.changeActiveItem("storage"),
      menuItems: [
        {
          label: "Device List",
          command: () => this.changeActiveItem("storage"),
        },
        {
          label: "Unreachable Devices",
          command: () =>
            this.changeActiveItem("storage", "unreachable-devices"),
        },
        {
          label: "Paused Devices",
          command: () => this.changeActiveItem("storage", "paused-devices"),
        },
        {
          label: "Detailed",
          command: () => this.changeActiveItem("storage", "detailed"),
        },
      ],
    },
    {
      label: "Network",
      command: () => this.changeActiveItem("network"),
      menuItems: [
        {
          label: "Device List",
          command: () => this.changeActiveItem("network"),
        },
        {
          label: "Unreachable Devices",
          command: () =>
            this.changeActiveItem("network", "unreachable-devices"),
        },
        {
          label: "Paused Devices",
          command: () => this.changeActiveItem("network", "paused-devices"),
        },
        {
          label: "Detailed",
          command: () => this.changeActiveItem("network", "detailed"),
        },
      ],
    },
    {
      label: "General",
      command: () => this.changeActiveItem("general"),
      menuItems: [
        {
          label: "Device List",
          command: () => this.changeActiveItem("general"),
        },
        {
          label: "Unreachable Devices",
          command: () =>
            this.changeActiveItem("general", "unreachable-devices"),
        },
        {
          label: "Paused Devices",
          command: () => this.changeActiveItem("general", "paused-devices"),
        },
        {
          label: "Detailed",
          command: () => this.changeActiveItem("general", "detailed"),
        },
      ],
    },
  ];

  activeSubMenuItem: MenuItem;

  deviceCategorySubject = new BehaviorSubject<string>("");
  deviceCategory$ = this.deviceCategorySubject.asObservable();

  loading = true;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private devicesService: DevicesService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.route.params.subscribe((params) => {
      let category = params["category"];
      let subCategory = params["subCategory"] ?? DefaultTab;
      const isCategory = this.getMenuItem(category, this.menuItems);
      if (!isCategory) {
        category = "server";
        subCategory = null;
        this.location.go(
          `/secure/products-and-services/monitoring/devices/${category.toLowerCase()}`
        );
      }
      this.changeActiveItem(category, subCategory);
      this.loading = false;
    });
  }

  changeActiveItem(category: string, subCategory: string = DefaultTab) {
    const newDeviceCategory = `${category}-${subCategory}`;
    const currentDeviceCategory = this.deviceCategorySubject.value;
    if (newDeviceCategory === currentDeviceCategory) {
      return;
    }
    this.activeMenuItem = this.getMenuItem(category, this.menuItems);
    this.activeSubMenuItem = this.getMenuItem(
      subCategory,
      this.activeMenuItem.menuItems
    );
    const [urlCategory, urlSubCategory] =
      this.devicesService.getDeviceCategoryAndSubCategory(newDeviceCategory);
    this.location.go(
      this.devicesService.devicesUrlBuilder({
        category: urlCategory,
        subCategory: urlSubCategory,
      })
    );
    this.deviceCategorySubject.next(newDeviceCategory);
    const breadcrumbs: MenuItem[] = [
      { label: "Device Utilisation" },
      {
        label: humanisePipe.transform(
          titleCasePipe.transform(category)
        ) as string,
      },
      {
        label: humanisePipe.transform(
          titleCasePipe.transform(subCategory.replace(/-/g, " "))
        ) as string,
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }

  convertLabel(label: string): string {
    if (!label) {
      return null;
    }
    return label
      .replace(/[^a-zA-Z0-9\s]/g, "")
      .replace(/\s+/g, "")
      .toLowerCase();
  }

  getMenuItem(
    category: string,
    menuItems: DeviceMenuItem[]
  ): DeviceMenuItem | undefined {
    return menuItems.find(
      (item) => this.convertLabel(item.label) === this.convertLabel(category)
    );
  }
}
