import { inject } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { firstValueFrom } from "rxjs";
import { RestrictionService } from "../services/restriction.service";
import { AuthService as Auth0Service } from "@auth0/auth0-angular";
import { AuthService } from "../services/auth/auth.service";
import { MenuService } from "../services/general/menu/menu.service";

export const DynamicGuard = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<boolean | UrlTree> => {
  const router = inject(Router);
  const restrictionService = inject(RestrictionService);
  const auth0Service = inject(Auth0Service);
  const authService = inject(AuthService);
  const menuService = inject(MenuService);

  let restrictionsLoaded = false;
  let restrictions: Array<any> = [];

  // Replace this.next with next directly as a parameter.
  await new Promise<void>((resolve) => {
    auth0Service.idTokenClaims$.subscribe((idToken) => {
      if (idToken) {
        authService.clearAuth0Token();
        authService.setAuth0Token(idToken.__raw);
        resolve();
      }
    });
  });

  let user = await authService.getUserOrImpersonatedUser();
  menuService.setUserMenuItems(user);

  if (!restrictionsLoaded) {
    restrictionsLoaded = true;
    restrictionService.restrictionsUpdate.subscribe((n) => {
      restrictions = n;
    });
    await restrictionService.getRestrictions();
  }

  for (let r of restrictions) {
    if (
      r.path == next.routeConfig?.path ||
      next.routeConfig?.path?.includes(r.path) ||
      r.path == state.url ||
      state.url.includes(r.path)
    ) {
      return router.parseUrl("/restricted");
    }
  }

  if (next.data?.requiresServiceManager) {
    if (!user.companyServiceManager) {
      return router.parseUrl("/unauthorised");
    }
  }

  if (user.uTimicoPortalPermissions.length > 0) {
    if (next.data?.permissions?.length > 0) {
      if (
        user.uTimicoPortalPermissions.some((permission) =>
          next.data.permissions.includes(permission)
        )
      ) {
        return true;
      } else {
        return router.parseUrl("/unauthorised");
      }
    } else {
      return true;
    }
  } else {
    return router.parseUrl("/unauthorised");
  }
};
