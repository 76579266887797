import { Component, OnInit } from "@angular/core";
import { IService } from "../../../products-and-services/services/status/models/service-notifications-models";
import { ServiceNotificationsService } from "../../../products-and-services/services/status/shared/service-notifications-service.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "service-notifications",
  templateUrl: "service-notifications.component.html",
})
export class ServiceNotificationsComponent implements OnInit {
  services: Array<IService>;

  constructor(
    private serviceNotificationsService: ServiceNotificationsService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.loadServices();
  }

  async loadServices() {
    this.services =
      await this.serviceNotificationsService.loadServiceNotifications();
  }

  async toggleServiceSubscription(service: IService) {
    service.subscribed = !service.subscribed;
    this.serviceNotificationsService.toggleServiceSubscription(service);
    this.toastService.showToastInfo(
      service.name + " - ",
      service.subscribed
        ? " Receiving Notifications"
        : " Not Receiving Notifications"
    );
  }
}
