import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "humanise",
})
export class HumanisePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (typeof value !== "string") {
      return value;
    }
    return value.split(/(?=[A-Z])/).join(" ");
  }
}
