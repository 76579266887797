import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { share } from "rxjs/operators";

@Injectable({ providedIn: "root" })



export class LocalStorageService {
  private onSubject = new Subject<{ key: string, value: any }>();
  public changes = this.onSubject.asObservable().pipe(share());

  constructor() {
    this.start();
  }

  ngOnDestroy() {
    this.stop();
}


  public store(key: string, data: any): void {
    localStorage.setItem(key, data);
    this.onSubject.next({key: key, value: data})
  }

    public getStorage() {
      let s = [];
      for (let i = 0; i < localStorage.length; i++) {
          s.push({
              key: localStorage.key(i),
              value: localStorage.getItem(localStorage.key(i))
          });
      }
      return s;
    }

    public getItem(key: string){
      return localStorage.getItem(key);
  }


  public saveData(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public getData(key: string) {
    return localStorage.getItem(key)
  }

  public getDataFromBroadBandOrder():boolean{
    let mutipleVisibility:boolean;
    localStorage.getItem("fromBroadBandOrder") == "true" ? mutipleVisibility=true : mutipleVisibility=false;
    return mutipleVisibility;
  }

  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }

  public clearUser(key) {
    localStorage.removeItem(key);
    // the local application doesn't seem to catch changes to localStorage...
    this.onSubject.next({key: key, value: null});
}

private storageEventListener(event: StorageEvent) {
  if (event.storageArea == localStorage) {
      let v;

          v = event.newValue;

      this.onSubject.next({key: event.key, value: v});
  }
}

private start(): void {
  window.addEventListener("storage", this.storageEventListener.bind(this));
}

private stop(): void {
  window.removeEventListener("storage", this.storageEventListener.bind(this));
  this.onSubject.complete();
}

}
