import { Inject, Injectable } from "@angular/core";
import {
  IPortal,
  IUserProfile,
} from "../../components/service-hub/company/users/shared/user-models";
import { ApiRequest } from "src/app/helpers/api.request.helper";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { firstValueFrom } from "rxjs";
import auth0 from "auth0-js";
import { AuthService as Auth0Service } from "@auth0/auth0-angular";
@Injectable({
  providedIn: "root",
})
/**
 * This class is used to authenticate the user and store the token in local storage
 */
export class AuthService {
  user: IUserProfile;

  auth0client = new auth0.WebAuth({
    domain: environment.auth0.domain,
    clientID: environment.auth0.clientId,
    responseType: "token id_token",
    redirectUri: window.location.origin,
  });

  constructor(
    protected http: HttpClient,
    private apiRequest: ApiRequest,
    private auth0Service: Auth0Service
  ) {
    const pollSession = setInterval(() => {
      this.checkSession(pollSession);
    }, environment.auth0.pollingInterval);
  }

  checkSession(interval?: any) {
    this.auth0client.checkSession({}, (err, authResult) => {
      if (err?.code === "login_required") {
        if (interval) {
          clearInterval(interval);
          this.logout();
        }
      }
    });
  }

  /**
   * This method is used to get the user profile
   *
   * @returns {Promise<IUserProfile>} The user profile
   */
  async getUser(): Promise<IUserProfile> {
    if (!this.user) {
      await this.loadUserProfile();
    }
    return this.user;
  }

  /**
   * This method is used to get the user or the impersonated user profile
   * The impersonated user profile will always be returned if it is set
   *
   * @returns {Promise<IUserProfile>} The user profile
   */
  async getUserOrImpersonatedUser(): Promise<IUserProfile> {
    if (this.isImpersonating()) {
      return this.getImpersonatedUser();
    }
    return this.getUser();
  }

  /**
   * Checks if a user has the specified permissions.
   *
   * @param {IUserProfile} user - The user profile object containing the user's permissions.
   * @param {string | string[]} permissions - A single permission string or an array of permission strings to check against the user's permissions.
   * @param {boolean} [allMatch=false] - If true, checks if the user has all specified permissions. If false, checks for at least one.
   * @returns {boolean} True if the user has the required permissions based on the criteria, false otherwise.
   */
  hasPermission(
    user: IUserProfile,
    permissions: string | string[],
    allMatch: boolean = false
  ): boolean {
    if (!Array.isArray(permissions)) {
      permissions = [permissions];
    }
    if (allMatch) {
      // Check if the user has all specified permissions
      return permissions.every((permission) =>
        user.uTimicoPortalPermissions.includes(permission)
      );
    } else {
      // Check for at least one overlapping permission
      return permissions.some((permission) =>
        user.uTimicoPortalPermissions.includes(permission)
      );
    }
  }

  /**
   * This method deletes old user token
   * calls the snow api to generate a new
   * user profile
   */
  async refreshUser() {
    localStorage.removeItem(environment.localStorage.serviceHubUser);
    this.user = null;
    this.getUser();
  }

  /**
   * This method is used to get the load profile
   *
   * @returns {Promise<void>}
   */
  async loadUserProfile(): Promise<void> {
    let localUser = localStorage.getItem(
      environment.localStorage.serviceHubUser
    );
    if (localUser) {
      return (this.user = JSON.parse(localUser));
    }
    const user: IUserProfile = await this.getUserProfile();
    user.portals = this.getAccessiblePortals(user);
    localStorage.setItem(
      environment.localStorage.serviceHubUser,
      JSON.stringify(user)
    );
    this.user = user;
  }

  /**
   * This method is used to get the accessible portals for the user based on a match
   * between the user's accessible portals and the portals in the environment config
   * @param user
   * @returns {IPortal[]} Array of accessible portals
   */
  private getAccessiblePortals(user: IUserProfile): IPortal[] {
    const uAccessiblePortals = user.uAccessiblePortals
      .split(",")
      .map((item) => item.trim());
    return environment.portals.filter((portal) =>
      uAccessiblePortals.includes(portal.name)
    );
  }

  /**
   * This method is used to get the user profile from the API
   * @param email
   * @returns {Promise<IUserProfile>} The user profile
   */
  async getUserProfile(email: string | null = null): Promise<IUserProfile> {
    const userProfile = await firstValueFrom(
      this.apiRequest.request({
        endpoint: "Authentication/LoadUserProfile",
        body: email,
      })
    );
    return userProfile;
  }

  /**
   * Gets the impersonated user from the API and stores it in local storage
   * Redirects to the base url if successful
   * Throws an error if the user is not found
   *
   * @param email
   */
  async impersonateUser(email: string) {
    const impersonateUser = await firstValueFrom(
      this.apiRequest.request({
        endpoint: "Authentication/ImpersonateUser",
        body: {
          username: email,
        },
      })
    );
    if (!impersonateUser) {
      throw new Error("User not found");
    }
    localStorage.setItem(
      environment.localStorage.serviceHubUserImpersonation,
      JSON.stringify(impersonateUser)
    );
    window.location.href = environment.baseUrl;
  }

  /**
   * Get the impersonated user from local storage if it is set
   *
   * @returns {IUserProfile | null}
   */
  getImpersonatedUser(): IUserProfile | null {
    let impersonatedUser = localStorage.getItem(
      environment.localStorage.serviceHubUserImpersonation
    );
    if (impersonatedUser) {
      return JSON.parse(impersonatedUser);
    }
    return null;
  }

  /**
   * Check if the user is impersonating another user
   * @returns {boolean}
   */
  isImpersonating(): boolean {
    return !!this.getImpersonatedUser();
  }

  /**
   * Clear the impersonated user from local storage
   */
  clearImpersonation(): void {
    localStorage.removeItem(
      environment.localStorage.serviceHubUserImpersonation
    );
    window.location.href = environment.baseUrl;
  }

  /**
   * This method is used to get the auth0 token from local storage
   *
   * @returns string
   */
  getAuth0Token(): string {
    return localStorage.getItem(environment.localStorage.auth0IdToken);
  }

  /**
   * This method is used to set the auth0 ID token in local storage
   * @param idToken
   */
  setAuth0Token(idToken: string): void {
    localStorage.setItem(environment.localStorage.auth0IdToken, idToken);
  }

  /**
   * This method is used to remove the auth0 ID token from local storage
   */
  clearAuth0Token(): void {
    localStorage.removeItem(environment.localStorage.auth0IdToken);
  }

  logout() {
    localStorage.clear();
    this.auth0Service.logout();
  }
}
