import { Component, Input } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { OrderService } from "src/app/services/api/order/order.service";
import { OrderDetails } from "../shared/internal.models";

@Component({
  selector: "order-internal-information",
  templateUrl: "./order-internal-information.component.html",
  styleUrl: "./order-internal-information.component.scss",
})
export class OrderInternalInformationComponent {
  @Input() orderNumber: string;
  @Input() addressReference: string;

  orderDetails: OrderDetails;

  constructor(private orderService: OrderService) {}
  async ngOnInit() {
    this.orderDetails = await this.getCustomerDetails();
  }

  async getCustomerDetails(): Promise<OrderDetails> {
    const response = await firstValueFrom(
      this.orderService.getCustomerDetails(this.orderNumber)
    );

    const orderDetails: OrderDetails = {
      addressReference: this.addressReference,
      externalId: response.externalId,
      quoteId: response.quoteId,
    };
    return orderDetails;
  }
}
