import { Inject, Injectable } from "@angular/core";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";

@Injectable({
  providedIn: "root",
})
export class HelpService {
  constructor(private apiRequest: ApiRequest) {}

  getPageHelp(filter: any) {
    const requestProps: IRequestOptions = {
      endpoint: "Help/GetPageHelp",
      body: filter,
    };
    return this.apiRequest.request(requestProps);
  }

  getSupportCategory() {
    const requestProps: IRequestOptions = {
      endpoint: "Help/GetSupportCategory",
    };
    return this.apiRequest.request(requestProps);
  }
}
