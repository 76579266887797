import { Inject, Injectable } from "@angular/core";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";
import {
  IAddUsersToContactTypeRequest,
  IRemoveUsersFromContactTypeRequest,
  IUsersForCategoryRequest,
} from "src/app/components/service-hub/company/company-contacts/shared/models";
import { Observable } from "rxjs";
import { ServiceNowFilterQuery } from "src/app/models/servicenow.model";

@Injectable({
  providedIn: "root",
})
export class CompanyAPIService {
  constructor(private apiRequest: ApiRequest) {}

  isEnterprise(companyId: string): Observable<boolean> {
    const requestProps: IRequestOptions = {
      endpoint: "Company/GetCompanyType",
      body: companyId,
    };
    return this.apiRequest.request(requestProps);
  }

  getAuthorisationTypes(contacts: any) {
    const requestProps: IRequestOptions = {
      endpoint: "Company/GetAuthorisationTypes",
    };
    return this.apiRequest.request(requestProps);
  }

  getContacts() {
    const requestProps: IRequestOptions = {
      endpoint: "Company/GetContacts",
    };
    return this.apiRequest.request(requestProps);
  }

  getUsersByAuthorisationType(body: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "Company/GetUsersByAuthorisationType",
      body: body,
    };
    return this.apiRequest.request(requestProps);
  }

  getUsersByContactType(body: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "Company/GetUsersByContactType",
      body: body,
    };
    return this.apiRequest.request(requestProps);
  }

  getAvailableUsersForCategory(body: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "Company/GetAvailableUsersForAuthorisationType",
      body: body,
    };
    return this.apiRequest.request(requestProps);
  }

  getAvailableUsersForCategoryContact(body: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "Company/GetAvailableUsersForContactType",
      body: body,
    };
    return this.apiRequest.request(requestProps);
  }

  addUserContacts(addUsersRequest: IAddUsersToContactTypeRequest) {
    const requestProps: IRequestOptions = {
      endpoint: "Company/AddUserContacts",
      body: addUsersRequest,
    };
    return this.apiRequest.request(requestProps);
  }

  addUserCompanyContacts(addUsersRequest: IAddUsersToContactTypeRequest) {
    const requestProps: IRequestOptions = {
      endpoint: "Company/AddUserTypeOfContacts",
      body: addUsersRequest,
    };
    return this.apiRequest.request(requestProps);
  }

  deleteUserContact(removeUsersRequest: IRemoveUsersFromContactTypeRequest) {
    const requestProps: IRequestOptions = {
      endpoint: "Company/DeleteUserContact",
      body: removeUsersRequest,
    };
    return this.apiRequest.request(requestProps);
  }

  deleteuUserTypeContact(
    removeUsersRequest: IRemoveUsersFromContactTypeRequest
  ) {
    const requestProps: IRequestOptions = {
      endpoint: "Company/DeleteUserTypeOfContact",
      body: removeUsersRequest,
    };
    return this.apiRequest.request(requestProps);
  }

  getCompanyTimicoContacts(companyId: string) {
    const requestProps: IRequestOptions = {
      endpoint: "Company/GetCompanyTimicoContacts",
      body: companyId,
    };
    return this.apiRequest.request(requestProps);
  }

  updateCompany(sysId: string, multiFactorAuthentication: boolean) {
    const requestProps: IRequestOptions = {
      endpoint: "Company/UpdateCompany",
      body: {
        SysId: sysId,
        UMultiFactorAuthentication: multiFactorAuthentication,
      },
    };
    return this.apiRequest.request(requestProps);
  }

  getContactTypes() {
    const requestProps: IRequestOptions = {
      endpoint: "Company/GetContactTypes",
    };
    return this.apiRequest.request(requestProps);
  }
}
