import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { ExportService } from "src/app/services/export.service";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { DOCUMENT } from "@angular/common";
import { InvoiceService } from "src/app/services/api/invoice/invoice.service";

import { DateTime } from "luxon";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Table } from "primeng/table";
import { PrimeNGFilterService } from "src/app/services/prime-ng-filter.service";
import { ActivatedRoute } from "@angular/router";
import { DownloadInvoiceCreditNoteService } from "../../../../services/download-invoice.service";
import { MenuItem } from "primeng/api/menuitem";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { ToastService } from "src/app/services/toast.service";
import { LazyLoadEvent } from "primeng/api";
import { ITableClickEvent, TableColumn } from "src/app/models/table";
import { cloneDeep } from "lodash-es";
import {
  IsPaginatorState,
  SetSelectedColumns,
  TableReset,
} from "src/app/helpers/table.helper";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { Paginator, PaginatorState } from "primeng/paginator";
import {
  BillingItemTypes,
  ExportPdfRequest,
} from "src/app/models/export/export-pdf.model";
import { InvoiceMultiSelectStateService } from "src/app/services/invoice-multi-select-state.service";
import { ExportPdfComponent } from "src/app/components/misc/pop-up/export-pdf/export-pdf.component";
import { CreditNote } from "src/app/models/billing/credit-note.models";
import { creditColumns, ICreditNote } from "../models/credit-note-models";

@Component({
  selector: "credit-notes",
  templateUrl: "credit-notes.component.html",
  styleUrls: ["credit-notes.component.scss"],
  providers: [DialogService],
})
export class CreditNotesComponent implements OnInit {
  @ViewChild("dataTable") dataTable: Table;
  @ViewChild("tablePaginator") paginator: Paginator;
  creditNotesSubject = new BehaviorSubject<Array<ICreditNote>>([]);
  creditNotes$ = this.creditNotesSubject.asObservable();
  selectedCreditNotes: number = 0;
  creditNotes: Array<any>;
  selectedCreditNote: number = 0;
  selectedNote!: ICreditNote;
  creditNoteNumber: string;
  cols: TableColumn[] = cloneDeep(creditColumns);
  _selectedColumns: TableColumn[] = [];
  totalRecords: number = 0;
  firstLoad: boolean = true;

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  constructor(
    private exp: ExportService,
    private InvoiceService: InvoiceService,
    private primeNGFilterService: PrimeNGFilterService,
    private creditMultiSelectState: InvoiceMultiSelectStateService,
    private route: ActivatedRoute,
    private downloadCreditNote: DownloadInvoiceCreditNoteService,
    private breadcrumbService: BreadcrumbService,
    private toastService: ToastService,
    private dialogService: DialogService,
    @Inject(DOCUMENT) private document: Document
  ) {}
  ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      {
        label: "Credit Notes",
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);

    if (this.route.snapshot.queryParams) {
      this.creditNoteNumber =
        this.route.snapshot.queryParams["creditNoteNumber"];
    }
  }
  ngAfterViewChecked() {
    if (this.dataTable && this.creditNoteNumber) {
      this.runSearchFilter();
    }
  }

  async loadCreditNotes(table: Table, event?: LazyLoadEvent | PaginatorState) {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }
    table.loading = true;
    const isFirstLoad = this.firstLoad ? true : false;

    this.creditNotesSubject.next([]);

    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(creditColumns));
      this.firstLoad = false;
    }

    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(creditColumns));
      this.firstLoad = false;
    }

    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event
    );
    const response = await firstValueFrom(
      this.InvoiceService.getCreditNotes(serviceNowFilter)
    );

    this.creditNotes = response.result.map((item) => ({
      ...item,
      date: DateTime.fromFormat(item.invoiceDate, "dd/MM/yyyy")
        .setZone(DateTime.local().zoneName)
        .toJSDate(),
    }));

    this.creditNotesSubject.next(this.creditNotes);
    this.totalRecords = response.overallQueryCount;
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    table.loading = false;
  }

  handleRowClicked(event: ITableClickEvent<CreditNote>): void {
    let creditNote = event.data;
    const index = this.creditMultiSelectState.creditNotes.findIndex(
      (selectedCreditNote) =>
        selectedCreditNote.invoiceNo === creditNote.invoiceNo
    );
    if (index === -1) {
      this.creditMultiSelectState.creditNotes.push(creditNote);
    } else {
      this.creditMultiSelectState.creditNotes.splice(index, 1);
    }
    this.selectedCreditNote = this.creditMultiSelectState.creditNotes.length;
  }

  handleRowUnselect(event: ITableClickEvent<CreditNote>): void {
    let creditNote: CreditNote = event.data;
    const index = this.creditMultiSelectState.creditNotes.findIndex(
      (selectedCreditNote) =>
        selectedCreditNote.invoiceNo === creditNote.invoiceNo
    );
    if (index !== -1) {
      this.creditMultiSelectState.creditNotes.splice(index, 1);
    }
    this.selectedCreditNote = this.creditMultiSelectState.creditNotes.length;
  }

  selectAllToggle(): void {
    //Clear the old items before adding new stops duplicates
    this.creditMultiSelectState.creditNotes.length = 0;
    this.creditMultiSelectState.creditNotes.push(...this.dataTable.selection);
    this.selectedCreditNote = this.creditMultiSelectState.creditNotes.length;
  }

  openExportDialog() {
    const creditNotesToExport = cloneDeep(
      this.creditMultiSelectState.creditNotes
    );

    const exportCreditNotesRequest: ExportPdfRequest = {
      billingItems: creditNotesToExport,
      itemType: BillingItemTypes.CreditNote,
    };

    const ref: DynamicDialogRef = this.dialogService.open(ExportPdfComponent, {
      showHeader: false,
      width: "70%",
      data: exportCreditNotesRequest,
      contentStyle: { overflow: "auto" },
      baseZIndex: 10000,
      maximizable: true,
      closeOnEscape: false,
    } as DynamicDialogConfig<ExportPdfRequest>);
  }

  getDatesFromCalendar(selectedDate: object): void {
    if (Array.isArray(selectedDate)) {
      this.primeNGFilterService.filterByDates(this.dataTable, selectedDate);
    } else {
      this.primeNGFilterService.filterByDate(this.dataTable, selectedDate);
    }
  }

  runSearchFilter() {
    this.dataTable.filter(this.creditNoteNumber, "invoiceNo", "contains");
    this.creditNoteNumber = "";
  }

  handleColumnChange(val: any[]) {
    this.cols = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = creditColumns
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter((column) => column?.default).map((column) => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
