export interface ITicketsOpenedClosed {
  date: string;
  opened: number;
  closed: number;
}

export interface IIncidentPriority {
  date: string;
  critical: number;
  high: number;
  moderate: number;
  low: number;
}

export enum TicketTypes {
  OVERVIEW = "overview",
  INCIDENT = "incident",
  SERVICE_REQUEST = "sc_req_item",
  CASE = "case",
  ORDER = "order",
  ORDER_LINE_ITEM = "order_line_item",
  CHANGE_REQUEST = "change_request",
}

export interface IGraphDataResponse {
  month: number;
  sysClassName: string;
  total: number;
  year: number;
}

export interface ITicketType {
  label: string;
  data: ITicketsOpenedClosed[];
}
