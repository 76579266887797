import { Injectable, EventEmitter, OnDestroy } from "@angular/core";
import * as dayjs from "dayjs";
import {
  firstValueFrom,
  interval,
  Observable,
  Subject,
  Subscription,
} from "rxjs";
import {
  IGetAllNotificationsRequest,
  Notification,
} from "../../components/service-hub/notifications/models/notification-models";
import { Router } from "@angular/router";
import { NotificationsService } from "../../services/api/notifications/notifications.service";
import { environment } from "src/environments/environment";

@Injectable()
export class NotificationHelper {
  subscribed: Boolean = false;
  intervalSubscription: Subscription;
  notifications: Array<Notification> = [];
  notificationsUpdate: Subject<Notification[]> = new Subject();
  notificationsCountUpdate: Subject<number> = new Subject();
  markAllReadUpdate: Subject<any> = new Subject();

  constructor(
    private route: Router,
    private notificationsService: NotificationsService
  ) {}

  getNotificationsUpdates(): Observable<Notification[]> {
    return this.notificationsUpdate.asObservable();
  }

  getNotificationCountUpdate(): Observable<number> {
    return this.notificationsCountUpdate.asObservable();
  }

  getMarkAllReadUpdate(): Observable<any> {
    return this.markAllReadUpdate.asObservable();
  }

  async loadAllNotifications(requestBody: any) {
    // TODO added await, check this is correct
    const notifications = await firstValueFrom(
      this.notificationsService.getAllNotifications(requestBody)
    );

    return notifications;
  }

  async getUnreadNotifications() {
    const unreadNotifications: Notification[] = await firstValueFrom(
      this.notificationsService.getUnreadNotifications("")
    );

    unreadNotifications.forEach((notification) => {
      notification.lastUpdated = this.lastUpdatedDate(notification.lastUpdated);
    });
    this.notifications = unreadNotifications;
    this.updateNotifications();
    if (!this.subscribed) {
      this.intervalSubscription = interval(environment.countTime).subscribe(
        () => {
          this.getUnreadNotifications();
        }
      );
      this.subscribed = true;
    }
    return unreadNotifications;
  }

  openNotification(notification: Notification) {
    firstValueFrom(
      this.notificationsService.setNotificationAsRead(notification.sysId)
    );
    this.readNotification(notification.sysId);
    if (notification.category != "Other") {
      if (notification.category == "Survey") {
        window.open(
          environment.baseUrl +
            this.getLink(notification.category) +
            "/" +
            notification.ticketSysId
        );
      } else {
        this.route.navigateByUrl(
          "/secure/tickets/" +
            this.getLink(notification.category) +
            "/" +
            notification.ticketSysId
        );
      }
    } else {
      this.route.navigateByUrl(
        "/secure/account/notification/" + notification.sysId
      );
    }
  }

  readNotification(sysId: String) {
    this.notifications
      .filter((notification) => notification.sysId == sysId)
      .forEach((notification) => (notification.uNotificationRead = true));
    this.updateNotifications();
  }

  updateNotifications() {
    this.notificationsUpdate.next(this.notifications);
    this.notificationsCountUpdate.next(
      this.notifications.filter((n) => n.uNotificationRead == false).length
    );
  }

  lastUpdatedDate(date): string {
    return dayjs(date, "DD/MM/YYYY").format("DD/MM/YYYY");
  }

  clearInterval() {
    this.intervalSubscription?.unsubscribe();
  }

  getLink(category): String {
    switch (category) {
      case "Incident":
        return "incident";
      case "Requested Item":
        return "service-request";
      case "Order":
        return "service-request";
      default:
        return category.toLowerCase();
    }
  }

  async markAllAsRead() {
    this.notifications.forEach((n) => (n.uNotificationRead = true));
    this.markAllReadUpdate.next(void 0);
    this.updateNotifications();
    firstValueFrom(this.notificationsService.setAllNotificationsAsRead());
  }
}
