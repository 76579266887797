export type DashBoardStatistic = {
  value: string | number | null;
  loading: boolean;
  command?: () => void;
};

export type DashBoardStatisticTotal = {
  [key: string]: DashBoardStatistic;
};

export type DashboardStatistics = {
  [key: string]: DashBoardStatisticTotal;
};

export const dashboardStatistics: DashboardStatistics = {
  orders: {
    allOpen: {
      value: null,
      loading: true,
    },
  },
  serviceNotices: {
    allOpen: {
      value: null,
      loading: true,
    },
  },
  serviceRequests: {
    allOpen: {
      value: null,
      loading: true,
    },
    openedThisMonth: {
      value: null,
      loading: true,
    },
    openedLastMonth: {
      value: null,
      loading: true,
    },
  },
  incidents: {
    allOpen: {
      value: null,
      loading: true,
    },
    openedThisMonth: {
      value: null,
      loading: true,
    },
    openedLastMonth: {
      value: null,
      loading: true,
    },
  },
};
