import { Component, OnInit, ViewChild } from "@angular/core";
import { cloneDeep } from "lodash-es";
import { LazyLoadEvent } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Table } from "primeng/table";
import { BehaviorSubject } from "rxjs";
import { CompanyService } from "src/app/components/service-hub/company/company-contacts/shared/company-service.service";
import {
  ContactType,
  IAddUsersToContactTypeRequest,
} from "src/app/components/service-hub/company/company-contacts/shared/models";
import { IServicenowUser } from "src/app/components/service-hub/company/users/users.model";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { IsPaginatorState, TableReset } from "src/app/helpers/table.helper";
import { TableColumn } from "src/app/models/table";
import { AddContactFilters } from "../../filters/filters";
import { IUserColumn, userColumns } from "./models/contact.models";
import { Paginator, PaginatorState } from "primeng/paginator";

@Component({
  selector: "add-contact",
  templateUrl: "add-contact.component.html",
  styleUrls: ["add-contact.component.scss"],
})
export class AddContactComponent implements OnInit {
  @ViewChild("dt1") dt1: Table;
  @ViewChild("tablePaginator") paginator: Paginator;
  filter = AddContactFilters;
  usersSubject = new BehaviorSubject(null);
  users$ = this.usersSubject.asObservable();
  users: IServicenowUser[];
  submittingData: boolean = false;
  categorySysID: string;
  categoryName: string;
  tableLoading: boolean = false;
  totalRecords: number = 0;
  defaultSelectedColumns: string[] = [];
  tableFilters: LazyLoadEvent;
  selectedUsers: IServicenowUser[] = [];
  firstLoad: boolean = true;
  _selectedColumns!: IUserColumn[];
  tableColumn: TableColumn[] = userColumns;
  contactType: string = "";

  constructor(
    private companyService: CompanyService,
    private dialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.categoryName = this.dialogConfig.data?.categoryName;
    this.categorySysID = this.dialogConfig.data?.categorySysID;
    this.contactType = this.dialogConfig.data?.type;
  }

  ngOnInit() {
    this.tableLoading = true;
  }

  async loadUsers(table: Table, event?: LazyLoadEvent | PaginatorState) {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }

    const isFirstLoad = this.firstLoad ? true : false;
    table.loading = true;
    this.usersSubject.next([]);
    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(userColumns));
      this.firstLoad = false;
    }
    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.tableColumn,
      currentPerPage,
      currentPage,
      event,
      { CategoryID: this.categorySysID }
    );

    const response =
      this.contactType === ContactType.contact
        ? await this.companyService.loadAvailableUsersForContactCategory(
            serviceNowFilter
          )
        : await this.companyService.loadAvailableUsersForCategory(
            serviceNowFilter
          );

    this.totalRecords = response.overallQueryCount;
    this.usersSubject.next(response.result);

    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    table.loading = false;
  }

  async submitClicked() {
    if (this.selectedUsers.length > 0) {
      this.submittingData = true;
      let requestSuccessful = await this.addUsersToContactType(
        this.selectedUsers
      );
      this.closeDialog(requestSuccessful);
    }
  }

  closeDialog(dialogResponse): void {
    this.ref.close(dialogResponse);
  }

  async addUsersToContactType(users) {
    let userIDs = users.map((user) => user.sysId);
    let addUsersRequest: IAddUsersToContactTypeRequest = {
      userIDs: userIDs,
      contactTypeID: this.categorySysID,
    };

    const requestSuccessful =
      this.contactType === ContactType.contact
        ? await this.companyService.addUserToCompanyContact(addUsersRequest)
        : await this.companyService.addUserToContactType(addUsersRequest);

    return requestSuccessful;
  }

  submitButtonEnabled(): boolean {
    if (this.selectedUsers.length > 0) return true;
    else return false;
  }

  close(): void {
    this.ref.close();
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = userColumns
  ) {
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
