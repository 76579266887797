import { Component, Inject, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { IService } from "src/app/components/service-hub/products-and-services/services/status/models/service-notifications-models";
import { ServiceNotificationsService } from "src/app/components/service-hub/products-and-services/services/status/shared/service-notifications-service.service";
import { ToastService } from "src/app/services/toast.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-service-notifications-dialog",
  templateUrl: "./service-notifications-dialog.component.html",
  styleUrls: ["./service-notifications-dialog.component.scss"],
  providers: [MessageService]
})
export class ServiceNotificationsDialogComponent implements OnInit {
  dialogData: any;
  checked: boolean = false;
  constructor(
    public serviceNotificationsService: ServiceNotificationsService,
    private dialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private toastService: ToastService,
    private messageService: MessageService
  ) {
    this.dialogData = this.dialogConfig.data;
  }

  ngOnInit(): void {}

  async toggleServiceSubscription(service: IService) {
    this.serviceNotificationsService.toggleServiceSubscription(service);
    this.showToastInfo(
      service.name,
      service.subscribed
        ? " Receiving Notifications"
        : " Not Receiving Notifications"
    );
  }
  cancelButton() {
    this.ref.close();
  }

  showToastInfo(summary: string, detail: string) {
    this.messageService.add({ severity: "info", summary, detail });
  }

}
