import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { LoadingSpinnerService } from "./loading-spinner.service";
import { BehaviorSubject } from "rxjs";
type PaddingFormat = `${number} ${number} ${number} ${number}`;
@Component({
  selector: "loading-spinner",
  templateUrl: "./loading-spinner.component.html",
  styleUrls: ["./loading-spinner.component.scss"],
})
export class LoadingSpinnerComponent implements OnInit {
  messageSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    "Loading..."
  );
  message$ = this.messageSubject.asObservable();

  @Input() padding: PaddingFormat = "4 0 4 0";
  @Input() size: "small" | "large" = "large";
  @Input() displayMessage: boolean = true;
  paddingArray: { [key: string]: boolean } = {};

  constructor(private loadingSpinnerService: LoadingSpinnerService) {
    this.loadingSpinnerService.messageChanged.subscribe(
      (newMessage: string) => {
        this.messageSubject.next(newMessage);
      }
    );
  }

  ngOnInit() {
    this.paddingArray = this.generatePaddingClasses(this.padding);
    this.paddingArray = this.generatePaddingClasses(this.padding);
  }

  generatePaddingClasses(padding: PaddingFormat): { [key: string]: boolean } {
    const paddingArray = padding.split(" ").map(Number);
    return {
      [`pt-${paddingArray[0]}`]: true,
      [`pr-${paddingArray[1]}`]: true,
      [`pb-${paddingArray[2]}`]: true,
      [`pl-${paddingArray[3]}`]: true,
    };
  }
}
