import { Injectable } from "@angular/core";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";
import { ServiceNowFilterQuery } from "src/app/models/servicenow.model";

@Injectable({
  providedIn: "root",
})
export class NospService {
  constructor(private apiRequest: ApiRequest) {}

  getDailyTransferBreakdown(filter: any) {
    const requestProps: IRequestOptions = {
      endpoint: "Nosp/GetDailyTransferBreakdown",
      body: filter,
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }

  getDailyTransferBreakdownGraph(filter: any) {
    const requestProps: IRequestOptions = {
      endpoint: "Nosp/GetDailyTransferBreakdownGraph",
      body: filter,
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }

  getAnnualTransferBreakdown(filter: any, currentPage: number) {
    const requestProps: IRequestOptions = {
      endpoint: "Nosp/GetAnnualTransferBreakdown",
      body: {
        Filter: filter,
        CurrentPage: currentPage,
      },
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }

  getUserInfo(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "Nosp/GetUserInfo",
      body: request,
    };

    return this.apiRequest.request(requestProps);
  }
}
