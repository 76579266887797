import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "not-authorised",
  templateUrl: "not-authorised.component.html",
  styleUrls: ["not-authorised.component.scss"],
})
export class NotAuthorisedComponent implements OnInit {
  constructor(private router: Router, private location: Location) {}
  async ngOnInit() {}

  goBackButton(): void {
    this.location.back();
  }

  backToDashBoardButton(): void {
    this.router.navigate([`/secure/dashboard`]);
  }
}
