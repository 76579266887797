import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { IServiceHubMenuItem, menu } from "../../../services/general/menu/menu";
import { Router } from "@angular/router";
import { ImpersonateUserComponent } from "src/app/components/misc/pop-up/impersonate-user/impersonate-user.component";
import { PermissionService } from "src/app/services/permissions.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { CompanyAPIService } from "src/app/services/api/company/company.service";
import { IUserProfile } from "../../service-hub/company/users/shared/user-models";
import { BehaviorSubject, Observable, firstValueFrom } from "rxjs";
import { MegaMenuItem, MenuItem } from "primeng/api";
import { MenuService } from "src/app/services/general/menu/menu.service";
import { ProfileMenuItems } from "src/app/services/general/menu/profile-menu";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ToastService } from "src/app/services/toast.service";

interface IServiceHubMegaMenuItem extends MegaMenuItem {
  items?: IServiceHubMenuItem[][];
}
@Component({
  selector: "menu-bar",
  templateUrl: "menu.component.html",
  styleUrls: ["menu.component.scss"],
  providers: [DialogService],
})
export class MenuComponent implements OnInit {
  @ViewChild("avatarMenu") avatarMenu: ElementRef;

  @Output() openImpersonateDialog = new EventEmitter<any>();

  private _megaMenuItems: BehaviorSubject<IServiceHubMegaMenuItem[]> =
    new BehaviorSubject<IServiceHubMegaMenuItem[]>([]);
  megaMenuItems$: Observable<IServiceHubMegaMenuItem[]> =
    this._megaMenuItems.asObservable();

  user: IUserProfile;
  quickPathItems: IServiceHubMenuItem[] = [];
  profileMenuItems: IServiceHubMenuItem[] = [];
  searchValue: string = "";
  isEnterpriseCompany: boolean;
  canViewDeveloperZone: boolean = false;
  canViewServicePolicies: boolean = false;
  canImpersonateUser: boolean = false;
  isImpersonating: boolean = false;
  impersonatedUser: IUserProfile;
  ref: DynamicDialogRef;

  get breadcrumbs$() {
    return this.breadcrumbService.breadcrumbs$;
  }

  constructor(
    private menuService: MenuService,
    private route: Router,
    private permissionService: PermissionService,
    public authService: AuthService,
    private companyService: CompanyAPIService,
    private breadcrumbService: BreadcrumbService,
    private dialogService: DialogService,
    private toastService: ToastService
  ) {}

  async ngOnInit() {
    const user = await this.authService.getUser();
    this.isImpersonating = this.authService.isImpersonating();
    this.impersonatedUser = this.authService.getImpersonatedUser();
    this.user = this.impersonatedUser ? this.impersonatedUser : user;
    //await this.getCompanyType();
    const userMenuItems = this.menuService.getUserMegaMenuItems();
    this.quickPathItems = this.menuService.getQuickPathMenuItems(
      this.menuService.getUserMenuItems()
    );
    this._megaMenuItems.next([{ icon: "pi pi-bars", items: userMenuItems }]);

    this.profileMenuItems = this.buildProfileMenuItems(
      this.isImpersonating,
      this.user.active,
      this.isEnterpriseCompany
    );

    this.profileMenuItems.forEach((item) => {
      if (item.label === "Impersonate") {
        item.command = (event) => this.openImpersonate();
      }
      if (item.label === "End Impersonation") {
        item.command = (event) => this.endImpersonate();
      }
    });
  }

  buildProfileMenuItems(
    isImpersonating,
    user,
    isEnterpriseCompany
  ): IServiceHubMenuItem[] {
    return ProfileMenuItems.filter((menuItem) => {
      if (
        menuItem.label === "Impersonate" ||
        menuItem.label === "End Impersonation"
      ) {
        if (
          this.permissionService.checkPermission("IMPERSONATE") &&
          this.permissionService.digitalSpaceID()
        ) {
          if (menuItem.label === "Impersonate") {
            return !isImpersonating;
          }
        }
        if (menuItem.label === "End Impersonation") {
          return isImpersonating;
        }

        return false;
      } else if (menuItem.label === "Developer") {
        return (
          this.permissionService.digitalSpaceID() &&
          this.permissionService.checkPermission("TIMICO_DEVELOPER")
        );
      } else if (menuItem.label === "Service Policies") {
        return isEnterpriseCompany;
      }
      return true;
    });
  }

  async getCompanyType() {
    this.isEnterpriseCompany = await firstValueFrom(
      this.companyService.isEnterprise(this.user.companyId)
    );
  }

  endImpersonation(): void {
    this.authService.clearImpersonation();
  }

  search() {
    if (this.searchValue.length) {
      this.route.navigate(["/secure/search"], {
        queryParams: { query: this.searchValue },
      });
    } else {
      this.toastService.showToastWarning(
        "Sorry ",
        "Please enter a search term to search"
      );
    }
  }

  openImpersonate() {
    this.ref = this.dialogService.open(ImpersonateUserComponent, {
      width: "40%",
      contentStyle: { overflow: "auto" },
      showHeader: false,
    });
  }

  endImpersonate() {
    this.authService.clearImpersonation();
  }
}
