import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subject, firstValueFrom } from "rxjs";
import { IBillingEntity } from "../products-and-services/telephony/models/telephony-models";
import { CloudHostingComponent } from "./cloud-hosting/cloud-hosting.component";
import { productCategories } from "./shared/product-models";
import { CostCentreService } from "src/app/services/cost-centre-service";
import { UserService } from "src/app/services/api/user/user.service";
import { MenuItem } from "primeng/api";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { IUserProfile } from "../company/users/shared/user-models";

@Component({
  selector: "app-billing-overview",
  templateUrl: "billing.component.html",
  styleUrls: ["billing.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingOverviewComponent implements OnInit, OnDestroy {
  connectivity: CloudHostingComponent;

  cdr = inject(ChangeDetectorRef);

  sites: IBillingEntity[];

  selectedSite: IBillingEntity;
  selectedSiteIdSubject = new BehaviorSubject(null);
  selectedSiteId$ = this.selectedSiteIdSubject.asObservable();

  selectedTab: string = productCategories.Cloud;
  loading: boolean = true;
  destroy$ = new Subject();

  user: IUserProfile;
  canEditCostCenters: boolean = false;

  menuItems: MenuItem[] = [
    { label: productCategories.Cloud },
    { label: productCategories.Connectivity },
    { label: productCategories.Services },
    { label: productCategories.Security },
    { label: productCategories.UCC },
  ];

  activeMenuItem: MenuItem = this.menuItems[0];

  siteId: string;

  constructor(
    private router: Router,
    private costCentreService: CostCentreService,
    private userService: UserService,
    private breadcrumbService: BreadcrumbService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.siteId = this.route?.snapshot?.params?.siteId;
  }

  async ngOnInit() {
    this.user = await this.authService.getUser();
    if (
      this.user.uTimicoPortalPermissions.includes("BILLING_COSTCENTRE_UPDATE")
    ) {
      this.canEditCostCenters = true;
    }
    const breadcrumbs: MenuItem[] = [
      {
        label: "Billing Overview",
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    await this.loadSites();
  }

  onLoadingStateChange(isLoading: boolean) {
    this.loading = isLoading;
    this.menuItems = this.menuItems.map((menuItem) => {
      return { ...menuItem, disabled: isLoading };
    });
    this.activeMenuItem = this.menuItems.find(
      (menuItem) => menuItem.label === this.activeMenuItem.label
    );
    this.cdr.detectChanges();
  }

  changeTab(event: MenuItem) {
    this.activeMenuItem = event;
    this.cdr.detectChanges();
  }

  setSelectedSite(event: Event) {
    this.router.navigate([
      `/secure/billing/${this.selectedSite.unityId}/overview`,
    ]);
    this.selectedSiteIdSubject.next(this.selectedSite.unityId);
    this.cdr.detectChanges();
  }

  async loadSites() {
    // TODO review this, need to check service this should be in
    const sites = await firstValueFrom(
      this.userService.getBillingEntities("invoice")
    );
    this.selectedSite = this.getSelectedSite(sites);
    if (this.selectedSite) {
      this.router.navigate([
        `/secure/billing/${this.selectedSite.unityId}/overview`,
      ]);
      this.selectedSiteIdSubject.next(this.selectedSite.unityId);
    }
    this.sites = sites;
    this.cdr.detectChanges();
  }

  cssSiteSelectionButton(loading: boolean) {
    switch (loading) {
      case true:
        return "btn-disabled";
      case false:
        return "";
    }
  }

  onTabChanged(selectedTab) {
    this.selectedTab = selectedTab.tab.textLabel;
  }

  goToCostCentresAndUsers() {
    this.router.navigate([
      `/secure/billing/${this.selectedSite.unityId}/costcentres-users`,
    ]);
  }

  public get productCategories(): typeof productCategories {
    return productCategories;
  }

  ngOnDestroy() {
    this.destroy$.next(void 0);
    this.destroy$.complete();
  }

  getSelectedSite(sites) {
    if (!sites?.length) {
      return null;
    }
    if (this.siteId) {
      const site = sites.find((site) => this.siteId === site.sysId);
      if (site) {
        return site;
      }
    }
    return sites[0];
  }
}
