import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { NotificationService } from "src/app/services/notification.service";
import { DatePipe } from "@angular/common";
import { AddCommentComponent } from "src/app/components/misc/pop-up/add-comment/add-comment.component";
import { FileManagerComponent } from "src/app/components/misc/pop-up/file-manager/file-manager.component";
import { FileManagerService } from "src/app/components/misc/pop-up/file-manager/file-manager.service";
import { ViewFilesComponent } from "src/app/components/misc/pop-up/view-files/view-files.component";
import { FileService } from "src/app/services/api/file/file.service";
import { ChangeRequestService } from "src/app/services/api/change-request/change-request.service";
import { firstValueFrom } from "rxjs";
import { MenuItem } from "primeng/api/menuitem";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Message, MessageService } from "primeng/api";
import { IChange, IServiceNowApproval } from "./shared/change-models";
import { ToastService } from "src/app/services/toast.service";
import { IUserProfile } from "../../../company/users/shared/user-models";
import { AuthService } from "src/app/services/auth/auth.service";
import { TableNames } from "src/app/models/servicenow.model";
import { ToastType } from "src/app/services/global/toast.service";
import {
  GenericApiErrorMessage,
  IsDisplayableException,
} from "src/app/helpers/error.helper";

@Component({
  selector: "change",
  templateUrl: "change.component.html",
  styleUrls: ["change.component.scss"],
  providers: [DialogService, MessageService],
})
export class SingleChangeComponent implements OnInit {
  user: IUserProfile;
  ref: DynamicDialogRef;
  changeId: any;
  change: IChange;
  stateOfAcceptance: IServiceNowApproval;
  comments: any;
  attachmentFiles: any;
  collatedRelatedTickets: Array<any> = [];
  endDateCheck: boolean;
  customerApprovalState: String = "N/A";
  loading: boolean = false;
  canMakeChanges: boolean = false;

  messages: Message[] | undefined = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private notifications: NotificationService,
    public fileManagerService: FileManagerService,
    private fileService: FileService,
    private ChangeRequestService: ChangeRequestService,
    private breadcrumbService: BreadcrumbService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private authService: AuthService
  ) {}
  async ngOnInit() {
    this.user = await this.authService.getUser();
    this.canMakeChanges =
      this.user.uTimicoPortalPermissions.includes("TICKETS_CHANGE_UPDATE") ||
      this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_UPDATE");
    this.activatedRoute.params.subscribe(async (params: Params) => {
      this.changeId = params["id"];
      await this.loadChange();

      const breadcrumbs: MenuItem[] = [
        { label: "Change Requests", routerLink: "/secure/tickets/changes" },
        { label: this.change?.number },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    });
  }

  async loadChange() {
    this.loading = true;
    try {
      this.change = await firstValueFrom(
        this.ChangeRequestService.getChangeRequest(this.changeId)
      );

      this.stateOfAcceptance = await firstValueFrom(
        this.ChangeRequestService.getApproverState(this.change.sysId)
      );

      this.notifications.readNotification(this.change.sysId);
      this.loadFiles();
      this.checkIsActivePlanned();

      if (
        this.stateOfAcceptance === null ||
        (this.stateOfAcceptance && this.stateOfAcceptance.state === null)
      ) {
        this.stateOfAcceptance = { state: "" };
      } else if (this.stateOfAcceptance.state == "Approved") {
        this.customerApprovalState = "Awaiting Digital Space Acknowledgement";
      } else if (this.stateOfAcceptance.state == "Requested") {
        this.customerApprovalState = "Awaiting Customer Approval";
      } else if (this.stateOfAcceptance.state == "Rejected") {
        this.customerApprovalState = "Awaiting Digital Space Acknowledgement";
      }

      if (this.change.state == "Implementation") {
        this.customerApprovalState =
          "Digital Space is currently implementing your change.";
      } else if (this.change.state == "Closed") {
        this.customerApprovalState = "This change is currently closed.";
      }
    } catch (error) {
      console.error(error);
      let message = {
        severity: ToastType.error,
        summary: "Error",
        detail: GenericApiErrorMessage,
        closable: false,
      };
      if (IsDisplayableException(error?.status)) {
        message = {
          severity: ToastType.error,
          summary: "Error",
          detail: error.error,
          closable: false,
        };
      }
      this.messages = [message];
    } finally {
      this.loading = false;
    }
  }

  async approve() {
    firstValueFrom(
      this.ChangeRequestService.updateApproval(
        this.change.sysId,
        "approved",
        "Approved"
      )
    );

    this.loadChange();
    this.toastService.showToastSuccess(
      "Submit",
      "You have submitted your approval for this change."
    );
  }
  async reject() {
    this.openAddComment();
  }

  openAddComment() {
    this.ref = this.dialogService.open(AddCommentComponent, {
      width: "32%",
      height: "42%",
      contentStyle: { overflow: "auto" },
      showHeader: false,
    });
    this.ref.onClose.subscribe((result) => {
      if (result && result != "Cancel") {
        this.addComment(result);
        this.toastService.showToastSuccess(
          "Change rejected",
          "You have submitted your rejection for this change."
        );
      } else {
      }
    });
  }

  async addComment(comment, showAlert = false) {
    await firstValueFrom(
      this.fileService.updateApproval({
        comments: comment,
        DocumentId: this.change.sysId,
        state: "rejected",
      })
    );
    this.loadChange();
    if (showAlert)
      this.toastService.showToastError("Adding Comment ", "Comment Added.");
  }

  async checkIsActivePlanned() {
    let datePipe = new DatePipe("en-GB");

    let today = datePipe.transform(Date.now(), "yyyy/MM/dd HH:mm:ss", "BST");

    let endDateNewFormatDate = this.change.endDate.split(" ")[0];
    let endDateNewFormatTime = this.change.endDate.split(" ")[1];
    let endDateNewFormatYear = endDateNewFormatDate.split("/")[2];
    let endDateNewFormatMonth = endDateNewFormatDate.split("/")[1];
    let endDateNewFormatDay = endDateNewFormatDate.split("/")[0];

    let endDateUnix =
      Date.parse(
        endDateNewFormatYear +
          "/" +
          endDateNewFormatMonth +
          "/" +
          endDateNewFormatDay +
          " " +
          endDateNewFormatTime
      ) / 1000;
    let todayUnix = Number(new Date(today).getTime() / 1000);

    if (todayUnix > endDateUnix) {
      this.endDateCheck = false;
    } else {
      this.endDateCheck = true;
    }
  }

  async loadFiles() {
    let files = await firstValueFrom(
      this.fileService.getFiles(this.change.sysId)
    );
    this.attachmentFiles = files.fileList;
  }

  openAttachments(): void {
    this.ref = this.dialogService.open(ViewFilesComponent, {
      showHeader: false,
      data: {
        files: this.attachmentFiles,
        sysId: this.change.sysId,
        table: TableNames.REQUESTED_ITEM,
      },
    });
  }

  //TODO:Fix file upload
  openFileManager(): void {
    this.ref = this.dialogService.open(FileManagerComponent, {
      showHeader: false,
      width: "45%",
      data: {
        files: this.attachmentFiles,
        sysId: this.change.sysId,
        table: "change_request",
      },
    });
    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.toastService.showToastSuccess("File Manager", " File(s) Uploaded");
        this.loadChange();
      }
    });
  }

  getColor(value: string): string {
    switch (value) {
      case "On Hold":
        return "onhold";
      case "Approval":
        return "approval";
      case "PIR":
        return "pir";
      case "Implementation":
        return "implementation";
      case "Closed":
        return "closed";
      default:
        return "closed";
    }
  }
}
