import { EventEmitter } from "@angular/core";
import { MenuItem } from "primeng/api";

export interface IServiceHubMenuItem extends MenuItem {
  permissions?: string[];
  hasAccess?: string;
  items?: IServiceHubMenuItem[];
  isQuickPath?: boolean;
  command?: any;
  // openDialog?: EventEmitter<any>;
}

export const menu: IServiceHubMenuItem[] = [
  {
    label: "Ticket",
    // description: "Tickets, requests and orders",
    permissions: [
      "TICKETS_INCIDENTS_READ",
      "TICKETS_REQUESTS_READ",
      "TICKETS_ORDERS_READ",
      "TICKETS_COMPANY_READ",
    ],
    items: [
      {
        label: "Raise Ticket",
        // description: "Raise a ticket",
        routerLink: "/secure/tickets/raise",
        isQuickPath: true,
        permissions: [
          "TICKETS_INCIDENTS_CREATE",
          "TICKETS_REQUESTS_CREATE",
          "TICKETS_ORDERS_CREATE",
        ],
      },
      {
        label: "Incidents",
        // description: "View incidents",
        routerLink: "/secure/tickets/incidents",
        permissions: ["TICKETS_INCIDENTS_READ"],
      },
      {
        label: "Service Requests",
        // description: "View service requests",
        routerLink: "/secure/tickets/service-requests",
        permissions: ["TICKETS_REQUESTS_READ"],
      },
      {
        label: "Order Requests",
        // description: "View orders",
        routerLink: "/secure/tickets/orders",
        permissions: ["TICKETS_ORDERS_READ"],
      },
      {
        label: "Change Requests",
        // description: "View change requests",
        routerLink: "/secure/tickets/changes",
        permissions: ["TICKETS_CHANGE_READ"],
      },
      {
        label: "Tickets Reporting Overview",
        // description: "View Reporting Graphs",
        routerLink: "/secure/tickets/tickets-reporting",
        permissions: ["VIEW_REPORT"],
        hasAccess: "company",
      },
    ],
  },
  {
    label: "Usage & Monitoring",
    // description: "Device usage, stats and monitoring",
    routerLink: "/secure/menu-detail",
    isQuickPath: true,
    permissions: [
      "PRODUCTS_SERVICES_BROADBAND_READ",
      "PRODUCTS_SERVICES_BROADBAND_READ_COMPANY",
      "PRODUCTS_SERVICES_SCIENCE_LOGIC_READ",
      "PRODUCTS_SERVICES_MOBILE_READ",
      "PRODUCTS_SERVICES_DOMAINS_READ",
      "PRODUCTS_SERVICES_SERVICE_STATUS_READ",
      "PRODUCTS_SERVICES_BROADBAND_CHECKER_READ",
    ],
    items: [
      {
        label: "Broadband",
        description: "Broadband management",
        routerLink: "/secure/products-and-services/broadband",
        permissions: [
          "PRODUCTS_SERVICES_BROADBAND_READ",
          "PRODUCTS_SERVICES_BROADBAND_READ_COMPANY",
        ],
      },
      {
        label: "Domains",
        description: "Domain management",
        routerLink: "/secure/products-and-services/domains",
        permissions: ["PRODUCTS_SERVICES_DOMAINS_READ"],
      },
      {
        label: "Mobile",
        description: "Mobile management",
        routerLink: "/secure/products-and-services/telephony",
        permissions: ["PRODUCTS_SERVICES_MOBILE_READ"],
      },
      {
        label: "Device Utilisation",
        description: "View servers & hubs",
        routerLink: "/secure/products-and-services/monitoring/devices/server",
        permissions: ["PRODUCTS_SERVICES_SCIENCE_LOGIC_READ"],
      },
      {
        label: "Service Status",
        description: "View service status",
        routerLink: "/secure/products-and-services/services/status",
        permissions: ["PRODUCTS_SERVICES_SERVICE_STATUS_READ"],
      },
      //{ label: "Broadband Checker", description: "Check broadband status", routerLink: "/secure/products-and-services/broadband-check", permissions: ["PRODUCTS_SERVICES_BROADBAND_CHECKER_READ"], visibility: false },
    ],
  },
  {
    label: "Billing & Budget",
    // description: "Company invoices, credit notes and usage",
    permissions: [
      "BILLING_INVOICES_READ",
      "BILLING_INVOICE_READ_COMPANY",
      "BILLING_CREDIT_NOTES_READ",
      "BILLING_CREDIT_NOTES_COMPANY_READ",
      "BILLING_USAGE_READ",
    ],
    items: [
      {
        label: "Billing Overview",
        // description: "Billing summary",
        routerLink: "/secure/billing/overview",
        permissions: ["BILLING_OVERVIEW_READ"],
      },
      {
        label: "Invoices",
        // description: "View invoices",
        routerLink: "/secure/billing/invoices",
        permissions: ["BILLING_INVOICES_READ", "BILLING_INVOICE_READ_COMPANY"],
      },

      {
        label: "Credit Notes",
        // description: "View credit notes",
        routerLink: "/secure/billing/credit-notes",
        permissions: [
          "BILLING_CREDIT_NOTES_READ",
          "BILLING_CREDIT_NOTES_READ_COMPANY",
        ],
      },
      {
        label: "Connectivity Usage",
        // description: "View usage stats",
        routerLink: "/secure/billing/usage",
        permissions: ["BILLING_USAGE_READ"],
      },
      // { label: "Payment", routerLink: "/secure/billing/payment", permissions: ["BILLING_PAYMENT_READ"] },
    ],
  },
  {
    label: "Company",
    // description: "User, contact and survey management",
    permissions: ["COMPANY_USERS_READ", "COMPANY_RAG_READ"],
    items: [
      {
        label: "Users",
        // description: "View users",
        routerLink: "/secure/company/users",
        permissions: ["COMPANY_USERS_READ"],
      },
      {
        label: "Roles",
        // description: "View roles",
        routerLink: "/secure/company/roles",
        permissions: ["COMPANY_ROLES_CREATE_UPDATE"],
      },
      {
        label: "Contact and Authorisation Groups",
        // description: "View contacts",
        routerLink: "/secure/company/contacts",
        permissions: ["AUTHORISATION_TYPES_READ"],
      },
      //{ label: "User Activity", description: "View user activity", routerLink: "/secure/company/company-activity" }
    ],
  },
  {
    label: "Developer",
    // description: "Developer tools",
    permissions: ["DEVELOPER_CREDENTIALS_READ", "DEVELOPER_WEB_HOOKS_READ"],
    items: [
      {
        label: "Credentials",
        // description: "Update credentials",
        routerLink: "/secure/developer/credentials",
        permissions: ["DEVELOPER_CREDENTIALS_READ"],
      },
      {
        label: "Webhooks",
        // description: "Update web hooks",
        routerLink: "/secure/developer/web-hooks",
        permissions: ["DEVELOPER_WEB_HOOKS_READ"],
      },
    ],
  },
];
