import { number } from "echarts";
import { TableColumn } from "src/app/models/table";

export interface IGetNnowledgeResponse {
  kbCategory: string;
  kbKnowledgeBase: string;
  number: string;
  published: Date;
  shortDescription: string;
  sysId: string;
  text: string;
}

export type KnowledgeArticleSummary = {
  sysId: string;
  number: string;
  shortDescription: string;
  description: string;
};

enum KnowledgeTableColumns {
  kbnumber = "number",
  kbdescription = "shortDescription",
  kbcategory = "KbCategory",
}

export const knowledgeColumns: TableColumn[] = [
  {
    field: "number",
    header: "Articles",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: KnowledgeTableColumns.kbnumber,
  },
  {
    field: "shortDescription",
    header: "Article Name",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: KnowledgeTableColumns.kbdescription,
  },
  {
    field: "kbCategory",
    header: "Category",
    display: { type: "text" },
    // filter: {
    //   type: "multiSelect",
    //   values: [],
    // },
    showSortIcon: true,
    default: true,
    serviceNowField: KnowledgeTableColumns.kbcategory,
  },
];
