import { Injectable } from "@angular/core";
import { Table } from "primeng/table";

@Injectable({
  providedIn: "root",
})
export class PrimeNGFilterService {
  constructor() {}

  filterByDate(dt1: Table, selectedDate, field = "date") {
    dt1.filter(selectedDate, field, "equals");
  }

  filterByDates(dt1: Table, dateRange, field = "date") {
    const startDate = dateRange[0];
    const endDate = dateRange[1];
    dt1.filter([startDate, endDate], field, "between");
  }
}
