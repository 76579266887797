import { Component, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "release-notes-notification",
  templateUrl: "release-notes-notification.component.html",
})
export class ReleaseNotesNotificationComponent {
  releaseNotes: any;
  viewingNotes: boolean = true;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private router: Router
  ) {
    this.releaseNotes = this.dialogConfig.data;
  }

  openLink(event: MouseEvent): void {
    event.preventDefault();
  }

  closeButton(viewNotes: boolean): void {
    this.ref.close(viewNotes);
  }
}
