import { Component } from '@angular/core';

@Component({
    selector: 'tickets',
    templateUrl: 'tickets.component.html',
    styleUrls: ['tickets.component.scss']
})
export class TicketsComponent {

}
