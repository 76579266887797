import { DateTime } from "luxon";

export const ConvertServiceNowDateToJsDate = (
  snowDate: string,
  format: string = "dd/MM/yyyy HH:ss"
): Date | null => {
  const dateTimeObject = DateTime.fromFormat(snowDate, format).setZone(
    DateTime.local().zoneName
  );
  if (!dateTimeObject.isValid) {
    return null;
  }
  return dateTimeObject.toJSDate();
};
