import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem } from "primeng/api/menuitem";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";

@Component({
  selector: "raise-ticket",
  templateUrl: "./raise-ticket.component.html",
  styleUrls: ["./raise-ticket.component.scss"],
})
export class RaiseTicketComponent implements OnInit {
  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService
  ) {}

  showHeaders: Boolean;

  ngOnInit() {
    const breadcrumbs: MenuItem[] = [{ label: "Raise a Ticket" }];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }

  openIdentifyIncident(): void {
    this.router.navigateByUrl("/secure/tickets/raise/create-incident");
  }
  openRaiseRequest() {
    this.router.navigateByUrl("/secure/tickets/raise/create-service-request");
  }
}
