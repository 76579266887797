import { TableColumn, TableSort } from "src/app/models/table";

export interface IRolePermissions {
  category: string;
  permissions: IPermission[];
}

export interface IPermission {
  sysId: string;
  description: string;
  globalPermission: boolean;
  label: string;
  name: string;
  portalCategory: string;
  portalSubCategory: string;
  servicehubConfigurable: boolean;
}

export interface ISelectedRole {
  sysId: string;
  name: string;
  description: string;
  default: boolean;
}

export interface IServiceNowRoleCreate {
  company: string;
  name: string;
  description: string;
  permissions: string;
}

export interface IServiceNowRoleUpdate {
  sysId: string;
  name: string;
  description: string;
  permissions: string;
}

export interface IRoleInterface {
  field: string;
  header?: string;
  display?: any;
  showSortIcon: boolean;
}

export const RolesColumns: IRoleInterface[] = [
  {
    field: "name",
    header: "Name",
    display: { type: "text" },
    showSortIcon: true,
  },
  {
    field: "description",
    header: "Description",
    display: { type: "text" },
    showSortIcon: true,
  },
  {
    field: "edit",
    header: "Edit",
    display: { type: "button" },
    showSortIcon: false,
  },
];

export type User = {
  active: boolean;
  companyId: string;
  email: string;
  firstName: string;
  lastLogin: Date | null;
  lastName: string;
  mobilePhone: string;
  permission: string;
  phone: string;
  roleSysId: string;
  sysId: string;
  typesOfContact: string[];
  uAccessiblePortals: string;
  uAuthorisationTypes: string;
  uBillingEntities: string;
  uOrdersBillingEntities: string;
};

enum userSnowField {
  email = "Email",
  name = "Name",
}

export const UserRoleColumns: TableColumn[] = [
  {
    field: "",
    header: "Name",
    display: { type: "textName" },
    showSortIcon: false,
    default: true,
    serviceNowField: userSnowField.name,
  },
  {
    field: "email",
    header: "Email Address",
    display: { type: "text" },
    default: true,
    serviceNowField: userSnowField.email,
  },
];
