import { TableColumn } from "src/app/models/table";

export type GetOrderCharacteristics = {
  characteristic: string;
  characteristicOptionValue: string;
};

export const OrderCharacteristicsColumns: TableColumn[] = [
  {
    field: "characteristic",
    header: "Name",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    disabled: true,
  },
  {
    field: "characteristicOptionValue",
    header: "Detail",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    disabled: true,
  },
];
