import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, firstValueFrom } from "rxjs";
import {
  ADSLUserFilter,
  CallAmount,
} from "src/app/components/misc/filters/filters";
import { BroadBandUsage, IBroadbandUser } from "./broadband-models";
import { NospService } from "src/app/services/api/nosp/nosp.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { cloneDeep } from "lodash-es";
import { AlertService } from "src/app/services/api/alert/alert.service";
import { DateTime } from "luxon";

@Injectable({
  providedIn: "root",
})
export class BroadbandUsageService {
  selectedUser: IBroadbandUser;
  usageAlerts: any;
  pureUsageAlerts: any;
  usageDataDaily: any;
  usageDataMonthly: any;
  graph: any = {};
  filter = ADSLUserFilter;
  currentPage: number = 0;
  currentPerPage: number = CallAmount;
  resultCount: number = -1;

  graphDataSubject = new BehaviorSubject<any>(null);

  constructor(
    private nospService: NospService,
    private alertService: AlertService,
    private authService: AuthService
  ) {}

  async initialiseTables(user) {
    this.selectedUser = user;
    //Load Alert data
    if (
      (await this.authService.getUser()).uTimicoPortalPermissions.some(
        (permission) => permission == "PRODUCTS_SERVICES_ALERTS_READ"
      )
    ) {
      this.loadUsageAlerts();
    }
  }

  getGraphData$(): Observable<any> {
    return this.graphDataSubject.asObservable();
  }

  async loadUsageAlerts() {
    this.usageAlerts = await firstValueFrom(
      this.alertService.getUsageAlerts({
        Filter: [
          { Column: "serviceName", Value: ["=" + this.selectedUser.name] },
        ],
      })
    );
    this.pureUsageAlerts = cloneDeep(this.usageAlerts);
  }

  async loadUsageMonthly(): Promise<BroadBandUsage[]> {
    if (this.selectedUser.name != null) {
      this.filter.filter((c) => c.Column == "Id")[0].Value = [
        this.selectedUser.name,
      ];

      const usageData = await firstValueFrom(
        this.nospService.getAnnualTransferBreakdown(
          this.filter,
          this.currentPage
        )
      );

      const formattedUsageData = usageData.results.map((item) => {
        const parsedFormattedDate = DateTime.fromFormat(
          item.formattedDate,
          "MM/yyyy"
        );
        return {
          ...item,
          formattedDate: parsedFormattedDate.toJSDate(),
        };
      });

      return formattedUsageData;
    }
  }

  //Loads table and graph display for daily data
  async loadUsageDaily(date: string) {
    let dateSplit = date.slice(1).split("/");

    let filter = {
      Username: this.selectedUser.name,
      Month: dateSplit[1],
      Year: dateSplit[2],
    };

    const transferBreakdown = await firstValueFrom(
      this.nospService.getDailyTransferBreakdown(filter)
    );

    const formattedUsageData = transferBreakdown.results.map((item) => {
      const parsedFormattedDate = DateTime.fromFormat(item.date, "dd/MM/yyyy");
      return {
        ...item,
        date: parsedFormattedDate.toJSDate(),
      };
    });

    this.usageDataDaily = formattedUsageData;
    this.graph = await firstValueFrom(
      this.nospService.getDailyTransferBreakdownGraph(filter)
    );

    this.graphDataSubject.next(this.graph);
    return transferBreakdown;
  }

  addAlert() {
    let newUsageAlert = {
      serviceName: this.selectedUser.name,
      percentage: 0,
    };
    this.usageAlerts.push(newUsageAlert);
  }

  async removeAlert(usageAlert, index) {
    if (usageAlert.user != undefined || usageAlert.user != null) {
      usageAlert.lastSent = null;
      await firstValueFrom(this.alertService.deleteUsageAlert(usageAlert));
    }
    this.usageAlerts.splice(index, 1);
    this.loadUsageAlerts();
  }

  async saveAlerts() {
    let updatedUsageAlerts = Array<any>();
    let newUsageAlerts = Array<any>();

    this.usageAlerts.forEach((c) => {
      if (c.user != undefined || c.user != null) {
        let pureUsageAlert = this.pureUsageAlerts.find(
          (pUA) => pUA.sysId == c.sysId
        );
        if (c.percentage != pureUsageAlert.percentage) {
          c.lastSent = null;
          updatedUsageAlerts.push(c);
        }
      } else {
        newUsageAlerts.push(c);
      }
    });

    if (newUsageAlerts != undefined && newUsageAlerts.length > 0)
      await firstValueFrom(this.alertService.createUsageAlert(newUsageAlerts)); //TODO Blocker

    if (updatedUsageAlerts != undefined && updatedUsageAlerts.length > 0)
      await firstValueFrom(
        this.alertService.updateUsageAlert(updatedUsageAlerts)
      ); //TODO Blocker

    this.loadUsageAlerts();
  }
}
