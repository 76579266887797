import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { ExportType, ExportService } from "src/app/services/export.service";
import { DataDate, GraphDataType } from "src/app/helpers/EnumHelper";
import { ScienceLogicService } from "src/app/services/api/science-logic/science-logic.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "science-logic-graph-reporting",
  templateUrl: "science-logic-graph-reporting.component.html",
  styleUrls: ["science-logic-graph-reporting.component.scss"],
})
export class ScienceLogicGraphReportingComponent implements OnInit, OnChanges {
  @Input("startDate") startDate: any = null;
  @Input("endDate") endDate: any = null;
  @Input("device") device: any;
  @Input("dataType") dataType: GraphDataType;

  graphsLoaded = false;
  loadingGraphs = false;
  start: Date = null;
  end: Date = null;
  filter = [];
  graphData: Array<any>;
  initialised: boolean = false;
  is24Hours: boolean = false;
  startUNIX: number = 0;
  endUNIX: number = 0;

  constructor(
    private exp: ExportService,
    private scienceLogicService: ScienceLogicService
  ) {}

  ngOnInit() {
    this.loadGraphData(DataDate.In24Hours);
    this.initialised = true;
  }

  ngOnChanges() {
    if (this.initialised) {
      if (this.startDate && this.endDate) {
        let startSplit = this.startDate[0].split("/");
        let endSplit = this.endDate[0].split("/");

        this.start = new Date(
          startSplit[2],
          Number(startSplit[1]) - 1,
          startSplit[0]
        );
        this.end = new Date(endSplit[2], Number(endSplit[1]) - 1, endSplit[0]);

        this.loadGraphData(DataDate.CustomDates);
      } else if (this.device) {
        this.loadGraphData(DataDate.In24Hours);
      }
    }
  }

  async loadGraphData(time: DataDate) {
    this.is24Hours = false;
    let title = "";

    //Date() is in UTC time so we must correct before passing to API
    if (time !== DataDate.CustomDates) {
      this.end = new Date();
      if (this.dataType == GraphDataType.TimeOnline) {
        if (time == DataDate.In24Hours) {
          this.end.setHours(this.end.getHours() - 1);
        } else {
          this.end.setDate(this.end.getDate() - 1);
        }
      }
    }

    var offset = this.end.getTimezoneOffset();
    //getTimezoneOffset is in minutes and we reverse the + of - per the documentation (+ means behind, - means ahead)
    //it may look incorrect on the front end but when we send the date into the server side the conversion will be correct
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
    offset /= 60;
    offset *= -1;
    this.end.setHours(this.end.getHours() + offset);
    this.endUNIX = Math.trunc(this.end.getTime() / 1000);

    switch (time) {
      case DataDate.In24Hours:
        this.is24Hours = true;
        title = "24 Hours (Nearest Available)";
        this.start = new Date();
        this.start.setHours(this.end.getHours() - 24);
        break;
      case DataDate.In7Days:
        title = "7 Days";
        this.start = new Date();
        this.start.setDate(this.end.getDate() - 6);
        break;
      case DataDate.In30Days:
        title = "30 Days";
        this.start = new Date();
        this.start.setDate(this.end.getDate() - 30);
        break;
      case DataDate.CustomDates:
        title = "Custom Dates";
        this.start.setHours(this.start.getHours() + offset);
        break;
      default:
        title = "Not Specified";
    }

    this.startUNIX = Math.trunc(this.start.getTime() / 1000);

    this.loadingGraphs = true;
    switch (this.dataType) {
      case GraphDataType.Latency:
        await firstValueFrom(
          this.scienceLogicService.getVitalsLatency(
            this.device.deviceId,
            this.start,
            this.end,
            title,
            this.is24Hours
          )
        ).then((data) => {
          if (data != null) {
            this.graphData = data;
            this.graphsLoaded = true;
          }
        });

        break;
      case GraphDataType.Availability:
        await firstValueFrom(
          this.scienceLogicService.getVitalsAvailability(
            this.device.deviceId,
            this.start,
            this.end,
            title,
            this.is24Hours
          )
        ).then((data) => {
          if (data != null) {
            this.graphData = data;
            this.graphsLoaded = true;
          }
        });

        break;
      case GraphDataType.TimeOnline:
        await firstValueFrom(
          this.scienceLogicService.getVitalsTimeOnline(
            this.device.deviceId,
            this.startUNIX,
            this.endUNIX,
            title,
            this.is24Hours
          )
        ).then((data) => {
          if (data != null) {
            this.graphData = data;
            this.graphsLoaded = true;
          }
        });
        break;
    }
  }

  async export() {
    switch (this.dataType) {
      case GraphDataType.Latency:
        await this.exp.fileExportList(
          "ScienceLogic/ExportVitalsLatency",
          {
            DeviceId: this.device.deviceId,
            DeviceName: "SL Latency - " + this.device.name,
            Start: this.start,
            End: this.end,
            in24Hours: this.is24Hours,
          },
          ExportType.Excel
        );
        break;
      case GraphDataType.Availability:
        await this.exp.fileExportList(
          "ScienceLogic/ExportVitalsAvailability",
          {
            DeviceId: this.device.deviceId,
            DeviceName: "SL Availability - " + this.device.name,
            Start: this.start,
            End: this.end,
            in24Hours: this.is24Hours,
          },
          ExportType.Excel
        );
        break;
      case GraphDataType.TimeOnline:
        await this.exp.fileExportList(
          "ScienceLogic/ExportTimeOnline",
          {
            DeviceId: this.device.deviceId,
            DeviceName: "SL Time Online - " + this.device.name,
            StartUNIX: this.startUNIX,
            EndUNIX: this.endUNIX,
            in24Hours: this.is24Hours,
          },
          ExportType.Excel
        );
        break;
    }
  }
}
