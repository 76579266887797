import { Component, OnInit } from "@angular/core";
import { AccountService } from "./shared/services/account.service";
import { ICookieSettings, IUserDetails } from "./shared/models/account-models";
import { UserDetailsDialogComponent } from "../../misc/pop-up/user-details-dialog/user-details-dialog.component";
import { IDialogResult } from "src/app/models/dialog-models";
import { CookieService } from "ngx-cookie-service";
import { PermissionService } from "src/app/services/permissions.service";
import { MenuItem } from "primeng/api/menuitem";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "account",
  templateUrl: "account.component.html",
  styleUrls: ["account.component.scss"],
  providers: [DialogService],
})
export class AccountComponent implements OnInit {
  loading: boolean = false;
  profile: IUserDetails;
  cookieSettings: ICookieSettings = { cookiesStatus: false };
  cookiesStatus: boolean = false;
  checked;
  ref: DynamicDialogRef;
  cookieModel: boolean = false;

  constructor(
    private accountService: AccountService,
    private cookieService: CookieService,
    private permissionService: PermissionService,
    private breadcrumbService: BreadcrumbService,
    private dialogService: DialogService,
    private toastService: ToastService
  ) {}

  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      {
        label: "Account Settings",
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    await this.loadProfile();
  }

  async loadProfile() {
    this.loading = true;
    this.profile = await this.accountService.loadProfile();
    await this.loadCookieSettings();
    this.loading = false;
    this.getCookieStatus();
  }

  async loadCookieSettings() {
    this.cookieSettings = await this.accountService.loadCookieSettings();
    this.cookiesStatus =
      this.cookieSettings?.uCookiesType === "Additional Cookies";
  }

  openUserDetailsDialog() {
    this.ref = this.dialogService.open(UserDetailsDialogComponent, {
      showHeader: false,
      data: {
        profile: this.profile,
      },
    });

    this.ref.onClose.subscribe(async (result: IDialogResult<IUserDetails>) => {
      if (result.updateSuccessful) {
        this.profile = result.updatedData;
        this.toastService.showToastSuccess(
          "Success - ",
          "User details updated."
        );
      }
    });
  }

  checkPermission(permission: string) {
    return this.permissionService.checkPermission(permission);
  }

  getCookieStatus() {
    if (this.cookieSettings?.uCookiesType == "Additional Cookies") {
      this.cookieModel = true;
    }
  }

  async toggleAdditionalCookies(toggleSelection) {
    await this.updateCookieSettings(toggleSelection.checked);
  }

  async updateCookieSettings(cookieSetting: boolean) {
    let cookies = {
      SysId: !this.cookieSettings?.uCookiesType
        ? this.cookieSettings?.sysId
        : "",
      UCookiesType: cookieSetting ? "Additional Cookies" : "",
    };
    await this.accountService.updateCookieSettings(cookies);
    this.cookieService.set(
      "Accept-DigitalSpace-Additional-Cookies",
      cookieSetting.toString(),
      10000
    );
  }
}
