import { Injectable } from "@angular/core";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";

@Injectable({
  providedIn: "root",
})
export class NotificationPreferenceService {
  constructor(private apiRequest: ApiRequest) {}

  updateNotificationPreference(notifications: any) {
    const requestProps: IRequestOptions = {
      endpoint: "NotificationPreference/UpdateNotificationPreference",
      body: notifications,
    };

    return this.apiRequest.request(requestProps);
  }

  getNotificationPreferences() {
    const requestProps: IRequestOptions = {
      endpoint: "NotificationPreference/GetNotificationPreferences",
      body: "",
    };

    return this.apiRequest.request(requestProps);
  }
}
