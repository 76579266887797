import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { broadbandColumns, IBroadbandUser } from "./shared/broadband-models";
import { NospService } from "src/app/services/api/nosp/nosp.service";
import { MenuItem } from "primeng/api";
import { LazyLoadEvent } from "primeng/api";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { Table } from "primeng/table";
import { ExportTable } from "../science-logic/cmdb-devices/shared/export-devices";
import {
  IsPaginatorState,
  SetSelectedColumns,
  TableReset,
} from "src/app/helpers/table.helper";
import { ToastService } from "src/app/services/toast.service";
import {
  ExportFileName,
  ExportService,
  ExportTableEndpoint,
  ExportType,
} from "src/app/services/export.service";
import { IDownloadFile, ITableToFile } from "src/app/models/export-models";
import { TableColumn } from "src/app/models/table";
import { cloneDeep } from "lodash-es";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { Paginator, PaginatorState } from "primeng/paginator";

@Component({
  selector: "broadband",
  templateUrl: "broadband.component.html",
  styleUrls: ["broadband.component.scss"],
})
export class BroadbandComponent implements OnInit {
  @ViewChild("dataTable") dataTable: Table;
  @ViewChild("tablePaginator") paginator: Paginator;
  ADSLinesSubject = new BehaviorSubject<Array<IBroadbandUser>>([]);
  ADSLines$ = this.ADSLinesSubject.asObservable();
  totalRecords: number = 0;
  cols: TableColumn[] = cloneDeep(broadbandColumns);
  _selectedColumns: TableColumn[] = [];
  firstLoad: boolean = true;
  columns: TableColumn[] = broadbandColumns;

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  exportOptions = [
    {
      label: "All",
      value: "all",
      command: () => this.exportAll(),
    },
    {
      label: "In View",
      value: "in_view",
      command: () =>
        ExportTable(
          this.dataTable,
          this.ADSLinesSubject.value,
          this._selectedColumns,
          "all"
        ),
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private noSpService: NospService,
    private breadcrumbService: BreadcrumbService,
    private toastService: ToastService,
    private exportService: ExportService
  ) {}

  ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      {
        label: "Broadband",
        routerLink: "/secure/products-and-services/broadband",
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }

  async loadADSLines(
    table: Table,
    event?: LazyLoadEvent | PaginatorState
  ): Promise<void> {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }

    const isFirstLoad = this.firstLoad ? true : false;
    table.loading = true;

    this.ADSLinesSubject.next([]);

    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(broadbandColumns));
      this.firstLoad = false;
    }

    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event
    );

    const response = await firstValueFrom(
      this.noSpService.getUserInfo(serviceNowFilter)
    );
    this.totalRecords = response.overallQueryCount;
    this.ADSLinesSubject.next(response?.result.results);
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    table.loading = false;
  }

  handleRowClicked(selectedRow): void {
    this.router.navigate([selectedRow.name], { relativeTo: this.route });
  }

  handleColumnChange(val: any[]) {
    this.cols = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  defaultExport() {
    ExportTable(this.dataTable, this.ADSLinesSubject.value, this.cols, "all");
  }

  async exportAll() {
    this.toastService.showToastInfo(
      "Downloading - ",
      " We are just sorting your data out for you. This won't take long."
    );

    const serviceNowFilter = BuildServiceNowQuery(this.dataTable, this.cols);
    const fields = this._selectedColumns.map((item) => item.exportField);
    const endpoint: string = ExportTableEndpoint.Broadband;
    const body: ITableToFile = {
      Filter: serviceNowFilter.Filter,
      ExportType: ExportType.Csv,
      CurrentPage: 0,
      Amount: 25,
      Pagination: false,
      ExportColumns: fields,
    };
    const downloadedFile: IDownloadFile =
      await this.exportService.exportTableToFile(endpoint, body);
    this.exportService.downloadFile(
      downloadedFile,
      ExportType.Csv,
      ExportFileName.broadband
    );
  }
  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = broadbandColumns
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter((column) => column?.default).map((column) => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
