import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { cloneDeep } from "lodash-es";
import { MenuItem, Message } from "primeng/api";
import { BehaviorSubject, Observable, firstValueFrom } from "rxjs";
import { take } from "rxjs/operators";
import { ADSLUserFilter } from "src/app/components/misc/filters/filters";
import { NotificationPreferenceService } from "src/app/services/api/notification-preference/notification-preference.service";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import {
  BroadBandUsage,
  BroadbandMonthlyColumns,
  IBroadbandMonthlyColumn,
  IBroadbandReport,
  broadbandReportColumns,
} from "../shared/broadband-models";
import { BroadbandUsageService } from "../shared/broadband-usage.service";

@Component({
  selector: "app-broadband-usage",
  templateUrl: "./broadband-usage.component.html",
  styleUrls: ["./broadband-usage.component.scss"],
})
export class BroadbandUsageComponent implements OnInit {
  usageMonthlySubject = new BehaviorSubject(null);
  usageDailySubject = new BehaviorSubject(null);
  usageMonthly$ = this.usageMonthlySubject.asObservable();
  usageDaily$ = this.usageDailySubject.asObservable();
  graphData$: Observable<any>;
  filter = cloneDeep(ADSLUserFilter);
  showGraph: boolean = false;
  graphLoaded: boolean;
  graphLoading: boolean = false;
  loading: boolean;
  reportDate: string = "";
  usageDataMonthly: BroadBandUsage[];
  usageDataDaily: any;
  notifications: Array<any>;
  monthTableColumn: IBroadbandMonthlyColumn[];
  reportTableColumns: IBroadbandReport[];
  graphToggle: boolean = false;
  selectedMonth!: BroadBandUsage;
  useAgeAvailable: boolean = true;
  errorMessage: Message[] | undefined;

  constructor(
    public broadbandUsageService: BroadbandUsageService,
    private route: ActivatedRoute,
    public datepipe: DatePipe,
    private notificationPreferenceService: NotificationPreferenceService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.loading = true;
    this.monthTableColumn = BroadbandMonthlyColumns;
    this.reportTableColumns = broadbandReportColumns;
    //Load user into  service
    this.getUser();
    //Default report date to this month
    this.reportDate = this.datepipe.transform(Date(), "MMMM y");
    this.notifications = await firstValueFrom(
      this.notificationPreferenceService.getNotificationPreferences()
    );

    //Load data
    await this.getUsageDataMonthly();
    await this.getUsageDataDaily(this.usageDataMonthly[0].date);
    this.graphData$ = this.broadbandUsageService.getGraphData$();
    this.graphData$.pipe(take(1)).subscribe((graph) => {
      if (graph) {
        this.graphLoaded = true;
      }
    });
    const breadcrumbs: MenuItem[] = [
      {
        label: "Broadband",
        routerLink: "/secure/products-and-services/broadband",
      },
      { label: this.broadbandUsageService?.selectedUser?.name },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.loading = false;
  }

  getUser() {
    this.route.params.subscribe((params: Params) => {
      this.broadbandUsageService.initialiseTables({
        name: params["id"],
      });
    });
  }

  //Disabled for now but do not delete
  //async openAlertManager() {
  // const dialogRef = this.dialog.open(AlertManagerComponent, {
  //   data: { notificationReply: this.notifications },
  //   autoFocus: false,
  //   panelClass: "alart-pop-up",
  // });
  // dialogRef.afterClosed().subscribe((result) => {});
  // }

  //This

  async getUsageDataMonthly() {
    try {
      this.usageDataMonthly =
        await this.broadbandUsageService.loadUsageMonthly();
      this.usageMonthlySubject.next(this.usageDataMonthly);
      this.selectedMonth = this.usageDataMonthly?.[0] ?? null;
      this.loading = false;
    } catch {
      this.errorMessage = [
        {
          severity: "error",
          summary: "Error",
          detail: "No ADSL information is available for this user.",
        },
      ];
      this.useAgeAvailable = false;
      this.loading = false;
    }
  }

  async getUsageDataDaily(date: string) {
    this.graphLoading = true;
    let usageDataDaily = await this.broadbandUsageService.loadUsageDaily(date);
    // Emit data
    this.usageDailySubject.next(usageDataDaily.results);
    this.graphLoading = false;
  }

  onRowSelect(event: any): void {
    this.getUsageDataDaily(event.data.date);
    this.reportDate = this.datepipe.transform(event.data.date, "dd/MM/yyyy");
    this.reportDate = this.datepipe.transform(this.reportDate, "MMMM y");
  }
}
