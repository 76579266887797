import { TableColumn, TableSort } from "src/app/models/table";
import { DateTime } from "luxon";
import {
  ordersColors,
  OrderTableColumnNames,
} from "src/app/models/order/order.model";

export const OrderRequestSizeLimit = 1000;

export enum OrderTabs {
  open = "Open",
  closed = "Closed",
}

export const OrderOpenStateValues = [
  "Pending",
  "Open",
  "Work In Progress",
  "Awaiting Info",
  "Fulfilled",
];
export const OrderClosedStateValues = ["Closed"];

export type GetOrdersResponseItem = {
  sysId: string;
  shortDescription: string;
  state: string;
  openedAt?: string;
  number: string;
  serviceRequestNumber: string;
  uRequestedFor: string;
};

export type OrderLineItem = {
  sysId: string;
  requestedFor: string;
  shortDescription: string;
  productSpecification: string;
  number: string;
  state: string;
  installationAddress: OrderLineAddress;
  requiredBy: DateTime;
  committedDate: DateTime;
  completedDate: DateTime;
  order: string;
  addressReference: string;
};
export type OrderLineAddress = {
  organisationName: string;
  siteName: string;
  subsiteName: string;
  streetAddress: string;
  city: string;
  state: string;
  country: string;
  zip: string;
};

export interface FileUpload {
  name: string;
  tableName: string;
  tableSysId: string;
  base64Encoding: string | ArrayBuffer;
  contentType: string;
  errorMessage?: string;
  size: number;
}

export enum OrderLineTableColumnNames {
  number = OrderTableColumnNames.number,
  request = OrderTableColumnNames.request,
  description = OrderTableColumnNames.description,
  requestedFor = OrderTableColumnNames.requestedFor,
  openedAt = OrderTableColumnNames.openedAt,
  state = OrderTableColumnNames.state,
}
export const searchableOrderFields = [
  OrderTableColumnNames.number,
  OrderTableColumnNames.request,
  OrderTableColumnNames.description,
  OrderTableColumnNames.requestedFor,
];

export const searchableOrderLineFields = [
  OrderLineTableColumnNames.number,
  OrderLineTableColumnNames.request,
  OrderLineTableColumnNames.description,
  OrderLineTableColumnNames.requestedFor,
];

export const searchableOrderLineItemFields = [];

enum exportFields {
  number = "number",
  request = "request",
  description = "subject",
  requestedFor = "requestedFor",
  openedAt = "opened",
  state = "state",
}

export const OrderOpenColumns: TableColumn[] = [
  {
    field: "number",
    header: "Number",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    disabled: true,
    serviceNowField: OrderTableColumnNames.number,
    exportField: exportFields.number,
  },
  {
    field: "request",
    header: "Request",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: OrderTableColumnNames.request,
    exportField: exportFields.request,
  },
  {
    field: "shortDescription",
    header: "Subject",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: OrderTableColumnNames.description,
    exportField: exportFields.description,
  },
  {
    field: "requestedFor",
    header: "Request For",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: OrderTableColumnNames.requestedFor,
    exportField: exportFields.requestedFor,
  },
  {
    field: "openedAt",
    header: "Opened At",
    display: { type: "date" },
    filter: {
      type: "dateRange",
    },
    showSortIcon: true,
    default: true,
    sortColumnOrder: 1,
    sortOrder: TableSort.desc,
    serviceNowField: OrderTableColumnNames.openedAt,
    exportField: exportFields.openedAt,
  },
  {
    field: "state",
    header: "State",
    display: {
      type: "chip",
      colors: ordersColors,
    },
    filter: {
      type: "multiSelect",
      values: [
        { label: "Pending", value: "Pending" },
        { label: "Open", value: "Open" },
        { label: "Work In Progress", value: "Work in progress" },
        { label: "Awaiting Info", value: "Awaiting info" },
        { label: "Fulfilled", value: "Fulfilled" },
      ],
    },
    showSortIcon: true,
    default: true,
    serviceNowField: OrderTableColumnNames.state,
    exportField: exportFields.state,
  },
];

const orderDefaultClosedDays = 90;

export const OrderClosedColumns: TableColumn[] = [
  {
    field: "number",
    header: "Number",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    disabled: true,
    serviceNowField: OrderTableColumnNames.number,
    exportField: exportFields.number,
  },
  {
    field: "request",
    header: "Request",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: OrderTableColumnNames.request,
    exportField: exportFields.request,
  },
  {
    field: "shortDescription",
    header: "Subject",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: OrderTableColumnNames.description,
    exportField: exportFields.description,
  },
  {
    field: "requestedFor",
    header: "Request For",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: OrderTableColumnNames.requestedFor,
    exportField: exportFields.requestedFor,
  },
  {
    field: "openedAt",
    header: "Opened At",
    display: { type: "date" },
    filter: {
      type: "dateRange",
      preset: {
        matchMode: "between",
        value: [
          DateTime.now().minus({ days: orderDefaultClosedDays }).toJSDate(),
          DateTime.now().toJSDate(),
        ],
      },
      message: `By default the last ${orderDefaultClosedDays} days of closed order requests are shown`,
    },
    showSortIcon: true,
    default: true,
    sortColumnOrder: 1,
    sortOrder: TableSort.desc,
    serviceNowField: OrderTableColumnNames.openedAt,
    exportField: exportFields.openedAt,
  },
  {
    field: "state",
    header: "State",
    display: {
      type: "chip",
      colors: ordersColors,
    },
    filter: null,
    showSortIcon: true,
    default: true,
    serviceNowField: OrderTableColumnNames.state,
    exportField: exportFields.state,
  },
];
