import { Component, Input, OnInit } from "@angular/core";
import { NgxEchartsModule } from "ngx-echarts";
import { EChartsOption } from "echarts";
import { Router } from "@angular/router";
import { graphIncidentFilter } from "src/app/components/misc/filters/filters";
import { cloneDeep } from "lodash-es";
import { IHoldReason } from "src/app/components/misc/graph/shared/pie-chart.models";
import { IncidentService } from "src/app/services/api/incident/incident.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-e-chart-pie",
  templateUrl: "./e-chart-pie.component.html",
  styleUrls: ["./e-chart-pie.component.scss"],
})
export class EChartPieComponent implements OnInit {
  @Input() data: any;
  holdinfo: IHoldReason[];
  noData: Boolean = true;
  hasTitle: boolean = true;
  title: string;
  newChart: EChartsOption;
  filter = cloneDeep(graphIncidentFilter);
  isLoading: boolean = true;

  constructor(
    private router: Router,
    private incidentService: IncidentService
  ) {}

  ngOnInit(): void {}

  async ngOnChanges() {
    await this.pieConfig();
  }

  async statePieChart(): Promise<EChartsOption> {
    var numbers = this.data.series[0].items.map((item) => {
      return item.count;
    });
    var labels = this.data.series[0].items.map((item) => {
      return item.label;
    });
    var colours = this.data.series[0].items.map((item) => {
      return item.colour;
    });
    var url = this.data.series[0].items.map((item) => {
      return item.url;
    });
    const self = this;

    return (this.newChart = {
      title: {
        left: "center",
      },
      tooltip: {
        trigger: "item",
        className: "pie-chart-tooltip",
        formatter: function (value) {
          let label = value.name;
          let color = value.color;
          if (value.name.includes("Awaiting Info")) {
            let dataPoints = self.holdinfo.map((item) => {
              return (
                '<span style="padding-left: 3px;">' +
                item.holdReason +
                ": <strong>" +
                item.count +
                "</strong></span>"
              );
            });
            return (
              '<div style="text-align: left;"><span class="awaiting-info" style="font-family:"Nunito" margin-right:0px;margin-left:0!important;border-radius:10px;width:9px;height:9px;background-color:' +
              ';"></span>' +
              dataPoints.join("<br/>") +
              "</div>"
            );
          } else {
            return (
              '<span style="border-radius:10px;width:9px;height:9px;background-color:' +
              color +
              ';"></span>' +
              label
            );
          }
        },
      },
      legend: {
        top: 1,
        left: "center",
        formatter: function (label) {
          return label.split(" (")[0];
        },
      },
      series: [
        {
          data: numbers.map(function (value, index) {
            return { value: value, name: labels[index], url: url[index] };
          }),
          radius: "45%",
          color: colours,
          type: "pie",

          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    });
  }

  async priorityPieChart(): Promise<EChartsOption> {
    var numbers = this.data.series[0].items.map((item) => {
      return item.count;
    });
    var labels = this.data.series[0].items.map((item) => {
      return item.label;
    });
    var colours = this.data.series[0].items.map((item) => {
      return item.colour;
    });
    var url = this.data.series[0].items.map((item) => {
      return item.url;
    });

    return (this.newChart = {
      title: {
        left: "center",
      },
      tooltip: {
        className: "pie-chart-tooltip",
        formatter: function (value) {
          let label = value.name;
          let color = value.color;
          return (
            '<span style="border-radius:10px;width:9px;height:9px;background-color:' +
            color +
            ';"></span>' +
            label
          );
        },
      },
      legend: {
        top: 1,
        left: "center",
        formatter: function (label) {
          return label.split(" (")[0];
        },
      },
      series: [
        {
          data: numbers.map(function (value, index) {
            return { value: value, name: labels[index], url: url[index] };
          }),
          type: "pie",
          color: colours,
          radius: "43%",

          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    });
  }

  onChartClick(event) {
    const url = event.data.url;
    if (url != null) this.router.navigateByUrl(event.data.url);
  }

  async pieConfig() {
    let holdInfo = await firstValueFrom(
      this.incidentService.getIncidentHoldReason(this.filter)
    );

    this.holdinfo = holdInfo;

    if (this.data != null) {
      if (
        this.data.series?.length == 0 ||
        this.data.series[0]?.items.length == 0
      ) {
        this.isLoading = false;
        this.noData = true;
        return;
      }

      if (this.data.title.includes("State")) {
        if (this.data != null) {
          this.title = this.data.title;
          this.noData = false;
          this.statePieChart();
          this.isLoading = false;
        } else {
          this.isLoading = true;
        }
      } else {
        if (this.data != null) {
          this.title = this.data.title;
          this.noData = false;
          this.priorityPieChart();
          this.isLoading = false;
        } else {
          this.isLoading = true;
        }
      }
    }
  }
}
