import { Component, OnInit, AfterViewInit, Input, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import * as Chart from 'chart.js';
import { Router } from '@angular/router';
import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { EChartsOption } from 'echarts';


@Component({
  selector: 'servicehub-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class ServiceHubGraphComponent {

  charts: any[] = [];
  chart: Chart;
  noData: Boolean = true;
  title: String = "";
  chartType: String;




  @ViewChild("chartCanvas", { static: true }) chartCanvas;
  @ViewChild("chartBody", { static: true }) chartBody;
  @Input() data: any;
  @Input("blocker") blocker;
  @Input("name") name;
  @Input("applyPadding") applyPadding = true;
  @Input("legend") legend = true;
  @Input("onlyGraph") onlyGraph = false;
  @Input() hasTitle: boolean = true;

  constructor(private router: Router) {
  }

  async ngOnInit() {
  }

  graphClick(event) {
    if (this.data.type == "pie" || this.data.type == "bar") {
      if (this.chart == undefined) return;
      var slice = this.chart.getElementAtEvent(event)[0];
      if (slice == undefined) return;
      this.router.navigateByUrl(slice._chart.config.data.datasets[0].url[slice._index]);
    }
  }


  pieConfig() {
    // Get the numbers, labels and colours
    var numbers = this.data.series[0].items.map(item => { return item.count });
    var labels = this.data.series[0].items.map(item => { return item.label });
    var colours = this.data.series[0].items.map(item => { return item.colour });
    var url = this.data.series[0].items.map(item => { return item.url });
    return {
      type: 'pie',
      title: this.data.title,
      data: {
        datasets: [{
          data: numbers,
          backgroundColor: colours,
          url: url,
          labels: labels
        }],
        labels: labels,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          align: "start",
          textAlign: "left",
          display: true,
          position: "top",
          onHover: (event) => {
            event.target.style.cursor = 'pointer';
         }
        },
        events: ['mousemove', 'click'],
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        tooltips: {
          callbacks: {
            //Show the square and show the label
            label: function(tooltipItem, data) {
              return data.labels[tooltipItem.index];
             },
          }
        }
      }
    };

  }

  barConfig() {

    // Get the numbers, labels and colours
    var numbers = this.data.series[0].items.map(item => { return item.count });
    var labels = this.data.series[0].items.map(item => { return item.label });
    var colours = this.data.series[0].items.map(item => { return item.colour });
    var url = this.data.series[0].items.map(item => { return item.url });
    return {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          data: numbers,
          backgroundColor: colours,
          url: url
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: this.legend,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              userCallback: function (label, index, labels) {
                // when the floored value is the same as the value we have a whole number
                if (Math.floor(label) === label) {
                  return label;
                }

              },
            }
          }],
        },
      }
    }
  }

  lineConfig() {

    var step = {};
    if(this.data.options.scales.yAxes[0].stepSize > 0) {
      step = this.data.options.scales.yAxes[0].stepSize;
    }

    var lineTicksY = {};
    var lineTicksX = {};
    var elementStyle = {};
    if(this.data.options.scales.yAxes[0].useMinAndMaxLineGraphBoundaries) {
      lineTicksY = {
          suggestedMin: this.data.options.scales.yAxes[0].min,
          suggestedMax: this.data.options.scales.yAxes[0].max,
          stepSize: step
      }
    } else if(this.onlyGraph) {
      lineTicksY = {
        maxTicksLimit: this.data.options.scales.yAxes[0].maxTicksLimit != 0 ? this.data.options.scales.yAxes[0].maxTicksLimit : 11,
        stepSize: this.data.options.scales.yAxes[0].stepSize != 0 ? this.data.options.scales.yAxes[0].stepSize : 5,
        min: this.data.options.scales.yAxes[0].min != 0 ? this.data.options.scales.yAxes[0].min : 0,
        max: this.data.options.scales.yAxes[0].max != 0 ? this.data.options.scales.yAxes[0].max : 100
      };

      lineTicksX = {
         padding: 10,
         maxTicksLimit: this.data.options.scales.xAxes[0].maxTicksLimit != 0 ? this.data.options.scales.xAxes[0].maxTicksLimit : 11
      };

      elementStyle = {
        point: {
          radius: 0
        }
      };
    }


    return {
      type: 'line',
      title: this.data.title,
      data: {
        labels: this.data.series[0].items.map(item => { return item.label }),
        datasets: this.data.series.map(series => {
          return {
            label: series.name,
            data: series.items.map(item => { return item.count }),
            backgroundColor: series.colour,
            borderColor: series.colour,
            borderWidth: 1,
            extraDetails: series.items.map(item => { return item.extraDetails }),
            fill: false
          }
        })
      },
      options: {
        elements: elementStyle,
        maintainAspectRatio: false,
        legend: {
          display: this.legend,
        },
        scales:
        {
          xAxes: [{
            ticks: lineTicksX,
            scaleLabel: {
              display: true,
              labelString: this.data.options.scales.xAxes[0].labelString
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.data.options.scales.yAxes[0].labelString
            },
            ticks: lineTicksY
          }]
        },
        tooltips: {
          callbacks: {
            afterLabel: function (tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].extraDetails[tooltipItem.index];
            }
          }
        }
      }
    };
  }

  // When a parent value is received, draws the chart.
  ngOnChanges() {
    if (this.data != null) {
      this.title = this.data.title;
      if (this.data.series != null) {
        if (this.data.series.length == 0 || this.data.series[0].items.length == 0) {
          this.noData = true;
          return;
        }
      }

      if (this.chart) {
        this.chart.destroy();
      };

      // Generate the chart config
      var config = {};
      switch (this.data.type) {
        case "pie":
          config = this.pieConfig();
          this.chart = new Chart(this.chartCanvas.nativeElement, config);
          break;
        case "bar":
          config = this.barConfig();
          this.chart = new Chart(this.chartCanvas.nativeElement, config);
          break;
        case "line":
          config = this.lineConfig();
          this.chart = new Chart(this.chartCanvas.nativeElement, config);
          break;
        default:
          this.chart = new Chart(this.chartCanvas.nativeElement, config);
      }
      this.chartType = this.chart.type;
      // Create the chart
      this.noData = false;
    } else {
      this.noData = true;
    }
  }
}
