import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { IncidentService } from "src/app/services/api/incident/incident.service";
import { InvoiceService } from "src/app/services/api/invoice/invoice.service";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { ServiceNowFilterQuery } from "src/app/models/servicenow.model";
import { RequestedItemsService } from "src/app/services/api/requested-items/requested-items.service";
import { OrderService } from "src/app/services/api/order/order.service";
import { CaseService } from "src/app/services/api/case/case.service";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { MenuItem } from "primeng/api";
import { IUserProfile } from "../../company/users/shared/user-models";
import { AuthService } from "src/app/services/auth/auth.service";
import {
  incidentSearchFields,
  serviceRequestSearchFields,
} from "src/app/models/search/search.models";
import { searchableOrderLineFields } from "src/app/models/order/order.model";
import { ServiceRequestService } from "src/app/services/api/service-request/service-request.service";
import { OrderLineItemService } from "src/app/services/api/order-line-item/order-line-item.service";

const searchMethod = "LIKE";

enum SearchLabels {
  INCIDENT = "Incident",
  INCIDENTS = "Incidents",
  CHANGE_REQUEST = "Change Request",
  CHANGE_REQUESTS = "Change Requests",
  SERVICE_REQUEST = "Service Request",
  SERVICE_REQUESTS = "Service Requests",
  CASE = "Case",
  CASES = "Cases",
  ORDER = "Order",
  ORDERS = "Orders",
  ORDER_LINE = "Order Line",
  ORDER_LINES = "Order Lines",
  INVOICE = "Invoice",
  INVOICES = "Invoices",
  CREDIT_NOTE = "Credit Note",
  CREDIT_NOTES = "Credit Notes",
}

@Component({
  selector: "search",
  templateUrl: "search.component.html",
  styleUrls: ["../../../global/menu/menu.component.scss"],
})
export class SearchComponent implements OnInit {
  loading: boolean = false;
  displayLabel: String;
  resultList: any[] = [];
  user: IUserProfile;

  get hasResults() {
    if (
      this.resultList?.length &&
      this.resultList.flatMap((results) => results).length > 0
    ) {
      return true;
    }
    return false;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private incidentService: IncidentService,
    private serviceRequestService: ServiceRequestService,
    private invoiceService: InvoiceService,
    private orderService: OrderService,
    private caseService: CaseService,
    private orderLineItemService: OrderLineItemService,
    private requestedItemsService: RequestedItemsService,
    private breadcrumbService: BreadcrumbService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUserOrImpersonatedUser();
    this.activatedRoute.queryParams.subscribe(async (params) => {
      const { query } = params;
      const breadcrumbs: MenuItem[] = [{ label: "Search" }, { label: query }];
      this.breadcrumbService.setBreadcrumbs(breadcrumbs);
      if (query) {
        this.loading = true;
        this.displayLabel = query;

        let incidentsCount,
          incidents,
          serviceRequestsCount,
          serviceRequests,
          ordersCount,
          orders,
          orderLinesCount,
          orderLines,
          casesCount,
          cases,
          invoicesCount,
          invoices,
          creditNotesCount,
          creditNotes;

        const promises = [];

        // Incidents
        if (
          this.user.uTimicoPortalPermissions.includes(
            "TICKETS_INCIDENTS_READ"
          ) ||
          this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")
        ) {
          const incidentsQuery = this.buildSearchQuery(
            incidentSearchFields,
            query
          );
          promises.push(
            firstValueFrom(this.incidentService.getCount(incidentsQuery)),
            firstValueFrom(this.incidentService.getMultiple(incidentsQuery))
          );
        }

        // ServiceRequests
        if (
          this.user.uTimicoPortalPermissions.includes(
            "TICKETS_REQUESTS_READ"
          ) ||
          this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")
        ) {
          const serviceRequestsQuery = this.buildSearchQuery(
            serviceRequestSearchFields,
            query
          );
          promises.push(
            firstValueFrom(
              this.serviceRequestService.getCount(serviceRequestsQuery)
            ),
            firstValueFrom(
              this.serviceRequestService.getMultiple(serviceRequestsQuery)
            )
          );
        }

        // Orders
        if (
          this.user.uTimicoPortalPermissions.includes("TICKETS_ORDERS_READ") ||
          this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")
        ) {
          const ordersQuery = this.buildSearchQuery(
            searchableOrderLineFields,
            query
          );
          promises.push(
            firstValueFrom(this.orderLineItemService.getCount(ordersQuery)),
            firstValueFrom(this.orderLineItemService.getMultiple(ordersQuery))
          );
        }

        // Order Lines
        if (
          this.user.uTimicoPortalPermissions.includes("TICKETS_ORDERS_READ") ||
          this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")
        ) {
          const orderLineItemsQuery = this.buildSearchQuery(
            searchableOrderLineFields,
            query
          );
          promises.push(
            firstValueFrom(
              this.orderLineItemService.getCount(orderLineItemsQuery)
            ),
            firstValueFrom(
              this.orderLineItemService.getMultiple(orderLineItemsQuery)
            )
          );
        }

        // Cases
        if (
          this.user.uTimicoPortalPermissions.includes(
            "TICKETS_REQUESTS_READ"
          ) ||
          this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")
        ) {
          const casesQuery = this.buildSearchQuery(
            serviceRequestSearchFields,
            query
          );
          promises.push(
            firstValueFrom(this.caseService.getCount(casesQuery)),
            firstValueFrom(this.caseService.getMultiple(casesQuery))
          );
        }

        // Invoices
        if (
          this.user.uTimicoPortalPermissions.includes(
            "BILLING_INVOICES_READ"
          ) ||
          this.user.uTimicoPortalPermissions.includes(
            "BILLING_INVOICE_READ_COMPANY"
          )
        ) {
          const invoicesQuery = this.buildSearchQuery(
            ["InvoiceNo"],
            query.toUpperCase()
          );
          promises.push(
            firstValueFrom(this.invoiceService.getInvoicesCount(invoicesQuery)),
            firstValueFrom(this.invoiceService.getInvoicesList(invoicesQuery))
          );
        }

        // Credit Notes
        if (
          this.user.uTimicoPortalPermissions.includes(
            "BILLING_CREDIT_NOTES_READ"
          ) ||
          this.user.uTimicoPortalPermissions.includes(
            "BILLING_CREDIT_NOTES_READ_COMPANY"
          )
        ) {
          const creditNotesQuery = this.buildSearchQuery(
            ["InvoiceNo"],
            query.toUpperCase()
          );
          promises.push(
            firstValueFrom(
              this.invoiceService.getCreditNotesCount(creditNotesQuery)
            ),
            firstValueFrom(
              this.invoiceService.getCreditNotesList(creditNotesQuery)
            )
          );
        }

        const results = await Promise.all(promises);

        let resultIndex = 0;
        const resultList = [];

        // Incidents
        if (
          this.user.uTimicoPortalPermissions.includes(
            "TICKETS_INCIDENTS_READ"
          ) ||
          this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")
        ) {
          incidentsCount = results[resultIndex++];
          incidents = results[resultIndex++];
          if (incidentsCount) {
            resultList.push({
              label: SearchLabels.INCIDENT,
              labelPlural: SearchLabels.INCIDENTS,
              recordCount: incidentsCount ?? 0,
              records: incidents.map((incident) => {
                return { ...incident, title: incident.shortDescription };
              }),
            });
          }
        }

        // Service Requests
        if (
          this.user.uTimicoPortalPermissions.includes(
            "TICKETS_REQUESTS_READ"
          ) ||
          this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")
        ) {
          serviceRequestsCount = results[resultIndex++];
          serviceRequests = results[resultIndex++];
          if (serviceRequestsCount) {
            resultList.push({
              label: SearchLabels.SERVICE_REQUEST,
              labelPlural: SearchLabels.SERVICE_REQUESTS,
              recordCount: serviceRequestsCount ?? 0,
              records: serviceRequests.map((serviceRequest) => {
                return {
                  ...serviceRequest,
                  title: serviceRequest.shortDescription,
                };
              }),
            });
          }
        }

        // Orders
        if (
          this.user.uTimicoPortalPermissions.includes("TICKETS_ORDERS_READ") ||
          this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")
        ) {
          ordersCount = results[resultIndex++];
          orders = results[resultIndex++];
          if (ordersCount) {
            resultList.push({
              label: SearchLabels.ORDER,
              labelPlural: SearchLabels.ORDERS,
              recordCount: ordersCount ?? 0,
              records: orders.map((order) => {
                return { ...order, title: order.shortDescription };
              }),
            });
          }
        }

        // Order Lines
        if (
          this.user.uTimicoPortalPermissions.includes("TICKETS_ORDERS_READ") ||
          this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")
        ) {
          orderLinesCount = results[resultIndex++];
          orderLines = results[resultIndex++];
          if (orderLinesCount) {
            resultList.push({
              label: SearchLabels.ORDER_LINE,
              labelPlural: SearchLabels.ORDER_LINES,
              recordCount: orderLinesCount ?? 0,
              records: orderLines.map((orderLine) => {
                return { ...orderLine, title: orderLine.shortDescription };
              }),
            });
          }
        }

        // Cases
        if (
          this.user.uTimicoPortalPermissions.includes(
            "TICKETS_REQUESTS_READ"
          ) ||
          this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")
        ) {
          casesCount = results[resultIndex++];
          cases = results[resultIndex++];
          if (casesCount) {
            resultList.push({
              label: SearchLabels.CASE,
              labelPlural: SearchLabels.CASES,
              recordCount: casesCount ?? 0,
              records: cases.map((caseItem) => {
                return { ...caseItem, title: caseItem.shortDescription };
              }),
            });
          }
        }

        // Invoices
        if (
          this.user.uTimicoPortalPermissions.includes(
            "BILLING_INVOICES_READ"
          ) ||
          this.user.uTimicoPortalPermissions.includes(
            "BILLING_INVOICE_READ_COMPANY"
          )
        ) {
          invoicesCount = results[resultIndex++];
          invoices = results[resultIndex++];
          if (invoicesCount) {
            resultList.push({
              label: SearchLabels.INVOICE,
              labelPlural: SearchLabels.INVOICES,
              recordCount: invoicesCount ?? 0,
              records: invoices.map((invoice) => {
                return {
                  ...invoice,
                  number: invoice.invoiceNo,
                  title: invoice.siteName,
                  openedAt: invoice.date,
                };
              }),
            });
          }
        }

        // Credit Notes
        if (
          this.user.uTimicoPortalPermissions.includes(
            "BILLING_CREDIT_NOTES_READ"
          ) ||
          this.user.uTimicoPortalPermissions.includes(
            "BILLING_CREDIT_NOTES_READ_COMPANY"
          )
        ) {
          creditNotesCount = results[resultIndex++];
          creditNotes = results[resultIndex++];
          if (creditNotesCount) {
            resultList.push({
              label: SearchLabels.CREDIT_NOTE,
              labelPlural: SearchLabels.CREDIT_NOTES,
              recordCount: creditNotesCount ?? 0,
              records: creditNotes.map((creditNote) => {
                return {
                  ...creditNote,
                  number: creditNote.invoiceNo,
                  title: creditNote.siteName,
                  openedAt: creditNote.invoiceDate,
                };
              }),
            });
          }
        }

        this.resultList = resultList;

        this.loading = false;
        if (this.resultList.length > 0) {
          this.resultList.forEach((result) => {
            if (
              result.recordCount === 1 &&
              result.records.length === 1 &&
              result.records[0].number.toLowerCase() === query.toLowerCase()
            ) {
              this.navigateToRecord(result.label, result.records[0]);
            }
          });
        }
      }
    });
  }

  buildSearchQuery(
    fields: string[],
    queryParam: string
  ): ServiceNowFilterQuery {
    const query: ServiceNowFilterQuery = BuildServiceNowQuery(
      null,
      null,
      10,
      0
    );
    for (let i = 0; i < fields.length; i++) {
      let value = `${searchMethod}${queryParam}`;
      let field = fields[i];
      if (i > 0) {
        field = `^OR${field}`;
      }
      query.Filter.push({
        Column: field,
        Value: [value],
      });
    }
    return query;
  }

  navigateToRecord(label: string, record) {
    switch (label) {
      case SearchLabels.CREDIT_NOTE:
        this.route.navigate(["/secure/billing/credit-notes"], {
          queryParams: { creditNoteNumber: record.number },
        });
        break;
      case SearchLabels.INVOICE:
        this.route.navigate(["/secure/billing/invoices"], {
          queryParams: { invoiceNumber: record.number },
        });
        break;
      default:
        this.route.navigateByUrl(
          `/secure/${this.getUrl(label)}/${record.sysId}`
        );
    }
  }

  getUrl(label: string): string {
    switch (label) {
      case SearchLabels.INCIDENT:
        return "tickets/incident";
      case SearchLabels.INCIDENTS:
        return "tickets/incidents";
      case SearchLabels.CHANGE_REQUEST:
        return "changeRequest";
      case SearchLabels.CHANGE_REQUESTS:
        return "changeRequests";
      case SearchLabels.SERVICE_REQUEST:
        return "tickets/service-request";
      case SearchLabels.SERVICE_REQUESTS:
      case SearchLabels.CASES:
        return "tickets/service-requests";
      case SearchLabels.CASE:
        return "tickets/case";
      case SearchLabels.ORDER:
        return "tickets/order";
      case SearchLabels.ORDERS:
      case SearchLabels.ORDER_LINES:
        return "tickets/orders";
      case SearchLabels.ORDER_LINE:
        return "tickets/orderlineitem";
      case SearchLabels.INVOICE:
      case SearchLabels.INVOICES:
        return "billing/invoices";
      case SearchLabels.CREDIT_NOTE:
      case SearchLabels.CREDIT_NOTES:
        return "billing/credit-notes";
      default:
        return null;
    }
  }
}
