import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { ExportService } from "src/app/services/export.service";
import { GraphDataType } from "src/app/helpers/EnumHelper";
import { ScienceLogicService } from "src/app/services/api/science-logic/science-logic.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "science-logic-graph-servers-reporting",
  templateUrl: "science-logic-graph-servers-reporting.component.html",
  styleUrls: ["science-logic-graph-servers-reporting.component.scss"],
})
export class ScienceLogicGraphServersReportingComponent
  implements OnInit, OnChanges
{
  @Input("device") device: any;
  @Input("dataType") dataType: GraphDataType;
  @ViewChild("availabilityContainer", { static: false })
  availabilityContainer: ElementRef;

  graphsLoaded = false;
  loadingVitalGraphs = true;
  loadingMiscGraphs = true;
  graphData: Array<any>;
  diskDrives: Array<any> = new Array<any>();
  networkData: any;

  availabilityWidth = "";
  availabilityPoints: any;
  percentageAvailable: any;
  chosenDuration = "7d";

  devicePerformanceDurations = [
    "24 Hours",
    "7 Days",
    "30 Days",
    "60 Days",
    "90 Days",
  ];

  cpuData: Array<any>;
  memoryData: Array<any>;

  constructor(
    private exp: ExportService,
    private scienceLogicService: ScienceLogicService
  ) {}

  ngOnInit() {
    this.loadGraphData();
    this.loadMiscData();
  }

  ngOnChanges() {}

  async loadGraphData() {
    const vitals = await firstValueFrom(
      this.scienceLogicService.getVMVitals(
        this.device.name,
        this.device.deviceId,
        this.chosenDuration,
        this.chosenDuration,
        this.chosenDuration
      )
    );

    this.availabilityPoints = vitals.availability;
    this.calculateAvailibilityWidth();

    this.cpuData = vitals.cpuGraph;
    this.memoryData = vitals.memoryGraph;
    this.loadingVitalGraphs = false;
  }

  async loadMiscData() {
    const response = await firstValueFrom(
      this.scienceLogicService.getVMMisc(
        this.device.name,
        this.device.deviceId,
        this.chosenDuration
      )
    );

    this.diskDrives = [];
    if (response != undefined) {
      response.drives.forEach((drive) => {
        if (drive.driveFullCapacity > 0) {
          drive.driveFullCapacity = Number(drive.driveFullCapacity.toFixed(2));
        } else {
          drive.driveFullCapacity = "Unavailable";
          drive.fullCapacityMeasurement = "";
        }

        drive.driveUsedCapacity = Number(drive.driveUsedCapacity.toFixed(2));
        this.diskDrives.push(drive);
      });
    }

    this.networkData = response.networkGraph;
    this.loadingMiscGraphs = false;
  }

  calculateAvailibilityWidth() {
    let totalOnline = this.availabilityPoints.reduce(
      (sum, current) => sum + current.value,
      0
    );
    let percent = (totalOnline / this.availabilityPoints.length) * 100;

    if (percent == 100) {
      this.percentageAvailable = percent.toFixed(0);
    } else {
      this.percentageAvailable = percent.toFixed(3);
    }

    //We had to move the "availability" tag to parent so we minus 50 to get original correct size when you take
    //left and right into account
    let containerWidth =
      this.availabilityContainer.nativeElement.offsetWidth - 50;
    this.availabilityWidth =
      (containerWidth / this.availabilityPoints.length).toFixed(2) + "px";
  }

  handleDurationChange(duration) {
    this.loadingVitalGraphs = true;
    this.loadingMiscGraphs = true;
    this.chosenDuration = duration;
    this.loadGraphData();
    this.loadMiscData();
  }

  getCorrectValueForDuration(duration) {
    switch (duration) {
      case "24 Hours":
        return "1d";
      case "7 Days":
        return "7d";
      case "30 Days":
        return "30d";
      case "60 Days":
        return "60d";
      case "90 Days":
        return "90d";
      default:
        return "7d";
    }
  }

  @HostListener("window:resize")
  onResize() {
    this.calculateAvailibilityWidth();
  }
}