import { Inject, Injectable } from "@angular/core";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";

@Injectable({
  providedIn: "root",
})
export class ChangeRequestService {
  constructor(private apiRequest: ApiRequest) {}

  getChangeRequests(request) {
    const requestProps: IRequestOptions = {
      endpoint: "ChangeRequests/GetChangeRequests",
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }

  getChangeRequest(changeId: string) {
    const requestProps: IRequestOptions = {
      endpoint: "ChangeRequests/GetChangeRequest",
      body: changeId,
    };
    return this.apiRequest.request(requestProps);
  }

  getApproverState(changeId: string) {
    const requestProps: IRequestOptions = {
      endpoint: "ChangeApproval/GetApproverState",
      body: {
        DocumentId: changeId,
      },
    };
    return this.apiRequest.request(requestProps);
  }

  updateApproval(changeId: string, newState: string, approvalComments: string) {
    const requestProps: IRequestOptions = {
      endpoint: "ChangeApproval/UpdateApproval",
      body: {
        DocumentId: changeId,
        state: newState,
        comments: approvalComments,
      },
    };
    return this.apiRequest.request(requestProps);
  }
}
