import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ShowApiCredentialsComponent } from "src/app/components/misc/pop-up/show-api-credentials/show-api-credentials.component";
import { GenerateApiCredentialsComponent } from "src/app/components/misc/pop-up/generate-api-credentials/generate-api-credentials.component";
import { BehaviorSubject, Observable, firstValueFrom, of } from "rxjs";
import { CredentialConfirmComponent } from "src/app/components/service-hub/developer/credential-confirm/credential-confirm.component";
import { DeveloperService } from "src/app/services/api/developer/developer.service";
import {
  credentialColumns,
  ICredentialColumn,
  ICredentials,
} from "src/app/components/service-hub/developer/shared/credentials-categories";
import { DateParseService } from "src/app/services/date-parse.service";
import { MenuItem } from "primeng/api/menuitem";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "credentials",
  templateUrl: "credentials.component.html",
  styleUrls: ["credentials.component.scss"],
  providers: [DialogService],
})
export class CredentialsComponent implements OnInit {
  credentialsSubject = new BehaviorSubject(null);
  credentials$ = this.credentialsSubject.asObservable();
  credentials: Array<any>;
  tableColums: ICredentialColumn[];
  ref: DynamicDialogRef | undefined;
  loading: boolean = true;

  constructor(
    private router: Router,
    private developerService: DeveloperService,
    private dateParseService: DateParseService,
    private breadcrumbService: BreadcrumbService,
    public dialogService: DialogService,
    private toastService: ToastService
  ) {}
  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      {
        label: "Credentials",
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.tableColums = credentialColumns;
    await this.loadCredentials();
    this.loading = false;
  }

  async loadCredentials() {
    const credentials = await firstValueFrom(
      this.developerService.getCompanyApiKeys()
    );
    this.credentials = credentials.map((item) => ({
      ...item,
      lastUsed: this.dateParseService.parseDate(item.lastUsed),
      created: this.dateParseService.parseDate(item.created),
    }));
    this.credentials.sort((a, b) => b.created.getTime() - a.created.getTime());
    this.credentialsSubject.next(this.credentials);
  }

  openNewCredentialsPopUp() {
    this.ref = this.dialogService.open(GenerateApiCredentialsComponent, {
      contentStyle: { overflow: "auto" },
      baseZIndex: 10000,
      maximizable: true,
      showHeader: false,
      width: "35%",
    });
    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.generateCredential(result);
      } else {
        this.toastService.showToastWarning(
          "Generate Credential - ",
          "Operation Cancelled"
        );
      }
    });
  }

  async generateCredential(friendlyName: string) {
    const credential = await firstValueFrom(
      this.developerService.generateApiKey(friendlyName)
    );

    this.showApiCredentials(credential, false);
  }

  async regenerateCredential(event: ICredentials) {
    this.ref = this.dialogService.open(CredentialConfirmComponent, {
      contentStyle: { overflow: "auto" },
      baseZIndex: 10000,
      showHeader: false,
      width: "35%",
      data: {
        title: "Are you sure ?",
        content: "You are about to regenerate the credential with the name ",
        authData: event.friendlyName,
        contentEnd:
          ", which will delete the existing key. Do you wish to continue?",
      },
    });
    this.ref.onClose.subscribe(async (result) => {
      if (result) {
        const credential = await firstValueFrom(
          this.developerService.regenerateApiKey(event?.friendlyName)
        );
        this.toastService.showToastSuccess(
          "Regenerate credential - ",
          "Operation completed."
        );
        this.showApiCredentials(credential, true);
      } else {
        this.toastService.showToastWarning(
          "Regenerate credential - ",
          "Operation cancelled."
        );
      }
    });
  }

  async revokeCredential(event: ICredentials) {
    this.ref = this.dialogService.open(CredentialConfirmComponent, {
      contentStyle: { overflow: "auto" },
      baseZIndex: 10000,
      showHeader: false,
      width: "35%",
      data: {
        title: "Are you sure?",
        content: "You are about to remove the credential with the name ",
        authData: event?.friendlyName,
        contentEnd: ". Do you wish to continue?",
      },
    });
    this.ref.onClose.subscribe(async (result) => {
      if (result) {
        const revoked = await firstValueFrom(
          this.developerService.revokeApiKey(event.friendlyName)
        );
        //Removing item from the table and updating table
        this.credentials = this.credentials.filter(
          (item) => item.friendlyName !== event.friendlyName
        );
        this.credentialsSubject.next(this.credentials);
        this.toastService.showToastSuccess(
          "Remove credential - ",
          "Operation completed."
        );
      } else {
        this.toastService.showToastWarning(
          "Remove credential - ",
          " Operation cancelled."
        );
      }
    });
  }

  showApiCredentials(credentials: ICredentials, regenerated: boolean) {
    this.ref = this.dialogService.open(ShowApiCredentialsComponent, {
      contentStyle: { overflow: "auto" },
      baseZIndex: 10000,
      showHeader: false,
      width: "50%",
      data: {
        title:
          "We've " +
          (regenerated ? "regenerated" : "generated") +
          " your credentials",
        message: "Your ServiceHub Developer key is",
        key: credentials.key,
      },
    });
    this.ref.onClose.subscribe(async (result) => {
      this.loadCredentials();
    });
  }
}
