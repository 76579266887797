import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NotificationService } from "src/app/services/notification.service";
import { Notification } from "../../service-hub/notifications/models/notification-models";
import { OverlayPanel } from "primeng/overlaypanel";

@Component({
  selector: "app-menu-notifications",
  templateUrl: "./menu-notifications.component.html",
  styleUrls: ["./menu-notifications.component.scss"],
})
export class MenuNotificationsComponent implements OnInit, OnDestroy {
  @ViewChild("op") overlayPanel: OverlayPanel;
  notifications: Notification[] = [];
  fullNotificationCount: number;
  notificationCount: number;
  @Output() closeMenuEvent = new EventEmitter<any>();
  destroy$ = new Subject();

  constructor(
    private notificationService: NotificationService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.notificationService
      .getNotificationsUpdates()
      .pipe(takeUntil(this.destroy$))
      .subscribe((notifications: Notification[]) => {
        this.notifications = notifications;
        this.fullNotificationCount = notifications.length;
      });
    this.notificationService
      .getNotificationCountUpdate()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (notificationCount: number) =>
          (this.notificationCount = notificationCount)
      );
    this.notificationService.getUnreadNotifications();
  }

  openNotification(notification: Notification) {
    this.notificationService.openNotification(notification);
  }

  viewAllNotifications() {
    this.route.navigate(["/secure/account/notifications"]);
    this.overlayPanel.hide();
  }

  markAllAsRead() {
    this.notificationService.markAllAsRead();
    this.notifications.forEach((notification) => {
      this.notificationService.readNotification(notification.sysId);
    });
  }

  closeMenu() {
    this.closeMenuEvent.emit("closeMenu");
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
