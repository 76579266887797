import { Injectable } from "@angular/core";
import { ICookieSettings, IUserDetails } from "../models/account-models";
import { UserService } from "src/app/services/api/user/user.service";

import { CookieService } from "src/app/services/api/cookie/cookie.service";
import { firstValueFrom } from "rxjs";
import { addEditNumberFormatter } from "../../../../../helpers/mobile.helper";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  constructor(
    private userService: UserService,
    private CookieService: CookieService
  ) {}

  async loadProfile() {
    let profile = firstValueFrom(
      await this.userService.getContactDetail("Profile")
    );
    return profile;
  }

  async updateUserProfile(userProfile: IUserDetails) {
    if (userProfile.mobilePhone) {
      userProfile.mobilePhone = addEditNumberFormatter(userProfile.mobilePhone);
    }
    await firstValueFrom(this.userService.setContactDetail(userProfile));
  }

  async loadCookieSettings() {
    const response: ICookieSettings = await firstValueFrom(
      this.CookieService.GetUserCookiesPreferences()
    );

    return response;
  }

  async updateCookieSettings(cookieSettings) {
    await firstValueFrom(
      this.CookieService.updateUserCookiesPreferences(cookieSettings)
    );
  }
}
