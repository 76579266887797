import { Injectable } from "@angular/core";
import jspdf from "jspdf";
import domtoimage from "dom-to-image";
import { IDownloadFile, ITableToFile } from "../models/export-models";
import { ExportApiService } from "./api/export-api/export-api.service";
import { firstValueFrom } from "rxjs";

@Injectable()
export class ExportService {
  constructor(private ExportApiService: ExportApiService) {}

  async exportTableToFile(endpoint: string, body: ITableToFile) {
    const downloadFile: IDownloadFile = await firstValueFrom(
      this.ExportApiService.exportTableToFile(endpoint, body)
    );
    return downloadFile;
  }

  async exportTableToFileVPN(endpoint: string, body: ITableToFile) {
    const downloadFile = await firstValueFrom(
      this.ExportApiService.exportTableToFileVPN(endpoint, body)
    );
    return downloadFile;
  }

  fileExportSingle(endpoint: string, id: string, type: ExportType): void {
    firstValueFrom(
      this.ExportApiService.fileExportSingle(endpoint, id, type)
    ).then((data) => {
      this.downloadFile(data);
    });
  }

  // Calls the url given by the endpoint and applies the filters given on the body.
  fileExportList(endpoint: string, body: any, type: ExportType): void {
    body.ExportType = type;
    firstValueFrom(this.ExportApiService.fileExportList(endpoint, body)).then(
      (data) => {
        this.downloadFile(data);
      }
    );
  }

  downloadFile(
    downloadFile: IDownloadFile,
    fileExtension?: string,
    fileName?: string
  ) {
    if (downloadFile.byteArray.length == 0) {
      return false;
    }

    let file = this.b64toBlob(downloadFile.byteArray, downloadFile.contentType);

    let download = document.createElement("a");
    download.style.display = "none";
    document.body.appendChild(download);
    download.href = window.URL.createObjectURL(file);

    if (fileName && fileExtension) {
      download.download = `${fileName} report.${fileExtension.toLowerCase()}`;
    } else {
      download.download = downloadFile.fileName;
    }

    download.click();
    document.body.removeChild(download);
  }

  convertAndDownloadAsPDF(element, fileName, callback?) {
    domtoimage
      .toPng(element, { bgcolor: "#F6F5F8" })
      .then(function (dataUrl) {
        let img = new Image();
        img.src = dataUrl;

        let newImage = img.src;

        img.onload = function () {
          let pdfWidth = img.width * 0.26;
          let pdfHeight = (img.height + 400) * 0.26;

          let document =
            pdfWidth > pdfHeight
              ? new jspdf("l", "mm", [pdfWidth, pdfHeight])
              : new jspdf("p", "mm", [pdfWidth, pdfHeight]);

          let width = document.internal.pageSize.getWidth();
          let height = document.internal.pageSize.getHeight();

          document.addImage(newImage, "PNG", 0, 0, width, height);

          if (callback == null) {
            document.save(fileName + ".pdf");
          } else {
            callback(document);
          }
        };
      })
      .catch(function () {});
  }

  b64toBlob(b64Data, contentType): Blob {
    var sliceSize = 64;
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  getTokenUrl() {
    var token = JSON.parse(localStorage.getItem("token"));
    if (token == null || token.accessToken == null) {
      return "";
    } else {
      return (
        "accessToken=" +
        token.accessToken +
        "&refreshToken=" +
        token.refreshToken +
        "&expireDate=" +
        token.expireDate
      );
    }
  }
}

export enum ExportFileName {
  BillingCloud = "Billing - Cloud",
  BillingConnectivity = "Billing - Connectivity",
  BillingServices = "Billing - Services",
  BillingSecurity = "Billing - Security",
  BillingUCC = "Billing - UCC",
  broadband = "Broadband",
  changeRequests = "Change Requests",
  connectivityUsage = "Usage",
  devices = "Devices",
  Domain = "DNS",
  Domains = "Domains",
  incidents = "Incidents",
  orders = "Orders",
  serviceRequests = "Service Requests",
  Telephony = "Mobile",
  users = "Users",
}

export enum ExportType {
  Pdf = "Pdf",
  Excel = "Excel",
  Csv = "Csv",
}

// TODO what is this for? Still needed? Unsure if this references endpoints or not
export enum ExportTableEndpoint {
  Broadband = "Nosp/ExportUserInfo",
  ChangeRequests = "ChangeRequests/ExportChangeRequests",
  ConnectivityUsage = "Invoices/ExportCDRCalls",
  DeviceUtilisation = "ScienceLogic/ExportDeviceTable",
  Domain = "Domains/ExportDomain",
  Domains = "Domains/ExportDomains",
  Incidents = "Incidents/ExportIncidents",
  Orders = "Order/ExportOrders",
  ServiceRequests = "RequestedItems/ExportRequestedItems",
  Telephony = "Telephony/ExportMobilePhones",
  Users = "Users/GetUserListExport",
}

export class File {
  id: String;
  name: String;
}
