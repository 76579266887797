import { IDeviceColumn } from "src/app/components/service-hub/products-and-services/science-logic/cmdb-devices/shared/device-category-interfaces";

export const DeviceServerDetailColumns: IDeviceColumn[] = [
  {
    field: "name",
    header: "Name",
    display: { type: "text" },
    default: true,
    order: 1,
    source: "ServiceNow",
  },
  {
    field: "class",
    header: "Class",
    display: { type: "text" },
    filter: {
      type: "multiSelect",
      values: [],
    },
    default: true,
    source: "ServiceNow",
  },
  {
    field: "make",
    header: "Make",
    display: { type: "text" },
    default: false,
    source: "ScienceLogic",
  },
  {
    field: "model",
    header: "Model",
    display: { type: "text" },
    default: true,
    source: "ScienceLogic",
  },
  {
    field: "ipAddress",
    header: "IP Address",
    display: { type: "text" },
    default: true,
    source: "ServiceNow",
  },
  {
    field: "pollingStatus",
    header: "Polling Status",
    display: { type: "text", colors: { Active: "teal" } },
    filter: {
      type: "multiSelect",
      values: [],
    },
    default: true,
    source: "ScienceLogic",
  },
  {
    field: "available",
    header: "Availability",
    display: {
      type: "chip",
      colors: { Available: "success", Unknown: "dark" },
    },
    filter: {
      type: "multiSelect",
      values: [],
    },
    default: true,
    source: "ScienceLogic",
  },
  {
    field: "cpuUsage",
    header: "CPU Usage",
    display: {
      type: "chipMetric",
    },
    filter: {
      type: "multiSelectMetric",
      values: [
        {
          label: "<= 75%",
          value: "<=75",
        },
        {
          label: "76% to 94%",
          value: ">=76&<95",
        },
        {
          label: "> 95%",
          value: ">=95",
        },
      ],
    },
    default: false,
    source: "ScienceLogic",
  },
  {
    field: "memoryUsage",
    header: "Memory Usage",
    display: {
      type: "chipMetric",
    },
    filter: {
      type: "multiSelectMetric",
      values: [
        {
          label: "<= 75%",
          value: "<=75",
        },
        {
          label: "76% to 94%",
          value: ">=76&<95",
        },
        {
          label: "> 95%",
          value: ">=95",
        },
      ],
    },
    default: false,
    source: "ScienceLogic",
  },
  {
    field: "antivirusName",
    header: "Antivirus Software",
    display: { type: "text" },
    default: false,
    source: "ScienceLogic",
  },
  {
    field: "antivirusVersion",
    header: "Antivirus Version",
    display: { type: "text" },
    default: false,
    source: "ScienceLogic",
  },
  {
    field: "antivirusLastUpdated",
    header: "Antivirus Last Updated",
    display: { type: "text" },
    default: false,
    source: "ScienceLogic",
  },
];
