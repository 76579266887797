import { Inject, Injectable } from "@angular/core";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";

@Injectable({
  providedIn: "root",
})
export class HelpArticleService {
  constructor(private apiRequest: ApiRequest) {}

  getSupportCateogoryContent(filter: any) {
    // TODO: check typo and fix if needed
    const requestProps: IRequestOptions = {
      endpoint: "HelpArticle/GetSupportCateogoryContent",
      body: {
        Filter: filter,
      },
    };
    return this.apiRequest.request(requestProps);
  }
}
