import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

export type DeleteDnsRecordData = {
  domain: string;
  subDomain: string;
  type: string;
  ttl: number;
  value: string[];
};

@Component({
  selector: "app-delete-dns",
  templateUrl: "./delete-dns.component.html",
  styleUrls: ["./delete-dns.component.scss"],
})
export class DeleteDnsComponent implements OnInit {
  request: DeleteDnsRecordData;

  constructor(
    private dialogConfig: DynamicDialogConfig<DeleteDnsRecordData>,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.request = this.dialogConfig.data;
  }

  deleteRecord(): void {
    this.ref.close(true);
  }

  close(): void {
    this.ref.close(false);
  }
}
