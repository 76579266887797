import { Component, Injectable, NgModule } from "@angular/core";
import { MessageService } from "primeng/api";

/**
 * @deprecated
 * Use the global toast service instead.
 */
@Injectable({ providedIn: "root" })
export class ToastService {
  constructor(private messageService: MessageService) {}

  /**
   * @deprecated
   * Use the global toast service instead.
   */
  showToastWarning(summary: string, detail: string) {
    this.messageService.add({ severity: "warn", summary, detail });
  }

  /**
   * @deprecated
   * Use the global toast service instead.
   */
  showToastSuccess(summary: string, detail: string) {
    this.messageService.add({ severity: "success", summary, detail });
  }

  /**
   * @deprecated
   * Use the global toast service instead.
   */
  showToastInfo(summary: string, detail: string) {
    this.messageService.add({ severity: "info", summary, detail });
  }

  /**
   * @deprecated
   * Use the global toast service instead.
   */
  showToastError(summary: string, detail: string) {
    this.messageService.add({ severity: "error", summary, detail });
  }
}