import { Inject, Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { DOCUMENT } from "@angular/common";
import { IUserProfile } from "src/app/components/service-hub/company/users/shared/user-models";
import { environment } from "src/environments/environment";

const microserviceHosts: string[] = Object.values(environment.microservices);

@Injectable()
export class CustomerPortalApiInterceptor implements HttpInterceptor {
  constructor(@Inject(DOCUMENT) protected document: Document) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes(environment.apiUrl) ||
      request.url.includes(environment.apiUrlVpc) ||
      microserviceHosts.some((host) => request.url.includes(host))
    ) {
      let newHeaders = new HttpHeaders();
      newHeaders = newHeaders.set("URL", this.document.location.href);
      if (JSON.parse(request?.body)) {
        newHeaders = newHeaders.set(
          "Content-Type",
          "application/json; charset=utf-8"
        );
      }
      const token = localStorage.getItem(environment.localStorage.auth0IdToken);
      if (token) {
        newHeaders = newHeaders.set("Authorization", "Bearer " + token);
      }
      if (microserviceHosts.some((host) => request.url.includes(host))) {
        newHeaders = newHeaders.set("x-auth-type", "JWT");
      }
      // TODO refactor to use AuthService?
      let impersonateUserLocalStorage: string = localStorage.getItem(
        environment.localStorage.serviceHubUserImpersonation
      );
      if (impersonateUserLocalStorage) {
        const impersonateUser: IUserProfile = JSON.parse(
          impersonateUserLocalStorage
        );
        if (impersonateUser) {
          newHeaders = newHeaders.set("Impersonate", impersonateUser.sysId);
        }
      }
      request = request.clone({
        headers: newHeaders,
      });
    }
    return next.handle(request);
  }
}
