import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { DatePipe } from "@angular/common";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { CallAmount } from "src/app/components/misc/filters/filters";
import { OutageService } from "src/app/services/api/outage/outage.service";
import { MenuItem } from "primeng/api";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { IdetailsColumn, detailsColumns } from "../shared/details.models";
import { ThisReceiver } from "@angular/compiler";

@Component({
  selector: "information-details",
  templateUrl: "information-details.component.html",
  styleUrls: [
    "information-details.component.scss",
    "../../../../global/menu/menu.component.scss",
  ],
})
export class InformationDetailsComponent implements OnInit {
  outageId: any;
  outage: any;
  pageData: any;
  currentPerPage: number = CallAmount;
  currentPage: number = 0;
  outageCategory: any;
  updatesSubject = new BehaviorSubject(null);
  updates$ = this.updatesSubject.asObservable();
  updates: Array<any>;
  loading: boolean = false;

  cols!: IdetailsColumn[];
  tableColumn: IdetailsColumn[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private outageService: OutageService,
    private breadcrumbService: BreadcrumbService
  ) {}
  ngOnInit() {
    this.tableColumn = detailsColumns;
    this.cols = this.tableColumn;

    this.activatedRoute.data.subscribe((v) => (this.pageData = v));
    this.activatedRoute.params.subscribe(async (params: Params) => {
      this.outageId = params["id"];
      this.outageCategory = params["type"];
      await this.loadOutage();
      if (this.outage) {
        const breadcrumbs: MenuItem[] = [
          {
            label: "Service Status",
            routerLink: "/secure/products-and-services/services/status",
          },
          { label: this.outage?.name },
        ];
        this.breadcrumbService.setBreadcrumbs(breadcrumbs);
      }
    });
  }

  getColor(value: string): string {
    switch (value) {
      case "Closed Complete":
        return "closed-complete";
      case "Open":
        return "open";
      default:
        return "closed-complete";
    }
  }

  async loadOutage() {
    this.loading = true;
    if (this.pageData.authorised) {
      this.outage = await firstValueFrom(
        this.outageService.getAuthorisedOutage(this.outageId)
      );
    } else {
      this.outage = await firstValueFrom(
        this.outageService.getOutage(this.outageId)
      );
    }
    this.outage.type = this.getServiceLabel(this.outage);
    let updates = this.getUpdatedComments();
    this.updatesSubject.next(updates);
    this.outage.updates = updates;
    this.loading = false;
  }

  getUpdatedComments() {
    let updates = [];

    this.outage.updates.forEach((update) => {
      if (update) {
        // Split into raised date and raised by using first instance of "-".
        let endOfDateRaised = update.indexOf("-");
        let startOfUpdates = update.indexOf("(");
        let endOfTitle = update.indexOf(")");

        let updateSplit = [
          update.slice(0, endOfDateRaised),
          update.slice(endOfDateRaised + 1, startOfUpdates),
          update.slice(endOfTitle + 1),
        ];

        // Split into title and content using first instance of ")".
        updates.push({
          date: updateSplit[0],
          raisedBy: updateSplit[1],
          content: updateSplit[2],
        });
      }
    });
    return updates;
  }

  getServiceLabel(outage) {
    if (outage.type == "") {
      return "Good";
    } else if (outage.type == "Outage") {
      return "Outage";
    } else if (outage.type == "Degradation") {
      return "Degradation";
    } else if (outage.type == "Intermittent Service") {
      return "Intermittent Service";
    } else {
      var datePipe = new DatePipe("en-US");
      var today = datePipe.transform(Date.now(), "dd/MM/yyyy");
      var start = outage.start;
      if (
        outage.type == "Planned Maintenance" &&
        outage.state == "Closed Complete"
      )
        return "Completed Maintenance";
      if (start < today) {
        return "Planned Maintenance";
      } else {
        return "Upcoming Maintenance";
      }
    }
  }

  get totalColumns() {
    return this.tableColumn.length + 1;
  }
}
