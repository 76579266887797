import { Component, ViewEncapsulation } from "@angular/core";
import { Validators } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { IDomainRecord } from "src/app/components/misc/pop-up/shared/record-model";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { firstValueFrom } from "rxjs/internal/firstValueFrom";
import { DomainService } from "src/app/services/api/domain/domain.service";
import { Message } from "primeng/api";
import { cloneDeep } from "lodash-es";
import {
  DnsRecord,
  RecordsSource,
} from "src/app/components/service-hub/products-and-services/domains/domain/domain.component";
import {
  CheckExistingRecord,
  CheckExistingRecordRoute53,
  ConvertTTLDisplayToNumber,
  CreateLandscapeRecord,
  CreateRoute53Record,
  IsValidDnsRecord,
} from "src/app/helpers/dns.helper";

type AddEditMethod = "Add" | "Edit";

@Component({
  selector: "add-edit-dns-record",
  templateUrl: "add-edit-dns-record.component.html",
  styleUrls: ["add-edit-dns-record-component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddEditDNSRecordComponent {
  loading: boolean = false;
  errorMessage: Message[] | undefined;
  domainSysId: string;
  domainName: string;
  landscapeResponse: any;

  existingRecords: Array<any>;
  isReverse: boolean;

  defaultTtl: number = 86400;
  ttlList: { label: string; buttonLabel: string; value: number }[] = [
    {
      label: `${ConvertTTLDisplayToNumber("5m")} (5m)`,
      buttonLabel: `5m`,
      value: ConvertTTLDisplayToNumber("5m"),
    },
    {
      label: `${ConvertTTLDisplayToNumber("15m")} (15m)`,
      buttonLabel: `15m`,
      value: ConvertTTLDisplayToNumber("15m"),
    },
    {
      label: `${ConvertTTLDisplayToNumber("1h")} (1h)`,
      buttonLabel: `1h`,
      value: ConvertTTLDisplayToNumber("1h"),
    },
    {
      label: `${ConvertTTLDisplayToNumber("4h")} (4h)`,
      buttonLabel: `4h`,
      value: ConvertTTLDisplayToNumber("4h"),
    },
    {
      label: `${ConvertTTLDisplayToNumber("12h")} (12h)`,
      buttonLabel: `12h`,
      value: ConvertTTLDisplayToNumber("12h"),
    },
    {
      label: `${ConvertTTLDisplayToNumber("1d")} (1d)`,
      buttonLabel: `1d`,
      value: ConvertTTLDisplayToNumber("1d"),
    },
    {
      label: `${ConvertTTLDisplayToNumber("3d")} (3d)`,
      buttonLabel: `3d`,
      value: ConvertTTLDisplayToNumber("3d"),
    },
    {
      label: `${ConvertTTLDisplayToNumber("1w")} (1w)`,
      buttonLabel: `1w`,
      value: ConvertTTLDisplayToNumber("1w"),
    },
  ];
  typeList: string[] = ["A", "AAAA", "CNAME", "MX", "SRV", "TXT"];

  addDNSFormControl = this.fb.group({
    name: ["", [Validators.required]],
    ttl: [this.defaultTtl, Validators.required],
    type: ["", [Validators.required]],
    value: ["", [Validators.required]],
  });

  recordsSource: RecordsSource;

  method: AddEditMethod = "Add";

  record: DnsRecord;

  route53RecordConflict: DnsRecord;

  constructor(
    private fb: FormBuilder,
    public ref: DynamicDialogRef,
    private domainService: DomainService,
    private dialogConfig: DynamicDialogConfig
  ) {
    this.domainName = this.dialogConfig?.data?.domainName;
    this.domainSysId = this.dialogConfig?.data?.domainSysId;
    this.landscapeResponse = cloneDeep(
      this.dialogConfig?.data?.landscapeResponse
    );
    this.recordsSource = this.dialogConfig?.data?.recordsSource;
    this.existingRecords = this.dialogConfig?.data?.existingRecords;
    this.record = this.dialogConfig?.data?.record;
    if (this.record) {
      this.setEditRecord(this.record);
    }
    this.isReverse = this.dialogConfig?.data?.isReverse;
    if (this.isReverse) this.typeList.push("PTR");
  }

  ngOnInit() {}

  close(): void {
    this.ref.close();
  }

  async submit(
    method: AddEditMethod,
    existingRecord: DnsRecord | null,
    domainName: string,
    records: DnsRecord[]
  ) {
    this.loading = true;
    try {
      let values = this.addDNSFormControl.value;
      if (method === "Edit") {
        values = {
          ...values,
          name: existingRecord.name,
          type: existingRecord.type,
        };
      }

      const record: IDomainRecord = CreateRoute53Record(
        this.domainName,
        values
      );
      if (
        method === "Add" &&
        this.recordsSource === "landscape" &&
        CheckExistingRecord(records, domainName, record)
      ) {
        throw Error("Record already found");
      }
      if (method === "Add" && this.recordsSource === "route53") {
        const route53existingRecord = cloneDeep(
          CheckExistingRecordRoute53(records, domainName, record)
        );
        if (route53existingRecord) {
          route53existingRecord.value = [
            ...route53existingRecord.value,
            ...record.value,
          ];
          this.route53RecordConflict = route53existingRecord;
          this.loading = false;
          return;
        }
      }
      // TODO: remove this after retirement of landscape
      if (this.recordsSource === "landscape") {
        const landscapeAddRecord = CreateLandscapeRecord(
          domainName,
          record,
          existingRecord?.landscapeRecord,
          this.isReverse,
          method === "Edit" ? "MODIFY" : "ADD"
        );
        if (
          !IsValidDnsRecord(landscapeAddRecord.type, landscapeAddRecord.value)
        ) {
          throw Error("Invalid DNS Record");
        }
        this.landscapeResponse.updates = [landscapeAddRecord];
        await firstValueFrom(
          this.domainService.updateDomain(this.landscapeResponse)
        );
      }
      if (this.recordsSource === "route53") {
        if (method === "Add") {
          await firstValueFrom(
            this.domainService.addDomainRecord(this.domainSysId, record)
          );
        }
        if (method === "Edit") {
          await firstValueFrom(
            this.domainService.editDomainRecord(this.domainSysId, record)
          );
        }
      }
      this.ref.close(true);
    } catch (error) {
      console.error(error);
      this.handleError(error);
    }
    this.loading = false;
  }

  setEditRecord(record: DnsRecord) {
    this.record = record;
    this.route53RecordConflict = undefined;
    this.method = "Edit";
    this.addDNSFormControl.controls.name.setValue(record.name);
    this.addDNSFormControl.controls.name.disable();
    this.addDNSFormControl.controls.ttl.setValue(record.ttl);
    this.addDNSFormControl.controls.type.setValue(record.type);
    this.addDNSFormControl.controls.type.disable();
    this.addDNSFormControl.controls.value.setValue(record.value.join("\n"));
  }

  setTtl(value) {
    this.addDNSFormControl.controls.ttl.setValue(value);
  }

  handleError(error) {
    let errorMessage: string = "";
    if (error?.message) {
      errorMessage = error?.message;
    }
    if (error?.error?.message) {
      errorMessage = error?.error?.message;
    }
    if (typeof error?.error === "string") {
      errorMessage = error?.error;
    }
    this.errorMessage = [
      {
        severity: "error",
        summary: "Error",
        detail:
          errorMessage.startsWith("[") && errorMessage.endsWith("]")
            ? errorMessage.slice(1, -1)
            : errorMessage,
      },
    ];
  }
}
