import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

export type DigitalSpaceTabMenuItem = { label: string; hovered: boolean };

@Component({
  selector: "digital-space-tab-menu",
  templateUrl: "./digital-space-tab-menu.component.html",
  styleUrls: ["./digital-space-tab-menu.component.scss"],
})
export class DigitalSpaceTabMenuComponent implements OnInit {
  @Input() tabs: DigitalSpaceTabMenuItem[] = [];
  @Input() activeTab: DigitalSpaceTabMenuItem;
  @Input() disabled: boolean = false;
  @Output() tabChange: EventEmitter<DigitalSpaceTabMenuItem> =
    new EventEmitter<DigitalSpaceTabMenuItem>();

  constructor() {}

  ngOnInit() {
    if (this.tabs?.length && !this.activeTab) {
      this.activeTab = this.tabs[0];
    }
  }

  switchTab(tab: DigitalSpaceTabMenuItem) {
    if (tab === this.activeTab) {
      return;
    }
    this.activeTab = tab;
    this.tabChange.emit(this.activeTab);
  }
}
