import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "domain-chip",
  templateUrl: "./domain-chip.component.html",
  styleUrls: ["./domain-chip.component.scss"],
})
export class DomainChipComponent implements OnInit {
  @Input() domainStatus: any;
  color: string;
  text: string;

  ngOnInit(): void {
    this.hostedZoneStatus(this.domainStatus);
  }

  ngOnChanges(): void {
    this.hostedZoneStatus(this.domainStatus);
  }

  hostedZoneStatus(domainStatus): void {
    switch (domainStatus) {
      case "RESUMED":
        this.text = "Active";
        this.color = "bg-teal-300";
        break;
      case "SUSPENDED":
        this.text = "Suspended";
        this.color = "bg-grey-200";
        break;
      case "RESUME_IN_PROGRESS":
        this.text = "In Progress";
        this.color = "bg-pink-500";
        break;
      case "SUSPEND_IN_PROGRESS":
        this.text = "In Progress";
        this.color = "bg-pink-500";
      default:
        this.text = "In Progress";
        this.color = "bg-pink-500";
        break;
    }
  }
}
