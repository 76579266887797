import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { OrderService } from "src/app/services/api/order/order.service";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import {
  GetOrderCharacteristics,
  OrderCharacteristicsColumns,
} from "../shared/order-characteristics.models";
import { TableColumn } from "src/app/models/table";
import { cloneDeep } from "lodash-es";
import { Table } from "primeng/table";
import { OrderLineItemService } from "src/app/services/api/order-line-item/order-line-item.service";

@Component({
  selector: "app-order-characteristics",
  templateUrl: "./order-characteristics.component.html",
  styleUrls: ["./order-characteristics.component.scss"],
})
export class OrderCharacteristicsComponent implements OnInit {
  @Input() sysId: string = "";
  @ViewChild("dataTable", { static: true }) dataTable: Table;
  orderCharacteristicsSubject = new BehaviorSubject<
    Array<GetOrderCharacteristics>
  >([]);
  orderCharacteristics$ = this.orderCharacteristicsSubject.asObservable();
  cols: TableColumn[] = cloneDeep(OrderCharacteristicsColumns);
  constructor(private orderLineItemService: OrderLineItemService) {}

  ngOnInit() {
    this.getGetOrderCharacteristics();
  }

  async getGetOrderCharacteristics() {
    this.dataTable.loading = true;
    const response = await firstValueFrom(
      this.orderLineItemService.getCharacteristics(this.sysId)
    );
    this.orderCharacteristicsSubject.next(response);
    this.dataTable.loading = false;
  }
}
