import { TableColumn } from "src/app/models/table";

enum costCentreColumns {
  productCategory = "productCategory",
  productGroup = "ProductGroup",
  cli = "cli",
  costCentre = "costCentre",
  siteUser = "siteUser",
}

export interface IEditCostCenterColumn {
  field: string;
  header?: string;
  default: boolean;
  display?: any;
  checkBoxVisible?: boolean;
  showSortIcon: boolean;
  disabled?: boolean;
}

export const editCostCenterColumns: TableColumn[] = [
  {
    field: "productGroup",
    header: "Practice",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: costCentreColumns.productGroup,
  },
  {
    field: "productCategory",
    header: "Category",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: costCentreColumns.productCategory,
  },
  {
    field: "cli",
    header: "CLI",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: costCentreColumns.cli,
  },
  {
    field: "costCentre",
    header: "Cost Centre",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: costCentreColumns.costCentre,
  },
  {
    field: "siteUser",
    header: "User",
    display: { type: "text" },
    filter: { type: "text", values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: costCentreColumns.siteUser,
  },
];
