import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { FileUploadService } from "src/app/services/file-upload.service";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import {
  TicketData,
  RaiseHeading,
  IncidentSeverity,
  IncidentResponse,
} from "src/app/components/service-hub/tickets/shared/incident-model";
import { FileUpload } from "src/app/components/misc/file-upload/file-upload";
import { LocationDialogComponent } from "src/app/components/service-hub/tickets/raise/location-dialog/location-dialog.component";
import { IncidentService } from "src/app/services/api/incident/incident.service";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { FileManagerService } from "src/app/components/misc/pop-up/file-manager/file-manager.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { MenuItem } from "primeng/api";
import { IncidentIssues } from "../models/identify-models";

@Component({
  selector: "create-incident",
  templateUrl: "./create-incident.component.html",
  styleUrls: ["./create-incident.component.scss"],
  providers: [DialogService],
})
export class CreateIncidentComponent {
  resetSubject = new BehaviorSubject<boolean | null>(null);
  reset$ = this.resetSubject.asObservable();
  showCreateIncident: boolean = false;
  reset: boolean = false;

  severities: IncidentSeverity[] = [
    { name: "Site Impacted", impact: "3", urgency: "3" },
    { name: "Site Down", impact: "2", urgency: "2" },
    { name: "Multiple Sites Impacted", impact: "2", urgency: "3" },
    { name: "Multiple Sites Down", impact: "1", urgency: "2" },
    { name: "Primary System Impacted", impact: "2", urgency: "2" },
    { name: "Primary System Down", impact: "1", urgency: "1" },
  ];

  raiseIncidentControl = this.fb.group({
    shortDescription: ["", [Validators.required]],
    description: ["", [Validators.required]],
    severity: new FormControl<IncidentSeverity | null>(null, [
      Validators.required,
    ]),
    location: [""],
    locationSysId: [""],
  });

  resetChild = false;
  uploadedFiles: FileUpload[];
  submit: boolean = false;
  showLoader: boolean = false;
  response: IncidentResponse;
  fileUploadErrors: object = {};
  textAlert: string = "";
  ref: DynamicDialogRef;

  identifyFormData: IncidentIssues;

  get headingText(): string {
    return this.showCreateIncident
      ? RaiseHeading.incident
      : RaiseHeading.identify;
  }

  constructor(
    private incidentService: IncidentService,
    private router: Router,
    private fileUploadService: FileUploadService,
    private fb: FormBuilder,
    private clipboard: Clipboard,
    private dialogService: DialogService,
    private fileManagerService: FileManagerService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.fileUploadService.filesToTransmit.subscribe((data: FileUpload[]) => {
      this.uploadedFiles = data;
    });
  }

  ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      { label: "Raise a Ticket", routerLink: "/secure/tickets/raise" },
      { label: "Raise an Incident" },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }

  async submitIncident(): Promise<void> {
    this.showLoader = true;
    this.submit = true;
    const incident: TicketData = {
      shortDescription: this.raiseIncidentControl.value.shortDescription,
      description: this.raiseIncidentControl.value.description,
      files: this.uploadedFiles,
      location: this.raiseIncidentControl.value.locationSysId,
      tableName: "incident",
      serviceComponent: this.identifyFormData.subCategory,
      symptom: this.identifyFormData.symptom,
      impact: this.raiseIncidentControl.value.severity?.impact,
      urgency: this.raiseIncidentControl.value.severity?.urgency,
    };

    this.response = await firstValueFrom(
      this.incidentService.createOne(incident)
    );

    this.fileUploadErrors = this.uploadedFiles?.filter(
      (f) => f.errorMessage != ""
    );
    this.submit = true;
    this.showLoader = false;
  }

  viewIncident(): void {
    this.router.navigateByUrl("secure/tickets/incident/" + this.response.sysId);
  }

  raiseAnotherIncident(): void {
    this.submit = false;
    this.uploadedFiles = [];
    this.raiseIncidentControl.reset();
    this.showCreateIncident = false;
    this.triggerReset();
  }

  async openLocationDialog(): Promise<void> {
    this.ref = this.dialogService.open(LocationDialogComponent, {
      width: "70%",
      showHeader: false,
    });

    let locationResponse = await firstValueFrom(this.ref.onClose);
    if (locationResponse) {
      this.raiseIncidentControl
        .get("location")
        .setValue(locationResponse.fullName);
      this.raiseIncidentControl
        .get("locationSysId")
        .setValue(locationResponse.sysId);
    }
  }

  removeLocation(): void {
    this.raiseIncidentControl.get("location").setValue(null);
    this.raiseIncidentControl.get("locationSysId").setValue(null);
  }

  copyIncidentNumber(): void {
    this.clipboard.copy(this.response?.number);
    this.textAlert = "Incident number copied to clipboard";
  }

  goBackButton(): void {
    this.showCreateIncident = false;
  }

  triggerReset() {
    this.resetSubject.next(true);
  }

  onFormComplete(data: IncidentIssues): void {
    this.showCreateIncident = true;
    if (data) {
      this.identifyFormData = data;
    }
  }
}
