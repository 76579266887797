import { Injectable } from "@angular/core";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";
import { ITableToFile } from "src/app/models/export-models";
import { ServiceNowFilterQuery } from "src/app/models/servicenow.model";

@Injectable({
  providedIn: "root",
})
export class ProductAPIService {
  constructor(private apiRequest: ApiRequest) {}

  getProduct(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: "Product/GetProduct",
      body: request,
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }

  getProductsAdditionalData(productResults: any, filter: any, siteId: string) {
    const requestProps: IRequestOptions = {
      endpoint: "Product/GetProductsAdditionalData",
      body: {
        products: productResults,
        filter: filter,
      },
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }

  GetProduct(
    categoryID: string,
    filter: any,
    pageNumbner: number,
    pageSize: number,
    pageNation: any
  ) {
    const requestProps: IRequestOptions = {
      endpoint: "Product/GetProduct",
      body: {
        SiteId: categoryID,
        filter: filter,
        currentPage: pageNumbner,
        amount: pageSize,
        pagination: pageNation,
      },
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }

  GetProductsAdditionalData(
    product: any,
    filter: any,
    siteId: string,
    exportColums: any,
    exportValue: boolean
  ) {
    const requestProps: IRequestOptions = {
      endpoint: "Product/GetProductsAdditionalData",
      body: {
        products: product,
        filter: filter,
        siteId: siteId,
        exportColums: exportColums,
        export: exportValue,
      },
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }
}
