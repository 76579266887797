import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Table } from "primeng/table";
import { BehaviorSubject, Observable, Subject, takeUntil } from "rxjs";
import {
  IDeviceColor,
  IDeviceColumn,
} from "../shared/device-category-interfaces";
import { ExportTable } from "../shared/export-devices";
import {
  ResetSelectedValues,
  SetMultiSelectColumnValues,
  SetSelectedColumns,
  SortMultipleColumns,
  TableReset,
} from "../../../../../../helpers/table.helper";
import { Router } from "@angular/router";
import { DevicesService } from "src/app/services/devices/devices.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { FilterService } from "primeng/api";
import { IUserProfile } from "src/app/components/service-hub/company/users/shared/user-models";
import { TableColumn } from "src/app/models/table";

@Component({
  selector: "app-device-category-table",
  templateUrl: "./device-category-table.component.html",
  styleUrls: ["./device-category-table.component.scss"],
  // providers: [DialogService],
})
export class DeviceServerTableComponent implements OnInit {
  @ViewChild("dt1") dt1: Table;
  @Input() deviceCategory$: Observable<string>;
  deviceCategory: string;
  private ngUnsubscribe = new Subject<void>();

  devicesSubject = new BehaviorSubject<any[]>([]);
  devices$ = this.devicesSubject.asObservable();

  user: IUserProfile;
  userCanAccessObservability: boolean;

  loading: boolean = true;

  deviceCategoryDetails: any;

  cols!: IDeviceColumn[];

  _selectedColumns!: IDeviceColumn[];

  defaultSelectedColumns: string[] = [];

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  get totalSelectedColumns() {
    return this.selectedColumns.length;
  }

  selectedValues: { [key: string]: any[] } = {};

  exportOptions = [
    {
      label: "All",
      value: "all",
      command: () =>
        ExportTable(
          this.dt1,
          this.devicesSubject.value,
          this._selectedColumns,
          "all"
        ),
    },
    {
      label: "In View",
      value: "in_view",
      command: () =>
        ExportTable(
          this.dt1,
          this.devicesSubject.value,
          this._selectedColumns,
          "in_view"
        ),
    },
  ];

  initialSortColumns: any[] | undefined = undefined;
  loadDateTime;

  // ref: DynamicDialogRef;

  filters = [];

  constructor(
    private devicesService: DevicesService,
    private authService: AuthService,
    public router: Router,
    private filterService: FilterService // public dialogService: DialogService
  ) {}

  async ngOnInit() {
    this.deviceCategory$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((newDeviceCategory) => {
        this.deviceCategory = newDeviceCategory;
        if (this.deviceCategory) {
          this.loadDevices();
        }
      });
    this.user = await this.authService.getUserOrImpersonatedUser();
    this.userCanAccessObservability =
      this.user.uTimicoPortalPermissions.includes(
        "DEVICES_GRAPHICAL_READ"
      );
  }

  getDeviceServiceRequest(category: string) {
    if (category.includes("paused")) {
      return this.devicesService.getDevicesPaused(category);
    }
    if (category.includes("unreachable")) {
      return this.devicesService.getDevicesUnreachable(category);
    }
    if (category.includes("detailed")) {
      return this.devicesService.getDevicesDetailed(category);
    }
    return this.devicesService.getDevices(category);
  }

  async loadDevices() {
    this.loading = true;
    const { devices, columns } = await this.getDeviceServiceRequest(
      this.deviceCategory
    );
    this.loadDateTime = new Date();
    this.cols = SetMultiSelectColumnValues(columns, devices);
    this.defaultSelectedColumns = this.cols
      .filter((column) => column?.default)
      .map((column) => column?.field);
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      this.defaultSelectedColumns
    );
    this.initialSortColumns = SortMultipleColumns(this.cols);
    this.devicesSubject.next(devices);
    this.dt1?.reset();
    this.loading = false;
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = this.cols
  ) {
    this.loadDevices();
    TableReset(table, columns, {
      firstLoad,
    });
  }

  defaultExport() {
    ExportTable(
      this.dt1,
      this.devicesSubject.value,
      this._selectedColumns,
      "all"
    );
  }

  getColor(colors: IDeviceColor[], value: string) {
    return colors[value] ?? null;
  }

  getColorMetric(value: number) {
    if (value >= 95) return "danger";
    if (value >= 76 && value <= 94) return "warning";
    return "success";
  }

  navigateToDevice(device: any) {
    const [category, subCategory] =
      this.devicesService.getDeviceCategoryAndSubCategory(this.deviceCategory);
    device.category = category;
    device.subCategory = subCategory;
    localStorage.setItem("science-logic-device", JSON.stringify(device));
    this.router.navigate(["/secure/products-and-services/monitoring/reports"]);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openScienceGraph(): void {
    this.router.navigate([
      "/secure/products-and-services/monitoring/devices/science-logic-visualization/last-24-hours",
    ]);
  }
}
